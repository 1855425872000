import { CHANNELS } from '../../../constants';

export const isRichTextEditor = (channelType: string): boolean => {
  const channelList = ['web', 'whatsapp', 'ms_teams', 'faq'];
  return channelList.includes(channelType);
};

const getTextNodes = (element: Node): Node[] => {
  if (element.nodeType === Node.TEXT_NODE) {
    return [element];
  } else {
    const textNodes: Node[] = [];
    element.childNodes.forEach(childNode => {
      textNodes.push(...getTextNodes(childNode));
    });
    return textNodes;
  }
};
export const stripHTMLTags = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const textNodes: Node[] = getTextNodes(doc.body);
  const cleanText = textNodes.map(node => node.textContent).join(' ');
  return cleanText.trim();
};

export const getChannelName = (label: string): string | null =>
  CHANNELS.find(item => item.value === label)?.text || null;
