import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { PresentationForm } from '../PresentationForm';
import { useCreate } from '../../../../hooks/useCreate';
import { KnowledgePresentationService } from '../../../../services/knowledge/presentation';
import { useIntegrations } from '../../../../state/integrations';
import { unexpectedError } from '../../../../utils/notification';
import { IPresentationValues } from '../../types';
import { ensure } from '../../../../utils/arrays';
import { ActionButton } from '../../../../components/ActionButton';

export const CreatePresentation: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const { notification } = App.useApp();
  const { knowledge } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [KnowledgePresentationService.queryKey],
    KnowledgePresentationService.create,
  );
  const onSubmit = async (data: Partial<IPresentationValues>): Promise<void> => {
    data.synchronisations_attributes = data.synchronisations_attributes?.map(el => ({
      destination_entity_id: el as unknown as string,
      project_id: ensure(knowledge?.externalId),
    }));
    await createItem(
      { values: data, data: knowledge },
      {
        onSuccess: () => navigate(-1),
        onError: data => {
          if (data?.response?.data?.title[0] === 'has already been taken')
            return notification['error']({ message: t('presentation_name_exists') });
          else return notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: IPresentationValues = {
    title: '',
    synchronisations_attributes: [],
    label_list: [],
    content_entities_attributes: { web: { payload: '', is_using_default_channel_content: false } },
  };

  return (
    <PresentationForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
    />
  );
};
