import styled from '@emotion/styled';
import { Alert } from 'antd';

export const StyledAlert = styled(Alert)`
  font-weight: 600;
  justify-content: center;
  .ant-alert-content {
    flex: 0 1 auto;
  }
  .ant-alert-close-icon {
    position: absolute;
    right: 18px;
    top: 14px;
  }
  .ant-alert-message {
    color: ${props => props.type === 'error' && 'white'};
  }
  svg {
    fill: ${props => props.type === 'error' && 'white'};
  }
`;
