import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IAutomationProfile,
  IAutomationProfileValues,
} from '../../pages/AI/Automation/AutomationProfiles/types';
import { IAiIntegrationUrl } from '../../state/integrations';
import { sortById } from '../../utils/sorting';

const queryKey = 'dial-plans';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomationProfile[]> => {
  const response = await integrationQuery(
    `/api/dial_plans?includes[]=dialogflow_agent&includes[]=text_to_speech_config&includes[]=dialogflow_conversation_profile`,
    {
      apiData,
      signal,
    },
  );
  return sortById(response.dial_plans);
};

const create = async ({
  data,
  apiData,
}: { data: IAutomationProfileValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/dial_plans`, {
    method: 'POST',
    data: { data: { type: 'dial_plans', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomationProfile> => {
  const response = await integrationQuery(
    `/api/dial_plans/${id}?includes[]=dialogflow_agent&includes[]=text_to_speech_config&includes[]=inbound_phone_numbers&includes[]=contexts&includes[]=voice_recording_config&includes[]=audio_config`,
    {
      apiData,
      signal,
    },
  );
  return response?.dial_plan;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IAutomationProfileValues>;
}): Promise<IAutomationProfile> => {
  return integrationQuery(`/api/dial_plans/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'dial_plans',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/dial_plans/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const DialPlansService = {
  queryKey,
  get,
  create,
  find,
  update,
  remove,
};
