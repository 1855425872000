import styled from '@emotion/styled';
import { Button } from 'antd';

export const ListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & svg:not(:last-of-type) {
    width: auto;
    margin-right: 4px;
  }
`;

export const LabelItem = styled.span`
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryColor};
  background: #f2f0ff;
  border: 1px solid ${({ theme }) => theme.primaryColor};
`;

export const NumberBadge = styled(Button)`
  flex: 0 0 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: ${({ theme }) => theme.primaryColor};
  background-color: #e2dff7;
  border-radius: 50%;
  :hover {
    color: #fff;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const DestinationWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    flex: 0 0 14px;
    margin-right: 2px;
  }
`;
export const RequiredAsterisk = styled.span`
  &&::before {
    color: ${props => props.theme.errorColor};
    margin-right: 4px;
    font-size: 13px;
    content: '*';
  }
`;
