import { omit } from 'ramda';
import { projectProducts } from '../pages/Projects/constants';
import {
  IAiNa,
  IFieldCheck,
  IProjectsPayload,
  IProjectsValues,
  ProductType,
} from '../pages/Projects/types';
import { getGCPKeyfile } from './files';
import { FormError } from './errors';

const mapProjectLanguages = (
  languages: number[] = [],
): IProjectsPayload['project_languages_attributes'] => languages?.map(id => ({ language_id: id }));

const buildAiNAAttributes = async (
  aiNAAttributes: IProjectsValues['ai_n_a_attributes'],
): Promise<IAiNa | undefined> => {
  if (!aiNAAttributes) return undefined;

  const { gcp_keyfile, gcp_fulfillment_auth_keyfile, ...rest } = aiNAAttributes;

  const gcpKeyfile = gcp_keyfile ? await getGCPKeyfile(gcp_keyfile) : undefined;
  const gcpFulfillmentKeyfile = gcp_fulfillment_auth_keyfile
    ? await getGCPKeyfile(gcp_fulfillment_auth_keyfile)
    : undefined;

  return {
    ...rest,
    ...(gcpKeyfile && { gcp_keyfile: gcpKeyfile }),
    ...(gcpFulfillmentKeyfile?.length && { gcp_fulfillment_auth_keyfile: gcpFulfillmentKeyfile }),
  };
};

export const getPayload = async (values: IProjectsValues): Promise<IProjectsPayload> => {
  const aiNAAttributes = await buildAiNAAttributes(values.ai_n_a_attributes);
  const productsList = projectProducts.map(product => product.value);
  const components = productsList.filter(key => values[key]);

  let payload: IProjectsPayload = {
    ...values,
    project_languages_attributes: mapProjectLanguages(values.project_languages_attributes),
    ai_n_a_attributes: aiNAAttributes,
    components,
  };

  if (!payload.ai_n_a) {
    payload = omit(
      [
        'ai_n_a_attributes',
        'project_languages_attributes',
        'default_language_id',
        'nlu_provider',
        'platform_edition',
        'voice_provider',
        'knowledge_v2_attributes',
      ],
      payload,
    );
  }

  if (!payload.pulse) {
    payload = omit(['pulse_attributes'], payload);
  }

  productsList.forEach(product => delete payload[product]);

  return payload;
};

export const getActiveKeys = (
  errorFields: FormError[],
  fieldChecks: IFieldCheck[],
): ProductType[] =>
  errorFields.reduce((keys: ProductType[], field) => {
    fieldChecks.forEach(check => {
      if (check.names.some(name => field.name.includes(name)) && !keys.includes(check.key)) {
        keys.push(check.key);
      }
    });
    return keys;
  }, []);
