import React from 'react';
import { Translation } from 'react-i18next';
import { Paths } from '../../../types/paths';
import { Users } from './index';
import { UsersList } from './UsersList';
import { UsersNew } from './UsersNew';
import { UsersEdit } from './UsersEdit';

export const userRoutes = () => ({
  path: Paths.users(),
  element: <Users />,
  breadcrumb: () => <Translation ns={'project_settings'}>{t => t('users')}</Translation>,
  children: [
    { index: true, element: <UsersList /> },
    { path: Paths.usersInvite(), element: <UsersNew /> },
    {
      path: Paths.userEdit(),
      element: <UsersEdit />,
      breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
    },
  ],
});
