import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { App, Divider, Form } from 'antd';
import styled from '@emotion/styled';
import { useUpdate } from '../../../../hooks/useUpdate';
import { ProjectsService } from '../../../../services/projects';
import { useAccount } from '../../../../state/account';
import { DisableConfirmation } from '../../../../components/DisableConfirmation';
import { unexpectedError } from '../../../../utils/notification';

export const ProjectControl: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams() as { id: string };
  const { notification } = App.useApp();
  const { activeAccountId } = useAccount() as { activeAccountId: string };
  const { getFieldValue } = Form.useFormInstance();
  const isEnabled = getFieldValue('is_enabled');

  const { mutate: disableItem } = useUpdate<{
    id: string;
    activeAccountId: string;
  }>([ProjectsService.queryKey, activeAccountId, id], ProjectsService.disable);

  const disableProject = async () => {
    await disableItem(
      { id, activeAccountId },
      {
        onSuccess: data => {
          notification['success']({
            message: <Trans t={t}>{t('project_updated_notify', { name: data.name })}</Trans>,
          });
        },
        onError: () => notification['error'](unexpectedError),
      },
    );
  };

  return (
    <StyledControl>
      <Divider />
      <p>
        <Trans t={t}>{isEnabled ? t('project_disable_desc') : t('project_enable_desc')}</Trans>
      </p>
      <DisableConfirmation
        onDisable={disableProject}
        description={t('disable_project_desc')}
        title={t('disable_project')}
        buttonText={t('project_disable')}
        confirmationText={t('disable')}
      />
    </StyledControl>
  );
};

const StyledControl = styled.div`
  margin-top: 20px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`;
