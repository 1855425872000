import React from 'react';
import { ThemeProvider } from '@emotion/react';
import Schema from 'async-validator';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider, App as AntdApp } from 'antd';
import { theme } from './theme';
import { GlobalStyles } from './globalStyles';
import { AuthProvider } from './state/auth';
import { Router } from './config/router';
import { antThemeConfig } from './antTheme';

Schema.warning = function () {
  // do nothing to disable async-validator warnings in dev
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={antThemeConfig}>
          <AuthProvider>
            <AntdApp>
              <GlobalStyles />
              <Router />
            </AntdApp>
          </AuthProvider>
          <ReactQueryDevtools position="bottom-right" />
        </ConfigProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
