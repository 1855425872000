import styled from '@emotion/styled';

export const DestinationValueWrap = styled.span`
  display: flex;
  flex-direction: column;
`;

export const DestinationValueTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.667;
  svg {
    margin-right: 4px;
  }
`;
