import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { StyledPageNotFound } from './PageNotFound.styles';
import { LinkButton } from '../../components/Buttons/LinkButton';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import errorImage from '../../assets/images/error.svg';
import { StyledSign } from '../../components/StyledSign/index.styles';
import { Paths } from '../../types/paths';

interface IError {
  emptyErrorMessage?: boolean;
}

export const PageNotFound: React.FC<IError> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const emptyErrorMessage = (location.state as IError)?.emptyErrorMessage;

  return (
    <StyledPageNotFound.Root>
      <StyledPageNotFound.LeftHalf>
        <img style={{ zIndex: 1, maxWidth: '60%' }} src={errorImage} alt="Error picture" />
        <StyledSign />
      </StyledPageNotFound.LeftHalf>
      <StyledPageNotFound.RightHalf>
        <StyledPageNotFound.Content>
          <StyledPageNotFound.Title>{t('opps')}</StyledPageNotFound.Title>
          {!emptyErrorMessage && (
            <p>
              {t('page_not_found')}
              <br />
              <b> {t('fixing_right_away')}</b>
            </p>
          )}
          <LinkButton to={Paths.index()}>
            <HomeIcon />
            {t('home')}
          </LinkButton>
        </StyledPageNotFound.Content>
      </StyledPageNotFound.RightHalf>
    </StyledPageNotFound.Root>
  );
};
