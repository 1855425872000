import React, { useMemo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Content } from '../Content';
import { DataTable } from '../DataTable';
import { IAiIntegrationUrl, useIntegrations } from '../../state/integrations';
import { ActionButton } from '../ActionButton';
import { AiIntegrationParams } from '../../services/api';
import { useDelete } from '../../hooks/useDelete';
import { DeleteConfirmation } from '../DeleteConfirmation';
import { ChannelTypes } from '../../pages/AI/Channels/Channel/types';

interface IService {
  queryKey: string;
  get: (params: { apiData: IAiIntegrationUrl }, signal?: AbortSignal) => Promise<any>;
  remove: (params: { id: string; apiData: IAiIntegrationUrl }) => Promise<any>;
}

interface Props<T> {
  service: IService;
  columns: ColumnProps<T>[];
  getCreatePath?: () => string;
  deleteConfirmationTitle?: string;
  getDisplayName: (record: T) => string;
  getChannelName?: (record: T) => string;
  channelType?: string;
  getIsDefault?: (record: T) => boolean | undefined;
}

export const AiFeatureList = <T extends { id: number }>({
  service,
  columns = [],
  getCreatePath,
  deleteConfirmationTitle,
  getDisplayName,
  getChannelName,
  channelType,
  getIsDefault,
}: Props<T>) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { aiIntegration } = useIntegrations();
  const filterChannelType =
    channelType === ChannelTypes.messenger ? ChannelTypes.facebook : channelType;

  const { data, isLoading } = useQuery(
    [service.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => service.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const filteredData =
    data && channelType && channelType != ChannelTypes.all
      ? data.filter((item: { channel_type: string }) => item.channel_type === filterChannelType)
      : data;

  const { mutate: deleteItem } = useDelete([service.queryKey], service.remove);

  const handleDelete = useCallback(
    async id => aiIntegration && (await deleteItem({ id, apiData: aiIntegration })),
    [aiIntegration],
  );

  const handleCreate = () => {
    if (getCreatePath) navigate(getCreatePath());
  };

  const renderDelete = (record: T): JSX.Element => (
    <DeleteConfirmation
      id={record?.id}
      onActionDelete={() => handleDelete(record?.id)}
      disabled={getIsDefault ? getIsDefault(record) : false}
      title={
        getChannelName
          ? t('delete_identifier', {
              channel:
                getChannelName(record) === ChannelTypes.facebook
                  ? t('channel_messenger')
                  : t(`channel_${getChannelName(record)}`),
            })
          : deleteConfirmationTitle
      }
    >
      <Trans>{t('delete_description', { name: getDisplayName(record) })}</Trans>
    </DeleteConfirmation>
  );

  const memoizedColumns: ColumnProps<T>[] = useMemo(() => {
    return columns.map(column => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: record => renderDelete(record),
        };
      }
      return column;
    });
  }, [columns]);

  const ButtonCreate = getCreatePath ? <ActionButton type="create" onClick={handleCreate} /> : null;

  return (
    <Content
      imgBg={false}
      Actions={[ButtonCreate]}
      breadcrumbParamTitle={t(`channel_${channelType}`)}
    >
      <DataTable dataSource={filteredData} columns={memoizedColumns} loading={isLoading} />
    </Content>
  );
};
