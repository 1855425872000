import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { ChannelType } from '../../pages/AI/Channels/Channel/types';

const queryKey = 'incoming-phone-numbers';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
  channelType?: ChannelType,
): Promise<string[]> => {
  return await integrationQuery(
    `/api/incoming_phone_numbers?channel_type=${channelType}&limit=50`,
    {
      apiData,
      signal,
    },
  );
};

export const IncomingPhoneNumbersService = {
  queryKey,
  get,
};
