import { useCallback, useState } from 'react';

interface IUseSteps {
  currentStep: number;
  next: () => void;
  prev: () => void;
  setStep: (step: number) => void;
}

export default function useSteps(): IUseSteps {
  const [currentStep, setCurrentStep] = useState(0);

  const next = useCallback(() => setCurrentStep(step => step + 1), []);
  const prev = useCallback(() => setCurrentStep(step => step - 1), []);
  const setStep = useCallback((step: number) => setCurrentStep(step), []);

  return { currentStep, next, prev, setStep };
}
