import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ConversationProfilesForm } from '../ConversationProfilesForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IConversationProfile, IConversationProfileValues } from '../types';
import { ConversationProfilesService } from '../../../../../services/ai/conversation-profiles';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { serializeData } from '../../../../../utils/serializeData';

export const ConversationProfilesEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IConversationProfile>(
    [ConversationProfilesService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      ConversationProfilesService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [ConversationProfilesService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    ConversationProfilesService.update,
  );

  const onSubmit = async (data: Partial<IConversationProfileValues>): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('conversation_profiles_updated_notify', {
                    name: data?.['dialogflow/conversation_profile'].dialogflow_data.display_name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: () => {
            notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IConversationProfileValues = {
    dialogflow_agent_id: data.dialogflow_agent.id,
    gcpProjectName: data.dialogflow_agent.gcp_project_name,
    platform_edition: data.dialogflow_agent.platform_edition,
    location: data.dialogflow_agent.location,
    uuid: data.dialogflow_agent.uuid,
    ...serializeData(['create_time', 'language_code', 'name', 'update_time'])(data.dialogflow_data),
  };

  if (initialValues.human_agent_assistant_config) {
    initialValues.human_agent_assistant_config.enabled =
      !!initialValues.human_agent_assistant_config?.human_agent_suggestion_config?.feature_configs;
  }

  return (
    <Content imgBg={false}>
      <ConversationProfilesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        isEditing
      />
    </Content>
  );
};
