import styled from '@emotion/styled';
import { Form } from 'antd';

export const FormWrapper = styled.div`
  @media (min-width: 1024px) {
    max-width: ${({ theme }) => theme.formWidth};
  }
`;

export const DestinationFormItem = styled(Form.Item)`
  margin-top: 20px;
  .ant-form-item-label > label {
    font-size: 16px;
    color: ${({ theme }) => theme.primaryColor};
  }
  .ant-table-content {
    padding-top: 16px;
  }
`;
