import { useQuery } from '@tanstack/react-query';
import { LanguagesService } from '../../services/languages';

export interface ILang {
  id: number;
  locale: string;
  name: string;
}

export default function useLanguages(): { isLoading: boolean; languages?: ILang[] } {
  const { isLoading, data: languages } = useQuery<ILang[], string>(
    [LanguagesService.queryKey],
    LanguagesService.get,
    {
      refetchOnWindowFocus: false,
    },
  );

  return { languages, isLoading };
}
