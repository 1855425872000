import React from 'react';
import { Form, Input, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IKnowledgeBasesValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { DFAgentSelector } from '../../../../../components/DFAgentSelector';

interface Props {
  initialValues: IKnowledgeBasesValues;
  onSubmit: (values: IKnowledgeBasesValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
  isModal?: boolean;
  handleCloseModal?: () => void;
}

export const KnowledgeBasesForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
  isModal,
  handleCloseModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);

  const handleCancel = () => {
    if (isModal) {
      handleCloseModal();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue())) {
        openModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="display_name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('knowledge_base_name')} />
      </Form.Item>
      <Form.Item
        name="dialogflow_agent_id"
        label={t('dialogflow_agent')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <DFAgentSelector
          placeholder={t('select_agent')}
          platformEdition={'ES'}
          ccaiEnabledOnly
          disabled={isEditing || isModal}
        />
      </Form.Item>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </>
      )}
    </Form>
  );
};
