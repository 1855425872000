import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../../state/auth';
import { ACTIVE_ACCOUNT_NAME, ACTIVE_PROJECT_NAME } from '../../constants';
import { IAccountContext } from '../../state/account';

type ContextKind = {
  anonymous?: boolean;
  key?: string;
  name?: string;
};

export interface ILDContext {
  kind: 'multi';
  user: ContextKind;
  account: ContextKind;
  project: ContextKind & {
    account?: Omit<ContextKind, 'anonymous'>;
  };
}

type Props = {
  activeAccountId?: IAccountContext['activeAccountId'];
  activeProjectId?: IAccountContext['activeProjectId'];
  isProjectDataFetched: boolean;
  setLdContext?: IAccountContext['setLdContext'];
};

export default function useUpdateLDContext({
  activeAccountId,
  activeProjectId,
  isProjectDataFetched,
  setLdContext,
}: Props): void {
  const ldClient = useLDClient();
  const { username, fullName } = useAuth();

  useEffect(() => {
    if (ldClient && isProjectDataFetched && username.length) {
      ldClient.identify(
        {
          ...ldClient.getContext(),
          user: { key: username, name: fullName },
          ...(activeAccountId
            ? {
                account: {
                  key: activeAccountId,
                  name: localStorage.getItem(ACTIVE_ACCOUNT_NAME),
                },
              }
            : { account: { anonymous: true } }),
          ...(activeProjectId
            ? {
                project: {
                  key: activeProjectId,
                  name: localStorage.getItem(ACTIVE_PROJECT_NAME),
                  account: {
                    key: activeAccountId,
                    name: localStorage.getItem(ACTIVE_ACCOUNT_NAME),
                  },
                },
              }
            : { project: { anonymous: true } }),
        },
        undefined,
        // Save context to the Account provider as we don't receive context
        // update event directly from ldClient
        () => setLdContext?.(ldClient.getContext() as ILDContext),
      );
    }
  }, [username, ldClient, activeAccountId, activeProjectId, isProjectDataFetched]);
}
