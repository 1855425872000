import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { IKnowledgeBases } from '..//types';
import { KnowledgeBasesNew } from '../KnowledgeBasesNew';
import { KnowledgeBaseDocumentNew } from '../KnowledgeBaseDocumentNew';

interface IParameterModal {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  dfAgentId?: number;
  setCreatedKey: React.Dispatch<React.SetStateAction<number>>;
}

export const KnowledgeBaseModal: React.FC<IParameterModal> = ({
  isVisible,
  setVisible,
  dfAgentId,
  setCreatedKey,
}) => {
  const { t } = useTranslation('ai');
  const [knowledgeBase, setKnowledgeBase] = useState<IKnowledgeBases | undefined>(undefined);
  const [modalWidth, setModalWidth] = useState<number>(410);
  const [newDocKey, setNewDocKey] = useState<number>(0);

  const handleCreateKB = (knowledgeBase: IKnowledgeBases) => {
    setCreatedKey(prevKey => prevKey + 1);
    setKnowledgeBase(knowledgeBase);
    setModalWidth(848);
  };

  const handleCancel = () => {
    setVisible(false);
    setKnowledgeBase(undefined);
    setNewDocKey(0);
    setTimeout(() => setModalWidth(410), 1000);
  };

  const handleCreateKBD = (createAnother: boolean) => {
    if (createAnother) {
      setNewDocKey(prevKey => prevKey + 1);
    } else handleCancel();
  };

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} />{' '}
          {knowledgeBase
            ? t('new_document', { name: knowledgeBase.display_name })
            : t('new_knowledge_base')}
        </>
      }
      width={modalWidth}
      centered
      open={isVisible}
      footer={false}
      onCancel={handleCancel}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
    >
      {knowledgeBase ? (
        <KnowledgeBaseDocumentNew
          key={newDocKey}
          isModal={true}
          handleCloseModal={handleCancel}
          handleCreateKBD={handleCreateKBD}
          knowledgeBaseId={String(knowledgeBase.id)}
        />
      ) : (
        <KnowledgeBasesNew
          isModal={true}
          handleCloseModal={handleCancel}
          handleCreateKB={handleCreateKB}
          dfAgentId={dfAgentId}
        />
      )}
    </Modal>
  );
};
