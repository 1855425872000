import styled from '@emotion/styled';

export const ButtonSkew = styled('button')`
  position: relative;
  height: 36px;
  border: 0 none;
  padding: 8px 20px;
  background-color: transparent;
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${({ theme }) => theme.fontFamilyAccent};

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skew(-15deg);
    background: ${({ theme }) => theme.secondaryBg};
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  svg {
    display: block;
  }
`;
