import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { KnowledgeBaseDocumentForm } from '../KnowledgeBaseDocumentForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IKnowledgeBaseDocumentValues, IKnowledgeBases, KnowledgeBasesDocTypes } from '../types';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { KnowledgeBaseDocumentService } from '../../../../../services/ai/knowledge-base-document';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { OverlaySpinner } from '../../../../../components/Spinner';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';

interface Props {
  isModal?: boolean;
  handleCloseModal?: () => void;
  handleCreateKBD?: (createAnother: boolean) => void;
  knowledgeBaseId?: string;
}

export const KnowledgeBaseDocumentNew: React.FC<Props> = ({
  isModal,
  handleCloseModal,
  handleCreateKBD,
  knowledgeBaseId,
}) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const [isCreateAnotherLoading, setIsCreateAnotherLoading] = useState<boolean>(false);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const { id: paramId } = useParams() as { id: string };
  const id = knowledgeBaseId || paramId;

  const { data: knowledgeBase, isLoading: isKBLoading } = useGetItem<IKnowledgeBases>(
    [KnowledgeBasesService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      KnowledgeBasesService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: createItem, isLoading } = useCreate(
    [KnowledgeBaseDocumentService.queryKey],
    KnowledgeBaseDocumentService.create,
  );

  const onSubmit = async (
    data: IKnowledgeBaseDocumentValues,
    isCreateAnother: boolean,
  ): Promise<void> => {
    isModal && isCreateAnother ? setIsCreateAnotherLoading(true) : setIsCreateLoading(true);
    data.dialogflow_knowledge_base_id = id;
    await createItem(
      { id, apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('document_created_notify', {
                  name: data['dialogflow/knowledge_base_document'].display_name,
                })}
              </Trans>
            ),
          });
          isModal && handleCreateKBD ? handleCreateKBD(isCreateAnother) : navigate(-1);
        },
        onError: () => {
          notification['error'](unexpectedError);
        },
        onSettled: () => {
          if (isModal) {
            setIsCreateAnotherLoading(false);
            setIsCreateLoading(false);
          }
        },
      },
    );
  };

  const initialValues: IKnowledgeBaseDocumentValues = {
    display_name: '',
    knowledge_types: isModal ? KnowledgeBasesDocTypes.FAQ : null,
  };

  const formProps = {
    initialValues,
    onSubmit,
  };

  return isModal ? (
    <KnowledgeBaseDocumentForm
      {...formProps}
      submitButton={
        <>
          <Button
            type="default"
            icon={<PlusIcon width={13} />}
            htmlType="submit"
            loading={isCreateAnotherLoading}
            data-action={true}
          >
            {t('create_another')}
          </Button>
          <ActionButton type="create" htmlType="submit" loading={isCreateLoading} />
        </>
      }
      isModal={isModal}
      handleCloseModal={handleCloseModal}
    />
  ) : (
    <>
      {!isKBLoading && (
        <Content imgBg={false} breadcrumbParamTitle={knowledgeBase?.display_name}>
          <KnowledgeBaseDocumentForm
            {...formProps}
            submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
          />
        </Content>
      )}
      <OverlaySpinner loading={isKBLoading} />
    </>
  );
};
