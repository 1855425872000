import dayjs, { Dayjs } from 'dayjs';
import { Key } from 'react';
import { ITimeSchedule } from '../pages/AI/Routing/Teams/types';
import { Weekday } from '../constants';
export const formatDate = (date: string): string => {
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatChinaDateFormat = (date: Dayjs): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getDatesInRange = (startDate: Date, endDate: Date): string[] => {
  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates.map(item => item.toLocaleDateString('en-GB'));
};

export const getFormattedDate = (date: Key | boolean, isStart: boolean): string => {
  if (typeof date === 'boolean') return '-';
  const time = isStart ? '00:00:00.00' : '24:00:00.00';
  const dateObj = dayjs(`${date} ${time}`, 'DD/MM/YYYY HH:mm:ss.SS');
  return dateObj.format('YYYY-MM-DD HH:mm:ss.SS');
};

export const formatTime = (date: Dayjs): string => {
  return dayjs(date).format('HH:mm');
};

export const getUtcOffset = (timezone: string): string => {
  try {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
    });
    const formattedDate = formatter.format(now);
    const timeZoneName = formattedDate.split(' ').pop();
    return timeZoneName ? `(${timeZoneName})` : '';
  } catch (error) {
    return '';
  }
};

export const getTimeScheduleAttributes = (timeSchedules: ITimeSchedule[]): ITimeSchedule[] => {
  return timeSchedules.map(item => {
    const startTime = dayjs(item.start_time, 'HH:mm');
    const endTime = dayjs(item.end_time, 'HH:mm');
    const isFullDay = startTime.format('HH:mm') === '00:00' && endTime.format('HH:mm') === '23:59';

    return {
      id: item.id,
      day: item.day.toLowerCase() as Weekday,
      start_time: startTime,
      end_time: endTime,
      closed: item.closed,
      state: isFullDay,
    };
  });
};
