import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { getProgressColor, getProgressBGColor } from '../../../../utils/knowledge';
import { formatDate } from '../../../../utils/date';
import { KnowledgeTranslationService } from '../../../../services/knowledge/translations';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { useAccount } from '../../../../state/account';
import { Content } from '../../../../components/Content';
import { DataTable } from '../../../../components/DataTable';
import { NoWrap } from '../../../../components/DataTable/DataTable.styles';
import { ActionButton } from '../../../../components/ActionButton';
import { ColumnPropsKnowledge, ITranslationStatuses, ITranslationStatus } from '../../types';
import { Paths } from '../../../../types/paths';

export const TranslationsList: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations() as { knowledge: IKnowledgeUrl };

  const { data, isLoading } = useQuery<ITranslationStatuses>(
    [KnowledgeTranslationService.queryKey, activeProjectId, knowledge?.externalId],
    ({ signal }) => KnowledgeTranslationService.getStatuses(knowledge, signal),
    { enabled: !!knowledge },
  );

  const onRowHandler = ({ language }: ITranslationStatus) => ({
    onClick: () => navigate(language.code),
  });

  const columns: ColumnPropsKnowledge<ITranslationStatus>[] = [
    {
      title: t('languages'),
      width: 200,
      fixed: 'left',
      dataIndex: 'name',
    },
    {
      title: t('verified_translation_column'),
      dataIndex: 'count_verified_percents',
      width: 150,
      render: (progress, row) => (
        <NoWrap>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Progress
              style={{ width: '90%', fontWeight: 'bold' }}
              strokeColor={getProgressColor(progress)}
              trailColor={getProgressBGColor(progress)}
              percent={progress}
            />
            <span style={{ marginLeft: 5 }}>({row.count_verified})</span>
          </div>
        </NoWrap>
      ),
    },
    {
      title: t('date_modified'),
      dataIndex: 'update_at',
      width: 80,
      render: date => (date ? <NoWrap>{formatDate(date)}</NoWrap> : ''),
    },
  ];
  const ButtonManage = (
    <ActionButton
      type="manage"
      onClick={() => navigate(Paths.projectEdit({ id: activeProjectId }))}
    />
  );

  return (
    <Content imgBg={false} Actions={[ButtonManage]}>
      <DataTable
        loading={isLoading}
        dataSource={data?.data.filter(item => !item.language.is_default) || []}
        columns={columns}
        onRow={onRowHandler}
      />
    </Content>
  );
};
