import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IDataRetentionPolicy,
  IDataRetentionPolicyValues,
} from '../../pages/Analytics/Configurations/DataRetentionPolicy/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'data-retention-policy';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IDataRetentionPolicy[]> => {
  const response = await integrationQuery(`/api/analytics/data_retention_policies`, {
    apiData,
    signal,
  });
  return response?.data_retention_policies;
};

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IDataRetentionPolicy> => {
  const response = await integrationQuery(`/api/analytics/data_retention_policies/${id}`, {
    apiData,
    signal,
  });
  return response?.data_retention_policy;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IDataRetentionPolicyValues>;
}): Promise<IDataRetentionPolicy> => {
  return integrationQuery(`/api/analytics/data_retention_policies/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'data_retention_policies',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/analytics/data_retention_policies/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const DataRententionPolicyService = {
  queryKey,
  get,
  find,
  update,
  remove,
};
