import React from 'react';
import { ButtonSkew } from '../../ButtonSkew';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

export const Search: React.FC = () => {
  return (
    <ButtonSkew>
      <SearchIcon width={14} height={14} />
    </ButtonSkew>
  );
};
