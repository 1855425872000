import { useState } from 'react';
import { DatePicker } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { path, Path } from 'ramda';
import { RangeValue } from 'rc-picker/lib/interface';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { formatDate, getDatesInRange } from '../../utils/date';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-alt.svg';
import { ReactComponent as ArrowSeparatorIcon } from '../../assets/icons/arrow-separator.svg';
import { theme } from '../../theme';

const { RangePicker } = DatePicker;
type DataIndex<T> = keyof T;
export function useDateColumnFilter<T>(
  dataIndex: DataIndex<T> | string[],
  searchDate: string[],
  onSearch: (value: string[]) => void,
): ColumnType<T> {
  const [rangeDate, setRangeDate] = useState<RangeValue<dayjs.Dayjs>>();
  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    onSearch(selectedKeys);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    onSearch([]);
  };

  return {
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div className="ant-table-filter-dropdown">
        <div className="ant-table-filter-dropdown-search">
          <RangePicker
            onChange={(dates, dateStrings) => {
              setRangeDate(dates);
              if (searchDate) {
                clearFilters && handleReset(clearFilters);
              }
              const allDates = getDatesInRange(new Date(dateStrings[0]), new Date(dateStrings[1]));
              setSelectedKeys(dateStrings ? allDates : []);
              handleSearch(allDates, confirm);
            }}
            format="YYYY-MM-DD"
            value={rangeDate}
            suffixIcon={<CalendarIcon width={14} height={20} color={theme.primaryColor} />}
            separator={<ArrowSeparatorIcon width={8} height={10} />}
          />
        </div>
      </div>
    ),
    filterIcon: () => <FilterIcon width={10} />,
    onFilter: (value, record) =>
      formatDate(String(path((Array.isArray(dataIndex) ? dataIndex : [dataIndex]) as Path, record)))
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    filterMultiple: true,
  };
}
