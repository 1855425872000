import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { AutomationProfilesForm } from '../AutomationProfilesForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IAutomationProfileValues, IAutomationProfile } from '../types';
import { DialPlansService } from '../../../../../services/ai/dial-plans';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

export const AutomationProfilesEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IAutomationProfile>(
    [DialPlansService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      DialPlansService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [DialPlansService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    DialPlansService.update,
  );

  const onSubmit = async (
    data: IAutomationProfileValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('automation_profile_updated_notify', {
                    name: data.dial_plan.name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const hasInboundPhoneNumberError = err.response.data.errors.some(
                (error: any) => 'inbound_phone_numbers.phone_number' in error,
              );
              if (hasInboundPhoneNumberError)
                notification.error({
                  message: t('channel_error'),
                });
              else {
                const formattedErrors = formatApiErrors(
                  Object.assign({}, ...err.response.data.errors),
                );
                setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
              }
            } else notification['error'](unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IAutomationProfileValues = {
    integration_type: data.dialogflow_conversation_profile_id ? 'ccai' : 'standard',
    platform_edition: data.dialogflow_agent?.platform_edition || 'ES',
    dialogflow_conversation_profile_id: data.dialogflow_conversation_profile_id,
    name: data.name,
    analyze_query_text_sentiment: data.analyze_query_text_sentiment,
    dialogflow_agent_id: data.dialogflow_agent?.id,
    dlp_config_id: data.dlp_config_id,
    context_ids: data.contexts?.map((context: { id: number }) => context.id),
    fallback_config_id: data.fallback_config_id,
    text_to_speech_config_id: data.text_to_speech_config?.id,
    locale: data.locale,
    audio_config_attributes: {
      speech_model: data.audio_config?.speech_model,
      speech_model_variant: data.audio_config?.speech_model_variant,
    },
    analytic_openai_summary_enabled: data.analytic_openai_summary_enabled,
    openai_summary_enabled: data.openai_summary_enabled,
    ccai_summary_enabled: data.ccai_summary_enabled,
    voice_recording_config_attributes: {
      voice_recording_enabled: data.voice_recording_config?.voice_recording_enabled,
      stop_on_escalation: data.voice_recording_config?.stop_on_escalation,
    },
    inbound_phone_numbers_attributes: data.inbound_phone_numbers?.map(
      ({ id, channel_type, phone_number }) => ({
        id,
        channel_type,
        phone_number,
      }),
    ),
    dialogflow_environment_uuid: data.dialogflow_environment_uuid,
  };

  return (
    <Content imgBg={false}>
      <AutomationProfilesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
