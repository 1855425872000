import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ReactComponent as LogoIcon } from '../../assets/icons/logoConsole.svg';

const headerHeight = 55;
const desktopHeaderHeight = 100;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${headerHeight}px;
  padding: 0 11px;
  color: #fff;
  background-color: ${({ theme }) => theme.primaryColor};
  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-end;
    height: ${desktopHeaderHeight}px;
    padding: 20px 32px;
  }
`;

const Logo = styled(Link)`
  display: block;
  @media (min-width: 1024px) {
    padding-right: 32px;
  }
`;

const SabioLogo = styled(LogoIcon)`
  display: block;
  width: auto;
  height: 33px;
  color: #fff;
  @media (min-width: 1024px) {
    height: 55px;
  }
`;

const NavigationButton = styled(Button)`
  padding: 0;
  color: inherit;
  background-color: transparent;
  &::after {
    display: none;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

const Navigation = styled.nav`
  position: fixed;
  top: ${headerHeight}px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 0;
  padding-top: 0;
  color: ${({ theme }) => theme.primaryColor};
  background-color: #fff;
  transform: translateY(-100% -${headerHeight}px);
  visibility: hidden;
  transition: visibility 0.3s ease, transform 0.3s ease, height 0.5s ease, padding-top 0.5s ease;
  &.open {
    height: calc(100vh - ${headerHeight}px);
    padding-top: 24px;
    transform: translateY(0);
    visibility: visible;
  }
  @media (min-width: 1024px) {
    position: static;
    display: block;
    width: auto;
    height: auto;
    padding-top: 0;
    color: inherit;
    background-color: transparent;
    transform: translateY(0);
    visibility: visible;
  }
`;

const NavigationItem = styled(NavLink)`
  display: inline-block;
  min-height: 60px;
  padding: 0 70px 0 30px;
  font-size: 18px;
  line-height: 22px;
  color: inherit;
  :hover {
    color: inherit;
  }
  @media (min-width: 1024px) {
    min-height: unset;
    padding: 0 15px;
    font-size: 14px;
  }
`;

const Root = styled.main`
  padding-top: ${headerHeight}px;
  @media (min-width: 1024px) {
    padding-top: ${desktopHeaderHeight}px;
  }
  background-color: #fff;
`;

const InnerWrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  @media (min-width: 1024px) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media (min-width: 1400px) {
    max-width: 1700px;
    padding-right: 100px;
    padding-left: 100px;
    margin: 0 auto;
  }
`;

const PageTitleWrap = styled.div`
  position: relative;
  z-index: 1;
  min-height: 6.105vw;
  padding-bottom: 60px;
  margin-bottom: 6.105vw;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  background-color: ${({ theme }) => theme.primaryColor};
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.primaryColor};
    transform: skewY(3.5deg) translateY(3.0829512465vw);
  }
  & > div {
    padding-top: 60px;
    padding-bottom: 40px;
    color: inherit;
    @media (min-width: 1400px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
`;

const PageTitle = styled.h1`
  margin-bottom: 0;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase;
  color: inherit;
  @media (min-width: 1024px) {
    font-size: 4.375rem;
  }
  @media (min-width: 1400px) {
    font-size: 5.625rem;
  }
`;

const Content = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
  & h2 {
    margin-bottom: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
  }
  h3 {
    font-weight: 400;
  }
  & p {
    font-size: 1.25rem;
    line-height: 1.4;
  }
  & p:last-child {
    margin-bottom: 0;
  }
  /* WebKit/Blink Browsers */
  & *::selection {
    color: currentColor;
    background: #9fc6f6;
  }
  /* Gecko Browsers */
  & *::-moz-selection {
    color: currentColor;
    background: #9fc6f6;
  }
  @media (min-width: 1024px) {
    & > ${InnerWrapper} > div {
      width: 50%;
    }
  }
  @media (min-width: 1400px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export const StyledLegalWrapper = {
  Header,
  Logo,
  SabioLogo,
  NavigationButton,
  Navigation,
  NavigationItem,
  Root,
  InnerWrapper,
  PageTitleWrap,
  PageTitle,
  Content,
};
