import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { Tag } from 'antd';
import { Content } from '../../../../components/Content';
import { IWorkflow } from '../types';
import { getName } from '../../../../utils/user';
import { DataTable } from '../../../../components/DataTable';
import { mockWorkflow } from '../../../../__mocks__/knowledge';
import { useDateColumnFilter } from '../../../../hooks/useDateColumnFilter';
import { formatDate } from '../../../../utils/date';
import { WorkflowHistory } from '../WorkflowHistory';
import { FlowDiagram } from './FlowDiagram';

export const WorkflowList: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const [searchDate, setSearchDate] = useState<string[]>([]);

  const columns: ColumnProps<IWorkflow>[] = [
    {
      title: t('presentation_name'),
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: '35%',
      fixed: 'left',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      render: status => (
        <Tag color={tagColor[status]} className={status === 'draft' ? 'ant-tag-draft' : ''}>
          {t(status)}
        </Tag>
      ),
      width: '18%',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: t('requester'),
      dataIndex: ['requester', 'external_data'],
      render: data => getName(data),
      width: '14%',
      sorter: (a, b) =>
        getName(a.requester.external_data).localeCompare(getName(b.requester.external_data)),
    },
    {
      title: t('approver'),
      dataIndex: ['approver', 'external_data'],
      render: data => getName(data),
      width: '14%',
      sorter: (a, b) =>
        getName(a.approver.external_data).localeCompare(getName(b.approver.external_data)),
    },
    {
      title: t('date_modified'),
      dataIndex: 'updated_at',
      sorter: true,
      width: '14%',
      ...useDateColumnFilter('updated_at', searchDate, setSearchDate),
      render: date =>
        searchDate.length ? searchDate.find(item => item == formatDate(date)) : formatDate(date),
    },
    {
      key: 'actions',
      width: '3%',
      render: record => <WorkflowHistory id={record.id} />,
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <Content imgBg={false} Actions={[<FlowDiagram key={'flowDiagram'} />]}>
      <DataTable dataSource={mockWorkflow.data} columns={columns} isClickable={false} />
    </Content>
  );
};

const tagColor: Record<string, string> = {
  approved: 'success',
  rejected: 'error',
  pending: 'warning',
};
