import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Content } from '../../../../../components/Content';
import { DataTable } from '../../../../../components/DataTable';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { KnowledgeBaseDocumentService } from '../../../../../services/ai/knowledge-base-document';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { AiIntegrationParams } from '../../../../../services/api';
import { IKnowledgeBaseDocument, IKnowledgeBases } from '../types';
import { DeleteConfirmation } from '../../../../../components/DeleteConfirmation';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useDelete } from '../../../../../hooks/useDelete';
import { getDocumentStatus } from '../../../../../utils/documents';
import { OverlaySpinner } from '../../../../../components/Spinner';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import { Paths } from '../../../../../types/paths';

export const KnowledgeBaseDocumentList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { id } = useParams() as { id: string };
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { data: knowledgeBaseDocuments, isLoading: isKBDLoading } = useQuery(
    [KnowledgeBaseDocumentService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      KnowledgeBaseDocumentService.get(
        { apiData: aiIntegration } as AiIntegrationParams,
        signal,
        id,
      ),
    { enabled: !!aiIntegration?.url },
  );

  const { data: knowledgeBase, isLoading: isKBLoading } = useGetItem<IKnowledgeBases>(
    [KnowledgeBasesService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      KnowledgeBasesService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: deleteItem } = useDelete(
    [KnowledgeBaseDocumentService.queryKey],
    KnowledgeBaseDocumentService.remove,
  );

  const handleDelete = useCallback(
    async docId => aiIntegration && (await deleteItem({ id, docId, apiData: aiIntegration })),
    [aiIntegration],
  );

  const onCellHandler = (record: IKnowledgeBaseDocument) => {
    return {
      onClick: () => {
        if (record.status === 'done')
          navigate(Paths.aiKnowledgeBaseDocumentEdit({ id, docId: record.id }));
      },
    };
  };

  const columns: ColumnProps<IKnowledgeBaseDocument>[] = [
    {
      title: t('document_name'),
      onCell: onCellHandler,
      dataIndex: 'display_name',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      fixed: 'left',
      width: '50%',
    },
    {
      title: t('status'),
      onCell: onCellHandler,
      dataIndex: 'status',
      width: '30%',
      render: status => getDocumentStatus(status),
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
      render: record =>
        record?.status !== 'in_progress' ? (
          <DeleteConfirmation
            id={record?.id}
            onActionDelete={() => handleDelete(record?.id)}
            title={t('delete_knowledge_base_document')}
            name={record?.display_name}
          />
        ) : null,
    },
  ];

  const ButtonCreate = (
    <Button
      type="primary"
      icon={<Plus width={13} height={14} />}
      onClick={() => navigate(Paths.aiKnowledgeBaseDocumentNew({ id }))}
    >
      {t('create_document')}
    </Button>
  );

  return (
    <>
      {!isKBLoading && (
        <Content
          imgBg={false}
          Actions={[ButtonCreate]}
          breadcrumbParamTitle={knowledgeBase?.display_name}
        >
          <DataTable loading={isKBDLoading} dataSource={knowledgeBaseDocuments} columns={columns} />
        </Content>
      )}
      <OverlaySpinner loading={isKBLoading} />
    </>
  );
};
