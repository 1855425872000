import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { AccountProvider } from '../../state/account';
import { IntegrationsProvider } from '../../state/integrations';
import { Header } from '../../components/Header';
import { Navbar } from '../../components/Navbar';
import { AsideModal } from '../../components/AsideModal';
import { useSessionTimeout } from '../../hooks/useSessionTimeout';

export const Home: React.FC = () => {
  useSessionTimeout();
  const [isVisible, setIsVisible] = useState(false);

  const modalToggle = () => {
    setIsVisible(!isVisible);
  };
  return (
    <AccountProvider>
      <IntegrationsProvider>
        <Main>
          <Header />
          <Navbar modalToggle={modalToggle} />
          <AsideModal isVisible={isVisible} modalToggle={modalToggle} />
          <MainContent>
            <Outlet />
          </MainContent>
        </Main>
      </IntegrationsProvider>
    </AccountProvider>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: ${({ theme }) => `${theme.headerHeight} 0 0 ${theme.navWidth}`};
  background-color: ${({ theme }) => theme.bgColor};
`;

export const MainContent = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  iframe {
    flex: 1 0 auto;
    width: 100%;
    border: 0 none;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top;
  }
`;
