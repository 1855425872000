import React, { useCallback } from 'react';
import { Form, Switch } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IProductFeature } from '../../../types';
import { useFormContext } from '../../../../../state/form';
import { projectProducts } from '../../../constants';

interface IProductSwitch {
  product: IProductFeature;
  required: boolean;
  tooltip: string;
  isLastItem: boolean;
  disabled: boolean;
  style?: React.CSSProperties;
}

export const ProductSwitch: React.FC<IProductSwitch> = ({
  product,
  required,
  tooltip,
  isLastItem,
  disabled,
  style,
}) => {
  const { t } = useTranslation('project_settings');
  const { setFieldValue, getFieldValue } = Form.useFormInstance();
  const { errorFields } = useFormContext();
  const { label, value } = product;

  const isError = useCallback(() => {
    return (
      isLastItem &&
      projectProducts.some(product =>
        errorFields.some(field => field.name.includes(product.value)),
      ) &&
      !projectProducts.some(product => getFieldValue(product.value) === true)
    );
  }, [isLastItem, errorFields]);

  const handleChecked = (checked: boolean) => {
    setFieldValue(value, checked || undefined);
  };

  return (
    <>
      <StyledFormItem
        valuePropName="checked"
        name={value}
        tooltip={tooltip}
        label={label}
        rules={[{ required }]}
        style={style}
        help=""
      >
        <Switch onChange={handleChecked} disabled={disabled} />
      </StyledFormItem>
      {isError() && <ErrorMessage>{t('products_select_error')}</ErrorMessage>}
    </>
  );
};

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 600;
`;

const StyledFormItem = styled(Form.Item)`
  width: 360px;
  .ant-form-item-label {
    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: unset;
    }
  }
  .ant-form-item-row {
    max-height: 36px;
    @media (min-width: 576px) {
      align-items: center;
      flex-direction: row;
    }
  }
  .ant-col {
    width: auto !important;
  }
  .ant-form-item-control-input {
    float: right;
  }
`;
