import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import { IChannelItem, IChannels } from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-channel';

const get = async (
  knowledgeURL: IKnowledgeUrl,
  signal: QueryFunctionContext['signal'],
): Promise<IChannels> => {
  const response = await knowledgeQuery('/channels', { data: knowledgeURL, signal });
  // web channel should be always first
  const index = response.data.findIndex((el: IChannelItem) => el.channel_type === 'web');
  if (index > 0) {
    response.data.unshift(response.data.splice(index, 1)[0]);
  }
  return response;
};

export const KnowledgeChannelsService = {
  queryKey,
  get,
};
