import { ReactElement } from 'react';

export const getHighlightedText = (text: string, highlight: string): ReactElement => {
  // Split on highlight term and include term into parts, ignore case
  const parts = String(text).split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) => {
        if (part == '') return null;
        if (part.toLowerCase() === highlight.toLowerCase()) return <mark key={i}>{part}</mark>;
        return <span key={i}>{part}</span>;
      })}
    </>
  );
};
