import { useState } from 'react';
import { Form, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { SuggestionFeatureType } from '../../../../../../constants';
import { SuggestionFeatureTypes } from '../../types';
import { KnowledgeBasesByAgentSelector } from '../../../../../../components/KnowledgeBasesByAgentSelector';
import { KnowledgeBaseModal } from '../../../../Configuration/KnowledgeBases/KnowledgeBaseModal';
import { StyledFormItem } from '../../../../AI.styles';

export const HumanAgentSuggestion = ({
  suggestionType,
  handleSuggestionTypeChange,
  dfAgentId,
}: {
  suggestionType: string;
  handleSuggestionTypeChange: (value: string) => void;
  dfAgentId?: number;
}): JSX.Element => {
  const { t } = useTranslation('ai');
  const [isVisibleKBModal, setVisibleKBModal] = useState<boolean>(false);
  const [createdKey, setCreatedKey] = useState(0);

  const onNewKnowledgeBase = () => setVisibleKBModal(true);

  return (
    <>
      <Form.Item
        name={[
          'human_agent_assistant_config',
          'human_agent_suggestion_config',
          'feature_configs',
          0,
          'suggestion_feature',
          'type',
        ]}
        label={t('type_suggestion')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={t('select_type_suggestion')}
          options={SuggestionFeatureType}
          onChange={handleSuggestionTypeChange}
        />
      </Form.Item>
      {suggestionType === SuggestionFeatureTypes.FAQ && (
        <>
          <Form.Item
            name={[
              'human_agent_assistant_config',
              'human_agent_suggestion_config',
              'feature_configs',
              0,
              'query_config',
              'knowledge_base_query_source',
              'knowledge_bases',
              0,
            ]}
            label={t('related_knowledge_bases')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <KnowledgeBasesByAgentSelector
              placeholder={t('select_knowledge_base')}
              key={createdKey}
              dfAgentId={dfAgentId}
              onNewKB={onNewKnowledgeBase}
            />
          </Form.Item>
          <KnowledgeBaseModal
            isVisible={isVisibleKBModal}
            setVisible={setVisibleKBModal}
            dfAgentId={dfAgentId}
            setCreatedKey={setCreatedKey}
          />
        </>
      )}
      {(suggestionType === SuggestionFeatureTypes.DIALOGFLOW_ASSIST ||
        suggestionType === SuggestionFeatureTypes.FAQ) && (
        <>
          <StyledFormItem
            name={[
              'human_agent_assistant_config',
              'human_agent_suggestion_config',
              'feature_configs',
              0,
              'enable_inline_suggestion',
            ]}
            valuePropName="checked"
            label={t('inline_suggestion')}
          >
            <Switch />
          </StyledFormItem>
          <StyledFormItem
            name={[
              'human_agent_assistant_config',
              'human_agent_suggestion_config',
              'feature_configs',
              0,
              'enable_event_based_suggestion',
            ]}
            valuePropName="checked"
            label={t('event_suggestion')}
          >
            <Switch />
          </StyledFormItem>
          <StyledFormItem
            name={[
              'human_agent_assistant_config',
              'human_agent_suggestion_config',
              'group_suggestion_responses',
            ]}
            valuePropName="checked"
            label={t('group_suggestion')}
          >
            <Switch />
          </StyledFormItem>
        </>
      )}
    </>
  );
};
