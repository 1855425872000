import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { KnowledgeBasesForm } from '../KnowledgeBasesForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IKnowledgeBasesValues, IKnowledgeBases } from '../types';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';

export const KnowledgeBasesEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IKnowledgeBases>(
    [KnowledgeBasesService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      KnowledgeBasesService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [KnowledgeBasesService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    KnowledgeBasesService.update,
  );

  const onSubmit = async (data: Partial<IKnowledgeBasesValues>): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('knowledge_base_updated_notify', {
                    name: data?.['dialogflow/knowledge_base'].display_name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: () => {
            notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IKnowledgeBasesValues = {
    display_name: data.display_name,
    dialogflow_agent_id: data.dialogflow_agent.id,
  };

  return (
    <Content imgBg={false}>
      <KnowledgeBasesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        isEditing
      />
    </Content>
  );
};
