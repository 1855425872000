import React, { useMemo, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { getHighlightedText } from '../../../../utils/formatText';
import { getPublicationStatus, getProviderIcon } from '../../../../utils/knowledge';
import { formatDate, getFormattedDate } from '../../../../utils/date';
import { getNextPageQsStringify } from '../../../../utils/queryString';
import { getTableMiscData } from '../../../../utils/table';
import { unexpectedError } from '../../../../utils/notification';
import { KnowledgeIntentService } from '../../../../services/knowledge/intents';
import { useIntegrations, IKnowledgeUrl } from '../../../../state/integrations';
import { useAccount } from '../../../../state/account';
import { useColumnSearchProps } from '../../../../hooks/useColumnSearchProps';
import { useDateColumnFilter } from '../../../../hooks/useDateColumnFilter';
import { usePrefetchData } from '../../../../hooks/usePrefetchData';
import { useCreate } from '../../../../hooks/useCreate';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { TableAction } from '../../../../components/TableAction';
import { AlertBanner } from '../../../../components/AlertBanner';
import { DataTable, TableParams } from '../../../../components/DataTable';
import { FullScreenSpinner } from '../../../../components/Spinner';
import { STATUSES, PROVIDERS } from '../../../../constants';
import { ColumnPropsKnowledge, IBatch, IIntents, IIntentSummary, Status } from '../../types';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';

const getParams = (params: TableParams) => ({
  page: params.pagination?.current,
  pageSize: params.pagination?.pageSize,
  'q[s]': params.sortOrder && `${params.sortField} ${params.sortOrder}`,
  'q[title_cont]': params.filters?.title?.[0],
  'q[destination_destination_type_eq]':
    params.filters?.destination?.length && params.filters.destination,
  'q[synchronisations_presentation_title_cont]': params.filters?.['synchronisations.title']?.[0],
  'q[updated_at_gteq]':
    params.filters?.updated_at?.length && getFormattedDate(params.filters.updated_at[0], true),
  'q[updated_at_lteq]':
    params.filters?.updated_at?.length &&
    getFormattedDate(params.filters.updated_at[params.filters.updated_at.length - 1], false),
  'q[g][0][synchronisations_status_eq_any[]': getStatuses(
    params.filters?.['synchronisations.status'],
  ),
  'q[g][0][synchronisations_id_null]': hasMissingStatus(
    params.filters?.['synchronisations.status'],
  ),
  'q[g][0][m]': params?.filters?.['synchronisations.status'] ? 'or' : undefined,
});

const hasMissingStatus = (values: FilterValue | null | undefined): boolean | undefined =>
  values?.includes('missing') ? true : undefined;

const getStatuses = (values: FilterValue | undefined | null): FilterValue | undefined => {
  if (values && values.length > 0) {
    const filteredValues = values.filter(item => item !== 'missing');
    if (filteredValues.length > 0) {
      return filteredValues;
    }
  }
  return undefined;
};
export const IntentsList: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const { notification } = App.useApp();
  const { activeProjectId } = useAccount();
  const [searchParams] = useSearchParams();
  const { knowledge } = useIntegrations();
  const [isNotification, setNotification] = useState<boolean>(false);
  const [isIntentsEmpty, setIntentsEmpty] = useState<boolean>(false);
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isSync, setIsSync] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<number>();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: Number(searchParams.get('page')) || 1, pageSize: 10 },
  });
  const queryString = qs.stringify(getParams(tableParams), {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });
  const { data, isLoading, refetch } = useQuery<IIntents>(
    [KnowledgeIntentService.queryKey, activeProjectId, knowledge?.externalId, queryString],
    ({ signal }) => KnowledgeIntentService.get(knowledge as IKnowledgeUrl, queryString, signal),
    { enabled: !isSync && !!knowledge },
  );
  const { mutate: publishItems } = useCreate(
    [KnowledgeIntentService.queryKey],
    KnowledgeIntentService.publish,
  );
  const { data: syncResponse, isFetching } = useQuery<string>(
    [KnowledgeIntentService.importQueryKey, activeProjectId, knowledge?.externalId, queryString],
    ({ signal }) => KnowledgeIntentService.sync(knowledge as IKnowledgeUrl, '', signal),
    { enabled: isSync },
  );
  const { data: batchResponse } = useQuery<IBatch>(
    [KnowledgeIntentService.publishQueryKey, activeProjectId, knowledge?.externalId],
    ({ signal }) =>
      KnowledgeIntentService.getBatchResponse(
        batchId as number,
        knowledge as IKnowledgeUrl,
        signal,
      ),
    { enabled: !!batchId, refetchInterval: 2000, cacheTime: 0 },
  );

  useEffect(() => {
    if (batchResponse && batchResponse.status !== 'in_progress') {
      setBatchId(undefined);
      refetch().then(() => notification.success({ message: t('operation_completed') }));
    }
  }, [batchResponse]);

  useEffect(() => {
    if (syncResponse) {
      const regex = /data: \s*({.*})/g;
      const dataMatch = syncResponse.match(regex);
      setIsSync(false);
      if (dataMatch && dataMatch.length > 1) {
        try {
          const dataObject = JSON.parse(dataMatch[1].slice(6)); // slice(6) removes "data: " from response string
          if (dataObject.error) {
            setNotification(true);
          }
        } catch {
          setNotification(true);
        }
      } else {
        setNotification(true);
      }
    }
  }, [syncResponse, isFetching]);

  useEffect(() => {
    if (!isLoading && !data?.data?.length) {
      setIntentsEmpty(true);
    } else {
      setIntentsEmpty(false);
    }
  }, [isLoading, data]);

  usePrefetchData({
    totalPages: data?.pagination?.total_pages || 0,
    paginationPage: tableParams.pagination?.current || 0,
    queryKey: [
      KnowledgeIntentService.queryKey,
      activeProjectId,
      knowledge?.externalId,
      getNextPageQsStringify(getParams, tableParams),
    ],
    queryFn: ({ signal }) =>
      KnowledgeIntentService.get(
        knowledge as IKnowledgeUrl,
        getNextPageQsStringify(getParams, tableParams),
        signal,
      ),
  });

  const intents = useMemo(() => {
    if (!data?.data?.length) {
      return [];
    }

    return data.data.map(item => ({
      ...item,
      synchronisations: item.synchronisations.length
        ? {
            status: item.synchronisations[0].status,
            title: item.synchronisations[0].presentation.title,
          }
        : { status: 'missing' as Status, title: '-' },
    }));
  }, [data]);

  const handlePublish = async () => {
    const selectedSynchronizations = data?.data.filter(el =>
      selectedRowKeys.includes(el.id.toString()),
    );
    const selectedIntents = intents.filter(el => selectedRowKeys.includes(el.id.toString()));
    const result = selectedSynchronizations?.map(i => i.synchronisations[0].id.toString());

    publishItems(
      { data: knowledge, values: result },
      {
        onError: () => notification['error'](unexpectedError),
        onSuccess: response => {
          setBatchId(response?.id);
          selectedIntents.forEach(el => {
            el.synchronisations.status = 'in_progress';
          });
        },
      },
    );
    setSelectedRowKeys([]);
  };

  const ButtonRefresh = (
    <ActionButton
      disabled={isFetching}
      type="sync"
      onClick={() => {
        if (isNotification) {
          setNotification(false);
        }
        setIsSync(true);
      }}
    />
  );

  const ButtonPublish = (
    <Button
      onClick={handlePublish}
      type="link"
      size="small"
      icon={<CheckIcon width={12} height={12} />}
    >
      {t('publish')}
    </Button>
  );

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 46,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys && setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: IIntentSummary) => ({
      disabled: record.synchronisations.status !== 'draft',
    }),
  };

  const columns: ColumnPropsKnowledge<IIntentSummary>[] = [
    {
      title: t('name'),
      width: 200,
      fixed: 'left',
      dataIndex: 'title',
      ...useColumnSearchProps('title', t('searchNamePlaceholder'), setSearchName),
      sorter: true,
      render: text => (searchName ? getHighlightedText(text, searchName) : text),
    },
    {
      title: t('providers'),
      width: 200,
      dataIndex: 'destination',
      filters: PROVIDERS,
      filterIcon: () => <FilterIcon width={10} />,
      render: provider => (
        <>
          {getProviderIcon(provider.destination_type, true)} {provider.name}
        </>
      ),
    },
    {
      title: t('presentations'),
      width: 200,
      dataIndex: ['synchronisations', 'title'],
      ...useColumnSearchProps(
        ['synchronisations', 'title'],
        t('searchNamePlaceholder'),
        setSearchName,
      ),
      // disabled because backend doesn't support this kind of sorting
      sorter: false,
      render: text => (searchName ? getHighlightedText(text, searchName) : text),
    },
    {
      title: t('last_updated'),
      dataIndex: 'updated_at',
      width: 120,
      sorter: true,
      ...useDateColumnFilter('updated_at', searchDate, setSearchDate),
      render: date =>
        searchDate.length ? searchDate.find(item => item == formatDate(date)) : formatDate(date),
    },
    {
      title: t('status'),
      dataIndex: ['synchronisations', 'status'],
      width: 70,
      filters: STATUSES,
      filterIcon: () => <FilterIcon width={10} />,
      render: status => getPublicationStatus(status),
    },
  ];

  return (
    <div style={{ marginTop: 36 }}>
      {isNotification && (
        <AlertBanner
          message={t('sync_error')}
          type="error"
          afterClose={() => setNotification(false)}
        />
      )}
      {isIntentsEmpty && (
        <AlertBanner
          message={t('no_intents_notification')}
          type="info"
          afterClose={() => setIntentsEmpty(false)}
        />
      )}

      {isFetching && <FullScreenSpinner />}

      <Content imgBg={false} Actions={[ButtonRefresh]}>
        <TableAction
          selectedRows={selectedRowKeys.length}
          setSelectedRowKeys={setSelectedRowKeys}
          actions={[ButtonPublish]}
        />
        <DataTable
          loading={isLoading && !isSync}
          dataSource={intents || []}
          columns={columns}
          rowSelection={rowSelection}
          totalItems={data?.pagination?.total_count}
          isClickable={false}
          onChange={(pagination, filters, sorter) => {
            setTableParams(getTableMiscData(pagination, filters, sorter));
          }}
        />
      </Content>
    </div>
  );
};
