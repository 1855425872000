import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExternalLink } from '../../assets/icons/external-link.svg';
import { TruncatedString } from '../TruncatedString';

interface ILinkBox {
  title?: string;
  url: string;
  width?: string;
}

export const LinkBox: React.FC<ILinkBox> = ({ title, url }) => {
  const { t } = useTranslation();
  return (
    <Root>
      {title && <Title>{title}</Title>}
      <Box>
        <TruncatedString>{url}</TruncatedString>
        <Link href={url} target="_blank">
          <ExternalLink width={16} height={16} /> {t('open')}
        </Link>
      </Box>
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: ${({ theme }) => theme.formItemMargin};
`;

const Title = styled.div`
  padding: ${({ theme }) => theme.labelPadding};
  font-size: ${({ theme }) => theme.labelFontSize};
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.inputHeight};
  padding: ${({ theme }) => `7px ${theme.inputPaddingHorizontal}`};
  background-color: #f5f5fa;
  border: ${({ theme }) => `1px solid ${theme.secondaryBg}`};
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.linkColor};
  margin-left: 10px;
  svg {
    margin-right: 6px;
  }
`;
