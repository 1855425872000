import styled from '@emotion/styled';

export const StyledTabs = styled.div`
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: none;
    margin: 0 28px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
    visibility: visible;
  }
  .ant-tabs-nav-add {
    border: none !important;
  }

  .ant-tabs-tab:first-of-type,
  .ant-tabs-tab-active:first-of-type {
    margin-left: 0 !important;
  }
  .ant-tabs-tab-remove {
    visibility: hidden;
    position: absolute;
    right: -17px;
  }
  .ant-tabs-tab-with-remove:hover .ant-tabs-tab-remove {
    visibility: visible;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add {
    min-width: 20px;
    padding: 0;
  }
`;
