import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { ExternalEndpointsService } from '../../../../../services/ai/external-endpoints';
import { IExternalEndpointValues } from '../types';
import { Content } from '../../../../../components/Content';
import { ExternalEndpointsForm } from '../ExternalEndpointsForm';
import { ActionButton } from '../../../../../components/ActionButton';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';

export const ExternalEndpointsEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IExternalEndpointValues>(
    [ExternalEndpointsService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      ExternalEndpointsService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [ExternalEndpointsService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    ExternalEndpointsService.update,
  );

  const onSubmit = async (
    data: IExternalEndpointValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('external_endpoint_updated_notify', { name: data.outbound_contact_uri.name })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      );
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues = {
    name: data.name,
    endpoint: data.endpoint,
    router_id: data.router_id?.toString(),
  };

  return (
    <Content imgBg={false}>
      <ExternalEndpointsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
