import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { TeamsForm } from '../TeamsForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { ITeamsValues, ITimeSchedule } from '../types';
import { TeamsService } from '../../../../../services/ai/teams';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { dayOrder } from '../../../../../constants';

export const TeamsNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate([TeamsService.queryKey], TeamsService.create);

  const onSubmit = async (
    data: ITeamsValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('teams_created_notify', {
                  name: data?.team.name,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const time_schedules_attributes: ITimeSchedule[] = dayOrder.map(day => ({
    day,
    start_time: dayjs('09:00 AM', 'h:mm A'),
    end_time: dayjs('05:00 PM', 'h:mm A'),
    closed: false,
    state: false,
  }));

  const initialValues: ITeamsValues = {
    name: '',
    skill: '',
    workflow_sid: '',
    timezone: undefined,
    is_default: false,
    time_schedules_attributes: time_schedules_attributes,
  };

  return (
    <Content imgBg={false}>
      <TeamsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
