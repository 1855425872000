import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Paths } from '../../../../types/paths';
import { KnowledgeParameterService } from '../../../../services/knowledge/parameter';
import { KnowledgeDestinationService } from '../../../../services/knowledge/destination';
import { useUpdate } from '../../../../hooks/useUpdate';
import { useGetItem } from '../../../../hooks/useGetItem';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { unexpectedError } from '../../../../utils/notification';
import { getDestinationParameterData } from '../../../../utils/knowledge';
import { CenteredSpinner } from '../../../../components/Spinner';
import { Content } from '../../../../components/Content';
import { IDestinations, IParameter, IParameterPayload, IParameterValues } from '../../types';
import { ParameterForm } from '../ParameterForm';
import { useAccount } from '../../../../state/account';
import { ActionButton } from '../../../../components/ActionButton';

export const EditParameter: React.FC = () => {
  const { notification } = App.useApp();
  const { knowledge } = useIntegrations();
  const { id } = useParams() as { id: string };
  const { activeProjectId } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation('knowledge');

  const { data, isLoading, error } = useGetItem<IParameter>(
    [KnowledgeParameterService.queryKey, activeProjectId, knowledge?.externalId, id],
    ({ signal }) => KnowledgeParameterService.find(knowledge as IKnowledgeUrl, id, signal),
    { enabled: !!knowledge, retry: 0 },
  );

  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate(Paths.knowledgeParameters(), { replace: true });
      notification['error']({ message: t('record_not_found') });
    }
  }, [error]);

  const initialValues = !!data
    ? {
        key: data.key,
        value: data.value,
        children_attributes: data.children.map(item => ({
          id: item.id,
          configurable_id: item.configurable_id,
          configurable_name: item.configurable.name,
          value: item.value,
        })),
      }
    : undefined;

  const { data: destinations } = useQuery<IDestinations>(
    [KnowledgeDestinationService.queryKey, activeProjectId, knowledge?.externalId],
    ({ signal }) => KnowledgeDestinationService.get(knowledge as IKnowledgeUrl, signal),
    { enabled: !!knowledge },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate<{
    id: string;
    data: IKnowledgeUrl;
    values: IParameterPayload;
  }>(
    [KnowledgeParameterService.queryKey, knowledge?.externalId as string, id],
    KnowledgeParameterService.update,
  );

  const onSubmit = async (values: IParameterValues) => {
    await updateItem(
      {
        id,
        data: knowledge as IKnowledgeUrl,
        values: {
          configurable_id: Number(knowledge?.externalId),
          configurable_type: 'Project',
          ...values,
          children_attributes: getDestinationParameterData(
            initialValues?.children_attributes || [],
            values.children_attributes,
            values.key,
          ),
        },
      },
      {
        onSuccess: data => {
          if (!data.error) {
            navigate(Paths.knowledgeParameters());
            notification['success']({
              message: <Trans t={t}>{t('parameter_updated_notify', { name: data.key })}</Trans>,
            });
          }
        },
        onError: () => notification['error'](unexpectedError),
      },
    );
  };

  return (
    <Content imgBg={false}>
      {isLoading ? (
        <CenteredSpinner height="50vh" />
      ) : (
        <ParameterForm
          initialValues={initialValues}
          destinations={destinations?.data || []}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        />
      )}
    </Content>
  );
};
