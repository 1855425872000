import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CodeIcon } from '../../../../../../assets/icons/code.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check.svg';
import { ActionButton } from '../../../../../../components/ActionButton';
import { StyledCodeSnippet } from '../../../../../../components/CodeSnippet';
import { useIntegrations } from '../../../../../../state/integrations';
import { useAccount } from '../../../../../../state/account';
import { CONSOLE_CHAT_ID } from '../../../../../../constants';

interface IEmbedCodeModal {
  isVisible: boolean;
  setVisible: (args: boolean) => void;
  profileId: string;
}

export const getSnippet = (
  accountId: string,
  projectId: string,
  consoleProjectId: string,
  url: string,
  profileId: string,
) =>
  `<!-- Start of Console Chat code -->\n` +
  `<div id="${CONSOLE_CHAT_ID}" data-account-id="${accountId}" data-project-id="${projectId}" data-project-console-id="${consoleProjectId}" data-profile-id="${profileId}"></div>\n` +
  `<script src="${url}" async></script>\n` +
  `<!-- End of Console Chat code -->`;

export const EmbedCodeModal: React.FC<IEmbedCodeModal> = ({ isVisible, setVisible, profileId }) => {
  const { t } = useTranslation('design');
  const { chat } = useIntegrations();
  const { activeProjectId } = useAccount();

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={680}
      open={isVisible}
      onCancel={handleCancel}
      title={
        <>
          <CodeIcon width={20} height={16} /> {t('embed_code')}
        </>
      }
      footer={[
        <ActionButton type="cancel" key="cancel" text={t('close')} onClick={handleCancel} />,
      ]}
    >
      <p style={{ marginBottom: '8px' }}>{t('embed_code_desc')}</p>
      {!!chat && activeProjectId && (
        <StyledCodeSnippet
          copyable={{
            icon: [
              <CopyIcon key="copy-icon" width={14} height={14} />,
              <CheckIcon key="copied-icon" width={14} height={14} />,
            ],
          }}
          code={true}
        >
          {getSnippet(
            chat.metadata.account_id.toString(),
            chat.metadata.project_id.toString(),
            activeProjectId,
            chat.url,
            profileId,
          )}
        </StyledCodeSnippet>
      )}
    </Modal>
  );
};
