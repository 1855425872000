import React from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { equals } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IExternalEndpointValues } from '../types';
import { Space } from '../../../../../components/Space';
import { useModal } from '../../../../../hooks/useModal';
import { useIntegrations } from '../../../../../state/integrations';
import { AiIntegrationParams } from '../../../../../services/api';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { RoutersService } from '../../../../../services/ai/routers';

interface Props {
  initialValues: IExternalEndpointValues;
  onSubmit: (values: IExternalEndpointValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const ExternalEndpointsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const { aiIntegration } = useIntegrations();

  const { data } = useQuery(
    [RoutersService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => RoutersService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item
          name="endpoint"
          label={t('endpoint')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_endpoint')} />
        </Form.Item>
        <Form.Item
          name="router_id"
          label={t('router')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select placeholder={t('select_router')}>
            {data?.map(item => (
              <Select.Option key={item.id.toString()} value={item.id.toString()}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
