import React from 'react';
import { Alert } from '../../../components/Alert';

export type LoginFormErrors = { [key: string]: ErrorMessage };

export interface ErrorMessage {
  title: string;
  body: string;
}

interface IErrorMessages {
  errors: LoginFormErrors;
}

export const ErrorMessages: React.FC<IErrorMessages> = ({ errors }) => {
  return (
    <>
      {Object.keys(errors).map(
        key =>
          errors[key] && (
            <Alert key={key} title={errors[key].title}>
              {errors[key].body}
            </Alert>
          ),
      )}
    </>
  );
};
