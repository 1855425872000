import styled from '@emotion/styled';
import { Form } from 'antd';

export const Description = styled.div`
  margin: 8px 0 20px 0;
`;

export const To = styled.div<{ firstIndex: string }>`
  margin-top: ${({ firstIndex }) => (firstIndex === 'true' ? '33px' : '10px')};
`;

export const TimeSchedule = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const FormTimePicker = styled(Form.Item)`
  .ant-form-item-explain-error {
    text-wrap: nowrap;
  }
  .ant-picker-panel {
    padding: 0 !important;
  }
`;
