import styled from '@emotion/styled';

const Root = styled.header`
  position: fixed;
  left: ${props => props.theme.navWidth};
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.headerHeight};
  padding: 14px 20px 14px 40px;
  background-color: #fff;
  z-index: 20;
  @media (max-width: 1024px) {
    padding-left: 15px;
  }

  h1 {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    svg {
      flex: 1 0 auto;
      margin: 1px 7px 0 0;
    }
    svg path {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const Controls = styled.div`
  display: flex;
`;

const User = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 10px;
  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.linkColor};
  color: #fff;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamilyAccent};
`;

export const StyledHeader = {
  Root,
  Controls,
  User,
};
