import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface Props {
  width?: number;
  maxWidth?: number;
  isShowFullOnHover?: boolean;
  isPointerEventsDisabled?: boolean;
  color?: string;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const TruncatedString: React.FC<Props> = props => {
  const {
    width,
    isShowFullOnHover = false,
    isPointerEventsDisabled = false,
    children,
    color,
    align,
    style,
  } = props;
  const [isTooltip, setIsTooltip] = useState<boolean>(false);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const elWidth = ref.current?.getBoundingClientRect().width;
    const parentWidth = ref.current?.parentElement?.clientWidth;
    if (elWidth && parentWidth && elWidth > parentWidth && isShowFullOnHover) {
      setIsTooltip(true);
    }
  }, []);

  return (
    <>
      {isTooltip ? (
        <Tooltip
          placement="bottom"
          title={children}
          overlayClassName={isPointerEventsDisabled ? 'no-pointer-events' : ''}
        >
          <Root style={style} elWidth={width} maxWidth={props.maxWidth} align={align} color={color}>
            <span ref={ref}>{children}</span>
          </Root>
        </Tooltip>
      ) : (
        <Root style={style} elWidth={width} align={align} color={color}>
          <span ref={ref}>{children}</span>
        </Root>
      )}
    </>
  );
};

interface CssProp {
  elWidth?: number;
  maxWidth?: number;
  color?: string;
  align?: 'left' | 'center' | 'right';
}

const Root = styled.div<CssProp>`
  ${({ elWidth }) =>
    elWidth &&
    css`
      width: ${elWidth}px;
    `}
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
