import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChartIcon } from '../../../../../assets/icons/chart-network.svg';
import { useModal } from '../../../../../hooks/useModal';
import flowDiagramImg from '../../../../../assets/images/flow-diagram.png';
import { ActionButton } from '../../../../../components/ActionButton';

export const FlowDiagram: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const { visible, openModal, closeModal } = useModal(false);

  return (
    <>
      <Button icon={<ChartIcon width={14} />} onClick={openModal} disabled={visible}>
        {t('flow_diagram')}
      </Button>
      <Modal
        width={808}
        title={
          <>
            <ChartIcon width={18} height={16} /> {t('flow_diagram')}
          </>
        }
        centered
        open={visible}
        footer={[<ActionButton type="cancel" key="cancel" onClick={closeModal} />]}
        onCancel={closeModal}
        styles={{ body: { padding: 0, marginBottom: 0 } }}
      >
        <img src={flowDiagramImg} alt="flow-diagram" />
      </Modal>
    </>
  );
};
