import React, { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledLegalWrapper } from '../LegalWrapper.styles';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';

export const NavigationMenu: React.FC = () => {
  const { t } = useTranslation('legal');
  const { legalPages } = useFlags();
  const [isOpenMenu, setOpenMenu] = useState(false);

  return (
    <div>
      <StyledLegalWrapper.NavigationButton onClick={() => setOpenMenu(!isOpenMenu)}>
        <MenuIcon height={29} />
      </StyledLegalWrapper.NavigationButton>
      <StyledLegalWrapper.Navigation
        onClick={() => setOpenMenu(false)}
        className={isOpenMenu ? 'open' : ''}
      >
        <StyledLegalWrapper.NavigationItem to="/login">
          <Translation ns={'login'}>{t => t('login')}</Translation>
        </StyledLegalWrapper.NavigationItem>
        {legalPages && (
          <StyledLegalWrapper.NavigationItem to="/terms-conditions">
            {t('terms_conditions')}
          </StyledLegalWrapper.NavigationItem>
        )}
        <StyledLegalWrapper.NavigationItem to="/fair-use-policy">
          {t('fair_use_policy')}
        </StyledLegalWrapper.NavigationItem>
        {legalPages && (
          <StyledLegalWrapper.NavigationItem to="/security-policy">
            {t('security_policy')}
          </StyledLegalWrapper.NavigationItem>
        )}
      </StyledLegalWrapper.Navigation>
    </div>
  );
};
