import React from 'react';
import { Button, Checkbox, Divider, Form, Input, InputNumber, Slider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { StyledCheckboxGroup } from '../VirtualAssistant.styles';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/icons/redo-alt.svg';
import { initialLayout } from '../index';
import { IVAConfigValues } from '../../types';

const { Title } = Typography;

type Props = {
  updateScriptConfig: (changedValues: Partial<IVAConfigValues>) => Promise<void>;
};

export const Layout: React.FC<Props> = ({ updateScriptConfig }) => {
  const { t } = useTranslation('design');
  const form = Form.useFormInstance();
  const isSpeechBubble = Form.useWatch(['config', 'is_speech_bubble']);

  const handleSetDefault = () => {
    const values = form.getFieldsValue(true);
    form.setFieldsValue({
      ...values,
      config: {
        ...values.config,
        ...initialLayout,
      },
    });
    updateScriptConfig({});
  };

  return (
    <>
      <Title level={5} type="secondary">
        {t('va_icon')}
      </Title>
      <Space>
        <Form.Item
          name={['config', 'icon_width']}
          label={t('width')}
          className={formItemClass.base}
        >
          <InputNumber min={20} max={200} />
        </Form.Item>
        <Form.Item
          name={['config', 'icon_height']}
          label={t('height')}
          className={formItemClass.base}
        >
          <InputNumber min={20} max={200} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['config', 'x_axis_offset']}
          label={t('x_axis_offset')}
          className={formItemClass.base}
        >
          <InputNumber min={1} max={500} />
        </Form.Item>
        <Form.Item
          name={['config', 'y_axis_offset']}
          label={t('y_axis_offset')}
          className={formItemClass.base}
        >
          <InputNumber min={1} max={500} />
        </Form.Item>
      </Space>
      <Form.Item
        name={['config', 'is_speech_bubble']}
        style={{ margin: 0 }}
        className={formItemClass.base}
        valuePropName="checked"
      >
        <Checkbox>
          <strong>{t('speech_bubble')}</strong>
        </Checkbox>
      </Form.Item>
      <Form.Item name={['config', 'speech_bubble_text']} className={formItemClass.full}>
        <Input placeholder={t('speech_bubble_placeholder')} disabled={!isSpeechBubble} />
      </Form.Item>
      <Divider />
      <Title level={5} type="secondary">
        {t('va_window')}
      </Title>
      <Space>
        <Form.Item
          name={['config', 'window_width']}
          label={t('width')}
          className={formItemClass.base}
        >
          <InputNumber min={290} max={900} />
        </Form.Item>
        <Form.Item
          name={['config', 'window_height']}
          label={t('height')}
          className={formItemClass.base}
        >
          <InputNumber min={320} max={900} />
        </Form.Item>
      </Space>
      <Form.Item
        name={['config', 'border_radius']}
        label={t('round_corners')}
        className={formItemClass.base}
      >
        <Slider
          min={0}
          max={20}
          tooltip={{
            open: true,
            getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
          }}
        />
      </Form.Item>
      <Divider />
      <Title level={5} type="secondary">
        {t('advance_options')}
      </Title>
      <p>{t('advance_options_desc')}</p>
      <StyledCheckboxGroup>
        <Form.Item name={['config', 'advance_options', 'avatar_icon']} valuePropName="checked">
          <Checkbox>{t('avatar_icon_header')}</Checkbox>
        </Form.Item>
        <Form.Item name={['config', 'advance_options', 'text_input']} valuePropName="checked">
          <Checkbox>{t('text_input_for_user')}</Checkbox>
        </Form.Item>
        <Form.Item name={['config', 'advance_options', 'upload_button']} valuePropName="checked">
          <Checkbox>{t('upload_button')}</Checkbox>
        </Form.Item>
      </StyledCheckboxGroup>
      <Divider />
      <Button icon={<ArrowIcon width={13} height={13} />} onClick={handleSetDefault}>
        {t('set_to_default')}
      </Button>
    </>
  );
};
