import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfoChannels } from '../../AI.styles';
import { ViewLink } from '../../../../components/ViewLink';
import { ReactComponent as WebIcon } from '../../../../assets/icons/robot.svg';
import { ReactComponent as VoiceIcon } from '../../../../assets/icons/phone-solid.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/icons/message-sms.svg';
import { ReactComponent as MessengerIcon } from '../../../../assets/icons/messenger_actual.svg';
import { ReactComponent as TeamsIcon } from '../../../../assets/icons/teams_actual.svg';
import { ReactComponent as WhatsAppIcon } from '../../../../assets/icons/whatsapp_actual.svg';
import { ReactComponent as ViewAllIcon } from '../../../../assets/icons/eye.svg';
import { TitleWithIcon } from '../../../../components/TitleWithIcon';
import { Paths } from '../../../../types/paths';
import { ChannelTypes } from '../Channel/types';

export const ChannelsList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { allChannels } = useFlags();

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard
            title={<TitleWithIcon title={t('channel_web')} icon={WebIcon} />}
            bordered={false}
          >
            <CardInfoChannels>{t('channel_web_desc')}</CardInfoChannels>
            <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.web })} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard
            title={<TitleWithIcon title={t('channel_voice')} icon={VoiceIcon} />}
            bordered={false}
          >
            <CardInfoChannels>{t('channel_voice_desc')}</CardInfoChannels>
            <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.voice })} />
          </StyledCard>
        </Col>
      </Row>
      <Row style={{ margin: '20px 0 10px' }}>
        <Col style={{ fontWeight: 'bold' }}>{t('others')}</Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard
            title={<TitleWithIcon title={t('channel_messenger')} icon={MessengerIcon} />}
            bordered={false}
          >
            <CardInfoChannels>{t('channel_messenger_desc')}</CardInfoChannels>
            <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.messenger })} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard
            title={<TitleWithIcon title={t('channel_sms')} icon={SmsIcon} />}
            bordered={false}
          >
            <CardInfoChannels>{t('channel_sms_desc')}</CardInfoChannels>
            <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.sms })} />
          </StyledCard>
        </Col>
        {allChannels && (
          <>
            <Col xs={24} sm={12} lg={8} xxl={6}>
              <StyledCard
                title={<TitleWithIcon title={t('channel_teams')} icon={TeamsIcon} />}
                bordered={false}
              >
                <CardInfoChannels>{t('channel_teams_desc')}</CardInfoChannels>
                <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.teams })} />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8} xxl={6}>
              <StyledCard
                title={<TitleWithIcon title={t('channel_whatsapp')} icon={WhatsAppIcon} />}
                bordered={false}
              >
                <CardInfoChannels>{t('channel_whatsapp_desc')}</CardInfoChannels>
                <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.whatsapp })} />
              </StyledCard>
            </Col>
          </>
        )}
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard
            title={<TitleWithIcon title={t('view_all')} icon={ViewAllIcon} />}
            bordered={false}
          >
            <CardInfoChannels>{t('view_all_desc')}</CardInfoChannels>
            <ViewLink to={Paths.aiChannelList({ channel: ChannelTypes.all })} />
          </StyledCard>
        </Col>
      </Row>
    </Content>
  );
};
