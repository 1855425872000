import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { RoutersService } from '../../../../../services/ai/routers';
import { IRouter } from '../types';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { Paths } from '../../../../../types/paths';

export const RoutersList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiRoutersEdit({ id })),
  });

  const columns: ColumnProps<IRouter>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '22%',
    },
    {
      title: t('outbound_contact_uri'),
      onCell: onCellHandler,
      dataIndex: 'outbound_contact_uri',
      width: '25%',
    },
    {
      title: t('protocol'),
      onCell: onCellHandler,
      dataIndex: 'protocol',
      width: '20%',
    },
    {
      title: t('routing_method'),
      onCell: onCellHandler,
      dataIndex: 'routing_method',
      width: '20%',
    },
    {
      title: t('default'),
      onCell: onCellHandler,
      dataIndex: 'is_default',
      width: '10%',
      render: record => <IconIndicator enabled={record} isStatus />,
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IRouter>
      service={RoutersService}
      columns={columns}
      getCreatePath={Paths.aiRoutersCreate}
      deleteConfirmationTitle={t('delete_router')}
      getDisplayName={record => record.name}
      getIsDefault={record => record.is_default}
    />
  );
};
