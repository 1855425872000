import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IdCard } from '../../../assets/icons/id-card.svg';
import { FixedNav } from '../../../components/FixedNav';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';

export const UserNav: React.FC = () => {
  const { t } = useTranslation('user_settings');

  const menuItems = [
    {
      key: '/user-settings',
      icon: <IdCard width={16} height={16} />,
      label: <Link to=".">{t('personal')}</Link>,
    },
  ];
  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
