import React from 'react';
import { Divider, Form, Input, Upload, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
import styled from '@emotion/styled';
import { formItemClass } from '../../../../theme';
import { Space } from '../../../../components/Space';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload.svg';
import { getBase64, normalizeFile } from '../../../../utils/files';
import { ProjectControl } from '../ProjectControl';
import { ProductSwitch } from './ProductSwitch';
import { projectProducts } from '../../constants';

export const ProjectDetails: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const form = Form.useFormInstance();
  const { getFieldValue, setFieldValue } = form;
  const ai_n_a = Form.useWatch('ai_n_a', form);
  const pulse = Form.useWatch('pulse', form);
  const { projectLogo, pulse: pulseFlag } = useFlags();
  const productsList = pulseFlag
    ? projectProducts
    : projectProducts.filter(product => product.value !== 'pulse');

  const handleLogoChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    const url = await getBase64(info.file.originFileObj as RcFile);
    setFieldValue('imageUrl', url);
  };

  return (
    <>
      <Space>
        <Form.Item
          name="name"
          label={t('project_name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('project_name')} />
        </Form.Item>

        {projectLogo && (
          <Form.Item
            name="project_logo"
            label={t('project_logo')}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
            style={{ marginLeft: '40px' }}
          >
            <Upload
              accept=".png,.jpg,.jpeg"
              listType="picture-card"
              showUploadList={false}
              onChange={handleLogoChange}
            >
              {getFieldValue('imageUrl') ? (
                <img src={getFieldValue('imageUrl')} alt="project-logo" style={{ width: '100%' }} />
              ) : (
                <div>
                  <UploadIcon width={12} height={12} /> {t('upload')}
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
      </Space>
      <Divider className={formItemClass.full} />
      <b>{t('products')} </b>
      <ProductsDescription>
        {t(!id ? 'products_description' : 'available_products')}
      </ProductsDescription>
      {productsList.map((product, index) => (
        <ProductSwitch
          key={product.value}
          product={product}
          // remove the !id below once the BE allows products changes on Edit
          required={!pulse && !ai_n_a && !id}
          isLastItem={index === productsList.length - 1}
          tooltip={t(`tooltip_${product.value}`)}
          disabled={!!id}
          style={{ marginBottom: '8px' }}
        />
      ))}
      {!!id && <ProjectControl />}
    </>
  );
};

const ProductsDescription = styled.div`
  margin: 8px 0 16px 0;
`;
