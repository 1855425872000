import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IAutoRespTemplate,
  IAutoRespTemplateValues,
} from '../../pages/AI/Automation/AutomatedResponseTemplates/types';
import { IAiIntegrationUrl } from '../../state/integrations';
import { sortById } from '../../utils/sorting';

const queryKey = 'automated-response-templates';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutoRespTemplate[]> => {
  const response = await integrationQuery(
    `/api/automated_response_templates?includes[]=automated_channel&includes[]=dialogflow_agent`,
    {
      apiData,
      signal,
    },
  );
  return sortById(response.automated_response_templates);
};

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutoRespTemplate> => {
  const response = await integrationQuery(`/api/automated_response_templates/${id}`, {
    apiData,
    signal,
  });
  return response?.automated_response_template;
};

const create = async ({ data, apiData }: { data: IAutoRespTemplateValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/automated_response_templates`, {
    method: 'POST',
    data: { data: { type: 'automated_response_templates', attributes: data } },
    apiData,
  });

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/automated_response_templates/${id}}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IAutoRespTemplateValues>;
}): Promise<IAutoRespTemplate> => {
  return integrationQuery(`/api/automated_response_templates/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'automated_response_templates',
        attributes: data,
      },
    },
    apiData,
  });
};

export const AutomatedResponseTemplateService = {
  queryKey,
  get,
  find,
  create,
  update,
  remove,
};
