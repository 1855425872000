import 'react-quill/dist/quill.snow.css';
import React, { MutableRefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { RangeStatic } from 'quill';
import 'quill-paste-smart';
import { StyledEditorOverlay } from './TextEditor.styles';
import { isRichTextEditor } from '../../pages/Knowledge/Presentations/utils';

const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');
const Parchment = Quill.import('parchment');

Break.prototype.insertInto = function (parent: HTMLElement, ref: MutableRefObject<HTMLElement>) {
  Embed.prototype.insertInto.call(this, parent, ref);
};
Break.prototype.length = () => 1;
Break.prototype.value = () => '\n';

type Props = ReactQuillProps & {
  toolbar?: React.ReactElement;
  isDisabled?: boolean;
};

const quillFormats = ['bold', 'italic', 'underline', 'list', 'link', 'break'];

function brMatcher() {
  const newDelta = new Delta();
  return newDelta.insert({ break: true });
}

function shiftEnterHandler(editor: ReactQuill['editor'], range: RangeStatic, id?: string) {
  if (id && !isRichTextEditor(id)) return null;
  const nextChar = editor?.getText(range.index + 1, 1);
  const currentLeaf = editor?.getLeaf(range.index)[0];
  const nextLeaf = editor?.getLeaf(range.index + 1)[0];
  editor?.insertEmbed(range.index, 'break', true, 'user');
  if (
    nextChar?.length === 0 ||
    (currentLeaf.parent !== nextLeaf.parent && currentLeaf.constructor.blotName !== 'break')
  ) {
    // second line break inserts only at the end of parent element
    editor?.insertEmbed(range.index, 'break', true, 'user');
  }
  editor?.setSelection({ index: range.index + 1, length: range.length }, 'silent');
}

function enterHandler(editor: ReactQuill['editor'], range: RangeStatic, context: any, id?: string) {
  if (id && !isRichTextEditor(id)) return null;
  if (range.length > 0) {
    editor?.scroll.deleteAt(range.index, range.length); // So we do not trigger text-change
  }
  const lineFormats = Object.keys(context.format).reduce((lineFormats, format) => {
    if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
      (lineFormats as Record<string, any>)[format] = context.format[format];
    }
    return lineFormats;
  }, {}) as Record<string, any>;
  const previousChar = editor?.getText(range.index - 1, 1);
  /* Earlier scroll.deleteAt might have messed up our selection,
     so insertText's built in selection preservation is not reliable */
  editor?.insertText(range.index, '\n', lineFormats, 'user');
  if (previousChar === '' || previousChar === '\n') {
    editor?.setSelection({ index: range.index + 2, length: range.length }, 'silent');
  } else {
    editor?.setSelection({ index: range.index + 1, length: range.length }, 'silent');
  }
  Object.keys(context.format).forEach(name => {
    if (lineFormats[name] !== null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === 'link') return;
    editor?.format(name, context.format[name], 'user');
  });
}

export const TextEditor: React.FC<Props> = ({
  id,
  value,
  onChange,
  toolbar,
  style,
  isDisabled,
  ...other
}): JSX.Element => {
  const quillRef = useRef<ReactQuill>(null);
  const { setFields } = Form.useFormInstance();
  const { t } = useTranslation('knowledge');

  const quillModules: ReactQuillProps['modules'] = useMemo(
    () => ({
      toolbar: toolbar
        ? {
            container: `#toolbar-${id}`,
          }
        : false,
      clipboard: {
        matchers: [['BR', brMatcher]],
        matchVisual: false,
        ...(id &&
          !isRichTextEditor(id) && {
            allowed: {
              tags: [],
              attributes: [],
            },
            hooks: {
              beforeSanitizeElements(node: Node) {
                if (node.nodeName && node.nodeName === '#text') {
                  node.textContent = node.textContent + ' ';
                }
                setTimeout(
                  () =>
                    setFields([
                      {
                        name: ['content_entities_attributes', id, 'payload'],
                        errors: [t('rich_content_cannot_be_used')],
                      },
                    ]),
                  100,
                );
              },
            },
          }),
      },
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler: (range: RangeStatic, context: any) =>
              enterHandler(quillRef.current?.getEditor(), range, context, id),
          },
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: (range: RangeStatic) =>
              shiftEnterHandler(quillRef.current?.getEditor(), range, id),
          },
        },
      },
    }),
    [],
  );

  return (
    <div style={{ pointerEvents: isDisabled ? 'none' : 'all' }}>
      {isDisabled && <StyledEditorOverlay />}
      {toolbar ? React.cloneElement(toolbar, { customRef: quillRef, id: id }) : null}
      <ReactQuill
        readOnly={isDisabled}
        value={value}
        onChange={onChange}
        ref={quillRef}
        modules={quillModules}
        formats={quillFormats}
        style={style}
        {...other}
      />
    </div>
  );
};
