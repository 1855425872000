import { useCallback, useState } from 'react';

export function useModal(initialValue: boolean): {
  visible: boolean;
  openModal: () => void;
  closeModal: () => void;
} {
  const [visible, setVisible] = useState(initialValue);

  const openModal = useCallback(() => setVisible(true), [visible]);
  const closeModal = useCallback(() => setVisible(false), [visible]);

  return {
    visible,
    openModal,
    closeModal,
  };
}
