import styled from '@emotion/styled';
import { theme } from '../../theme';

const Card = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: #fff;
  margin-bottom: ${({ isVisible }) => (isVisible ? '12px' : 0)};
  box-shadow: ${({ theme }) => theme.dropdownShadow};
  color: ${({ theme }) => theme.primaryColor};
  transition: height 0.25s;
  overflow: hidden;
  height: ${({ isVisible }) => (isVisible ? '48px' : 0)};

  .ant-btn-link,
  .ant-btn-text {
    background: transparent !important;
    span:not(.ant-btn-icon) {
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s;
    }
  }
  .ant-btn-link {
    color: ${theme.linkColor} !important;
    &:hover {
      span {
        border-color: ${theme.linkColor};
      }
    }
  }
  .ant-btn-dangerous.ant-btn-link {
    color: ${theme.errorColor} !important;
    &:hover {
      span {
        border-color: ${theme.errorColor};
      }
    }
  }
  .ant-btn-text {
    color: ${theme.textColor} !important;
    &:hover {
      span {
        border-color: ${theme.textColor};
      }
    }
  }
`;

const Part = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Selected = styled.div`
  font-weight: 600;
`;

export const StyledTableAction = { Card, Part, Selected };
