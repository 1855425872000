import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IAutoRespTemplateValues } from '../types';
import { AutomatedResponseTemplateService } from '../../../../../services/ai/automated-response-template';
import { Content } from '../../../../../components/Content';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { AutomatedResponseTemplatesForm } from '../AutomatedResponseTemplatesForm';
import { stripNonAlphanumeric } from '../../../../../utils/string';
import { ActionButton } from '../../../../../components/ActionButton';

export const AutomatedResponseTemplatesNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [AutomatedResponseTemplateService.queryKey],
    AutomatedResponseTemplateService.create,
  );

  const onSubmit = async (
    data: IAutoRespTemplateValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { response_key } = data;
    data.response_key = stripNonAlphanumeric(response_key);
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: response => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('auto_resp_temp_created_notify', {
                  name: response?.automated_response_template?.response_key,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response?.status === 400) {
            const formattedErrors = formatApiErrors(
              Object.assign({}, ...err.response.data?.errors),
            );
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification.error(unexpectedError);
        },
      },
    );
  };

  const initialValues: IAutoRespTemplateValues = {
    body: '',
    response_key: '',
    automated_channel_id: undefined,
    dialogflow_agent_id: undefined,
  };

  return (
    <Content imgBg={false}>
      <AutomatedResponseTemplatesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
