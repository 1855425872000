import React from 'react';
import styled from '@emotion/styled';

export const FixedNav = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  position: fixed;
  left: ${({ theme }) => theme.navWidth};
  right: 0;
  z-index: 10;

  & + div {
    margin-top: ${({ theme }) => theme.menuItemHeight};
  }
`;
