import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { PasswordChecklist } from '../../../components/PasswordChecklist';
import { StyledLoginForm } from '../Login.styles';
import { AuthService } from '../../../services/auth';
import { FloatField } from '../../../components/FloatField';
import { Paths } from '../../../types/paths';

export type PasswordResetValues = {
  token: string;
  newPassword: string;
};

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

export const PasswordReset: React.FC = () => {
  const { i18n, t } = useTranslation('login');
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecklistValid, setChecklistValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = getToken(location.pathname) || '';
  const newPassword = Form.useWatch('newPassword', form) || '';
  const confirmPassword = Form.useWatch('confirmPassword', form) || '';
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale');

  useEffect(() => {
    locale && i18n.changeLanguage(locale);
  }, []);

  async function onSubmit({ newPassword }: FormValues) {
    setIsLoading(true);
    const { error } =
      (await AuthService.resetPassword({
        newPassword,
        token,
      })) || {};
    if (error) {
      if (error.status === 400 && error.data.error === 'Invalid or expired token used') {
        navigate(Paths.loginRecoverExpired(), { replace: true });
      }
    } else {
      navigate(Paths.loginPasswordResetSuccess(), { replace: true });
    }
  }

  const checkValid = (isValid: boolean) => setChecklistValid(isValid);

  return (
    <>
      <h2>{t('reset_password')}</h2>
      <Form form={form} layout="vertical" onFinish={onSubmit} requiredMark={false}>
        <StyledLoginForm>
          <div>
            <FloatField
              name="newPassword"
              label={t('new_password')}
              type="password"
              validateStatus={!!newPassword && !isChecklistValid ? 'error' : ''}
            />
            <FloatField
              name="confirmPassword"
              label={t('confirm_password')}
              type="password"
              validateStatus={!!confirmPassword && newPassword !== confirmPassword ? 'error' : ''}
            />
            <PasswordChecklist password={newPassword} onValid={checkValid} />
          </div>
          <div>
            <Button
              block
              disabled={
                !(isChecklistValid && newPassword.length && newPassword === confirmPassword)
              }
              htmlType="submit"
              type="primary"
            >
              {t('confirm')}
              {isLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 14, marginLeft: 8 }} spin />}
                />
              ) : (
                <CheckIcon width={14} height={14} />
              )}
            </Button>
          </div>
        </StyledLoginForm>
      </Form>
    </>
  );
};

const getToken = (pathname: string): string | undefined => {
  const pathArr = pathname.split('/');
  return pathArr[pathArr.findIndex(val => val === 'password-reset') + 1];
};
