import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { TruncatedString } from '../../TruncatedString';
import { useDropdown } from '../../../hooks/useDropdown';
import { DropdownButton, DropdownMenu } from '../../Dropdown';
import { AccountsService } from '../../../services/client-account';
import { IAccount } from '../../../pages/Accounts/types';
import { useAccount } from '../../../state/account';
import { isInArray } from '../../../utils/arrays';
import { findByBiggestId } from '../../../utils/filtering';
import { ACTIVE_ACCOUNT_NAME, ACTIVE_ACCOUNT_ID, ACTIVE_PROJECT_ID } from '../../../constants';
import { Paths } from '../../../types/paths';

type MenuItem = MenuItemType & { key: number; value: string; label: JSX.Element; name: string };

export const Accounts: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<ReactElement | string>();
  const { visible, toggleDropdown, dropdownRef } = useDropdown<HTMLButtonElement>(false);
  const { activeAccountId, setActiveAccountId } = useAccount();
  const { data, isFetched } = useQuery<IAccount[], string>(
    [AccountsService.queryKey],
    AccountsService.get,
  );
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (data) {
      if (data.length) {
        const items = data
          .filter(account => account.is_enabled)
          .map(account => ({
            label: (
              <TruncatedString
                isShowFullOnHover={true}
                isPointerEventsDisabled={true}
                key={account.name}
              >
                {account.name}
              </TruncatedString>
            ),
            key: account.id,
            value: account.id.toString(),
            name: account.name,
          }));
        setMenuItems(items);
      } else {
        setActiveAccountId('', '');
      }
    }
  }, [data]);

  useEffect(() => {
    if (menuItems.length && isFetched) {
      const setSelectedMenuItem = () => {
        const lastItemById = findByBiggestId(menuItems);
        if (activeAccountId && !isInArray(activeAccountId, menuItems, 'key'))
          setActiveAccountId(lastItemById);
        if (!activeAccountId) {
          const initialAccountId = localStorage.getItem(ACTIVE_ACCOUNT_ID);
          if (initialAccountId && isInArray(initialAccountId, menuItems, 'key')) {
            const initialProjectId = localStorage.getItem(ACTIVE_PROJECT_ID) || undefined;
            setActiveAccountId(initialAccountId, initialProjectId);
          } else setActiveAccountId(lastItemById);
        }
      };
      setSelectedMenuItem();
    }
  }, [menuItems, isFetched]);

  useEffect(() => {
    if (activeAccountId) {
      const account = data?.find(el => el.id === parseInt(activeAccountId));
      setSelectedItem(
        <TruncatedString isShowFullOnHover={true} isPointerEventsDisabled={true}>
          {account?.name}
        </TruncatedString>,
      );
      localStorage.setItem(ACTIVE_ACCOUNT_NAME, account?.name as string);
    }
  }, [menuItems, activeAccountId]);

  const onMenuSelect = ({ key }: MenuInfo) => {
    if (key === activeAccountId) {
      return null;
    } else {
      if (menuItems?.length) {
        const selectedItem = menuItems.find(item => item.key.toString() === key);
        if (selectedItem) {
          setActiveAccountId(key);
          setSelectedItem(selectedItem.label);
        }
      }
      toggleDropdown();
      navigate(Paths.projects());
    }
  };

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: false,
        onClick: onMenuSelect,
        selectedKeys: [activeAccountId || ''],
      }}
      dropdownRender={menu => (
        <DropdownMenu.Root>
          {!!menuItems.length && <DropdownMenu.Menu>{menu}</DropdownMenu.Menu>}
          <DropdownMenu.Footer
            single={!menuItems?.length}
            to={'account-settings'}
            onClick={toggleDropdown}
          >
            {t('view_all_accounts')}
          </DropdownMenu.Footer>
        </DropdownMenu.Root>
      )}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
    >
      <DropdownButton ref={dropdownRef} id="accounts-button" onClick={toggleDropdown}>
        <DropdownMenu.Text>{selectedItem}</DropdownMenu.Text>
      </DropdownButton>
    </Dropdown>
  );
};
