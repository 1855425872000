import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { StyledPasswordChecklist } from './PasswordChecklist.styles';

interface IPasswordChecklist {
  password: string;
  onValid?: (isAllValid: boolean) => any;
}

export const PasswordChecklist: React.FC<IPasswordChecklist> = ({ password, onValid }) => {
  const { t } = useTranslation('login');
  const [allValid, setAllValid] = useState(false);
  const rules: {
    [key: string]: { valid: boolean; message: string };
  } = {
    minLength: {
      valid: password.length >= 8 && password.length <= 30,
      message: t('password_chars'),
    },
    capital: {
      valid: /[A-Z]/.test(password),
      message: t('password_uppercase'),
    },
    lowercase: {
      valid: /[a-z]/.test(password),
      message: t('password_lowercase'),
    },
    number: {
      valid: /\d/g.test(password),
      message: t('password_number'),
    },
    specialChar: {
      valid: /[~`!#$%\^&*@+=\-\[\]\\';,_/{}|\\":<>\?\.]/g.test(password),
      message: t('password_spec_char'),
    },
  };

  useEffect(() => {
    if (Object.keys(rules).every((item: any) => rules[item].valid)) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  }, [password]);

  useEffect(() => {
    if (typeof onValid === 'function') {
      onValid(allValid);
    }
  }, [allValid]);

  return (
    <StyledPasswordChecklist>
      <ul>
        {Object.entries(rules).map(([key, value]) => {
          return (
            <li key={key}>
              {value.valid ? <Check width={10} height={10} /> : <span>-</span>} {value.message}
            </li>
          );
        })}
      </ul>
    </StyledPasswordChecklist>
  );
};
