import React from 'react';
import { CollapseProps } from 'antd';
import { theme } from '../../theme';
import { ReactComponent as ArrowTop } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowBottom } from '../../assets/icons/arrow-down.svg';
import { StyledCollapse } from './CollapseComponent.styles';
import { ProductType } from '../../pages/Projects/types';

const ExpandIcon = ({ isActive }: { isActive?: boolean }): JSX.Element => (
  <div style={{ color: theme.primaryColor }}>
    {isActive ? <ArrowTop width={16} /> : <ArrowBottom width={16} />}
  </div>
);

export const CollapseComponent: React.FC<{
  items: CollapseProps['items'];
  activeKeys: ProductType[];
  setActiveKeys: React.Dispatch<React.SetStateAction<ProductType[]>>;
}> = ({ items = [], activeKeys, setActiveKeys }): JSX.Element => (
  <StyledCollapse
    items={items}
    bordered={false}
    defaultActiveKey={items.map(item => item.key as ProductType)}
    expandIconPosition="end"
    expandIcon={ExpandIcon}
    onChange={keys => setActiveKeys(keys as ProductType[])}
    style={{ background: 'transparent' }}
    {...(activeKeys.length && { activeKey: activeKeys })}
  />
);
