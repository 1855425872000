import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Divider, App, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { PasswordChecklist } from '../../../components/PasswordChecklist';
import { StyledLoginForm } from '../Login.styles';
import { useCreate } from '../../../hooks/useCreate';
import { UserService } from '../../../services/user';
import { FloatField } from '../../../components/FloatField';
import { Paths } from '../../../types/paths';
import { unexpectedError } from '../../../utils/notification';

export interface UserCreateValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword?: string;
  email?: string;
  clientid?: number;
  projectId?: number;
}

export const CreatePassword: React.FC = () => {
  const { t } = useTranslation('login');
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const [isChecklistValid, setChecklistValid] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const password = Form.useWatch('password', form) || '';
  const confirmPassword = Form.useWatch('confirmPassword', form) || '';
  const { mutate: createItem, isLoading } = useCreate<UserCreateValues>(
    [UserService.queryKey],
    UserService.create,
  );

  const isFormValid = (): boolean => {
    const { firstName, lastName } = form.getFieldsValue();
    return !(
      isChecklistValid &&
      password.length &&
      password === confirmPassword &&
      firstName &&
      lastName
    );
  };

  const isPasswordCheckValid = (isValid: boolean) => setChecklistValid(isValid);

  const onSubmit = async (values: UserCreateValues) => {
    const identifier = searchParams.get('identifier');
    const data = identifier && atob(identifier)?.split('|');
    if (data && data.length === 3) {
      delete values.confirmPassword;
      const payload: Omit<UserCreateValues, 'confirmPassword'> = {
        ...values,
        email: data[0],
        projectId: Number(data[1]),
        clientid: Number(data[2]),
      };

      await createItem(payload, {
        onSuccess: data => {
          if (!data.error) {
            navigate(Paths.loginCreatePasswordComplete(), { replace: true });
          } else {
            notification['error']({
              message: data.error.message,
            });
          }
        },
        onError: () => notification['error'](unexpectedError),
      });
    } else notification['error'](unexpectedError);
  };

  return (
    <>
      <h2>{t('create_account')}</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        validateTrigger="onBlur"
        requiredMark={false}
      >
        <StyledLoginForm>
          <div>
            <FloatField name="firstName" label={t('first_name')} rules={[{ required: true }]} />
            <FloatField name="lastName" label={t('last_name')} rules={[{ required: true }]} />
            <Divider />
            <FloatField
              name="password"
              label={t('new_password')}
              type="password"
              validateStatus={!!password && !isChecklistValid ? 'error' : ''}
            />
            <FloatField
              name="confirmPassword"
              label={t('confirm_password')}
              type="password"
              validateStatus={!!confirmPassword && password !== confirmPassword ? 'error' : ''}
            />
            <PasswordChecklist password={password} onValid={isPasswordCheckValid} />
          </div>
          <div>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Button block disabled={isFormValid()} htmlType="submit" type="primary">
                  {t('confirm')}
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 14, marginLeft: 8 }} spin />}
                    />
                  ) : (
                    <CheckIcon width={14} height={14} />
                  )}
                </Button>
              )}
            </Form.Item>
          </div>
        </StyledLoginForm>
      </Form>
    </>
  );
};
