import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { IAgenticModel, IAgenticModelValues } from '../../pages/AI/Automation/AgenticModels/types';
import { sortById } from '../../utils/sorting';

const queryKey = 'agentic-models';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAgenticModel[]> => {
  const response = await integrationQuery(`/api/agentic_models`, {
    apiData,
    signal,
  });
  return sortById(response.agentic_models);
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/agentic_models/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const create = async ({
  data,
  apiData,
}: { data: IAgenticModelValues } & AiIntegrationParams): Promise<{
  agentic_model: IAgenticModel;
}> =>
  await integrationQuery(`/api/agentic_models`, {
    method: 'POST',
    data: { data: { type: 'agentic_models', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAgenticModel> => {
  const response = await integrationQuery(`/api/agentic_models/${id}`, { apiData, signal });
  return response?.agentic_model;
};

const update = async ({
  id,
  data,
  apiData,
}: { id: string; data: IAgenticModelValues } & AiIntegrationParams): Promise<{
  agentic_model: IAgenticModel;
}> =>
  await integrationQuery(`/api/agentic_models/${id}`, {
    method: 'PUT',
    data: { data: { type: 'agentic_models', attributes: data } },
    apiData,
  });

export const AgenticModelsService = {
  queryKey,
  get,
  remove,
  create,
  find,
  update,
};
