import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { unexpectedError } from '../../../../utils/notification';
import { useUpdate } from '../../../../hooks/useUpdate';
import { ISchedulePayload, IScheduleValues } from '../../types';
import { useGetItem } from '../../../../hooks/useGetItem';
import { PulseService } from '../../../../services/pulse';
import { SchedulesForm } from '../SchedulesForm';
import { Content } from '../../../../components/Content';
import { formatDataForEdit, formatValuesForPayload } from '../../../../utils/pulse';
import { SaveConfirmation } from '../../../../components/SaveConfirmation';
import { Paths } from '../../../../types/paths';

export const EditSchedule: React.FC = () => {
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notification } = App.useApp();

  const { data, isLoading } = useGetItem<ISchedulePayload>(
    [PulseService.queryKey, id],
    PulseService.find,
    { enabled: !!id },
  );

  const { mutate: updateItem, isLoading: isUpdating } = useUpdate<{
    id: string;
    values: ISchedulePayload;
  }>([PulseService.queryKey, id], PulseService.update);

  const onSubmit = async (values: IScheduleValues) => {
    const data = formatValuesForPayload(values);
    await updateItem(
      { id, values: data },
      {
        onSuccess: data => {
          if (!data.error) {
            navigate(Paths.pulseSchedules());
            notification['success']({
              message: <Trans t={t}>{t('schedule_updated_notify')}</Trans>,
            });
          }
        },
        onError: () => notification['error'](unexpectedError),
      },
    );
    // delete the line below once the BE is working
    navigate(-1);
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: Partial<IScheduleValues> = formatDataForEdit(data);

  return (
    <Content imgBg={false}>
      <p>{t('form_description')}</p>
      <SchedulesForm
        initialValues={initialValues}
        submitButton={
          <SaveConfirmation onSubmit={onSubmit} loading={isLoading || isUpdating} isEdit>
            <Trans>{t('save-schedule-confirmation')}</Trans>
          </SaveConfirmation>
        }
      />
    </Content>
  );
};
