import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { DLPTemplateService } from '../../../../../services/ai/dlp-configs';
import { IDLPTemplate } from '../types';
import { Paths } from '../../../../../types/paths';

export const DLPTemplateList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.analyticDLPEdit({ id })),
  });

  const columns: ColumnProps<IDLPTemplate>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '25%',
    },
    {
      title: t('de_identify'),
      onCell: onCellHandler,
      dataIndex: 'deidentify_template_name',
      width: '24%',
    },
    {
      title: t('inspect_template'),
      onCell: onCellHandler,
      dataIndex: 'inspect_template_name',
      width: '24%',
    },
    {
      title: t('gcp_id'),
      onCell: onCellHandler,
      dataIndex: 'gcp_project_name',
      width: '24%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IDLPTemplate>
      service={DLPTemplateService}
      columns={columns}
      getCreatePath={Paths.analyticDLPCreate}
      deleteConfirmationTitle={t('delete_dlp_template')}
      getDisplayName={record => record.name}
    />
  );
};
