import styled from '@emotion/styled';

const Root = styled.div<{ isVisible: boolean }>`
  position: fixed;
  background: ${({ theme }) => theme.bgDark};
  z-index: 20;
  left: 64px;
  top: 0;
  height: 100vh;
  width: calc(100% - 64px);
  align-items: end;
  display: flex;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bgDark};
`;

const Inner = styled.div<{ isVisible: boolean }>`
  width: 448px;
  padding: 24px;
  margin: 12px;
  background: ${({ theme }) => theme.bgColor};
  box-shadow: 0 -5px 25px 5px ${({ theme }) => theme.bgDark};
  transition: transform 0.3s;
  overflow-y: auto;
  transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(-15px)')};
  .ant-card {
    padding: 20px 4px;
  }
  .ant-card-head {
    min-height: 0;
  }
  .ant-card-head-title {
    text-transform: none;
    font-size: 14px;
  }
  .ant-card-head-wrapper {
    margin-bottom: 4px;
  }
  .ant-card-body {
    padding-bottom: 0;
  }
`;

const Close = styled.button`
  width: 28px;
  height: 28px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.bgDark};
  transition: color 0.25s;
  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const StyledAsideModal = { Root, Header, Title, Bg, Inner, Close };
