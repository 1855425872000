import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { NLUProvidersForm } from '../NLUProvidersForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IDFAgentValues, IDFAgent } from '../types';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { Space } from '../../../../../components/Space';

export const NLUProvidersEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IDFAgent>(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      NLUProvidersService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    NLUProvidersService.update,
  );

  const onSubmit = async (
    data: Partial<IDFAgentValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('nlu_provider_updated_notify', { name: data.dialogflow_agent.display_name })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (error: any) => {
            if (error.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...error.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IDFAgentValues = {
    display_name: data.display_name,
    gcp_keyfile: data.gcp_keyfile,
    gcp_project_name: data.gcp_project_name,
    ccai_enabled: data.ccai_enabled,
    gcp_fulfillment_auth_keyfile: data.gcp_fulfillment_auth_keyfile,
    platform_edition: data.platform_edition,
    location: data.location,
    uuid: data.uuid,
  };

  return (
    <Content imgBg={false}>
      <Space direction="vertical" size="middle">
        <NLUProvidersForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
          isEditing
        />
      </Space>
    </Content>
  );
};
