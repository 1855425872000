import React from 'react';
import { Form, Input, Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ActionButton, ActionButtonType } from '../ActionButton';
import { formItemClass } from '../../theme';

type Props = {
  titleIcon: React.ReactElement;
  title: string;
  description: string;
  confirmationText?: string;
  onDisable?: () => void;
  visible: boolean;
  handleCancel: () => void;
  buttonType: ActionButtonType;
};

export const ConfirmationModal: React.FC<Props> = ({
  titleIcon,
  title,
  description,
  confirmationText,
  onDisable,
  visible,
  handleCancel,
  buttonType,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => form.submit();
  const handleOk = async () => {
    form.resetFields();
    return onDisable?.();
  };

  const _handleCancel = () => {
    handleCancel();
    confirmationText && form.resetFields();
  };

  const confirmationInput = () => (
    <>
      {confirmationText && (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ confirmation: '' }}
          onFinish={handleOk}
          requiredMark={false}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="confirmation"
            label={t('type_disable_to_confirm', { text: `“${confirmationText}”` })}
            className={formItemClass.base}
            rules={[
              () => ({
                validator: async (_, value) => {
                  if (value !== confirmationText)
                    throw new Error(t('type_disable_to_confirm', { text: confirmationText }));
                },
              }),
            ]}
          >
            <Input placeholder={t('type_disable_to_confirm', { text: `“${confirmationText}”` })} />
          </Form.Item>
        </Form>
      )}
    </>
  );

  return (
    <Modal
      width={420}
      title={
        <>
          {titleIcon} {title}
        </>
      }
      centered
      open={visible}
      className="modal-danger"
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={_handleCancel} />,
        <ActionButton
          type={buttonType}
          key={buttonType}
          onClick={confirmationText ? handleSubmit : handleOk}
        />,
      ]}
      onCancel={_handleCancel}
    >
      <p>
        <Trans>{description}</Trans>
      </p>
      {confirmationText && confirmationInput()}
    </Modal>
  );
};
