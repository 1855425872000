import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { DLPTemplateService } from '../../services/ai/dlp-configs';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';

export const DLPTemplateSelector: React.FC<SelectProps> = props => {
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [DLPTemplateService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      DLPTemplateService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  return (
    <Select {...props}>
      {data?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
