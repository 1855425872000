import React from 'react';
import { Form, FormInstance, Switch, InputNumber, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IDataRetentionPolicyValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { DataRetentionType, TimeUnits } from '../../../../../constants';

interface Props {
  initialValues: IDataRetentionPolicyValues;
  onSubmit: (values: IDataRetentionPolicyValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
}

export const DataRetentionPolicyForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item name="is_enabled" valuePropName="checked" label={t('enabled')}>
        <Switch />
      </Form.Item>
      <Form.Item
        name="name"
        label={t('type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select options={DataRetentionType} disabled />
      </Form.Item>
      <Form.Item
        name="time_amount"
        label={t('duration')}
        tooltip={t('duration_tooltip')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <InputNumber
          addonAfter={
            <Form.Item name="time_unit" noStyle>
              <Select options={TimeUnits} />
            </Form.Item>
          }
        />
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
