import { ReactElement } from 'react';
import { Tooltip } from 'antd';
import i18n from '../locales/i18n';
import { theme } from '../theme';
import { CHANNELS, TRANSLATION_STATUS_LIST } from '../constants';
import { isRichTextEditor, stripHTMLTags } from '../pages/Knowledge/Presentations/utils';
import { ReactComponent as WebIcon } from '../assets/icons/message-bot.svg';
import { ReactComponent as VoiceIcon } from '../assets/icons/phone.svg';
import { ReactComponent as SmsIcon } from '../assets/icons/sms.svg';
import { ReactComponent as FaqIcon } from '../assets/icons/faq.svg';
import { ReactComponent as WhatsappIcon } from '../assets/icons/whatsapp.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as TeamsIcon } from '../assets/icons/grid.svg';
import { ReactComponent as DialogflowCxIcon } from '../assets/icons/dialogflow-cx.svg';
import { ReactComponent as DialogflowEsIcon } from '../assets/icons/dialogflow-es.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as SaveIcon } from '../assets/icons/save.svg';
import { ReactComponent as TriangleIcon } from '../assets/icons/triangle-exclamation-solid.svg';
import { ReactComponent as DotsIcon } from '../assets/icons/dots.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import {
  ContentEntityPayload,
  IContentEntity,
  IContentEntityTransStatus,
  IDestinationParameterItem,
  IDestinationParameterPayload,
  IPresentation,
  IPresentations,
  IPresentationSummary,
  ITranslationInitialValues,
} from '../pages/Knowledge/types';
import { StyledEditTranslations } from '../pages/Knowledge/Translations/EditTranslations/EditTranslations.styles';

type FormValues = {
  channel: { id: string | number; channel_type: string };
  language: { id: string | number };
};
export const preparePresentationsData = (
  presentationsData: IPresentations,
): IPresentationSummary[] => {
  return presentationsData.data.map(item => ({
    id: item.id,
    title: item.title,
    channels: item.content_entities.map(item => item.channel.channel_type),
    intents: item.synchronisations.map(item => ({
      type: item.destination_entity.destination.destination_type,
      name: item.destination_entity.title,
    })),
    labels: item.label_list,
    updated_at: item.updated_at,
  }));
};

export const getChannel = (type: string, disabled: boolean): ReactElement | null => {
  switch (type) {
    case CHANNELS[0].value:
      return (
        <Tooltip title={CHANNELS[0].text} key={type}>
          <WebIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[1].value:
      return (
        <Tooltip title={CHANNELS[1].text} key={type}>
          <FaqIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[2].value:
      return (
        <Tooltip title={CHANNELS[2].text} key={type}>
          <FacebookIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[3].value:
      return (
        <Tooltip title={CHANNELS[3].text} key={type}>
          <WhatsappIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[4].value:
      return (
        <Tooltip title={CHANNELS[4].text} key={type}>
          <SmsIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[5].value:
      return (
        <Tooltip title={CHANNELS[5].text} key={type}>
          <TeamsIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    case CHANNELS[6].value:
      return (
        <Tooltip title={CHANNELS[6].text} key={type}>
          <VoiceIcon height={14} color={disabled ? theme.secondaryBg : theme.primaryColor} />
        </Tooltip>
      );
    default:
      return null;
  }
};

export const getProviderIcon = (
  provider: string,
  isTooltip?: boolean,
  size?: number,
): ReactElement => {
  switch (provider) {
    case 'dialogflow_cx':
      return (
        <Tooltip title="Dialogflow CX" trigger={isTooltip ? 'hover' : undefined}>
          <DialogflowCxIcon width={size || 18} height={size || 18} />
        </Tooltip>
      );
    case 'dialogflow_es':
      return (
        <Tooltip title="Dialogflow ES" trigger={isTooltip ? 'hover' : undefined}>
          <DialogflowEsIcon width={size || 18} height={size || 18} />
        </Tooltip>
      );
    case 'default':
      return <DotsIcon width={size || 18} height={size || 18} className="icon-dots" />;
    default:
      return <></>;
  }
};

export const getPublicationStatus = (status: string): ReactElement | null => {
  switch (status) {
    case 'success':
      return (
        <Tooltip title={i18n.t('knowledge:published')}>
          <CheckIcon width={14} height={14} color={theme.successColor} />
        </Tooltip>
      );
    case 'in_progress':
      return (
        <Tooltip title={i18n.t('knowledge:publishing')}>
          <SpinnerIcon width={14} height={14} color={theme.inputPlaceholderColor} />
        </Tooltip>
      );
    case 'missing':
      return (
        <Tooltip title={i18n.t('knowledge:missing')}>
          <TriangleIcon width={14} height={14} color={theme.progressColor} />
        </Tooltip>
      );
    case 'draft':
      return (
        <Tooltip title={i18n.t('knowledge:draft')}>
          <SaveIcon width={14} height={14} color={theme.inputPlaceholderColor} />
        </Tooltip>
      );
    case 'failed':
      return (
        <Tooltip title={i18n.t('knowledge:failed')}>
          <CloseIcon width={12} height={12} color={theme.errorColor} />
        </Tooltip>
      );
    default:
      return <></>;
  }
};

// TODO: getFilterLabels will be removed or changed inthe future
export const getFilterLabels = (
  data: IPresentationSummary[],
): { text: string; value: string }[] => {
  const labels: string[] = [];

  data.forEach(item => {
    item.labels.forEach(label => {
      if (!labels.includes(label)) {
        labels.push(label);
      }
    });
  });

  const filterLabels: { text: string; value: string }[] = [];
  labels.forEach(item => {
    filterLabels.push({ text: item, value: item });
  });

  return filterLabels;
};

export const getProgressColor = (progress: number): string => {
  if (progress < 30) {
    return theme.errorColor;
  } else if (progress >= 30 && progress < 70) {
    return theme.progressColor;
  } else {
    return theme.successColor;
  }
};

export const getProgressBGColor = (progress: number): string => {
  if (progress < 30) {
    return theme.errorBGColor;
  } else if (progress >= 30 && progress < 70) {
    return theme.progressBGColor;
  } else {
    return theme.successBGColor;
  }
};

export const getDestinationParameterData = (
  initialValuesChildren: IDestinationParameterItem[],
  valuesChildren: IDestinationParameterItem[],
  key: string,
) => {
  const newChildren: Record<string, IDestinationParameterPayload> = {};

  initialValuesChildren.forEach(parameter => {
    newChildren[Object.keys(newChildren).length] = {
      _destroy: !valuesChildren.find(item => item.id === parameter.id),
      configurable_id: parameter.configurable_id,
      configurable_type: 'Destination',
      id: parameter.id,
      key: key,
      value:
        valuesChildren.find(item => item.configurable_id === parameter.configurable_id)?.value ||
        parameter.value,
    };
  });
  valuesChildren
    .filter(item => !item.id)
    .forEach(item => {
      newChildren[Object.keys(newChildren).length] = {
        configurable_id: item.configurable_id,
        configurable_type: 'Destination',
        key: key,
        value: item.value,
      };
    });

  return newChildren;
};

export const getPayload = (channel: string, payload: string) => {
  /**Fix for react-quill <p><br/></p> when field is empty**/
  if (stripHTMLTags(payload).trim().length === 0) return null;
  return isRichTextEditor(channel) ? payload : stripHTMLTags(payload);
};

export const getTranslationValues = (data: FormValues, payload: string): ContentEntityPayload => {
  return {
    content_type: 'raw_html',
    payload: getPayload(data.channel.channel_type, payload),
    channel_id: data.channel.id,
    language_id: data.language.id,
    translation_status: 'verified',
  };
};

export const getUsedChannels = (data: IPresentation[] = []) => {
  const presentationsChannels = data.map(item =>
    item.content_entities.map(el => el.channel.channel_type),
  );
  const channelsValues: string[] = [];
  presentationsChannels.forEach(item => {
    item.forEach(el => {
      channelsValues.indexOf(el) === -1 && channelsValues.push(el);
    });
  });

  const channelsText: string[] = [];
  channelsValues.forEach(item => {
    CHANNELS.forEach(el => {
      if (el.value === item) {
        channelsText.push(el.text);
      }
    });
  });

  return channelsText;
};

export const prepareChannelsValues = (values: Record<string, boolean>): string[] => {
  const channelsValues: string[] = [];
  CHANNELS.forEach(el => {
    Object.keys(values).forEach(item => {
      el.text === item && values[item] && channelsValues.push(el.value);
    });
  });
  return channelsValues;
};

export const prepareStatusesValues = (values: Record<string, boolean>): string[] => {
  const result: string[] = [];
  Object.keys(values).forEach(item => {
    if (TRANSLATION_STATUS_LIST.includes(item) && values[item]) {
      result.push(item);
    }
  });
  return result;
};

export const renderStatusDots = (presentation: IPresentation, languageCode?: string) => {
  const transStatus: IContentEntityTransStatus[] = [];

  presentation.content_entities.forEach((item: IContentEntity) => {
    const isDefaultLanguage = item.language.is_default;
    isDefaultLanguage &&
      !presentation.content_entities.some(
        el =>
          el.channel.channel_type === item.channel.channel_type &&
          el.language.code === languageCode,
      ) &&
      transStatus.push(undefined);
    !isDefaultLanguage &&
      item.language.code === languageCode &&
      transStatus.push(item.translation_status);
  });

  const verified = transStatus.includes('verified');
  const unverified = transStatus.includes('unverified');
  const untranslated = transStatus.includes(undefined);

  if (verified && !unverified && !untranslated) {
    return <StyledEditTranslations.BadgeColor key={presentation.id} color={theme.successColor} />;
  }

  if (verified && !unverified && untranslated) {
    return (
      <>
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'untranslated'}
          color="#fff"
          outlinecolor="#CCCCCC"
        />
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'verified'}
          color={theme.successColor}
          outlinecolor={theme.secondaryBg}
        />
      </>
    );
  }

  if (unverified && !verified && !untranslated) {
    return <StyledEditTranslations.BadgeColor key={presentation.id} color={theme.progressColor} />;
  }

  if (unverified && !verified && untranslated) {
    return (
      <>
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'untranslated'}
          color="#fff"
          outlinecolor="#CCCCCC"
        />
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'unverified'}
          color={theme.progressColor}
          outlinecolor={theme.secondaryBg}
        />
      </>
    );
  }

  if (unverified && verified && !untranslated) {
    return (
      <>
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'unverified'}
          color={theme.progressColor}
          outlinecolor={theme.secondaryBg}
        />
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'verified'}
          color={theme.successColor}
          outlinecolor={theme.secondaryBg}
        />
      </>
    );
  }

  if (unverified && verified && untranslated) {
    return (
      <>
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'untranslated'}
          color="#fff"
          outlinecolor="#CCCCCC"
        />
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'unverified'}
          color={theme.progressColor}
          outlinecolor={theme.secondaryBg}
        />
        <StyledEditTranslations.BadgeColor
          key={presentation.id + 'verified'}
          color={theme.successColor}
          outlinecolor={theme.secondaryBg}
        />
      </>
    );
  }

  return (
    <StyledEditTranslations.BadgeColor key={presentation.id} color="#fff" outlinecolor="#CCCCCC" />
  );
};

export const getBadgeColor = (translationStatus: IContentEntityTransStatus) => {
  switch (translationStatus) {
    case 'verified':
      return theme.successColor;
    case 'unverified':
      return theme.progressColor;
    default:
      return '#fff';
  }
};

export const isPresentationVerifiable = (data?: ITranslationInitialValues) => {
  let result = false;

  if (!data) {
    return false;
  }

  Object.keys(data).forEach(el => {
    if (data[el].translation?.payload && data[el].translation?.id) {
      result = true;
    }
  });
  return result;
};
