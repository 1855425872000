import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { consoleManagerQuery } from './api';
import { sortAscUsersByName } from '../utils/sorting';
import { ApiResponse } from './types';
import { IUser } from '../pages/Projects/Users/UsersList';

const queryKey = 'project-users';

const get = async ({ queryKey, signal }: QueryFunctionContext): Promise<IUser[]> => {
  const response = await consoleManagerQuery(
    `/client_accounts/${queryKey[1]}/projects/${queryKey[2]}/users`,
    { signal },
  );
  return sortAscUsersByName(response);
};

const find = async ({ queryKey, signal }: QueryFunctionContext): Promise<IUser> => {
  return consoleManagerQuery(
    `/client_accounts/${queryKey[1]}/projects/${queryKey[2]}/users/${queryKey[3]}`,
    { signal },
  );
};

type DeleteProps = {
  id: string;
  activeAccountId: string;
  activeProjectId: string;
};
const deleteUser = async ({
  id,
  activeAccountId,
  activeProjectId,
}: DeleteProps): Promise<ApiResponse> =>
  await consoleManagerQuery(
    `/client_accounts/${activeAccountId}/projects/${activeProjectId}/users/${id}`,
    { method: 'DELETE' },
  );

export const ProjectUsers = { queryKey, get, find, deleteUser };
