import React from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { Content } from '../../../../../components/Content';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { useIntegrations } from '../../../../../state/integrations';
import { useCreate } from '../../../../../hooks/useCreate';
import { TextToSpeechForm } from '../TextToSpeechForm';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { ITTSValues } from '../types';
import { ActionButton } from '../../../../../components/ActionButton';

const initialValues: ITTSValues = {
  name: '',
  locale: 'en-GB',
  voice: undefined,
  speaking_rate: 1.0,
  pitch: 0.0,
  tts_provider: 'Google',
};

export const TextToSpeechNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [TextToSpeechService.queryKey],
    TextToSpeechService.create,
  );

  const onSubmit = async (
    data: ITTSValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: response => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('tts_profile_created_notify', { name: response.text_to_speech_config.name })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  return (
    <Content imgBg={false}>
      <TextToSpeechForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
