import { Dropdown, MenuProps } from 'antd';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-dots.svg';
import { DeleteConfirmation } from '../DeleteConfirmation';
import { useDelete } from '../../hooks/useDelete';
import { IAiIntegrationUrl, useIntegrations } from '../../state/integrations';

interface IService {
  queryKey: string;
  remove: (params: { id: string; apiData: IAiIntegrationUrl }) => Promise<any>;
}

interface Props {
  id: number;
  name: string;
  deleteConfirmationTitle: string;
  service: IService;
  disableDelete: boolean;
}

export const CardMenu: React.FC<Props> = ({
  id,
  name,
  deleteConfirmationTitle,
  service,
  disableDelete,
}) => {
  const { t } = useTranslation('ai');
  const { aiIntegration } = useIntegrations();

  const { mutate: deleteItem } = useDelete([service.queryKey], service.remove);

  const handleDelete = useCallback(
    async id => aiIntegration && (await deleteItem({ id, apiData: aiIntegration })),
    [aiIntegration],
  );

  const items: MenuProps['items'] = [
    {
      label: (
        <DeleteConfirmation
          id={id}
          onActionDelete={() => handleDelete(id)}
          title={deleteConfirmationTitle}
          isMenu
          disabled={disableDelete}
        >
          <Trans>{t('delete_description', { name: name })}</Trans>
        </DeleteConfirmation>
      ),
      key: '0',
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
      <a onClick={e => e.preventDefault()}>
        <MenuIcon width={14} height={14} />
      </a>
    </Dropdown>
  );
};
