import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    backend: {
      loadPath:
        process.env.NODE_ENV === 'test'
          ? '/locales/{{lng}}/{{ns}}.json'
          : `${window.__RUNTIME_CONFIG__.REACT_APP_BASENAME}/locales/{{lng}}/{{ns}}.json`.replace(
              /\/\//g,
              '/',
            ),
    },
    fallbackLng: 'en-GB',
    debug: false,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;
