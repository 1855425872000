import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { DialPlansService } from '../../../../../services/ai/dial-plans';
import { IAutomationProfile } from '../types';
import { Paths } from '../../../../../types/paths';

export const AutomationProfilesList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiAutomationProfilesEdit({ id })),
  });

  const columns: ColumnProps<IAutomationProfile>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '37%',
    },
    {
      title: t('dialogflow_agent'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow_agent', 'display_name'],
      width: '20%',
      render: record => record || '-',
    },
    {
      title: t('conversation_profile'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow/conversation_profile', 'dialogflow_data', 'display_name'],
      width: '20%',
      render: record => record || '-',
    },
    {
      title: t('text_to_speech_voice'),
      onCell: onCellHandler,
      dataIndex: ['text_to_speech_config', 'name'],
      width: '20%',
      render: record => record || '-',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IAutomationProfile>
      service={DialPlansService}
      columns={columns}
      getCreatePath={Paths.aiAutomationProfilesCreate}
      deleteConfirmationTitle={t('delete_automation_profile')}
      getDisplayName={record => record.name}
    />
  );
};
