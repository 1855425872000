import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ColumnProps } from 'antd/es/table';
import { Content } from '../../../components/Content';
import { DataTable } from '../../../components/DataTable';
import { ActionButton } from '../../../components/ActionButton';
import { useAccount } from '../../../state/account';
import { IAccount } from '../types';
import { AccountsService } from '../../../services/client-account';
import { IconIndicator } from '../../../components/IconIndicator';
import { Paths } from '../../../types/paths';

export const AccountsList: React.FC = () => {
  const { t } = useTranslation('account_settings');
  const navigate = useNavigate();
  const { setActiveAccountId } = useAccount();
  const { data: accounts, isLoading } = useQuery<IAccount[], string>(
    [AccountsService.queryKey],
    AccountsService.get,
  );

  const onRowHandler = ({ id }: IAccount) => ({
    onClick: () => {
      navigate(Paths.accountEdit({ id }));
      setActiveAccountId(id.toString());
    },
  });

  const columns: ColumnProps<IAccount>[] = [
    {
      title: t('account'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('account_enabled'),
      dataIndex: 'is_enabled',
      width: 150,
      render: enabled => <IconIndicator enabled={enabled} />,
      sorter: (a, b) => Number(a.is_enabled) - Number(b.is_enabled),
    },
  ];

  const ButtonCreate = (
    <ActionButton type="create" onClick={() => navigate(Paths.accountCreate())} />
  );

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable
        dataSource={accounts}
        columns={columns}
        onRow={onRowHandler}
        loading={isLoading}
        rowClassName={record => (!record.is_enabled ? 'is-disabled-row' : '')}
      />
    </Content>
  );
};
