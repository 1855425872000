export type ChannelType = 'facebook' | 'sms' | 'voice' | 'web' | 'teams' | 'whatsapp';

export interface IChannels {
  id: number;
  phone_number: string;
  channel_type: ChannelType;
  dial_plan: { name: string };
  dial_plan_id: number;
}

export interface IChannelsValues {
  id?: number;
  channel_type?: ChannelType;
  phone_number?: string;
  dial_plan_id?: number;
  _destroy?: boolean;
}

export enum ChannelTypes {
  facebook = 'facebook',
  messenger = 'messenger',
  sms = 'sms',
  voice = 'voice',
  teams = 'teams',
  web = 'web',
  whatsapp = 'whatsapp',
  all = 'all',
}
