import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, Typography } from 'antd';
import { TableProps } from 'rc-table/lib/Table';
import { Space } from '../../../../../../../components/Space';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/plus.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/info-circle.svg';
import { ProfilesTable } from '../../ProfilesTable';
import { AddProfilesModal } from '../../AddProfilesModal';
import { IAutomationProfile } from '../../../types';
import { EmbedCodeModal } from '../../EmbedCodeModal';

const { Title } = Typography;

type Props = {
  initialProfiles: IAutomationProfile[];
};

export const AutomationProfiles: React.FC<Props> = ({ initialProfiles }) => {
  const { t } = useTranslation('design');
  const [selectedProfile, setSelectedProfile] = useState<string>('');
  const [isAddProfileVisible, setIsAddProfileVisible] = useState<boolean>(false);
  const [isEmbedCodeVisible, setIsEmbedCodeVisible] = useState<boolean>(false);
  const { setFieldValue, getFieldValue, setFields } = Form.useFormInstance();
  const profilesValue = getFieldValue(['dial_plan_ids']);

  const onRowHandler: TableProps<IAutomationProfile>['onRow'] = record => ({
    onClick: () => {
      setSelectedProfile(record.id.toString());
      setIsEmbedCodeVisible(true);
    },
  });

  return (
    <>
      <Title level={5} type="secondary">
        {t('automation_profiles')}
      </Title>
      <p>{t('automation_profiles_desc')}</p>
      <Space direction="vertical" size="middle">
        <Button
          type="default"
          icon={<PlusIcon width={13} />}
          onClick={() => setIsAddProfileVisible(true)}
          disabled={isAddProfileVisible}
        >
          {t('add_profiles')}
        </Button>
        <div>
          <ProfilesTable profiles={initialProfiles} pagination={false} onRow={onRowHandler} />
          <Flex align="center" gap={4} style={{ fontSize: '13px', marginTop: '6px' }}>
            <CopyIcon width={13} /> {t('profile_info')}
          </Flex>
        </div>
      </Space>
      <AddProfilesModal
        isVisible={isAddProfileVisible}
        setIsVisible={setIsAddProfileVisible}
        setFields={setFields}
        setFieldValue={setFieldValue}
        profilesValue={profilesValue}
      />
      <EmbedCodeModal
        isVisible={isEmbedCodeVisible}
        setVisible={setIsEmbedCodeVisible}
        profileId={selectedProfile}
      />
    </>
  );
};
