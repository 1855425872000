import { useTranslation } from 'react-i18next';
import { getBadgeColor } from '../../../../../utils/knowledge';
import { IContentEntityTransStatus } from '../../../types';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { ReactComponent as TriangleIcon } from '../../../../../assets/icons/triangle-exclamation-solid.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/check-circle.svg';

export const TranslationStatus: React.FC<{
  status: IContentEntityTransStatus;
}> = ({ status }) => {
  const { t } = useTranslation('knowledge');

  const badgeColor = getBadgeColor(status);
  const icon =
    status === 'unverified' ? (
      <TriangleIcon width={14} height={14} color="white" />
    ) : (
      <CheckIcon width={14} height={14} color="white" />
    );

  return (
    <>
      {status ? (
        <StyledEditTranslations.TranslationStatus color={badgeColor}>
          {icon}
          <div>{t(status)}</div>
        </StyledEditTranslations.TranslationStatus>
      ) : null}
    </>
  );
};
