import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Path, path } from 'ramda';
import { Input, Button } from 'antd';
import type { InputRef } from 'antd';
import { ColumnType } from 'antd/lib/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

type DataIndex<T> = keyof T;

export function useColumnSearchProps<T>(
  dataIndex: DataIndex<T> | string[],
  inputPlaceholder: string,
  onSearch: (value: string) => void,
  onFilter?: ColumnType<T>['onFilter'],
): ColumnType<T> {
  const { t } = useTranslation();
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    onSearch(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    onSearch('');
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="filter-search" onKeyDown={e => e.stopPropagation()}>
        <div className="filter-search-input">
          <Input
            ref={searchInput}
            placeholder={inputPlaceholder}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          />
        </div>
        <div className="filter-search-btns">
          <Button
            type="link"
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            disabled={selectedKeys.length < 1}
          >
            {t('reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchIcon width={13} />}
            size="small"
          >
            {t('search')}
          </Button>
        </div>
      </div>
    ),
    filterIcon: () => <SearchIcon width={12} />,
    onFilter: onFilter
      ? onFilter
      : (value, record) => {
          return String(path((Array.isArray(dataIndex) ? dataIndex : [dataIndex]) as Path, record))
            .toLowerCase()
            .includes((value as string).toLowerCase());
        },
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };
}
