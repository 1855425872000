import { route } from '../utils/routes';

export interface IRouteParams {
  id?: number | string | undefined;
  docId?: number;
  channel?: string;
}

export const Paths = {
  index: route('/'),
  accounts: route('/account-settings'),
  accountCreate: route('/account-settings/create'),
  accountEdit: route<IRouteParams>('/account-settings/:id'),
  analytics: route('/analytics'),
  analyticDashboards: route('/analytics/dashboards'),
  analyticDashboardView: route<IRouteParams>('/analytics/dashboards/:id'),
  analyticLooks: route('/analytics/looks'),
  analyticLookView: route<IRouteParams>('/analytics/looks/:id'),
  analyticExplore: route('/analytics/explore'),
  analyticExploreView: route<IRouteParams>('/analytics/explore/:id'),
  analyticFAReports: route('/analytics/fAReports'),
  analyticFAReportView: route<IRouteParams>('/analytics/fAReports/:id'),
  analyticConfigurations: route('/analytics/configurations'),
  analyticWebhook: route('/analytics/configurations/analytics-webhook'),
  analyticDLP: route('/analytics/configurations/dlp-configs'),
  analyticDLPCreate: route('/analytics/configurations/dlp-configs/create'),
  analyticDLPEdit: route<IRouteParams>('/analytics/configurations/dlp-configs/:id'),
  analyticDataRetentionPolicy: route('/analytics/configurations/data-retention-policy'),
  analyticDataRetentionPolicyEdit: route<IRouteParams>(
    '/analytics/configurations/data-retention-policy/:id',
  ),
  analyticsDeleteConversations: route<IRouteParams>(
    '/analytics/configurations/delete-conversations',
  ),
  designV1: route('/design'),
  oldKnowledge: route('/knowledge'),
  knowledge: route('/new-knowledge'),
  knowledgePresentations: route('/new-knowledge/presentations'),
  knowledgePresentationsCreate: route('/new-knowledge/presentations/create'),
  knowledgePresentationsEdit: route<IRouteParams>('/new-knowledge/presentations/:id'),
  knowledgeParameters: route('/new-knowledge/parameters'),
  knowledgeParametersCreate: route('/new-knowledge/parameters/create'),
  knowledgeParametersEdit: route<IRouteParams>('/new-knowledge/parameters/:id'),
  knowledgeIntents: route('/new-knowledge/intents'),
  knowledgeTranslations: route('/new-knowledge/translations'),
  knowledgeTranslationsEdit: route<IRouteParams>('/new-knowledge/translations/:id'),
  knowledgeWorkflow: route('/new-knowledge/workflow'),
  login: route('/login'),
  loginRecover: route('/login/recover-account'),
  loginRecoverExpired: route('/login/recover-account-expired'),
  loginRecoverSuccess: route('/login/recover-account-success'),
  loginPasswordReset: route('/login/password-reset/*'),
  loginPasswordResetSuccess: route('/login/password-reset-success'),
  loginInviteExpired: route('/login/invite-link-expired'),
  loginCreatePassword: route('/login/create-password'),
  loginCreatePasswordComplete: route('/login/create-password-complete'),
  projects: route('/projects'),
  projectCreate: route('/projects/create'),
  projectEdit: route<IRouteParams>('/projects/:id'),
  users: route('/users'),
  usersInvite: route('/users/invite'),
  userEdit: route<IRouteParams>('/users/:id'),
  userSettings: route('/user-settings'),
  termsConditions: route('/terms-conditions'),
  fairUsePolicy: route('/fair-use-policy'),
  securityPolicy: route('/security-policy'),
  health: route('/health'),
  pulse: route('/pulse'),
  pulseInsights: route('/pulse/insights'),
  pulseSchedules: route('/pulse/schedules'),
  pulseScheduleCreate: route('/pulse/schedules/create'),
  pulseScheduleEdit: route<IRouteParams>('/pulse/schedules/:id'),
  ai: route('/ai'),
  aiAutomation: route('/ai/automation'),
  aiTextToSpeech: route('/ai/automation/text-to-speech'),
  aiTextToSpeechCreate: route('/ai/automation/text-to-speech/create'),
  aiTextToSpeechEdit: route<IRouteParams>('/ai/automation/text-to-speech/:id'),
  aiNLUProviders: route('/ai/automation/nlu-providers'),
  aiNLUProvidersCreate: route('/ai/automation/nlu-providers/create'),
  aiNLUProvidersEdit: route<IRouteParams>('/ai/automation/nlu-providers/:id'),
  aiAutoRespTemplates: route('/ai/automation/auto-resp-templates'),
  aiAutoRespTemplatesCreate: route('/ai/automation/auto-resp-templates/create'),
  aiAutoRespTemplatesEdit: route<IRouteParams>('/ai/automation/auto-resp-templates/:id'),
  aiContexts: route('/ai/automation/contexts'),
  aiContextCreate: route('/ai/automation/contexts/create'),
  aiContextEdit: route<IRouteParams>('/ai/automation/contexts/:id'),
  aiConversationProfiles: route('/ai/automation/conversation-profiles'),
  aiConversationProfilesCreate: route('/ai/automation/conversation-profiles/create'),
  aiConversationProfilesEdit: route<IRouteParams>('/ai/automation/conversation-profiles/:id'),
  aiAutomationProfiles: route('/ai/automation/automation-profiles'),
  aiAutomationProfilesCreate: route('/ai/automation/automation-profiles/create'),
  aiAutomationProfilesEdit: route<IRouteParams>('/ai/automation/automation-profiles/:id'),
  aiAgenticModels: route<IRouteParams>('/ai/automation/agentic-models'),
  aiAgenticModelsCreate: route<IRouteParams>('/ai/automation/agentic-models/create'),
  aiAgenticModelsEdit: route<IRouteParams>('/ai/automation/agentic-models/:id'),
  aiConfiguration: route('/ai/configuration'),
  aiDesign: route('/ai/configuration/design'),
  aiDesignEdit: route<IRouteParams>('/ai/configuration/design/:id'),
  aiFallbackBehaviour: route('/ai/configuration/fallback-behaviour'),
  aiFallbackBehaviourCreate: route('/ai/configuration/fallback-behaviour/create'),
  aiFallbackBehaviourEdit: route<IRouteParams>('/ai/configuration/fallback-behaviour/:id'),
  aiKnowledgeBases: route('/ai/configuration/knowledge-bases'),
  aiKnowledgeBasesCreate: route('/ai/configuration/knowledge-bases/create'),
  aiKnowledgeBasesEdit: route<IRouteParams>('/ai/configuration/knowledge-bases/:id'),
  aiKnowledgeBaseDocument: route('/ai/configuration/knowledge-bases/document'),
  aiKnowledgeBaseDocumentList: route<IRouteParams>(
    '/ai/configuration/knowledge-bases/document/:id',
  ),
  aiKnowledgeBaseDocumentNew: route<IRouteParams>(
    '/ai/configuration/knowledge-bases/document/:id/create',
  ),
  aiKnowledgeBaseDocumentEdit: route<IRouteParams>(
    '/ai/configuration/knowledge-bases/document/:id/:docId',
  ),
  aiRouting: route<IRouteParams>('/ai/routing'),
  aiExternalEndpoints: route<IRouteParams>('/ai/routing/external-endpoints'),
  aiExternalEndpointsCreate: route<IRouteParams>('/ai/routing/external-endpoints/create'),
  aiExternalEndpointsEdit: route<IRouteParams>('/ai/routing/external-endpoints/:id'),
  aiTeams: route('/ai/routing/teams'),
  aiTeamsCreate: route('/ai/routing/teams/create'),
  aiTeamsEdit: route<IRouteParams>('/ai/routing/teams/:id'),
  aiRouters: route('/ai/routing/routers'),
  aiRoutersCreate: route('/ai/routing/routers/create'),
  aiRoutersEdit: route<IRouteParams>('/ai/routing/routers/:id'),
  aiChannels: route('/ai/channels'),
  aiChannelList: route<IRouteParams>('/ai/channels/:channel'),
  aiChannelCreate: route<IRouteParams>('/ai/channels/:channel/create'),
  aiChannelEdit: route<IRouteParams>('/ai/channels/:channel/:id'),
};
