import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

interface ILinkButton {
  to: string;
}

export const LinkButton: React.FC<ILinkButton> = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>;
};

const StyledLink = styled(Link)`
  background: var(--yellow-color);
  color: ${({ theme }) => theme.primaryColor};
  display: inline-block;
  padding: 10px 22px;
  font-size: ${({ theme }) => theme.typography.bodyLarge.fontSize};
  line-height: 1.25;
  font-weight: 600;
  position: relative;
  transition: background 0.2s;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--yellow-color);
    width: 100%;
    height: 100%;
    transition: all 0.2s;
  }
  svg {
    margin-right: 5px;
    transition: all 0.2s;
  }
  &:hover {
    &:before {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
    }
  }
`;
