import { useQuery } from '@tanstack/react-query';
import { Flex, Select, SelectProps, Typography } from 'antd';
import { NLUProvidersService } from '../../services/ai/nlu-providers';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { DFAgentTag, DFAgentType } from '../DFAgentTag';

const { Text } = Typography;

interface Props extends SelectProps {
  platformEdition?: DFAgentType;
  ccaiEnabledOnly?: boolean;
  disabled?: boolean;
  humanAssist?: boolean;
}

export const DFAgentSelector: React.FC<Props> = ({
  platformEdition,
  ccaiEnabledOnly,
  disabled,
  humanAssist,
  ...other
}) => {
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [
      NLUProvidersService.queryKey,
      aiIntegration?.metadata.PROJECT_ID,
      platformEdition,
      humanAssist,
    ],
    ({ signal }) =>
      NLUProvidersService.get(
        { apiData: aiIntegration } as AiIntegrationParams,
        signal,
        platformEdition,
        humanAssist,
      ),
    { enabled: !!aiIntegration?.url },
  );
  const filteredData = ccaiEnabledOnly ? data?.filter(item => item.ccai_enabled) : data;

  const options = filteredData?.map(item => ({
    label: (
      <Flex align={'center'}>
        <Text style={{ maxWidth: '80%', color: 'inherit' }} ellipsis>
          {item.display_name}
        </Text>
        <DFAgentTag type={item.platform_edition} disabled={disabled}>
          {item.platform_edition}
        </DFAgentTag>
      </Flex>
    ),
    value: item.id,
    gcpProjectName: item.gcp_project_name,
    platform_edition: item.platform_edition,
    uuid: item.uuid,
    location: item.location,
    display_name: item.display_name,
  }));

  return (
    <Select
      showSearch
      optionFilterProp="display_name"
      disabled={disabled}
      options={options}
      {...other}
    ></Select>
  );
};
