import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Content } from '../../../../components/Content';
import { SchedulesForm } from '../SchedulesForm';
import { Paths } from '../../../../types/paths';
import { IScheduleValues } from '../../types';
import { useCreate } from '../../../../hooks/useCreate';
import { PulseService } from '../../../../services/pulse';
import { unexpectedError } from '../../../../utils/notification';
import { SaveConfirmation } from '../../../../components/SaveConfirmation';
import { formatValuesForPayload } from '../../../../utils/pulse';

export const CreateSchedule: React.FC = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const { mutate: createItem, isLoading } = useCreate([PulseService.queryKey], PulseService.create);

  const onSubmit = async (values: IScheduleValues) => {
    const data = formatValuesForPayload(values);
    await createItem(
      { projectName: 'test-project', data },
      {
        onSuccess: data => {
          if (!data.error) {
            navigate(Paths.pulseSchedules());
            notification['success']({
              message: <Trans t={t}>{t('schedule_created_notify')}</Trans>,
            });
          }
        },
        onError: () => notification['error'](unexpectedError),
      },
    );
  };

  const initialValues: Partial<IScheduleValues> = {
    enabled: true,
    recording: true,
    executionType: 'twilioflow',
    timezone: 'Europe/London',
  };

  return (
    <Content imgBg={false}>
      <p>{t('form_description')}</p>
      <SchedulesForm
        initialValues={initialValues}
        submitButton={
          <SaveConfirmation onSubmit={onSubmit} loading={isLoading}>
            <Trans>{t('save-schedule-confirmation')}</Trans>
          </SaveConfirmation>
        }
      />
    </Content>
  );
};
