import React from 'react';
import { Input, InputRef } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import { ReactComponent as HideIcon } from '../../assets/icons/eye-slash.svg';
import { ReactComponent as ShowIcon } from '../../assets/icons/eye.svg';

export const PasswordInput: React.FC<PasswordProps & React.RefAttributes<InputRef>> = (
  props,
): JSX.Element => (
  <Input.Password
    iconRender={isVisible => (isVisible ? <HideIcon width={16} /> : <ShowIcon width={14} />)}
    {...props}
  />
);
