import React, { useEffect, useState } from 'react';
import { OverlaySpinner } from '../../../../components/Spinner';
import { AnalyticsService } from '../../../../services/analytics';
import { useIntegrations } from '../../../../state/integrations';
import { useGetItem } from '../../../../hooks/useGetItem';
import { ILookerItem } from '../../../Analytics/Reports/types';
import { theme } from '../../../../theme';
import { useAnalyticsData } from '../../../../hooks/useAnalyticsData';

export const InsightsIframe: React.FC = () => {
  const [currentUrl, setCurrentUrl] = useState<string>();
  const { analytics: { url, externalId: projectId } = {} } = useIntegrations();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { data } = useAnalyticsData({
    queryKey: AnalyticsService.queryKeyDashboards,
    fetchDataFn: AnalyticsService.getDashboards,
  });
  const { data: currentReport } = useGetItem<ILookerItem>(
    [AnalyticsService.queryKeyDashboards, projectId, currentUrl],
    ({ signal }) =>
      AnalyticsService.find({ url: currentUrl as string, analyticsUrl: url as string }, signal),
    { enabled: !!currentUrl && !!projectId },
  );

  useEffect(() => {
    if (data) {
      const currentUrl = data.find(e => e.name === 'Insights')?.url;
      currentUrl ? setLoading(true) : setLoading(false);
      setCurrentUrl(currentUrl);
    }
  }, [data]);

  return (
    <>
      <OverlaySpinner loading={loading} />
      {currentReport && (
        <iframe
          width="100%"
          style={{ paddingTop: theme.subNavHeight }}
          height="100%"
          src={currentReport.url}
          onLoad={() => setLoading(false)}
        />
      )}
    </>
  );
};
