import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuProps } from 'antd/lib/menu';

export default function useSelectedMenuItem(menuItems: MenuProps['items']): string {
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState('');

  useEffect(() => {
    const selectedItem = menuItems
      ?.find(_item => location.pathname.startsWith(_item?.key?.toString() || ''))
      ?.key?.toString();

    setSelectedMenuItem(selectedItem || '/');
  }, [location]);

  return selectedMenuItem;
}
