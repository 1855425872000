export const isInArray = (id: string, array: Record<string, any>[], prop = 'id'): boolean =>
  array.length ? array.some(item => item[prop].toString() === id) : false;

export const sortBySearchedName = <T extends { name: string }>(query: string, items: T[]): T[] => {
  let newItems: T[] = [];
  if (query) {
    const selectedItem = items.find(item => item.name.toLowerCase().includes(query.toLowerCase()));
    if (selectedItem) {
      newItems = [selectedItem, ...items.filter(item => item.name !== selectedItem.name)];
    }
  } else {
    newItems = items;
  }

  return newItems;
};

export const ensure = <T>(
  argument: T | undefined | null,
  message = 'This value was promised to be there.',
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};
