import { Modal } from 'antd';
import { ActionButton } from '../ActionButton';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

type Props = {
  isVisible: boolean;
  description: string;
  title: string;
  handleOk?: () => void;
  handleCancel?: () => void;
  okButtonText?: string;
  okButton?: React.JSX.Element;
  cancelButton?: React.JSX.Element;
  titleIcon?: React.JSX.Element;
};

export const ConfirmLeaveModal: React.FC<Props> = ({
  isVisible,
  description,
  title,
  handleCancel,
  handleOk,
  okButtonText,
  okButton,
  cancelButton,
  titleIcon,
}) => {
  const Footer = (): React.JSX.Element => {
    const firstButton = cancelButton ?? (
      <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
    );
    const secondButton = okButton ?? (
      <ActionButton type="save" text={okButtonText} key="save" onClick={handleOk} />
    );

    return <>{[firstButton, secondButton]}</>;
  };

  return (
    <Modal
      centered
      width={400}
      open={isVisible}
      title={
        <>
          {titleIcon ?? <CheckIcon width={16} height={16} />} {title}
        </>
      }
      footer={<Footer />}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      {description}
    </Modal>
  );
};
