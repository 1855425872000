import styled from '@emotion/styled';
import { getChannelName } from '../../../Presentations/utils';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { IContentEntityTransStatus } from '../../../types';
import { theme } from '../../../../../theme';
import { TranslationBadge } from '../TranslationBadge';
import { TranslationStatus } from '../TranslationStatus';

export const ChannelLabel: React.FC<{
  channelType: string;
  translationStatus: IContentEntityTransStatus;
}> = ({ channelType, translationStatus }) => {
  return (
    <StyledEditTranslations.TransStatusContainer>
      <StyledEditTranslations.TransChannelType
        icon={<TranslationBadge status={translationStatus} />}
        color={theme.secondaryBg}
      >
        <StyledNameWrapper>{getChannelName(channelType)}</StyledNameWrapper>
      </StyledEditTranslations.TransChannelType>
      <TranslationStatus status={translationStatus} />
    </StyledEditTranslations.TransStatusContainer>
  );
};

const StyledNameWrapper = styled.div`
  display: inline-flex;
  color: ${({ theme }) => theme.primaryColor};
`;
