import React from 'react';
import { Translation } from 'react-i18next';
import { Projects } from './index';
import { ProjectsList } from './ProjectsList';
import { ProjectsNew } from './ProjectsNew';
import { ProjectsEdit } from './ProjectsEdit';
import { Paths } from '../../types/paths';

export const projectRoutes = () => ({
  path: Paths.projects(),
  element: <Projects />,
  breadcrumb: () => <Translation ns={'project_settings'}>{t => t('projects')}</Translation>,
  children: [
    { index: true, element: <ProjectsList /> },
    {
      path: Paths.projectCreate(),
      element: <ProjectsNew />,
      breadcrumb: () => <Translation>{t => t('create')}</Translation>,
    },
    {
      path: Paths.projectEdit(),
      element: <ProjectsEdit />,
      breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
    },
  ],
});
