import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { StyledContent } from '../Content';

export const NoProject: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root imgBg={true}>
      <div>
        <h3>{t('create_project')}</h3>
      </div>
    </Root>
  );
};

const Root = styled((props: { imgBg: boolean }) => <StyledContent {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > div {
    max-width: 640px;
    margin-bottom: 6%;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
  }
`;
