import { Select, SelectProps } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RoutingMethod } from '../../pages/AI/Routing/Routers/types';

export const routingMethods: RoutingMethod[] = ['task_router', 'direct_dial'];

export const RoutingMethodSelector: React.FC<SelectProps> = props => {
  const { genesysRouter, avayaRouter, livechatRouter } = useFlags();

  genesysRouter && !routingMethods.includes('genesys') && routingMethods.push('genesys');
  avayaRouter && !routingMethods.includes('avaya') && routingMethods.push('avaya');
  livechatRouter && !routingMethods.includes('livechat') && routingMethods.push('livechat');

  return (
    <Select {...props}>
      {routingMethods.map(item => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};
