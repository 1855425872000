import { ReactElement, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { getProviderIcon } from '../../../../../utils/knowledge';
import { sortBySearchedName } from '../../../../../utils/arrays';
import { DestinationWrap, ListWrapper, NumberBadge } from '../../../Knowledge.styles';
import { IPresentationIntent } from '../../../types';

type DestinationType = {
  data: IPresentationIntent[];
  searchDestination: string;
};

export const DestinationTags = ({
  data,
  searchDestination,
}: DestinationType): JSX.Element | null => {
  const [firstItem, setFirstItem] = useState<IPresentationIntent>();
  const [otherItems, setOtherItems] = useState<{ key: number; label: ReactElement | null }[]>([]);

  useEffect(() => {
    if (!!data?.length) {
      const destinationData = searchDestination
        ? sortBySearchedName(searchDestination, data)
        : data;

      setFirstItem(destinationData[0]);
      const currentOtherItems = destinationData.slice(1).map((item, index) => ({
        key: index,
        label: (
          <DestinationWrap>
            {getProviderIcon(item.type, false, 14)}
            {item.name}
          </DestinationWrap>
        ),
        style: {
          cursor: 'default',
        },
      }));
      setOtherItems(currentOtherItems);
    }
  }, [data, searchDestination]);

  const onClick: MenuProps['onClick'] = ({ domEvent }) => {
    domEvent.stopPropagation();
  };

  if (!data?.length) return <span>-</span>;

  return (
    <ListWrapper>
      {firstItem && (
        <DestinationWrap>
          {getProviderIcon(firstItem.type, false, 14)}
          {firstItem.name}
        </DestinationWrap>
      )}
      {otherItems.length > 0 && (
        <Dropdown menu={{ items: otherItems, onClick }} placement="bottomLeft" trigger={['click']}>
          <NumberBadge onClick={e => e.stopPropagation()}>+{otherItems.length}</NumberBadge>
        </Dropdown>
      )}
    </ListWrapper>
  );
};
