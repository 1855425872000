import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ExclamationCircle } from '../../assets/icons/exclamation-circle.svg';

export const ModalWarning = ({ children }: { children: React.ReactNode }) => {
  return (
    <Root>
      <ExclamationCircle width={12} /> {children}
    </Root>
  );
};

export const StyledWarning = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  font-weight: 600;
  color: ${({ theme }) => theme.errorColor};
  svg {
    margin-right: 4px;
  }
`;

const Root = styled(StyledWarning)`
  position: absolute;
  bottom: 22px;
`;
