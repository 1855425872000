import React from 'react';
import { Outlet } from 'react-router-dom';
import { AccountsNav } from './AccountsNav';

export const Accounts: React.FC = () => {
  return (
    <>
      <AccountsNav />
      <Outlet />
    </>
  );
};
