import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { getHighlightedText } from '../../../../utils/formatText';
import { formatDate, getFormattedDate } from '../../../../utils/date';
import { getNextPageQsStringify } from '../../../../utils/queryString';
import { getTableMiscData } from '../../../../utils/table';
import { useDelete } from '../../../../hooks/useDelete';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { KnowledgeParameterService } from '../../../../services/knowledge/parameter';
import { useColumnSearchProps } from '../../../../hooks/useColumnSearchProps';
import { useDateColumnFilter } from '../../../../hooks/useDateColumnFilter';
import { usePrefetchData } from '../../../../hooks/usePrefetchData';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { DataTable, TableParams } from '../../../../components/DataTable';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { ColumnPropsKnowledge, IParameter, IParameters } from '../../types';
import { ParameterValue } from './ParameterValue';
import { useAccount } from '../../../../state/account';

const getParams = (params: TableParams) => ({
  page: params.pagination?.current,
  pageSize: params.pagination?.pageSize,
  'q[s]': params.sortOrder && `${params.sortField} ${params.sortOrder}`,
  'q[key_cont]': params.filters?.key?.[0],
  'q[value_cont]': params.filters?.value?.[0],
  'q[updated_at_gteq]':
    params.filters?.updated_at?.length && getFormattedDate(params.filters.updated_at[0], true),
  'q[updated_at_lteq]':
    params.filters?.updated_at?.length &&
    getFormattedDate(params.filters.updated_at[params.filters.updated_at.length - 1], false),
});

export const ParametersList: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations();
  const [searchName, setSearchName] = useState('');
  const [, setSearchValue] = useState('');
  const [searchDate, setSearchDate] = useState<string[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: Number(searchParams.get('page')) || 1, pageSize: 10 },
  });
  const queryString = qs.stringify(getParams(tableParams), { addQueryPrefix: true });
  const { data, isLoading } = useQuery<IParameters>(
    [KnowledgeParameterService.queryKey, activeProjectId, knowledge?.externalId, queryString],
    ({ signal }) => KnowledgeParameterService.get(knowledge as IKnowledgeUrl, queryString, signal),
    { enabled: !!knowledge },
  );

  usePrefetchData({
    totalPages: data?.pagination?.total_pages || 0,
    paginationPage: tableParams.pagination?.current || 0,
    queryKey: [
      KnowledgeParameterService.queryKey,
      activeProjectId,
      knowledge?.externalId,
      getNextPageQsStringify(getParams, tableParams),
    ],
    queryFn: ({ signal }) =>
      KnowledgeParameterService.get(
        knowledge as IKnowledgeUrl,
        getNextPageQsStringify(getParams, tableParams),
        signal,
      ),
  });

  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [KnowledgeParameterService.queryKey],
    KnowledgeParameterService.deleteParameter,
  );

  const ButtonCreate = <ActionButton type="create" onClick={() => navigate('create')} />;

  const onRowHandler = ({ id }: IParameter) => ({
    onClick: () => navigate(`${id}`),
  });

  const handleDelete = useCallback(
    async id => !!knowledge && (await deleteItem({ id, data: knowledge })),
    [knowledge],
  );

  const columns: ColumnPropsKnowledge<IParameter>[] = [
    {
      title: t('name'),
      dataIndex: 'key',
      sorter: true,
      ...useColumnSearchProps('key', t('searchNamePlaceholder'), setSearchName),
      render: text => (searchName ? getHighlightedText(text, searchName) : text),
      fixed: 'left',
      width: 150,
    },
    {
      title: t('value'),
      dataIndex: 'value',
      sorter: true,
      ...useColumnSearchProps('value', t('searchValuePlaceholder'), setSearchValue),
      render: (_, record) => (
        <ParameterValue value={record.value} destinationValues={record.children} />
      ),
      width: 200,
    },
    {
      title: t('date_modified'),
      dataIndex: 'updated_at',
      width: 84,
      sorter: true,
      ...useDateColumnFilter('updated_at', searchDate, setSearchDate),
      render: date =>
        searchDate.length ? searchDate.find(item => item == formatDate(date)) : formatDate(date),
    },
    {
      width: 56,
      className: 'actions',
      key: 'actions',
      fixed: 'right',
      onCell: () => ({
        onClick: e => e.stopPropagation(),
      }),
      render: record => (
        <DeleteConfirmation
          id={record.id.toString()}
          onActionDelete={handleDelete}
          title={t('delete_parameter')}
        >
          <Trans>
            {t('delete_description', {
              name: record.key,
            })}
          </Trans>
        </DeleteConfirmation>
      ),
    },
  ];

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable
        loading={isLoading || isDeleteLoading}
        dataSource={data?.data || []}
        columns={columns}
        onRow={onRowHandler}
        expandable={{
          showExpandColumn: false,
        }}
        totalItems={data?.pagination?.total_count}
        onChange={(pagination, filters, sorter) => {
          setTableParams(getTableMiscData(pagination, filters, sorter));
        }}
      />
    </Content>
  );
};
