import React from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { TextToSpeechForm } from '../TextToSpeechForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { ITextToSpeechConfig, ITTSValues } from '../types';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';

export const TextToSpeechEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<ITextToSpeechConfig>(
    [TextToSpeechService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      TextToSpeechService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading } = useUpdate(
    [TextToSpeechService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    TextToSpeechService.update,
  );

  const onSubmit = async (
    data: ITTSValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      updateItem(
        { apiData: aiIntegration, data, id },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('tts_profile_updated_notify', { name: response.text_to_speech_config.name })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification['error'](unexpectedError);
          },
        },
      );
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues = {
    name: data.name,
    locale: data.locale,
    voice: data.voice,
    speaking_rate: data.speaking_rate,
    pitch: data.pitch,
    tts_provider: data.tts_provider,
  };

  return (
    <Content imgBg={false}>
      <TextToSpeechForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
