import { IWorkflow } from '../pages/Knowledge/Workflow/types';

export const mockPresentationsData = {
  data: [
    {
      id: '1',
      title: 'Test 1',
      label_list: ['Label1 1', 'Label1 2'],
      content_entities: [
        {
          channel: {
            id: 11,
            channel_type: 'web',
            content_entities: [
              {
                id: 11,
                title: 'Content Entity-1',
              },
            ],
          },
          language: { id: 1, code: 'en-gb', display: 'English UK' },
          translation_status: 'verified',
        },
        {
          channel: {
            id: 12,
            channel_type: 'sms',
            content_entities: [
              {
                id: 11,
                title: 'Content Entity-2',
              },
            ],
          },
          language: { id: 1, code: 'en-gb', display: 'English UK' },
          translation_status: 'unverified',
        },
      ],
      synchronisations: [
        {
          id: 101,
          destination_entity: {
            created_at: '2022-12-01',
            destination: {
              created_at: '2022-12-01',
              destination_type: 'dialogflow_es',
              destination_type_text: 'Dialogflow ES',
              id: 5,
              metadata: '{}',
              name: 'Main DF ES Bot',
              updated_at: '2022-12-01',
            },
            id: 1,
            title: 'Main DF DestinationEntity title',
            updated_at: '2022-12-01',
            uuid: '123456789',
            language: { id: 1, code: 'en-gb', display: 'English UK' },
          },
        },
      ],
      updated_at: '2022-12-01',
    },
    {
      id: '2',
      title: 'Test 2',
      label_list: ['Label2 2'],
      content_entities: [
        {
          channel: {
            id: 21,
            channel_type: 'web',
            content_entities: [
              {
                id: 11,
                title: 'Content Entity-2',
                language: { id: 1, code: 'es', display: 'Spanish' },
              },
            ],
          },
        },
      ],
      synchronisations: [
        {
          id: 201,
          destination_entity: {
            created_at: '2022-12-05',
            destination: {
              created_at: '2022-12-05',
              destination_type: 'dialogflow_es',
              destination_type_text: 'Dialogflow ES',
              id: 5,
              metadata: '{}',
              name: 'My new destinationEntity title',
              updated_at: '2022-12-05',
            },
            id: 1,
            title: 'DestinationEntity title',
            updated_at: '2022-12-05',
            uuid: '123456789',
            language: { id: 1, code: 'es', display: 'Spanish' },
          },
        },
      ],
      updated_at: '2022-12-05',
    },
  ],
  pagination: {
    current: 1,
    previous: null,
    next: 2,
    page_size: 10,
    total_pages: 1,
    total_count: 2,
  },
};

export const mockPresentationsDataResult = [
  {
    id: '1',
    title: 'Test 1',
    channels: ['web', 'sms'],
    intents: [{ type: 'dialogflow_es', name: 'Main DF DestinationEntity title' }],
    labels: ['Label1 1', 'Label1 2'],
    updated_at: '2022-12-01',
  },
  {
    id: '2',
    title: 'Test 2',
    channels: ['web'],
    intents: [{ type: 'dialogflow_es', name: 'DestinationEntity title' }],
    labels: ['Label2 2'],
    updated_at: '2022-12-05',
  },
];

export const mockParametersData = {
  data: [
    {
      id: 1,
      key: 'company_name',
      value: 'Sabio',
      configurable_type: 'Project',
      configurable_id: 4,
      configurable: {
        id: 4,
        name: 'Test project',
      },
      children: [
        {
          id: 2,
          key: 'company_name',
          value: 'ACME',
          configurable_type: 'Destination',
          configurable_id: 3,
          configurable: {
            id: 3,
            name: 'Welcome',
            destination_type: 'dialogflow_es',
          },
        },
      ],
      updated_at: '2023-02-13T10:04:54.972Z',
    },
    {
      id: 3,
      key: 'address',
      value: 'London',
      configurable_type: 'Project',
      configurable_id: 4,
      configurable: {
        id: 4,
        name: 'Test project',
      },
      children: [],
      updated_at: '2023-02-14T10:04:54.972Z',
    },
  ],
  pagination: {
    current: 1,
    previous: null,
    next: 2,
    page_size: 10,
    total_pages: 1,
    total_count: 2,
  },
};

export const mockWorkflow = {
  data: [
    {
      id: 1,
      title: 'How do I cancel an order?',
      status: 'draft',
      requester: {
        id: 1,
        username: 'user1',
        external_data: {
          firstName: 'Donnie',
          lastName: 'Yen',
          email: 'yen@user.com',
          username: 'user1',
        },
      },
      approver: {
        id: 2,
        username: 'user2',
        external_data: {
          firstName: 'Winston',
          lastName: 'Scott',
          email: 'scott@user.com',
          username: 'user2',
        },
      },
      updated_at: '2023-02-14T10:04:54.972Z',
    },
    {
      id: 2,
      title: 'How do I make payment?',
      status: 'pending',
      requester: {
        id: 1,
        username: 'user1',
        external_data: {
          firstName: 'Donnie',
          lastName: 'Yen',
          email: 'yen@user.com',
          username: 'user1',
        },
      },
      approver: {
        id: 2,
        username: 'user2',
        external_data: {
          firstName: 'Winston',
          lastName: 'Scott',
          email: 'scott@user.com',
          username: 'user2',
        },
      },
      updated_at: '2023-02-15T10:04:54.972Z',
    },
    {
      id: 3,
      title: 'What are the steps to claim a voucher?',
      status: 'rejected',
      requester: {
        id: 1,
        username: 'user1',
        external_data: {
          firstName: 'Donnie',
          lastName: 'Yen',
          email: 'yen@user.com',
          username: 'user1',
        },
      },
      approver: {
        id: 2,
        username: 'user2',
        external_data: {
          firstName: 'Winston',
          lastName: 'Scott',
          email: 'scott@user.com',
          username: 'user2',
        },
      },
      updated_at: '2023-02-16T10:04:54.972Z',
    },
    {
      id: 4,
      title: 'What should I do if I did not receive a confirmation after placing an order?',
      status: 'approved',
      requester: {
        id: 1,
        username: 'user1',
        external_data: {
          firstName: 'Donnie',
          lastName: 'Yen',
          email: 'yen@user.com',
          username: 'user1',
        },
      },
      approver: {
        id: 2,
        username: 'user2',
        external_data: {
          firstName: 'Winston',
          lastName: 'Scott',
          email: 'scott@user.com',
          username: 'user2',
        },
      },
      updated_at: '2023-02-16T10:04:54.972Z',
    },
  ] as IWorkflow[],
};
