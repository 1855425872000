import React from 'react';
import styled from '@emotion/styled';
import { Alert, AlertProps } from 'antd';
import { ReactComponent as WarningIcon } from '../../assets/icons/triangle-exclamation-solid.svg';

type Props = Omit<AlertProps, 'type' | 'icon' | 'showIcon' | 'closable'>;

export const WarningBanner: React.FC<Props> = props => (
  <StyledAlert
    type="warning"
    icon={<WarningIcon width={20} />}
    showIcon
    closable={false}
    {...props}
  />
);

const StyledAlert = styled(Alert)`
  max-width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.secondaryColor};
  background-color: ${props => props.theme.warningBgColor};
  min-height: 56px;
  .ant-alert-message {
    margin: 0 32px 0 4px;
  }
  svg {
    fill: ${props => props.theme.secondaryColor};
  }
`;
