import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { IAiIntegrationUrl } from '../../state/integrations';
import {
  IExternalEndpoint,
  IExternalEndpointValues,
} from '../../pages/AI/Routing/ExternalEndpoints/types';
import { sortById } from '../../utils/sorting';

const queryKey = 'external-endpoints';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IExternalEndpoint[]> => {
  const response = await integrationQuery(`/api/outbound_contact_uris?includes[]=router`, {
    apiData,
    signal,
  });
  return sortById(response.outbound_contact_uris);
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams): Promise<void> =>
  await integrationQuery(`/api/outbound_contact_uris/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const create = async ({
  data,
  apiData,
}: { data: IExternalEndpointValues } & AiIntegrationParams): Promise<IExternalEndpoint> =>
  await integrationQuery(`/api/outbound_contact_uris`, {
    method: 'POST',
    data: { data: { type: 'outbound_contact_uris', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IExternalEndpointValues> => {
  const response = await integrationQuery(`/api/outbound_contact_uris/${id}`, { apiData, signal });
  return response?.outbound_contact_uri;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: IExternalEndpointValues;
}): Promise<IExternalEndpointValues> => {
  return integrationQuery(`/api/outbound_contact_uris/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'outbound_contact_uris',
        attributes: data,
      },
    },
    apiData,
  });
};

export const ExternalEndpointsService = {
  get,
  remove,
  create,
  find,
  update,
  queryKey,
};
