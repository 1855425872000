import * as React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Col, Flex, Row, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TeamsService } from '../../../../../services/ai/teams';
import { AiIntegrationParams } from '../../../../../services/api';
import { useIntegrations } from '../../../../../state/integrations';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { ViewLink } from '../../../../../components/ViewLink';
import { CardMenu } from '../../../../../components/CardMenu';
import { StyledCard } from '../../../AI.styles';
import { ReactComponent as TimerIcon } from '../../../../../assets/icons/timer.svg';
import { days, TimezoneByCity, Weekday } from '../../../../../constants';
import { getUtcOffset } from '../../../../../utils/date';
import { Paths } from '../../../../../types/paths';
import { sortByDay } from '../../../../../utils/sorting';

export const TeamsList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { data } = useQuery(
    [TeamsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => TeamsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleCreate = () => {
    navigate(Paths.aiTeamsCreate());
  };

  const handleEdit = (id: number) => {
    navigate(Paths.aiTeamsEdit({ id }));
  };

  const ButtonCreate = <ActionButton type="create" onClick={handleCreate} />;

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <Row gutter={16}>
        {data?.map(item => (
          <Col xs={24} sm={12} lg={8} xxl={6} key={item.id}>
            <StyledCard
              title={item.name}
              bordered={false}
              extra={
                <CardMenu
                  id={item.id}
                  name={item.name}
                  deleteConfirmationTitle={t('delete_team')}
                  service={TeamsService}
                  disableDelete={item.is_default ? true : false}
                />
              }
            >
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Flex gap={5}>
                    <Tag color={item.open ? 'success' : 'error'}>
                      {item.open ? t('active') : t('inactive')}
                    </Tag>
                    {item.is_default && <Tag color="default">{t('default')}</Tag>}
                  </Flex>
                </Col>
                <Col span={24}>
                  <Flex gap={5} align={'center'}>
                    <TimerIcon width={14} height={14} />
                    {`${t('timezone')}: ${item.timezone} `}
                    {getUtcOffset(
                      TimezoneByCity.find(entry => entry.value === item.timezone)?.zone || '',
                    )}
                  </Flex>
                </Col>
                <Col span={24}>
                  {sortByDay(item.time_schedules).map(schedule => (
                    <Row key={schedule.id}>
                      <Col span={12}>
                        {days[schedule.day.toLowerCase() as Weekday] || schedule.day}
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        {schedule.closed
                          ? t('closed')
                          : `${dayjs(schedule.start_time, 'HH:mm').format('h:mm A')} - ${dayjs(
                              schedule.end_time,
                              'HH:mm',
                            ).format('h:mm A')}`}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
              <ViewLink isEdit to="" onClick={() => handleEdit(item.id)} />
            </StyledCard>
          </Col>
        ))}
      </Row>
    </Content>
  );
};
