import { ReactElement } from 'react';
import { Tooltip } from 'antd';
import i18n from '../locales/i18n';
import { theme } from '../theme';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

export type DocumentStatus = 'done' | 'in_progress' | 'fail';

export const getDocumentStatus = (status: string): ReactElement | null => {
  switch (status) {
    case 'done':
      return (
        <Tooltip title={i18n.t('ai:doc_success_created')}>
          <CheckIcon width={14} height={14} color={theme.successColor} />
        </Tooltip>
      );
    case 'in_progress':
      return (
        <Tooltip title={i18n.t('ai:doc_creation_in_progress')}>
          <SpinnerIcon width={14} height={14} color={theme.inputPlaceholderColor} />
        </Tooltip>
      );
    case 'fail':
      return (
        <Tooltip title={i18n.t('ai:doc_creation_failed')}>
          <CloseIcon width={12} height={12} color={theme.errorColor} />
        </Tooltip>
      );
    default:
      return <></>;
  }
};
