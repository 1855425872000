import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledHeader } from './Header.styles';
import { Projects } from './Projects';
import { Agents } from './Agents';
import { titleMap } from './header.constants';
import { Search } from './Search';
import { ProfileMenu } from './ProfileMenu';
import { isPartPathname } from './utils';
import { Settings } from './Settings';
import { Accounts } from './Accounts';

export const Header: React.FC = () => {
  const [title, setTitle] = useState<React.ReactNode>('');
  const location = useLocation();
  const { headerSearch, profileMenu, agents } = useFlags();

  useEffect(() => {
    const curTitle = titleMap.find(
      item =>
        item.path === location.pathname || isPartPathname(location.pathname, item.path as string),
    );
    if (curTitle && curTitle.title) {
      setTitle(curTitle.title);
    } else {
      setTitle('');
    }
  }, [location]);

  return (
    <StyledHeader.Root>
      <h1>{title ? title : ''}</h1>
      <StyledHeader.Controls>
        {headerSearch && <Search />}
        <Accounts />
        <Projects />
        {agents && <Agents />}
        <Settings />
        {profileMenu && <ProfileMenu />}
      </StyledHeader.Controls>
    </StyledHeader.Root>
  );
};
