import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const StyledViewLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  color: ${({ theme }) => theme.linkColor};
  span {
    position: relative;
    top: 1px;
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s;
  }
  svg {
    margin-right: 4px;
  }
  &:hover {
    color: ${({ theme }) => theme.linkColor};
    span {
      border-color: ${({ theme }) => theme.linkColor};
    }
  }
`;
