import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

export const TableBase = styled.div<{ isClickable: boolean; isPaginationFixed: boolean }>`
  .ant-table-expanded-row-fixed {
    width: auto !important;
  }
  .ant-table-tbody {
    border-bottom: none;
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
  .ant-table-header {
    box-shadow: -1px 0 0 0 ${props => props.theme.tableHeaderBorderColor};
  }
  .ant-table-cell-scrollbar:not([rowspan]):before {
    position: absolute;
    content: '';
    background: ${props => props.theme.tableHeaderBg};
    width: calc(100% + 2px);
    top: 1px;
    bottom: 1px;
    left: -1px;
  }
  .ant-table-header tr > .ant-table-cell-scrollbar::after {
    content: '';
    border-right: 1px solid ${props => props.theme.tableHeaderBorderColor};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort::before {
    background-color: ${props => props.theme.secondaryBg} !important;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.scrollColor};
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollColor};
    &:hover {
      background: ${({ theme }) => theme.textColor50};
    }
  }
  .ant-table {
    svg {
      vertical-align: middle;
    }
  }
  .ant-table-thead > tr > .ant-table-cell {
    border-top: 1px solid ${props => props.theme.tableHeaderBorderColor};
    border-bottom: 1px solid ${props => props.theme.tableHeaderBorderColor};
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
    padding: 6px 10px;
  }
  .ant-table-thead > tr > th [class^='ant-table'] {
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    &:before {
      background-color: ${props => props.theme.secondaryBg} !important;
    }
  }
  .ant-table-wrapper .ant-table-column-sorter-up {
    margin-top: 0.1em;
  }
  .ant-table-wrapper .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.4em;
  }
  .ant-table-tbody td {
    word-break: break-word;
    transition: background;
  }
  .ant-table-tbody td:first-of-type,
  .ant-table-thead th:first-of-type {
    padding-left: 20px;
    text-align: left;
  }
  .ant-table-tbody .actions {
    padding: 4px 8px;
    pointer-events: none;
  }

  ${props =>
    props.isClickable &&
    css`
      .ant-table-tbody > tr > td:not(.actions) {
        cursor: pointer;
      }
    `}
  .actions a,
  .actions button,
  .actions [role="button"] {
    pointer-events: auto;
    white-space: nowrap;
    & + a,
    & + button {
      margin-left: 8px;
    }
  }
  .actions .ant-btn-default {
    padding-left: 10px;
    padding-right: 10px;
    color: ${({ theme }) => theme.textColor};
    &:hover,
    &:focus {
      background-color: transparent !important;
      color: inherit !important;
    }
  }
  .actions .ant-typography {
    display: inline-block;
    margin-bottom: 0;
    div[role='button'] {
      padding: 7px 10px !important;
      margin-left: 0;
    }
    .ant-typography-copy {
      color: ${({ theme }) => theme.textColor};
    }
  }
  td.is-clickable {
    color: ${props => props.theme.primaryColor};
  }
  .is-disabled-row {
    pointer-events: none;
    td {
      color: ${props => props.theme.tableColorDisabled};
    }
  }
  .ant-empty-normal .ant-empty-description {
    color: ${props => props.theme.primaryColor};
    opacity: 0.3;
  }
  .ant-table-pagination {
    justify-content: flex-start;
    ${props =>
      props.isPaginationFixed &&
      css`
        position: fixed;
        bottom: 12px;
        right: 40px;
        z-index: 10;
        @media (max-width: 1024px) {
          right: 15px;
        }
      `}
  }
  .ant-pagination-total-text {
    order: -2;
    flex-grow: 1;
    margin-right: 0;
    ${props =>
      props.isPaginationFixed &&
      css`
        position: fixed;
        left: 104px;
        @media (max-width: 1024px) {
          left: 79px;
        }
      `}
  }
  .ant-pagination-options {
    order: -1;
  }
  .ant-pagination-options .ant-select-selector {
    border-color: ${({ theme }) => theme.secondaryBg};
  }
  .ant-pagination-options .ant-select-arrow {
    .ant-select-suffix {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${({ theme }) => theme.primaryColor};
      margin-top: -2px;
      transition: transform 0.3s ease;
    }
  }
  .ant-table-small .ant-table-thead > tr > th {
    padding: 6px 10px !important;
    color: ${props => props.theme.inputPlaceholderColor};
  }
  .ant-table-small .ant-table-tbody > tr > td {
    vertical-align: middle;
    line-height: 1;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.secondaryBg};
    border-color: ${({ theme }) => theme.secondaryBg} !important;
  }
  .is-relative {
    position: relative;
  }
  .warningIcon {
    position: absolute;
    top: 50%;
    right: 8px;
    color: ${({ theme }) => theme.errorColor};
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  .ant-table-row-selected .warningIcon {
    opacity: 1;
    visibility: visible;
  }
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const TableActionButton = styled(Button)`
  &,
  &[disabled],
  .actions &[disabled]:hover {
    background: transparent;
  }
  .actions &[disabled]:hover {
    color: ${({ theme }) => theme.textColor};
  }
  &[disabled] {
    opacity: 0.2;
  }
`;
