import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { isEmpty } from 'ramda';
import { fAReportsQuery, analyticsQuery } from './api';
import { ILookerItem } from '../pages/Analytics/Reports/types';
import { ApiResponse } from './types';
import { sortById } from '../utils/sorting';

export type AnalyticsParams = { projectId: string; analyticsUrl: string };

type DeleteProps = { reportUrl: string; analyticsUrl: string };
export interface IAnalyticSearchParams {
  name?: string | string[];
}

const queryKeyDashboards = 'reportDashboards';
const queryKeyExplores = 'reportExplores';
const queryKeyLooks = 'reportLooks';
const queryKeyFAReports = 'fAReports';

const find = async (
  { url, analyticsUrl }: { url: string; analyticsUrl: string },
  signal: QueryFunctionContext['signal'],
): Promise<ILookerItem> => await analyticsQuery(url, { analyticsUrl, signal });

const getDashboards = async (
  { projectId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
  params?: IAnalyticSearchParams,
) => {
  const response = await analyticsQuery(`/api/projects/${projectId}/looker/embed/dashboards`, {
    analyticsUrl,
    signal,
    params,
  });
  return sortById(response);
};

const deleteDashboard = async ({ reportUrl, analyticsUrl }: DeleteProps): Promise<ApiResponse> =>
  await analyticsQuery(reportUrl, {
    method: 'DELETE',
    analyticsUrl,
  });

const getExplores = async (
  { projectId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
  params?: IAnalyticSearchParams,
): Promise<ILookerItem[]> => {
  const response: ILookerItem[] = await analyticsQuery(
    `/api/projects/${projectId}/looker/embed/explores`,
    { analyticsUrl, signal, params },
  );
  return response.filter(item => !isEmpty(item)).map(el => ({ ...el, id: el.name })) || [];
};

const getLooks = async (
  { projectId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
  params?: IAnalyticSearchParams,
): Promise<ILookerItem[]> => {
  const response = await analyticsQuery(`/api/projects/${projectId}/looker/embed/looks`, {
    analyticsUrl,
    signal,
    params,
  });
  return sortById(response);
};

const deleteLook = async ({ reportUrl, analyticsUrl }: DeleteProps): Promise<ApiResponse> =>
  await analyticsQuery(reportUrl, {
    method: 'DELETE',
    analyticsUrl,
  });

const getFAReports = async (signal: QueryFunctionContext['signal']): Promise<ILookerItem[]> => {
  const response = await fAReportsQuery({ signal });
  return response.Reports || [];
};

export const AnalyticsService = {
  queryKeyDashboards,
  queryKeyExplores,
  queryKeyLooks,
  queryKeyFAReports,
  find,
  getDashboards,
  deleteDashboard,
  getExplores,
  getLooks,
  deleteLook,
  getFAReports,
};
