import React from 'react';
import { Select, SelectProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

type Props = {
  handleChange?: (value: string) => void;
  options?: SelectProps['options'];
};

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      className="presentation-option-tag"
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};
export const LabelSelector: React.FC<Props> = ({ handleChange, options, ...rest }) => {
  const { t } = useTranslation('knowledge');
  return (
    <Select
      tagRender={tagRender}
      mode="tags"
      style={{ width: '100%' }}
      placeholder={t('select_label')}
      onChange={handleChange}
      options={options}
      suffixIcon={null}
      {...rest}
    />
  );
};
