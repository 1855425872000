import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuProps } from 'antd/lib/menu';
import { isEmpty } from 'ramda';
import { DropdownButton, DropdownMenu } from '../../Dropdown';
import { TruncatedString } from '../../TruncatedString';
import { useDropdown } from '../../../hooks/useDropdown';
import { useAccount } from '../../../state/account';
import { findByBiggestId } from '../../../utils/filtering';
import { IProject } from '../../../pages/Projects/types';
import { ProjectsService } from '../../../services/projects';
import { ACTIVE_PROJECT_NAME } from '../../../constants';

type MenuItem = MenuItemType & { key: string; value: string; label: JSX.Element };

export const Projects: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation('header');
  const [selectedItem, setSelectedItem] = useState<ReactElement | string>();
  const { visible, toggleDropdown, dropdownRef } = useDropdown<any>(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { activeAccountId, activeProjectId, setActiveProjectId, resetActiveProject, isNoAccount } =
    useAccount();
  const { data, isFetching, isFetched } = useQuery<IProject[], string>(
    [ProjectsService.queryKey, activeAccountId],
    ProjectsService.get,
    { enabled: !!activeAccountId },
  );

  useEffect(() => {
    if (data?.length) {
      const options = data
        .filter(item => item.is_enabled)
        .map(item => ({
          key: item.id.toString(),
          label: (
            <TruncatedString
              isShowFullOnHover={true}
              isPointerEventsDisabled={true}
              key={item.name}
            >
              {item.name}
            </TruncatedString>
          ),
          value: item.id.toString(),
        }));
      setMenuItems(options);
      !options.length && resetActiveProject(true);
    } else if (!isFetching && isFetched) {
      resetActiveProject(true);
      setMenuItems([]);
    } else if (isNoAccount) {
      setSelectedItem(t('select_project'));
    }
  }, [data, isFetching, isFetched, isNoAccount]);

  useEffect(() => {
    const setProjectsBiggestId = () => {
      const lastItemById = findByBiggestId(menuItems);
      setActiveProjectId(lastItemById);
      const projectLabel = menuItems[menuItems.findIndex(item => item.key === lastItemById)].label;
      setSelectedItem(projectLabel || t('select_project'));
      localStorage.setItem(ACTIVE_PROJECT_NAME, projectLabel?.key as string);
    };
    const setSelectedMenuItem = () => {
      if (menuItems?.length) {
        if (activeProjectId) {
          const activeProject = menuItems.find(item => item.key === activeProjectId);
          activeProject ? setSelectedItem(activeProject.label) : setProjectsBiggestId();
        } else setProjectsBiggestId();
      } else if (!isFetching && isFetched) setSelectedItem(t('select_project'));
    };
    setSelectedMenuItem();
  }, [menuItems, activeProjectId]);

  const handleProjectSelect: MenuProps['onClick'] = ({ key }) => {
    if (menuItems?.length) {
      const selectedItem = menuItems.find(item => item.key === key);
      if (selectedItem) {
        setSelectedItem(selectedItem.label);
        setActiveProjectId(selectedItem.key);
        localStorage.setItem(ACTIVE_PROJECT_NAME, selectedItem.label.key as string);
        if (!isEmpty(params)) {
          navigate('..', { relative: 'path' });
        }
      }
    }
  };

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: false,
        onClick: handleProjectSelect,
        ...(activeProjectId && { selectedKeys: [activeProjectId] }),
      }}
      dropdownRender={menu => (
        <DropdownMenu.Root>
          {!!menuItems.length && <DropdownMenu.Menu>{menu}</DropdownMenu.Menu>}
          <DropdownMenu.Footer single={!menuItems?.length} to={'projects'} onClick={toggleDropdown}>
            {t('view_all_projects')}
          </DropdownMenu.Footer>
        </DropdownMenu.Root>
      )}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
      disabled={isNoAccount}
    >
      <DropdownButton ref={dropdownRef} id="projects-button" onClick={toggleDropdown}>
        <DropdownMenu.Text>{selectedItem}</DropdownMenu.Text>
      </DropdownButton>
    </Dropdown>
  );
};
