import React from 'react';
import { Form, Input, Radio, Select, Divider, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import { LocationSelect } from '../../LocationSelect';
import { LanguagesSearch } from '../../LanguagesSearch';
import { filterSelectOptions } from '../../../../../utils/filtering';
import useLanguages from '../../../../../hooks/useLanguages';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { Space } from '../../../../../components/Space';
import { GoogleCloudProjectInput } from '../../../../../components/GoogleCloudProjectInput';

const { Option } = Select;

export const AIAutomationSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const { knowledgeWorkflow } = useFlags();
  const { languages } = useLanguages();
  const { getFieldValue } = Form.useFormInstance();
  const isAIAutoSelected = getFieldValue('ai_n_a');

  return (
    <>
      <Divider style={{ margin: '0 0 8px' }} />
      {!id && (
        <>
          <Form.Item name="nlu_provider" label={t('nlu_provider')} className={formItemClass.base}>
            <Select placeholder={t('nlu_provider')}>
              <Option value="google">Google DialogFlow</Option>
            </Select>
          </Form.Item>
          <GoogleCloudProjectInput isProjectCreation isAIAutoSelected />
          <Form.Item
            name="platform_edition"
            label={t('platform_edition')}
            className={formItemClass.base}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="es">ES</Radio.Button>
              <Radio.Button value="cx" disabled>
                CX
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.platform_edition !== currentValues.platform_edition
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('platform_edition') === 'cx' && (
                <Space>
                  <Form.Item
                    name="uuid"
                    label={t('uuid')}
                    className={formItemClass.base}
                    tooltip={t('uuid_tooltip')}
                  >
                    <Input placeholder={t('uuid')} />
                  </Form.Item>
                  <Form.Item
                    name="location"
                    label={t('location')}
                    tooltip={t('location_tooltip')}
                    className={formItemClass.base}
                  >
                    <LocationSelect placeholder={t('location')} />
                  </Form.Item>
                </Space>
              )
            }
          </Form.Item>
          <Divider />
          <Form.Item
            name="voice_provider"
            label={t('chat_voice_provider')}
            className={formItemClass.base}
          >
            <Select placeholder={t('chat_voice_provider')}>
              <Option value="twilio">Twilio</Option>
            </Select>
          </Form.Item>
          <Space>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_account_sid']}
              label={t('twilio_account_sid')}
              className={formItemClass.base}
              rules={[{ required: isAIAutoSelected }]}
              tooltip={t('twilio_account_sid_tooltip')}
            >
              <Input placeholder="ACxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
            </Form.Item>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_auth_token']}
              label={t('twilio_auth_token')}
              className={formItemClass.base}
              rules={[{ required: isAIAutoSelected }]}
              tooltip={t('twilio_auth_token_tooltip')}
            >
              <PasswordInput placeholder="*******************" />
            </Form.Item>
          </Space>
          <Form.Item
            name={['ai_n_a_attributes', 'twilio_phone_number']}
            label={t('twilio_phone_number')}
            className={formItemClass.base}
            rules={[{ required: isAIAutoSelected }]}
          >
            <Input placeholder="###-###-####" />
          </Form.Item>
          <Divider />
        </>
      )}
      <Space size={40}>
        <Form.Item
          name="default_language_id"
          label={t('default_language')}
          className={formItemClass.base}
          rules={[{ required: isAIAutoSelected }]}
        >
          <Select
            placeholder={t('default_language')}
            disabled={!!id}
            showSearch
            filterOption={filterSelectOptions}
          >
            {languages?.map(lng => (
              <Option key={lng.locale} value={lng.id.toString()}>
                {lng.name} ({lng.locale})
              </Option>
            ))}
          </Select>
        </Form.Item>
        {knowledgeWorkflow && (
          // remove the disabled below once the new project Edit is working on the BE
          <Form.Item
            name={['knowledge_v2_attributes', 'is_approval_flow_enabled']}
            label={t('workflow')}
            valuePropName="checked"
            tooltip={t('tooltip_workflow')}
          >
            <Switch disabled={!!id} />
          </Form.Item>
        )}
      </Space>
      <Form.Item
        label={t('additional_languages')}
        name="project_languages_attributes"
        style={{ marginBottom: 0 }}
      >
        <LanguagesSearch languages={languages} placeholder={t('search_language')} />
      </Form.Item>
    </>
  );
};
