import styled from '@emotion/styled';

export const StyledDropdownParameter = styled.div`
  overflow: auto;
  background: #fff;
  box-shadow: ${({ theme }) => theme.dropdownShadow};
  width: 400px;
  margin: 10px 10px 0 0;
  .ant-dropdown-menu {
    box-shadow: none;
  }

  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    padding: 4px 16px;
  }

  .ant-dropdown-menu-title-content {
    font-size: 13px;
    color: black;
  }
`;
