import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { ExternalEndpointsService } from '../../../../../services/ai/external-endpoints';
import { IExternalEndpoint } from '../types';
import { Paths } from '../../../../../types/paths';

export const ExternalEndpointsList = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiExternalEndpointsEdit({ id })),
  });

  const columns: ColumnProps<IExternalEndpoint>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '40%',
    },
    {
      title: t('endpoint'),
      onCell: onCellHandler,
      dataIndex: 'endpoint',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '40%',
    },
    {
      title: t('router'),
      onCell: onCellHandler,
      dataIndex: ['router', 'name'],
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '17%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IExternalEndpoint>
      service={ExternalEndpointsService}
      columns={columns}
      getCreatePath={Paths.aiExternalEndpointsCreate}
      deleteConfirmationTitle={t('delete_endpoint')}
      getDisplayName={record => record.name}
    />
  );
};
