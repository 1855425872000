import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AutoComplete, SelectProps } from 'antd';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { IncomingPhoneNumbersService } from '../../services/ai/incoming-phone-numbers';
import { ChannelType } from '../../pages/AI/Channels/Channel/types';

interface Props extends SelectProps {
  channelType?: ChannelType;
}

export const IncomingPhoneNumberSelector: React.FC<Props> = ({ channelType, ...other }) => {
  const { aiIntegration } = useIntegrations();
  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useQuery(
    [IncomingPhoneNumbersService.queryKey, aiIntegration?.metadata.PROJECT_ID, channelType],
    ({ signal }) =>
      IncomingPhoneNumbersService.get(
        { apiData: aiIntegration } as AiIntegrationParams,
        signal,
        channelType,
      ),
    { enabled: !!aiIntegration?.url },
  );

  const options = data
    ? data
        .filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()))
        .map(item => ({
          label: item,
          value: item,
        }))
    : [];

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  return <AutoComplete options={options} onSearch={handleSearch} {...other} />;
};
