import React, { useEffect, useRef, useState } from 'react';

type UseDropdownResult<TDropdownEl> = {
  dropdownRef: React.Ref<TDropdownEl>;
  toggleDropdown: () => void;
  visible: boolean;
};

export function useDropdown<TDropdownEl>(initialValue: boolean): UseDropdownResult<TDropdownEl> {
  const [visible, setVisible] = useState(initialValue);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };
    document.addEventListener('click', handleOutsideClick);

    const listener = () => {
      if (document.activeElement === document.querySelector('iframe')) {
        closeDropdown();
      }
    };
    window.addEventListener('blur', listener);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('blur', listener);
    };
  }, []);

  const closeDropdown = () => setVisible(false);

  const toggleDropdown = () => setVisible(!visible);

  return { dropdownRef, toggleDropdown, visible };
}
