import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@tanstack/react-query';
import { AnalyticsParams, IAnalyticSearchParams } from '../../services/analytics';
import { useIntegrations } from '../../state/integrations';
import { ILookerItem } from '../../pages/Analytics/Reports/types';

interface Props {
  queryKey: string;
  fetchDataFn: (
    params: AnalyticsParams,
    signal?: AbortSignal,
    searchParams?: IAnalyticSearchParams,
  ) => Promise<ILookerItem[]>;
}

export const useAnalyticsData = ({ queryKey, fetchDataFn }: Props) => {
  const {
    analytics: { url: analyticsUrl, externalId: projectId } = {},
    aiIntegration,
    pulse,
  } = useIntegrations();
  const { aiEmail } = useFlags();
  const params = {
    name: [aiIntegration && 'airline', aiEmail && 'ai_email', pulse && 'pulse'].filter(Boolean),
  };

  return useQuery<ILookerItem[]>(
    [queryKey, projectId, params],
    ({ signal }) => fetchDataFn({ projectId, analyticsUrl } as AnalyticsParams, signal, params),
    { enabled: !!projectId && !!analyticsUrl },
  );
};
