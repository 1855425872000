export type RoutingMethod = 'task_router' | 'direct_dial' | 'genesys' | 'avaya' | 'livechat';
export type RouterProtocol = 'pstn' | 'sip';

export interface IRouter {
  id: number;
  name: string;
  outbound_contact_uri?: string;
  protocol?: RouterProtocol;
  routing_method?: RoutingMethod;
  is_default?: boolean;
  config?: string;
  sip_refer_enabled?: boolean;
  team_id?: number;
  integration_id?: string;
  client_id?: string;
  client_secret?: string;
  region?: string;
  organization_id?: string;
  pat_token?: string;
}

export interface IRouterValues {
  name: string;
  outbound_contact_uri?: string;
  is_default?: boolean;
  protocol?: RouterProtocol;
  routing_method?: RoutingMethod;
  sip_refer_enabled?: boolean;
  team_id?: number;
  config?: string;
  integration_id?: string;
  client_id?: string;
  client_secret?: string;
  region?: string;
  organization_id?: string;
  pat_token?: string;
}

export enum RoutingMethods {
  task_router = 'task_router',
  direct_dial = 'direct_dial',
  genesys = 'genesys',
  avaya = 'avaya',
  livechat = 'livechat',
}
