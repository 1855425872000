import { ThemeConfig } from 'antd';
import { theme } from './theme';

export const antThemeConfig: ThemeConfig = {
  token: {
    fontFamily: theme.fontFamilyBase,
    colorPrimary: theme.primaryColor,
    fontSize: 14,
    lineHeight: 1.4155,
    colorText: theme.textColor,
    colorTextHeading: theme.primaryColor,
    colorInfo: theme.primaryColor,
    colorError: theme.errorColor,
    colorErrorHover: theme.primaryColor,
    colorBorder: theme.primaryColor,
    colorBgContainerDisabled: theme.lightBg,
    borderRadius: 0,
    controlHeight: 36,
    colorTextPlaceholder: theme.inputPlaceholderColor,
    controlOutline: theme.primaryColor,
    controlOutlineWidth: 1,
    colorErrorOutline: theme.errorColor,
    controlItemBgHover: theme.lightBg,
    boxShadowSecondary: theme.dropdownShadow,
    colorInfoBg: 'rgba(0, 158, 252, 0.1)',
    colorSuccess: theme.successColor,
    colorSuccessBg: '#e8f7e8',
    colorSuccessBorder: theme.successColor,
    colorWarning: theme.warningColor,
    colorWarningBg: theme.warningBgColor,
    colorWarningBorder: theme.warningColor,
    colorErrorBg: '#ffebeb',
    colorErrorBorder: theme.errorColor,
    marginXS: 4,
  },
  components: {
    Button: {
      colorBgContainer: theme.secondaryBg,
      colorText: theme.primaryColor,
      colorBorder: 'transparent',
      colorPrimaryHover: theme.primaryColor,
      colorPrimaryTextHover: '#fff',
      colorPrimary: theme.secondaryColor,
      colorTextLightSolid: theme.primaryColor,
      colorTextDisabled: 'rgba(16, 0, 107, 0.1)',
      colorPrimaryBorder: 'transparent',
      dangerColor: '#fff',
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      boxShadowTertiary: 'none',
      marginXS: 6, // icon margin
      lineWidth: 0, //border-width
      lineType: 'none',
      lineHeight: 1,
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',
      controlHeightSM: 28,
    },
    Menu: {
      itemHeight: 36,
      horizontalLineHeight: theme.inputHeight,
      itemActiveBg: theme.secondaryBg,
      groupTitleColor: 'rgba(51, 51, 51, 0.5)',
      itemColor: theme.primaryColor,
      itemMarginBlock: '0',
      itemMarginInline: 0,
      itemPaddingInline: '20px',
      activeBarBorderWidth: 0,
      activeBarWidth: 0,
      itemHoverBg: theme.lightBg,
      itemHoverColor: theme.primaryColor,
      itemSelectedBg: theme.secondaryBg,
      horizontalItemSelectedBg: theme.secondaryBg,
      horizontalItemHoverBg: theme.secondaryBg,
      iconMarginInlineEnd: '5px',
      colorSplit: theme.secondaryBg,
      lineWidth: 0,
    },
    Table: {
      colorBgContainer: theme.bgColor,
      colorBorderSecondary: theme.secondaryBg,
      colorFillAlter: theme.tableHeaderBg,
      colorFillContent: theme.tableHeaderBg,
      colorFillSecondary: theme.tableHeaderBg,
      controlItemBgActive: '#fdfae9',
      controlItemBgActiveHover: theme.tableHeaderBg,
      colorPrimary: theme.textColor,
      colorTextHeading: theme.textColor,
      padding: 10,
      paddingXS: 10,
      margin: 8,
      paddingContentVerticalLG: 13,
      fontSizeIcon: 11,
    },
    Dropdown: {
      paddingXXS: 0,
      controlPaddingHorizontal: 16,
      controlItemBgHover: theme.lightBg,
      colorText: theme.primaryColor,
      controlItemBgActive: theme.primaryColor,
      controlItemBgActiveHover: theme.primaryColor,
      colorPrimary: '#fff',
      colorSplit: theme.secondaryBg,
      padding: 10,
      paddingXS: 10,
      lineHeight: 1.57143,
    },
    Pagination: {
      itemActiveBg: theme.secondaryBg,
      lineWidth: 0,
      colorText: theme.primaryColor,
      marginXS: 0,
      colorBgTextHover: theme.lightBg,
    },
    Steps: {
      iconFontSize: 16,
      fontSizeLG: 14,
      colorText: theme.primaryColor,
      colorTextDescription: theme.primaryColor,
      iconTop: -1.5,
      colorFillContent: theme.lightBg,
      colorTextLabel: '#999',
      colorTextLightSolid: theme.primaryColor,
      controlItemBgActive: theme.successColor10,
      colorSplit: theme.secondaryBg,
      marginXS: 10,
      colorPrimary: '#fff',
    },
    Form: {
      fontSize: 14,
      labelFontSize: 13,
      paddingXS: 4,
      marginLG: 20,
      colorTextHeading: theme.textColor,
    },
    Input: {
      paddingSM: 17,
      colorTextDisabled: theme.inputPlaceholderColor,
    },
    InputNumber: {
      paddingSM: 17,
      colorTextDisabled: theme.inputPlaceholderColor,
    },
    Switch: {
      fontSize: 13,
      lineHeight: 2,
      colorTextQuaternary: theme.secondaryBg,
      colorTextTertiary: theme.secondaryBg,
    },
    Divider: {
      colorSplit: theme.secondaryBg,
      marginLG: 20,
    },
    Select: {
      paddingSM: 17,
      paddingXXS: 0,
      colorTextQuaternary: theme.primaryColor,
      controlItemBgActive: theme.primaryColor,
      colorTextDisabled: theme.inputPlaceholderColor,
      multipleItemBg: theme.lightBg,
      multipleItemBorderColor: theme.primaryColor,
      multipleItemHeight: 24,
    },
    Radio: {
      colorPrimary: theme.primaryColor,
      lineWidth: 0,
      lineType: 'none',
      fontSize: 14,
      colorTextDisabled: theme.inputPlaceholderColor,
    },
    Popconfirm: {
      marginXS: 12,
      colorWarning: '#faad14',
      colorTextHeading: theme.textColor,
    },
    Card: {
      headerFontSize: 24,
      headerFontSizeSM: 24,
      colorTextHeading: theme.primaryColor,
      colorText: theme.primaryColor,
      lineType: 'none',
      boxShadowTertiary: theme.dropdownShadow,
    },
    Modal: {
      contentBg: theme.bgColor,
      headerBg: theme.bgColor,
      titleColor: theme.primaryColor,
      titleFontSize: 20,
      titleLineHeight: 1.4,
      paddingContentHorizontalLG: 0,
      paddingMD: 0,
    },
    DatePicker: {
      controlHeightSM: 36,
      controlHeightLG: 36,
      padding: 0,
      paddingXXS: 0,
      colorTextHeading: theme.primaryColor,
      colorIcon: theme.iconColor,
      colorSplit: theme.secondaryBg,
      colorLinkActive: 'red',
      colorLinkHover: 'yellow',
      colorPrimaryHover: 'purple',
      colorLink: 'green',
      colorPrimary: theme.primaryColor,
    },
    Checkbox: {
      borderRadiusSM: 1,
      colorPrimary: theme.secondaryColor,
      colorPrimaryHover: theme.secondaryColor,
      colorWhite: theme.primaryColor,
      controlInteractiveSize: 16,
    },
    Tag: {
      defaultColor: theme.primaryColor,
      defaultBg: '#e7e5f0',
      paddingXXS: 8,
    },
    Notification: {
      width: 420,
      fontFamily: theme.fontFamilyBase,
      padding: 16,
      paddingContentHorizontalLG: 16,
      paddingLG: 16,
      paddingMD: 16,
      marginLG: 16,
      fontSizeLG: 14,
    },
    Carousel: {
      dotWidth: 6,
      dotHeight: 6,
      dotActiveWidth: 6,
    },
    Tree: {
      borderRadiusSM: 1,
      controlHeightSM: 24,
      colorPrimary: theme.secondaryColor,
      colorPrimaryHover: theme.secondaryColor,
      colorWhite: theme.primaryColor,
      controlInteractiveSize: 16,
      controlItemBgHover: 'transparent',
    },
    Alert: {
      colorInfoBorder: theme.linkColor,
      colorErrorBorder: theme.errorColor,
      colorErrorBg: theme.errorColor,
      colorWarning: theme.textColor,
      colorWarningBg: theme.secondaryColor,
      paddingMD: 10,
      paddingContentVerticalSM: 10,
      defaultPadding: '10px 15px',
    },
    Tabs: {
      titleFontSize: 12,
      horizontalItemPadding: '6px 0',
      colorBorderSecondary: theme.primaryColor10,
      colorFillAlter: 'transparent',
      colorBgContainer: 'transparent',
      cardPadding: '6px 0',
      itemColor: theme.primaryColor,
    },
    Tooltip: {
      colorBgSpotlight: 'rgba(51,51,51,.98)',
      controlHeight: 30,
      sizePopupArrow: 12,
      borderRadiusOuter: 0,
      borderRadiusXS: 0,
    },
    Typography: {
      colorTextDescription: theme.textColor,
      titleMarginBottom: '0.8em',
    },
    Slider: {
      railBg: theme.secondaryBg,
      railHoverBg: theme.secondaryBg,
      colorBgElevated: theme.primaryColor,
      handleColor: theme.primaryColor,
      handleLineWidthHover: 1,
    },
    List: {
      itemPadding: '5px 8px',
      lineWidth: 0,
    },
    Badge: { statusSize: 10 },
    Collapse: {
      headerBg: theme.lightBg,
      headerPadding: '8px 16px',
      colorTextHeading: '#1a1a1a',
      contentPadding: '0px 16px 16px 16px !important',
    },
  },
};
