import React, { useState } from 'react';
import { Form, Input, Switch, FormInstance, Radio, RadioChangeEvent } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { GoogleCloudProjectInput } from '../../../../../components/GoogleCloudProjectInput';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { getGCPKeyfile } from '../../../../../utils/files';
import { IDFAgentValues } from '../types';
import { LocationSelect } from '../../../../Projects/ProjectsForm/LocationSelect';
import { validateMessages } from '../../../../../utils/validation';

interface Props {
  initialValues: IDFAgentValues;
  onSubmit: (values: IDFAgentValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
}

export const NLUProvidersForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [platform, setPlatform] = useState<string>(initialValues.platform_edition ?? 'ES');

  const onChange = (e: RadioChangeEvent) => {
    setPlatform(e.target.value);
  };

  const handleCancel = () => {
    const newInitialValues = {
      ...initialValues,
    };
    if (isEditing) delete newInitialValues.gcp_keyfile;
    if (initialValues.platform_edition === 'ES') delete newInitialValues.uuid;
    if (!equals(newInitialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = async () => {
    const formData = form.getFieldsValue();
    if (formData.gcp_keyfile) formData.gcp_keyfile = await getGCPKeyfile(formData.gcp_keyfile);
    if (formData.gcp_fulfillment_auth_keyfile)
      formData.gcp_fulfillment_auth_keyfile = await getGCPKeyfile(
        formData.gcp_fulfillment_auth_keyfile,
      );
    if (formData.platform_edition === 'ES') formData.uuid = null;
    delete formData.platform_edition;
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <GoogleCloudProjectInput initialValues={initialValues} isEditing={isEditing} />
      <Space>
        <Form.Item
          name="platform_edition"
          label={t('platform_edition')}
          className={formItemClass.base}
        >
          <Radio.Group buttonStyle="solid" onChange={onChange} value={platform}>
            <Radio.Button value="ES">ES</Radio.Button>
            <Radio.Button value="CX">CX</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="ccai_enabled" valuePropName="checked" label={t('ccai_enabled')}>
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item
        name="location"
        label={t('location')}
        tooltip={t('location_tooltip')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <LocationSelect placeholder={t('location')} />
      </Form.Item>
      {platform === 'CX' && (
        <Form.Item
          name="uuid"
          label={t('uuid')}
          className={formItemClass.base}
          tooltip={t('uuid_tooltip')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('uuid')} />
        </Form.Item>
      )}
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
