import { ReactNode, useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import 'simplebar/dist/simplebar.min.css';
import { ILang } from '../../../../hooks/useLanguages';
import { Scrollbar } from '../../../../components/Scrollbar';
import { SearchInput, StyledFilter } from './LanguageSearch.styles';
import { LanguageItem, StyledLanguageItem } from './LanguageItem';

export type IProps = Pick<InputProps, 'id' | 'placeholder'> & {
  languages?: ILang[];
  value?: number[];
};

export const LanguagesSearch: React.FC<IProps> = ({ id, placeholder, languages, value = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<number[]>(value);
  const [languagesItems, setLanguagesItems] = useState<ReactNode[]>();
  const form = Form.useFormInstance();
  const defaultLanguageId = Number(Form.useWatch('default_language_id', form));

  const onLanguageSelect = (id: number, val: boolean) => {
    setTimeout(() => {
      val
        ? setSelectedItems(items => [id, ...items])
        : setSelectedItems(items => items.filter(item => item !== id));
      setSearchTerm('');
    }, 300);
  };

  useEffect(() => {
    form.setFieldsValue({ project_languages_attributes: selectedItems });
  }, [selectedItems]);

  useEffect(() => {
    if (selectedItems.includes(defaultLanguageId)) {
      setSelectedItems(selectedItems.filter(item => item !== defaultLanguageId));
    }
  }, [defaultLanguageId, selectedItems]);

  useEffect(() => {
    const items = languages
      ?.filter(lng => lng.id !== defaultLanguageId)
      .filter(
        val =>
          searchTerm === '' ||
          val.name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          val.locale.toLowerCase().startsWith(searchTerm.toLowerCase()),
      )
      .sort((a: ILang, b: ILang) => selectedItems.indexOf(b.id) - selectedItems.indexOf(a.id))
      .map((item: ILang, i: number) => {
        const ListItem = (
          <LanguageItem
            key={item.locale}
            selectedItem={selectedItems}
            language={item}
            onSelected={onLanguageSelect}
          />
        );
        return i === selectedItems.length - 1 && searchTerm.length === 0
          ? [
              ListItem,
              <StyledLanguageItem key="divider">
                <Divider />
              </StyledLanguageItem>,
            ]
          : ListItem;
      });
    setLanguagesItems(items);
  }, [languages, searchTerm, defaultLanguageId, selectedItems]);

  return (
    <StyledFilter>
      <SearchInput
        id={id}
        placeholder={placeholder}
        type="text"
        value={searchTerm}
        onChange={e => {
          setSearchTerm(e.target.value);
        }}
      />
      <Scrollbar autoHide={false} style={{ maxHeight: 200 }}>
        {languagesItems}
      </Scrollbar>
    </StyledFilter>
  );
};
