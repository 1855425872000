import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { FallbackBehaviourNew } from '../FallbackBehaviourNew';

interface IParameterModal {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  setCreatedKey: React.Dispatch<React.SetStateAction<number>>;
}

export const FallbackBehaviourModal: React.FC<IParameterModal> = ({
  isVisible,
  setVisible,
  setCreatedKey,
}) => {
  const { t } = useTranslation('ai');

  const handleCreateBehaviour = () => {
    setCreatedKey(prevKey => prevKey + 1);
    setVisible(false);
  };

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} /> {t('new_behaviour')}
        </>
      }
      width={848}
      centered
      open={isVisible}
      footer={false}
      onCancel={handleCancel}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
    >
      <FallbackBehaviourNew
        isModal={true}
        handleCloseModal={handleCancel}
        handleCreateBehaviour={handleCreateBehaviour}
      />
    </Modal>
  );
};
