import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ColorPicker, Divider, Form, Typography } from 'antd';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/icons/redo-alt.svg';
import { initialColours } from '../index';
import { IVAConfigValues } from '../../types';
import { ButtonTypeSelector } from '../../../../../../components/ButtonTypeSelector';

const { Title } = Typography;

type Props = {
  updateScriptConfig: (changedValues: Partial<IVAConfigValues>) => Promise<void>;
};

export const Colours: React.FC<Props> = ({ updateScriptConfig }) => {
  const { t } = useTranslation('design');
  const form = Form.useFormInstance();

  const buttonType: 'outline' | 'filled' = Form.useWatch(['config', 'button_type'], form);

  const handleSetDefault = () => {
    const values = form.getFieldsValue(true);
    form.setFieldsValue({
      ...values,
      config: {
        ...values.config,
        ...initialColours,
      },
    });
    updateScriptConfig({});
  };

  return (
    <>
      <Space>
        <Form.Item
          name={['config', 'header_background']}
          label={t('header_background')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={['config', 'header_text']}
          label={t('header_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['config', 'body_background']}
          label={t('body_background')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={['config', 'body_text']}
          label={t('body_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Divider />
      <Title level={5} type="secondary">
        {t('button_text')}
      </Title>
      <Space>
        <Form.Item
          label={t('type')}
          name={['config', 'button_type']}
          className={formItemClass.base}
        >
          <ButtonTypeSelector />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={[
            'config',
            buttonType === 'filled' ? 'button_default_background' : 'button_default_outline',
          ]}
          label={
            buttonType === 'filled' ? t('button_default_background') : t('button_default_outline')
          }
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={[
            'config',
            buttonType === 'filled' ? 'button_default_text' : 'button_default_outline_text',
          ]}
          label={t('button_default_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={[
            'config',
            buttonType === 'filled' ? 'button_hover_background' : 'button_hover_outline',
          ]}
          label={buttonType === 'filled' ? t('button_hover_background') : t('button_hover_outline')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={[
            'config',
            buttonType === 'filled' ? 'button_hover_text' : 'button_hover_outline_text',
          ]}
          label={t('button_hover_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Divider />
      <Title level={5} type="secondary">
        {t('text_speech_bubbles')}
      </Title>
      <Space>
        <Form.Item
          name={['config', 'chatbot_agent_background']}
          label={t('chatbot_agent_background')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={['config', 'chatbot_agent_text']}
          label={t('chatbot_agent_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['config', 'user_background']}
          label={t('user_background')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={['config', 'user_text']}
          label={t('user_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Divider />
      <Title level={5} type="secondary">
        {t('input')}
      </Title>
      <Space>
        <Form.Item
          name={['config', 'input_background']}
          label={t('input_background')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
        <Form.Item
          name={['config', 'input_text']}
          label={t('input_text')}
          className={formItemClass.base}
        >
          <ColorPicker showText />
        </Form.Item>
      </Space>
      <Form.Item
        name={['config', 'input_icon']}
        label={t('input_icon')}
        className={formItemClass.base}
      >
        <ColorPicker showText />
      </Form.Item>
      <Divider />
      <Button icon={<ArrowIcon width={13} height={13} />} onClick={handleSetDefault}>
        {t('set_to_default')}
      </Button>
    </>
  );
};
