import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ButtonSkew } from '../ButtonSkew';
import { Scrollbar } from '../Scrollbar';

export const DropdownButton = styled(ButtonSkew)`
  width: 240px;
  margin-left: 10px;
  text-align: left;

  @media (max-width: 600px) {
    width: 160px;
  }

  &::before {
    transform-origin: bottom;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.primaryColor};
    margin-top: -2px;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  &[disabled] {
    color: ${({ theme }) => theme.textColorDisabled};
    cursor: not-allowed;
    &:before {
      background-color: ${({ theme }) => theme.lightBg};
    }
    &::after {
      border-top-color: ${({ theme }) => theme.textColorDisabled};
    }
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  background: #fff;
  box-shadow: ${({ theme }) => theme.dropdownShadow};

  @media (max-width: 600px) {
    min-width: 160px;
  }

  .ant-dropdown-menu {
    box-shadow: none;
  }

  .ant-dropdown-menu-item a {
    font-weight: 400;
  }
`;

const Text = styled.div`
  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IDropdownMenuNav {
  maxHeight?: number;
}

const Menu: React.FC<IDropdownMenuNav> = ({ maxHeight = 200, children }) => {
  return (
    <Scrollbar autoHide={true} style={{ maxHeight }}>
      {children}
    </Scrollbar>
  );
};

const Footer = styled(Link, {
  shouldForwardProp: propName => propName !== 'single',
})<{ single: boolean }>`
  margin-top: ${({ single }) => (single ? '4px' : 0)};
  margin-bottom: 4px;
  padding: 0 16px;
  height: ${({ theme }) => theme.menuItemHeight};
  line-height: ${({ theme }) => theme.menuItemHeight};
  font-weight: 400;
  box-shadow: ${({ theme, single }) => (single ? 'none' : `0 -1px 0 0 ${theme.secondaryBg}`)};
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background-color: ${({ theme }) => theme.lightBg};
  }
`;

export const DropdownMenu = {
  Root,
  Menu,
  Footer,
  Text,
};
