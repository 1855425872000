import React, { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { List, Tag } from 'antd';
import { isEmpty, without } from 'ramda';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { IPresentation, IPresentations } from '../../../types';
import { theme } from '../../../../../theme';
import { TruncatedString } from '../../../../../components/TruncatedString';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/plus.svg';
import { renderStatusDots } from '../../../../../utils/knowledge';
import { DiscardModal } from '../../DiscardModal';

interface Props {
  isVisible: boolean;
  filterValues: { [key: string]: boolean };
  setFilterValues: (value: { [key: string]: boolean }) => void;
  clearAllFilters: () => void;
  presentations?: IPresentations;
  activePresentation?: IPresentation;
  setActivePresentation: (item: IPresentation) => void;
  onLoadMore?: () => void;
  isLoading?: boolean;
  isFormSaved?: boolean;
  languageCode?: string;
}

export const SideList: React.FC<Props> = ({
  presentations,
  isVisible,
  filterValues,
  setFilterValues,
  clearAllFilters,
  setActivePresentation,
  activePresentation,
  onLoadMore,
  isLoading,
  languageCode,
  isFormSaved,
}) => {
  const { t } = useTranslation(['knowledge', 'project_settings']);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [tempPresVariable, setTempPresVariable] = useState<IPresentation>({} as IPresentation);
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    if (filterValues && !isEmpty(filterValues)) {
      const currentValues = Object.entries(filterValues)
        .filter(([, value]) => value !== false)
        .map(([key]) => key.replaceAll('_', ' '));
      setFilters(currentValues);
    } else {
      setFilters([]);
    }
  }, [filterValues]);

  const onRemoveFilterItem = (value: string) => {
    setFilterValues({ ...filterValues, [value.replaceAll(' ', '_')]: false });
    setFilters(without([value], filters));
  };

  const onClear = () => {
    setFilters([]);
    clearAllFilters();
  };

  return (
    <StyledEditTranslations.SideContent className={!isVisible ? 'open' : ''}>
      <DiscardModal
        handleOk={() => {
          setActivePresentation(tempPresVariable);
          setIsModalVisible(false);
        }}
        isVisible={isModalVisible}
        handleCancel={() => {
          setTempPresVariable({} as IPresentation);
          setIsModalVisible(false);
        }}
      />
      <StyledEditTranslations.SubHeader style={{ marginBottom: 8 }}>
        <StyledEditTranslations.SubHeaderTitle>
          {presentations?.pagination?.total_count || 0}{' '}
          <Translation ns={'pages_title'}>{t => t('presentations')}</Translation>
        </StyledEditTranslations.SubHeaderTitle>
        {!!filters.length && (
          <StyledEditTranslations.LinkButton type="link" onClick={onClear}>
            {t('clear_all')}
          </StyledEditTranslations.LinkButton>
        )}
      </StyledEditTranslations.SubHeader>
      <div>
        {filters.map(item => (
          <Tag key={item} closable onClose={() => onRemoveFilterItem(item)}>
            {item === 'missing' ? t('untranslated') : t(item)}
          </Tag>
        ))}
      </div>
      <StyledEditTranslations.Divider />
      <StyledEditTranslations.ContentList>
        <div>
          <StyledEditTranslations.PresentationList>
            <StyledEditTranslations.Title>
              <Translation ns={'pages_title'}>{t => t('presentations')}</Translation> (
              {presentations?.pagination?.total_count || 0})
            </StyledEditTranslations.Title>
            <List
              loading={isLoading}
              style={{ marginBottom: 10 }}
              dataSource={presentations?.data}
              renderItem={item => (
                <List.Item
                  style={{
                    backgroundColor: item.id === activePresentation?.id ? theme.secondaryBg : '',
                    cursor: 'pointer',
                    paddingRight: 0,
                  }}
                  actions={[renderStatusDots(item, languageCode)]}
                  onClick={() => {
                    if (!isFormSaved) {
                      setIsModalVisible(true);
                      return setTempPresVariable(item);
                    }
                    setActivePresentation(item);
                  }}
                  key={item.id}
                >
                  <TruncatedString style={{ flex: 1 }} isShowFullOnHover>
                    {item.title}
                  </TruncatedString>
                </List.Item>
              )}
            />
            {presentations?.pagination?.current !== presentations?.pagination?.total_pages && (
              <StyledEditTranslations.LinkButton
                onClick={onLoadMore}
                type="link"
                icon={<AddIcon width={11} />}
              >
                {t('view_more')}
              </StyledEditTranslations.LinkButton>
            )}
          </StyledEditTranslations.PresentationList>
        </div>
      </StyledEditTranslations.ContentList>
    </StyledEditTranslations.SideContent>
  );
};
