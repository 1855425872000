import { pipe } from 'ramda';

const isObjInArray = (array: unknown[]) => array.some(item => typeof item == 'object');

const formatNestedData = (obj: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      const formattedVal = Array.isArray(v)
        ? isObjInArray(v.flat())
          ? Object.assign({}, ...v.flat())
          : v.flat()
        : v;
      return [k, formattedVal];
    }),
  );

const convertToDotObj = (obj: Record<string, any>): Record<string, any> => {
  function walk(into: Record<string, any>, obj: Record<string, any>, prefix: string[] = []) {
    Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val)) walk(into, val, [...prefix, key]);
      else into[[...prefix, key].join('.')] = val;
    });
  }
  const out = {};
  walk(out, obj);
  return out;
};

export const formatApiErrors = pipe(formatNestedData, convertToDotObj);

export const getFormErrorPage = (
  stepsFields: string[][],
  errors: Record<string, any>,
): number | undefined => {
  let page: number | undefined = undefined;
  Object.keys(errors).forEach(field => {
    stepsFields.forEach((stepFields, index) => {
      if (stepFields.includes(field)) {
        (page === undefined || (!!page && page > index)) && (page = index);
      }
    });
  });
  return page;
};

export type FormError = {
  name: string[];
  errors: string[];
};

export const getFormErrors = (errors: Record<string, any>): FormError[] => {
  const formErrors: FormError[] = [];
  Object.keys(errors).forEach(field =>
    formErrors.push({
      name: field.split('.'),
      errors: Array.isArray(errors[field]) ? errors[field] : [errors[field]],
    }),
  );
  return formErrors;
};
