import { AiIntegrationParams, integrationQuery } from '../api';

const queryKey = 'delete-conversations';

type Data = {
  from?: string;
  session_uuid?: string;
};
const remove = async ({ apiData, data }: { data: Data } & AiIntegrationParams) =>
  await integrationQuery(`/api/analytics/deleters`, {
    method: 'POST',
    data: { data: { type: 'deleters', attributes: data } },
    apiData: apiData,
  });

export const DeleteConversationsService = {
  remove,
  queryKey,
};
