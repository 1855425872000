import dayjs, { Dayjs } from 'dayjs';
import { IScheduleTime, IScheduleValues, ISchedulePayload } from '../pages/Pulse/types';
import { formatChinaDateFormat, formatTime } from './date';
import { Weekday, days } from '../constants';

export const extractScheduleTimes = (payload: any) => {
  const scheduleTimes: IScheduleTime[] = [];

  Object.keys(days).forEach(day => {
    if (payload[day as Weekday]) {
      scheduleTimes.push({
        dayOfWeek: day as Weekday,
        startTime: formatTime(payload[`start_time_${day as Weekday}`]),
        endTime: formatTime(payload[`end_time_${day as Weekday}`]),
      });
    }
    delete payload[`start_time_${day as Weekday}`];
    delete payload[`end_time_${day as Weekday}`];
    delete payload[day as Weekday];
  });

  if (scheduleTimes.length) {
    payload.scheduleTimes = scheduleTimes;
  }
};

export const formatDataForEdit = (data: ISchedulePayload) => {
  const {
    scheduleTimes,
    startDate,
    endDate,
    exceptionStartDate,
    exceptionEndDate,
    repeatPeriod,
    ...restData
  } = data;

  const formattedData: Partial<IScheduleValues> = {
    ...restData,
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    repeatPeriod: repeatPeriod / 1000,
  };

  if (scheduleTimes) {
    scheduleTimes.forEach((scheduleTime: IScheduleTime) => {
      formattedData[scheduleTime.dayOfWeek] = true;
      formattedData[`start_time_${scheduleTime.dayOfWeek}`] = dayjs(
        scheduleTime.startTime,
        'HH:mm',
      );
      formattedData[`end_time_${scheduleTime.dayOfWeek}`] = dayjs(scheduleTime.endTime, 'HH:mm');
    });
  }

  if (exceptionStartDate && exceptionEndDate) {
    formattedData.exception_period = [
      dayjs(new Date(exceptionStartDate)),
      dayjs(new Date(exceptionEndDate)),
    ];
  }

  return formattedData;
};

interface IPayload extends Omit<ISchedulePayload, 'id'> {
  exception_period?: [Dayjs, Dayjs];
}

export const formatValuesForPayload = (values: IScheduleValues): Omit<ISchedulePayload, 'id'> => {
  const { exception_period, startDate, endDate, repeatPeriod, executionType } = values;
  const payload: IPayload = {
    ...values,
    startDate: formatChinaDateFormat(startDate),
    endDate: formatChinaDateFormat(endDate),
    exceptionStartDate: formatChinaDateFormat(exception_period?.[0]),
    exceptionEndDate: formatChinaDateFormat(exception_period?.[1]),
    repeatPeriod: repeatPeriod * 1000,
  };
  extractScheduleTimes(payload);

  delete payload.exception_period;
  executionType === 'twilioflow' && delete payload.twiml && delete payload.responseTimeout;
  executionType === 'twiml' && delete payload.flowSid;

  //sending request to the api with enabled true triggers calls, so we set it up as false if not prod
  process.env.NODE_ENV !== 'production' && (payload.enabled = false);
  return payload;
};
