import { Select, SelectProps } from 'antd';
import { VAFonts } from '../../constants';

const { Option } = Select;

export const FontSelector: React.FC<SelectProps> = props => {
  return (
    <Select {...props}>
      {VAFonts.map(item => (
        <Option key={item.value} value={item.value}>
          {item.option}
        </Option>
      ))}
    </Select>
  );
};
