import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { IconLink } from '../IconLink';
import { StyledAsideModal } from './AsideModal.styles';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-circle.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';

interface Props {
  isVisible: boolean;
  modalToggle: () => void;
}

export const AsideModal: React.FC<Props> = ({ isVisible, modalToggle }) => {
  const { t } = useTranslation('translation');

  return (
    <StyledAsideModal.Root isVisible={isVisible}>
      <StyledAsideModal.Bg onClick={modalToggle} />
      <StyledAsideModal.Inner isVisible={isVisible}>
        <StyledAsideModal.Header>
          <StyledAsideModal.Title>
            <QuestionIcon width={22} height={22} />
            {t('heed_help')}
          </StyledAsideModal.Title>
          <StyledAsideModal.Close onClick={() => modalToggle()}>
            <TimesIcon width={20} height={20} />
          </StyledAsideModal.Close>
        </StyledAsideModal.Header>
        <Card title={t('view_guides')} bordered={false} size="small">
          <p>{t('explore_guides')}</p>
          <IconLink
            href="https://sabiogroup.atlassian.net/wiki/spaces/SC/overview?homepageId=36515152292"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon width={12} height={12} />
            {t('browse_articles')}
          </IconLink>
        </Card>
        <Card title={t('feature_suggestion')} bordered={false} size="small">
          <p>{t('suggestion_description')}</p>
          <IconLink href="https://sabio-console.hellonext.co/ " target="_blank" rel="noreferrer">
            <ExternalLinkIcon width={12} height={12} />
            {t('give_suggestion')}
          </IconLink>
        </Card>
      </StyledAsideModal.Inner>
    </StyledAsideModal.Root>
  );
};
