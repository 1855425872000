import React from 'react';
import { DatePicker as AntDatepicker, DatePickerProps, TimePicker as AntTimepicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useTranslation } from 'react-i18next';
import { TimePickerProps } from 'antd/lib';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-alt.svg';
import { ReactComponent as UpDownArromIcon } from '../../assets/icons/blue-arrow-up-down.svg';
import { theme } from '../../theme';

export const DatePicker = (props: DatePickerProps): JSX.Element => (
  <AntDatepicker
    allowClear={false}
    suffixIcon={<CalendarIcon width={13} height={20} color={theme.primaryColor} />}
    format="DD/MM/YYYY"
    {...props}
  />
);

export const RangePicker = (props: RangePickerProps): JSX.Element => (
  <AntDatepicker.RangePicker
    allowClear={false}
    suffixIcon={<CalendarIcon width={13} height={20} color={theme.primaryColor} />}
    format="DD/MM/YYYY"
    {...props}
  />
);

export const TimePicker = (props: TimePickerProps): JSX.Element => {
  const { t } = useTranslation('translation');

  return (
    <AntTimepicker
      allowClear={false}
      use12Hours
      format="h:mm A"
      placeholder={!props.disabled ? t('select-time') : ''}
      suffixIcon={!props.disabled && <UpDownArromIcon width={13} height={20} />}
      {...props}
    />
  );
};
