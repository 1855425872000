import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { equals, isEmpty, remove } from 'ramda';
import { Button, Divider, Form, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { formItemClass } from '../../../../theme';
import { validateMessages } from '../../../../utils/validation';
import { useModal } from '../../../../hooks/useModal';
import { useColumnSearchProps } from '../../../../hooks/useColumnSearchProps';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { DataTable } from '../../../../components/DataTable';
import { FixedBottomBar } from '../../../../components/FixedBottomBar';
import { FormActions } from '../../../../components/FormActions';
import { ActionButton } from '../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../components/DiscardChangesModal';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { IDestination, IDestinationParameterItem, IParameterValues } from '../../types';
import { DestinationParameterModal } from './DestinationParameterModal';
import { DestinationFormItem, FormWrapper } from './ParameterForm.style';
import { stripSpecialChars } from '../../../../utils/string';

interface Props {
  initialValues?: IParameterValues;
  destinations: IDestination[];
  onSubmit: (values: any) => void;
  submitButton: React.ReactElement;
}

export const ParameterForm: React.FC<Props> = ({
  initialValues,
  destinations,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const { visible, openModal, closeModal } = useModal(false);
  const [form] = Form.useForm();
  const [isVisibleDestinationModal, setVisibleDestinationModal] = useState<boolean>(false);
  const [initModalValues, setInitModalValues] = useState<
    | {
        configurable_id: number;
        configurable_name: string;
        value: string;
      }
    | undefined
  >();
  const [tableData, setTableData] = useState<IDestinationParameterItem[]>([]);
  const [, setSearchValue] = useState('');

  useEffect(() => {
    if (!!initialValues?.children_attributes) {
      setTableData(initialValues.children_attributes);
    }
  }, []);

  useEffect(() => {
    if (!isVisibleDestinationModal && !!initModalValues) {
      setInitModalValues(undefined);
    }
  }, [isVisibleDestinationModal, initModalValues]);

  useEffect(() => {
    form.setFieldValue('children_attributes', tableData);
  }, [tableData]);

  const onAddDestination = () => {
    setVisibleDestinationModal(true);
  };

  const onCellHandler = (record: IDestinationParameterItem) => {
    return {
      onClick: () => {
        setVisibleDestinationModal(true);
        setInitModalValues({ ...record });
      },
    };
  };

  const handleCancel = () => {
    if (!isEmpty(initialValues) && !equals(initialValues, form.getFieldsValue(true))) {
      openModal();
    } else {
      form.resetFields();
      navigate(-1);
    }
  };

  const handleOnKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const cleanedValue = stripSpecialChars(currentValue);
    if (currentValue !== cleanedValue) form.setFieldValue('key', cleanedValue);
  };

  const onFinish = () => {
    form.setFieldValue('children_attributes', tableData);
    return onSubmit(form.getFieldsValue(true));
  };

  const onModalSubmit = (values: IDestinationParameterItem) => {
    if (!!tableData.find(item => item.configurable_id === values.configurable_id)) {
      const newTable = [...tableData].map(item => {
        if (item.configurable_id === values.configurable_id) {
          item.value = values.value;
        }
        return item;
      });
      return setTableData(newTable);
    }

    return setTableData([...tableData, values]);
  };

  const columns: ColumnProps<IDestinationParameterItem>[] = [
    {
      title: t('destination'),
      dataIndex: 'configurable_name',
      onCell: onCellHandler,
      filters: destinations.map(item => ({ text: item.name, value: item.name })),
      filterIcon: () => <FilterIcon width={10} />,
      onFilter: (value, record) => record.configurable_name.includes(value.toString()),
      width: 240,
    },
    {
      title: t('value'),
      dataIndex: 'value',
      onCell: onCellHandler,
      ...useColumnSearchProps('value', t('searchValuePlaceholder'), setSearchValue),
      render: value => value,
    },
    {
      title: '',
      dataIndex: 'delete',
      width: 56,
      className: 'actions',
      render: (_, dataItem, index) => (
        <DeleteConfirmation
          id={dataItem?.configurable_id.toString()}
          onActionDelete={() => {
            setTableData(remove(index, 1, tableData));
          }}
          title={t('delete_parameter')}
        >
          <Trans>
            {t('destination_parameter_delete_description', {
              name: dataItem.value,
            })}
          </Trans>
        </DeleteConfirmation>
      ),
    },
  ];

  return (
    <>
      <FormWrapper>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="key"
            label={t('name')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t('parameter_name_placeholder')}
              disabled={!!initialValues}
              maxLength={30}
              onChange={handleOnKeyChange}
            />
          </Form.Item>
          <Form.Item
            name="value"
            label={t('value')}
            rules={[{ required: true }]}
            tooltip={t('parameter_value_tooltip')}
          >
            <Input.TextArea
              style={{ height: 56 }}
              placeholder={t('parameter_value_placeholder')}
              maxLength={256}
            />
          </Form.Item>
          <Divider />
          <div>
            <Button
              icon={<PlusIcon width={10} height={10} />}
              onClick={onAddDestination}
              disabled={destinations.length === tableData.length}
            >
              {t('add_destination_parameter')}
            </Button>
            <DestinationFormItem
              name="children_attributes"
              label={t('destination_parameters')}
              rules={[{ required: false }]}
              tooltip={t('destination_parameters_tooltip')}
            >
              <DataTable dataSource={tableData} columns={columns} pagination={false} />
            </DestinationFormItem>
          </div>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </Form>
      </FormWrapper>
      <DestinationParameterModal
        isEdit={!!initModalValues}
        initialValues={initModalValues}
        destinations={destinations.map(item => ({
          ...item,
          isDisabled: !!tableData.find(tableItem => tableItem.configurable_id === item.id),
        }))}
        visible={isVisibleDestinationModal}
        setVisible={setVisibleDestinationModal}
        onSubmit={onModalSubmit}
      />
    </>
  );
};
