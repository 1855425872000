import styled from '@emotion/styled';
import { Scrollbar } from '../Scrollbar';
import { theme } from '../../theme';

interface ISelectItems {
  maxHeight?: number;
}

const Items: React.FC<ISelectItems> = ({ maxHeight = 200, children }) => {
  return (
    <Scrollbar
      autoHide={true}
      style={{ maxHeight, borderBottom: `solid 1px ${theme.secondaryBg}` }}
    >
      {children}
    </Scrollbar>
  );
};

const Footer = styled.a`
  padding: 0 16px;
  height: ${({ theme }) => theme.menuItemHeight};
  line-height: ${({ theme }) => theme.menuItemHeight};
  color: ${({ theme }) => theme.linkColor};
  text-transform: uppercase;
`;

export const SelectWithFooter = {
  Items,
  Footer,
};
