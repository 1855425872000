import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import styled from '@emotion/styled';
import { ActionButton } from '../ActionButton';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';

interface IProps {
  children: React.ReactNode;
  onSubmit: (values: any) => void;
  loading: boolean;
  isEdit?: boolean;
}

export const SaveConfirmation: React.FC<IProps> = ({ children, onSubmit, loading, isEdit }) => {
  const { t } = useTranslation();
  const { validateFields, getFieldsValue } = Form.useFormInstance();
  const [visible, setVisible] = useState(false);
  const type = isEdit ? 'save' : 'create';

  const isFormValid = async () => {
    try {
      await validateFields();
      setVisible(true);
    } catch {}
  };

  const handleOk = () => {
    setVisible(false);
    onSubmit(getFieldsValue(true));
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderTitle = () => (
    <TitleContainer>
      {isEdit ? (
        <>
          <Check width={20} height={20} />
          {t('save_changes').toUpperCase()}
        </>
      ) : (
        <>
          <Plus width={20} height={20} />
          {t('create').toUpperCase()}
        </>
      )}
    </TitleContainer>
  );

  return (
    <>
      <ActionButton type={type} loading={loading} htmlType="submit" onClick={isFormValid} />
      <Modal
        width={408}
        title={renderTitle()}
        centered
        open={visible}
        footer={[
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
          <ActionButton type={type} key={type} onClick={handleOk} />,
        ]}
        onCancel={handleCancel}
      >
        {children}
      </Modal>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;
