import { useEffect, useState } from 'react';
import { Divider, Form, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { InputWithRemove } from '../../../../../../components/InputWithRemove';
import { AddField } from '../../../../../../components/AddField';
import { ChannelType, ChannelTypes } from '../../../../Channels/Channel/types';
import { ChannelIdentifierFields, ChannelTypesLabel } from '../../../../../../constants';
import { IAutomationProfileValues, IIdentifier } from '../../types';
import { useWindowDimensions } from '../../../../../../utils/useWindowDimensions';

const { Title } = Typography;

export const Channels = ({
  initialValues,
}: {
  initialValues: IAutomationProfileValues;
}): JSX.Element => {
  const { t } = useTranslation('ai');
  const { width } = useWindowDimensions();
  const { getFieldValue, setFieldValue, validateFields } = Form.useFormInstance();
  const [isChannelError, setIsChannelError] = useState<boolean>(false);
  const [identifier, setIdentifier] = useState<IIdentifier>({});

  const getIdentifierDetails = (channel: ChannelType) => {
    const field = ChannelIdentifierFields.find(field => field.channel === channel);
    return {
      channelType: field?.channel as ChannelType,
      placeholder: field ? field.placeholder : '',
      tooltip: field ? field.tooltip : '',
    };
  };

  const initializeChannels = () => {
    const initialIdentifier: IIdentifier = {};
    initialValues.inbound_phone_numbers_attributes?.forEach((channel, index) => {
      initialIdentifier[index] = getIdentifierDetails(channel.channel_type as ChannelType);
    });
    setIdentifier(initialIdentifier);
  };

  useEffect(() => {
    if (initialValues.inbound_phone_numbers_attributes) {
      initializeChannels();
    }
  }, [initialValues.inbound_phone_numbers_attributes]);

  const handleChannelTypeChange = (index: number, value: ChannelType) => {
    const identifier = getIdentifierDetails(value);
    setIdentifier(prevState => ({
      ...prevState,
      [index]: identifier,
    }));
    setFieldValue(['inbound_phone_numbers_attributes', index, 'phone_number'], undefined);
  };

  const checkChannelError = async () => {
    const parameters = getFieldValue('inbound_phone_numbers_attributes');
    if (parameters && parameters.length > 0) {
      const lastIndex = parameters.length - 1;
      const fieldToValidate =
        width <= 873
          ? ['inbound_phone_numbers_attributes', lastIndex, 'phone_number']
          : ['inbound_phone_numbers_attributes', lastIndex, 'channel_type'];

      try {
        await validateFields([fieldToValidate]);
        setIsChannelError(false);
      } catch ({ errorFields }) {
        errorFields && setIsChannelError(true);
      }
    }
  };

  const updateIdentifier = (indexToRemove: number): IIdentifier => {
    const newIdentifier: IIdentifier = { ...identifier };
    delete newIdentifier[indexToRemove];
    const updatedIdentifier: IIdentifier = {};
    Object.keys(newIdentifier).forEach(key => {
      const numericKey = Number(key);
      if (numericKey > indexToRemove) {
        updatedIdentifier[numericKey - 1] = newIdentifier[numericKey];
      } else {
        updatedIdentifier[numericKey] = newIdentifier[numericKey];
      }
    });
    return updatedIdentifier;
  };

  const addNewChannel = (): IIdentifier => {
    const newIdentifier: IIdentifier = { ...identifier };
    const newIndex = Object.keys(identifier).length;
    newIdentifier[newIndex] = {
      channelType: 'web',
      placeholder: 'enter_identifier',
      tooltip: 'identifier_web_tooltip',
    };
    return newIdentifier;
  };

  return (
    <>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('channels')}
      </Title>
      <Form.List name="inbound_phone_numbers_attributes">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space key={field.key}>
                <Form.Item
                  name={[index, 'channel_type']}
                  label={t('type')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={ChannelTypesLabel}
                    placeholder={t('select_channel_type')}
                    onChange={value => handleChannelTypeChange(index, value)}
                  />
                </Form.Item>
                <InputWithRemove
                  name={[index, 'phone_number']}
                  onClick={() => {
                    remove(index);
                    setIdentifier(updateIdentifier(index));
                    checkChannelError();
                  }}
                  placeholder={t(identifier[index]?.placeholder ?? '')}
                  label={t('identifier')}
                  required
                  tooltip={t(identifier[index]?.tooltip ?? '')}
                  channelType={identifier[index]?.channelType}
                  onChange={() => index === fields.length - 1 && checkChannelError()}
                />
              </Space>
            ))}
            <AddField
              onClick={() => {
                setIdentifier(addNewChannel());
                add({
                  channel_type: ChannelTypes.web,
                });
              }}
              buttonText={t('add_channel')}
              moveUp={fields.length > 0 && !isChannelError}
            />
          </>
        )}
      </Form.List>
    </>
  );
};
