import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Content } from '../../../components/Content';
import { ActionButton } from '../../../components/ActionButton';
import { AccountsForm } from '../AccountsForm';
import { AccountsService } from '../../../services/client-account';
import { useCreate } from '../../../hooks/useCreate';
import { IAccountsValues } from '../types';
import { formatApiErrors, getFormErrors } from '../../../utils/errors';
import { unexpectedError } from '../../../utils/notification';
import { useAccount } from '../../../state/account';
import { Paths } from '../../../types/paths';

export const AccountsNew: React.FC = () => {
  const { t } = useTranslation('account_settings');
  const { notification } = App.useApp();
  const { setActiveAccountId } = useAccount();
  const { mutate: createItem, isLoading } = useCreate<IAccountsValues>(
    [AccountsService.queryKey],
    AccountsService.create,
  );
  const navigate = useNavigate();

  const onSubmit = async (values: IAccountsValues, setFields: FormInstance['setFields']) => {
    await createItem(values, {
      onSuccess: data => {
        navigate(Paths.accounts());
        notification.success({
          message: <Trans t={t}>{t('account_created_notify', { name: data.name })}</Trans>,
        });
        setActiveAccountId(data.id);
      },
      onError: (err: any) => {
        if (err?.response.status === 400) {
          const formattedErrors = formatApiErrors(err.response.data);
          setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
        } else notification.error(unexpectedError);
      },
    });
  };

  return (
    <Content imgBg={true}>
      <p>{t('form_description')}</p>
      <AccountsForm
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
