import React from 'react';
import { RequireAuth } from '../state/auth';
import { Home } from '../pages/Home';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { FairUsePolicy } from '../pages/FairUsePolicy';
import { TermsConditions } from '../pages/TermsConditions';
import { SecurityPolicy } from '../pages/SecurityPolicy';
import { Healthcheck } from '../pages/Healthcheck';
import { accountRoutes } from '../pages/Accounts/routes';
import { userRoutes } from '../pages/Projects/Users/routes';
import { projectRoutes } from '../pages/Projects/routes';
import { userSettingsRoutes } from '../pages/UserSettings/routes';
import { designV1Routes } from '../pages/DesignV1/routes';
import { knowledgeRoutes } from '../pages/Knowledge/routes';
import { oldKnowledgeRoutes } from '../pages/OldKnowledge/routes';
import { analyticRoutes } from '../pages/Analytics/routes';
import { loginRoutes } from '../pages/Login/routes';
import { pulseRoutes } from '../pages/Pulse/routes';
import { aiRoutes } from '../pages/AI/routes';
import { Paths } from '../types/paths';
import { HomeMain } from '../pages/HomeMain';

export const useRouteConfig = (authenticated: boolean) => {
  return [
    {
      path: '/',
      element: (
        <RequireAuth>
          <Home />
        </RequireAuth>
      ),
      children: [
        { index: true, element: <HomeMain />, breadcrumb: null },
        { ...accountRoutes() },
        { ...analyticRoutes() },
        { ...designV1Routes() },
        { ...oldKnowledgeRoutes() },
        { ...knowledgeRoutes() },
        { ...userSettingsRoutes() },
        { ...userRoutes() },
        { ...projectRoutes() },
        { ...pulseRoutes() },
        { ...aiRoutes() },
      ],
    },
    { ...loginRoutes(authenticated) },
    { path: Paths.termsConditions(), element: <TermsConditions /> },
    { path: Paths.fairUsePolicy(), element: <FairUsePolicy /> },
    { path: Paths.securityPolicy(), element: <SecurityPolicy /> },
    { path: Paths.health(), element: <Healthcheck /> },
    { path: '*', element: <PageNotFound /> },
  ];
};
