import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDropdown } from '../../../hooks/useDropdown';
import { useAuth } from '../../../state/auth';
import { StyledHeader } from '../Header.styles';
import { getInitials } from '../../../utils/auth';
import { DropdownMenu } from '../../Dropdown';

export const ProfileMenu: React.FC = () => {
  const { t } = useTranslation('header');
  const { fullName, username, signOut } = useAuth();
  const { visible, toggleDropdown, dropdownRef } = useDropdown<HTMLButtonElement>(false);
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    if (fullName || username) {
      setInitials(getInitials(fullName || username));
    }
    return () => setInitials('');
  }, ['fullName', 'username']);

  const handleLogout = () => signOut();

  const items = [
    { label: <Link to="/user-settings">{t('user_settings')}</Link>, key: 'account' },
    {
      key: 'divider',
      type: 'divider',
    },
    { label: t('logout'), key: 'logout', onClick: handleLogout },
  ];

  return (
    <Dropdown
      menu={{ items, selectable: false, onClick: toggleDropdown }}
      dropdownRender={menu => <DropdownMenu.Root>{menu}</DropdownMenu.Root>}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
    >
      <StyledHeader.User
        ref={dropdownRef}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        {initials}
      </StyledHeader.User>
    </Dropdown>
  );
};
