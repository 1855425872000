import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Form } from 'antd';

type Props = { occupied: boolean };

export const StyledFloatItem = styled(Form.Item, {
  shouldForwardProp: propName => propName !== 'occupied',
})<Props>(
  ({ occupied, theme }) => css`
    position: relative;
    font-family: ${theme.fontFamilyBase};
    .ant-form-item-label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding: 0 9px;
      background-color: transparent;
      pointer-events: none;
      overflow: visible;
      z-index: 2;

      label {
        padding: 0 6px 0 5px;
        font-weight: 400;
        line-height: 1;
        background-color: ${occupied ? '#fff' : 'transparent'};
        color: ${theme.primaryColor};
        transform: ${occupied
          ? 'translate3d(2px, -126%, 0) scale(0.75)'
          : 'translate3d(0) scale(1)'};
        transform-origin: left top;
        transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
          background-color 0.15s cubic-bezier(0, 0, 0.2, 1), color 0.2s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &.ant-form-item-has-error label {
      color: ${occupied ? theme.errorColor : theme.primaryColor};
    }
    .ant-input,
    .ant-input-affix-wrapper {
      padding-left: 14px;
      padding-right: 14px;
    }
    .ant-form-item-control-input + div,
    .ant-form-item-margin-offset {
      display: none !important;
    }
  `,
);
