import { descend, pipe, prop, sort } from 'ramda';
import { dayOrder, Weekday } from '../constants';
import { ITimeSchedule } from '../pages/AI/Routing/Teams/types';
import { IUser } from '../pages/Projects/Users/UsersList';
import { formatFullName } from './user';

export const sortAsc = <T extends { name: string }>(items: T[]): T[] =>
  items.sort((a, b) => a.name.localeCompare(b.name));

export const sortAscUsersByName = (items: IUser[]): IUser[] =>
  items.sort((a, b) =>
    formatFullName(a.first_name, a.last_name).localeCompare(
      formatFullName(b.first_name, b.last_name),
    ),
  );

export const sortByDay = (schedules: ITimeSchedule[]): ITimeSchedule[] => {
  return schedules
    .slice()
    .sort(
      (a, b) =>
        dayOrder.indexOf(a.day.toLowerCase() as Weekday) -
        dayOrder.indexOf(b.day.toLowerCase() as Weekday),
    );
};

export const sortById = sort(descend(pipe(prop('id'), Number)));
