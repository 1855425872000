import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { sortById } from '../../utils/sorting';

interface IAutomatedChannel {
  id: number;
  name: string;
}

const queryKey = 'automated-channels';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomatedChannel[]> => {
  const response = await integrationQuery(`/api/automated_channels`, {
    apiData,
    signal,
  });
  return sortById(response.automated_channels);
};

export const AutomatedChannelsService = {
  queryKey,
  get,
};
