import styled from '@emotion/styled';
import { Layout } from 'antd';
import { StyledOption } from '../PresentationForm/Selectors/IntentSelector.styles';

const { Sider } = Layout;

export const StyledChatPreview = {
  Header: styled.div`
    background: #f2f0ff;
    padding: 8px 16px 16px;
    color: ${props => props.theme.primaryColor};
    font-size: 16px;
    font-weight: 600;
    height: 106px;
    position: absolute;
    top: 0;
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    .ant-select {
      width: 100%;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-color: ${({ theme }) => theme.secondaryBg};
    }
    .ant-select-single:not(.ant-select-customize-input):not(.ant-select-disabled)
      .ant-select-selector {
      background-color: ${({ theme }) => theme.bgColor};
    }
    .ant-select-disabled {
      ${StyledOption.Icon} {
        background-color: ${({ theme }) => theme.bgColor};
      }
      ${StyledOption.SubLabel} {
        color: #ccc;
      }
    }
  `,
  Content: styled.div`
    max-width: 300px;
    background: #ffffff;
    padding: 10px 16px;
    margin: 15px 50px 15px 15px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 18px 18px 18px 0;
    word-wrap: break-word;
    ul,
    ol {
      padding-left: 24px;
    }
    p,
    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
  ContentWrapper: styled.div`
    height: calc(100% - 148px);
    overflow-y: auto;
    position: absolute;
    top: 106px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    & a {
      text-decoration: underline;
    }
  `,
  Footer: styled.div`
    padding: 12px 16px;
    color: black;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid ${props => props.theme.primaryColor10};
    width: 100%;
  `,
};
export const StyledSider = styled(Sider)`
  background: ${props => props.theme.bgColor};
  border-left: 1px solid ${props => props.theme.primaryColor10};
  overflow: hidden;
  height: calc(100% - 166px);
  position: fixed;
  right: 0;
  top: 64px;
  bottom: 0;
  z-index: 1;
  margin-top: 36px;
`;
