import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButton';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';

interface Props {
  visible: boolean;
  closeModal: () => void;
}

export const DiscardChangesModal: React.FC<Props> = ({ visible, closeModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        width={408}
        title={
          <>
            <Cancel width={16} height={16} /> {t('unsaved_changes')}
          </>
        }
        centered
        open={visible}
        className="modal-danger"
        footer={[
          <ActionButton type="cancel" key="cancel" onClick={closeModal} />,
          <ActionButton
            icon={<Cancel width={12} height={12} />}
            type="disable"
            text={t('discard_changes')}
            key="disable"
            onClick={() => navigate(-1)}
          />,
        ]}
        onCancel={closeModal}
      >
        <p>{t('unsaved_changes_description')}</p>
      </Modal>
    </>
  );
};
