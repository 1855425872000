import React, { useEffect, useState } from 'react';
import { Tabs, Form, Switch, Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { TextEditor } from '../../../../components/TextEditor';
import {
  PresentationToolbar,
  PresentationToolbarLight,
} from '../../../../components/TextEditor/Toolbars/PresentationToolbar';
import { RequiredAsterisk } from '../../Knowledge.styles';
import { PlusIconWrapper, StyledEditor } from '../../../../components/TextEditor/TextEditor.styles';
import { IChannelItem, IParameter } from '../../types';
import { getChannelName, isRichTextEditor, stripHTMLTags } from '../utils';
import { useModal } from '../../../../hooks/useModal';
import { DeleteChannelModal } from './DeleteChannelConfirmation';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/add-channel.svg';
import { InfoBanner } from '../../../../components/InfoBanner';

type Props = {
  onChannelChange: (key: string) => void;
  options: IParameter[];
  channels: IChannelItem[];
  currentChannel: string;
};

type GeneratedChannels = {
  children: JSX.Element;
  label: JSX.Element;
  key: string;
};

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export const ChannelsTab: React.FC<Props> = ({
  onChannelChange,
  options,
  channels,
  currentChannel,
}) => {
  const [visibleChannels, setVisibleChannels] = useState<IChannelItem[]>([]);
  const [dropdownItems, setDropdownItems] = useState<{ key: string; label: string }[]>([]);
  const [defaultChannelValue, setDefaultChannelValue] = useState<string>('');
  const [checkedChannels, setCheckedChannels] = useState<{ [key: string]: boolean }>({});
  const [channelToRemove, setChannelToRemove] = useState<string>();
  const { setFieldValue, getFieldValue, setFields } = Form.useFormInstance();
  const { visible, openModal, closeModal } = useModal(false);
  const { t } = useTranslation('knowledge');

  const content = getFieldValue('content_entities_attributes');

  useEffect(() => {
    const defaultChannelValue = getFieldValue(['content_entities_attributes', 'web', 'payload']);
    const isChecked = getFieldValue([
      'content_entities_attributes',
      currentChannel,
      'is_using_default_channel_content',
    ]);
    setDefaultChannelValue(defaultChannelValue);
    if (checkedChannels[currentChannel] || isChecked) {
      setFieldValue(
        ['content_entities_attributes', currentChannel, 'payload'],
        defaultChannelValue,
      );
    }
  }, [currentChannel]);

  useEffect(() => {
    if (channels && content) {
      const channelList = channels.filter(el => Object.keys(content).includes(el.channel_type));
      if (channelList) {
        setVisibleChannels(channelList);
      }
      const activeChannels = channels.filter(el => !Object.keys(content).includes(el.channel_type));
      const items = activeChannels.map(el => ({
        key: el.channel_type,
        label: getChannelName(el.channel_type) as string,
      }));
      setDropdownItems(items);
    }
  }, [channels]);

  const onEdit = (targetKey: TargetKey, action: 'add' | 'remove'): void => {
    if (action === 'remove') {
      openModal();
      setChannelToRemove(targetKey as string);
    }
  };

  const removeChannel = (): void => {
    const removedChannel = {
      key: channelToRemove,
      label: getChannelName(channelToRemove as string),
    };
    setDropdownItems((prev: any) => [...prev, removedChannel]);
    const tempChannels = visibleChannels?.filter(item => item.channel_type !== channelToRemove);
    setVisibleChannels(tempChannels);
    onChannelChange(tempChannels[tempChannels?.length - 1]?.channel_type);
    setFieldValue(['content_entities_attributes', channelToRemove as string, 'payload'], '');
    closeModal();
  };

  const onClick = ({ key }: MenuInfo): void => {
    const result = channels?.find(item => item.channel_type === key);
    if (result) {
      setVisibleChannels(prev => [...prev, result]);
    }
    setDropdownItems(dropdownItems.filter(item => item.key !== key));
  };

  const PlusIconComponent = () => (
    <Dropdown menu={{ items: dropdownItems, onClick }} trigger={['click']}>
      <PlusIconWrapper>
        <PlusIcon width={10} height={10} />
      </PlusIconWrapper>
    </Dropdown>
  );

  const onSwitchChecked = (checked: boolean, channel: string): void => {
    setCheckedChannels(prev => ({ ...prev, [channel]: checked }));
    if (checked) {
      if (!isRichTextEditor(channel)) {
        setCheckedChannels(prev => ({ ...prev, [channel]: false }));
        setFieldValue(
          ['content_entities_attributes', channel, 'is_using_default_channel_content'],
          false,
        );
        setFieldValue(
          ['content_entities_attributes', channel, 'payload'],
          stripHTMLTags(defaultChannelValue),
        );
        setTimeout(
          () =>
            setFields([
              {
                name: ['content_entities_attributes', channel, 'payload'],
                errors: [t('rich_content_cannot_be_used')],
              },
            ]),
          100,
        );
        return;
      } else {
        setFieldValue(['content_entities_attributes', channel, 'payload'], defaultChannelValue);
      }
    }
  };

  const generateChannels = (
    data: IChannelItem[],
    options: IParameter[],
  ): GeneratedChannels[] | undefined => {
    return data?.map(el => {
      const channelType = el.channel_type;
      const isChecked = getFieldValue([
        'content_entities_attributes',
        channelType,
        'is_using_default_channel_content',
      ]);
      return {
        label: (
          <div>
            {el.is_default && <RequiredAsterisk />}
            {getChannelName(channelType)?.toUpperCase()}
          </div>
        ),
        key: channelType,
        closable: !el.is_default,
        children: (
          <StyledEditor>
            {!el.is_default && (
              <InfoBanner
                style={{ marginBottom: 16 }}
                message={t('presentation_default_response')}
                action={
                  <Form.Item
                    noStyle
                    name={[
                      'content_entities_attributes',
                      channelType,
                      'is_using_default_channel_content',
                    ]}
                    valuePropName="checked"
                  >
                    <Switch onChange={checked => onSwitchChecked(checked, channelType)} />
                  </Form.Item>
                }
              />
            )}

            <Form.Item
              name={['content_entities_attributes', channelType, 'payload']}
              rules={[
                () => ({
                  validator: async (_, value) => {
                    if (el.is_default) {
                      /** this is workaround for default channel.
                      react-quill leaves <p><br/></p> for rich-text channels even when all content is removed
                      **/
                      if (stripHTMLTags(value).trim().length === 0) {
                        currentChannel !== 'web' && onChannelChange('web');
                        throw new Error(t('default_channel_required'));
                      }
                    }
                  },
                }),
              ]}
            >
              {isRichTextEditor(channelType) ? (
                <TextEditor
                  id={channelType}
                  isDisabled={isChecked}
                  toolbar={<PresentationToolbar options={options} />}
                />
              ) : (
                <TextEditor
                  id={channelType}
                  isDisabled={isChecked}
                  toolbar={<PresentationToolbarLight options={options} />}
                />
              )}
            </Form.Item>
          </StyledEditor>
        ),
      };
    });
  };

  return (
    <>
      <Tabs
        style={{ height: currentChannel !== 'web' ? '470px' : '400px' }}
        activeKey={currentChannel}
        onEdit={onEdit}
        hideAdd={dropdownItems.length === 0}
        type="editable-card"
        addIcon={<PlusIconComponent />}
        onTabClick={onChannelChange}
        items={generateChannels(visibleChannels, options)}
      />
      <DeleteChannelModal
        visible={visible}
        close={closeModal}
        action={removeChannel}
        channel={channelToRemove}
      />
    </>
  );
};
