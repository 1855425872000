import styled from '@emotion/styled';

export const IconLink = styled.a`
  color: ${({ theme }) => theme.linkColor};
  display: inline-flex;
  align-items: baseline;
  margin: 5px 0;
  svg {
    margin-right: 4px;
  }
`;
