import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { consoleManagerQuery } from './api';
import { IProject, IProjectsPayload } from '../pages/Projects/types';
import { sortAsc } from '../utils/sorting';
import { IProductIntegration } from '../state/integrations';

const queryKey = 'projects';
const queryKeyIntegrations = 'integrations';

const get = async ({ queryKey, signal }: QueryFunctionContext): Promise<IProject[]> => {
  const response = await consoleManagerQuery(`/client_accounts/${queryKey[1]}/projects`, {
    signal,
  });
  return sortAsc(response);
};

const find = async ({ queryKey, signal }: QueryFunctionContext): Promise<IProject> =>
  consoleManagerQuery(`/client_accounts/${queryKey[1]}/projects/${queryKey[2]}`, { signal });

const getIntegrations = async ({
  queryKey,
  signal,
}: QueryFunctionContext): Promise<IProductIntegration[]> =>
  await consoleManagerQuery(
    `/client_accounts/${queryKey[1]}/projects/${queryKey[2]}/product_integrations?locale=${queryKey[3]}`,
    { signal },
  );

const create = ({
  activeAccountId,
  data,
}: {
  activeAccountId: string;
  data: IProjectsPayload;
}): Promise<IProject> => {
  delete data.nlu_provider;
  delete data.platform_edition;
  delete data.voice_provider;
  return consoleManagerQuery(`/client_accounts/${activeAccountId}/projects`, {
    method: 'POST',
    data: { project: data },
  });
};

const update = ({
  id,
  activeAccountId,
  values,
}: {
  id: string;
  activeAccountId: string;
  values: Partial<IProjectsPayload>;
}): Promise<IProject> =>
  consoleManagerQuery(`/client_accounts/${activeAccountId}/projects/${id}`, {
    method: 'PUT',
    data: values,
  });

const disable = ({
  id,
  activeAccountId,
}: {
  id: string;
  activeAccountId: string;
}): Promise<IProject> =>
  consoleManagerQuery(`/client_accounts/${activeAccountId}/projects/${id}/disablements`, {
    method: 'POST',
  });

export const ProjectsService = {
  queryKey,
  queryKeyIntegrations,
  get,
  getIntegrations,
  find,
  create,
  update,
  disable,
};
