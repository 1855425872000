import styled from '@emotion/styled';

export const StyledPasswordChecklist = styled.div`
  padding: 10px 20px;
  border: 1px solid #9fc6f6;
  background-color: rgba(159, 198, 246, 0.1);
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.typography.bodySmall.lineHeight};
  ul {
    margin: 0;
    padding: 0;
    list-style: outside none;
  }
  svg {
    color: ${({ theme }) => theme.successColor};
    margin-right: 7px;
  }
  span {
    display: inline-block;
    width: 10px;
    margin-right: 7px;
    text-align: center;
  }
`;
