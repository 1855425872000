import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import { IAutomationProfile } from '../../types';
import { DestinationValueWrap } from '../../../../../Knowledge/Parameters/ParametersList/ParameterValue/ParameterValue.styles';
import { ListWrapper, NumberBadge } from '../../../../../Knowledge/Knowledge.styles';

type Props = {
  profiles: IAutomationProfile[];
};

export const AutomationProfiles: React.FC<Props> = React.memo(({ profiles }) => {
  const items =
    profiles?.length > 1
      ? profiles.slice(1).map(item => ({
          key: item.id,
          label: <DestinationValueWrap>{item.name}</DestinationValueWrap>,
          style: { cursor: 'default' },
        }))
      : [];

  const onClick: MenuProps['onClick'] = ({ domEvent }) => domEvent.stopPropagation();

  return (
    <ListWrapper>
      {profiles?.[0].name}
      {!!items.length && (
        <Dropdown menu={{ items, onClick }} placement="bottomLeft" trigger={['click']}>
          <NumberBadge onClick={e => e.stopPropagation()}>+{items.length}</NumberBadge>
        </Dropdown>
      )}
    </ListWrapper>
  );
});
AutomationProfiles.displayName = 'AutomationProfiles';
