import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, FormInstance, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { equals } from 'ramda';
import { useModal } from '../../../../../hooks/useModal';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { Space } from '../../../../../components/Space';
import { CopyableInput } from '../../../../../components/CopyableInput';
import { IAgenticModelValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { validateDateInput } from '../../../../../utils/ai';
import { aiProviders } from '../../../../../constants';

interface Props {
  initialValues: IAgenticModelValues;
  onSubmit: (values: IAgenticModelValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const AgenticModelsForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const navigate = useNavigate();
  const { setFieldValue } = form;

  const handleProviderSelect = useCallback(
    (e: string) => {
      setFieldValue('provider', e);
      setFieldValue('model', undefined);
    },
    [setFieldValue],
  );

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue(true))) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const data = form.getFieldsValue(true);
    data.provider !== 'openaiAZ' && delete data.api_endpoint && delete data.api_version;
    onSubmit(data, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_name')} />
      </Form.Item>
      <Space>
        <Form.Item
          name="provider"
          label={t('provider')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select placeholder={t('select_provider')} onSelect={handleProviderSelect}>
            {aiProviders.map(el => (
              <Select.Option key={el.value} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.provider !== currentValues.provider
          }
        >
          {({ getFieldValue }) => {
            const provider = getFieldValue('provider');
            const selectedProvider = aiProviders.find(i => i.value === provider);

            return (
              <Form.Item
                name="model"
                label={t('model')}
                className={formItemClass.base}
                rules={[{ required: true }]}
              >
                <Select disabled={!provider} placeholder={t('select_model')}>
                  {selectedProvider?.models?.map(el => (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Space>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.provider !== currentValues.provider}
      >
        {({ getFieldValue }) => {
          return (
            <>
              {getFieldValue('provider') === 'openaiAZ' && (
                <Space>
                  <Form.Item
                    name="api_version"
                    label={t('api_version')}
                    className={formItemClass.base}
                    validateTrigger="onBlur"
                    validateFirst
                    rules={[{ required: true }, { validator: validateDateInput }]}
                    tooltip={t('tooltip_api_version')}
                  >
                    <Input placeholder={t('enter_api_version')} />
                  </Form.Item>
                  <Form.Item
                    name="api_endpoint"
                    label={t('api_endpoint')}
                    className={formItemClass.base}
                    rules={[{ required: true }]}
                    tooltip={t('tooltip_api_endpoint')}
                  >
                    <Input placeholder={t('enter_api_endpoint')} />
                  </Form.Item>
                </Space>
              )}
            </>
          );
        }}
      </Form.Item>
      <Divider />
      <Form.Item
        name="api_key"
        label={t('api_key')}
        className={formItemClass.full}
        rules={[{ required: true }]}
      >
        <CopyableInput placeholder={t('enter_api_key')} />
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
