import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { FallbackConfigsService } from '../../../../../services/ai/fallback-configs';
import { Paths } from '../../../../../types/paths';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { IFallbackConfig } from '../types';

export const FallbackBehaviourList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiFallbackBehaviourEdit({ id })),
  });

  const columns: ColumnProps<IFallbackConfig>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '12%',
    },
    {
      title: t('handoff'),
      onCell: onCellHandler,
      dataIndex: 'escalate_message',
      width: '33%',
    },
    {
      title: t('no_agents'),
      onCell: onCellHandler,
      dataIndex: 'hangup_message',
      width: '33%',
    },
    {
      title: t('raise_error_twilio'),
      onCell: onCellHandler,
      dataIndex: 'powered_by_twilio',
      width: '11%',
      render: record => <IconIndicator enabled={record} isStatus />,
    },
    {
      title: t('default'),
      onCell: onCellHandler,
      dataIndex: 'is_default',
      width: '8%',
      render: record => <IconIndicator enabled={record} isStatus />,
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IFallbackConfig>
      service={FallbackConfigsService}
      columns={columns}
      getCreatePath={Paths.aiFallbackBehaviourCreate}
      deleteConfirmationTitle={t('delete_fallback_behav')}
      getDisplayName={record => record.name}
      getIsDefault={record => record.is_default}
    />
  );
};
