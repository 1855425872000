import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { FormInstance } from 'rc-field-form/es/interface';
import useUpdateConfig from '../../hooks/useUpdateConfig';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/plus.svg';
import { ActionButton } from '../../../../../../components/ActionButton';
import { IAutomationProfile } from '../../types';
import { DesignService } from '../../../../../../services/design';
import { AiIntegrationParams } from '../../../../../../services/api';
import { useIntegrations } from '../../../../../../state/integrations';
import { ProfilesTable } from '../ProfilesTable';
import { ModalWarning } from '../../../../../../components/ModalWarning';
import { isAssignedProfile } from '../../../../../../utils/design';

type Props = {
  isVisible: boolean;
  setIsVisible: (args: boolean) => void;
  setFields: FormInstance['setFields'];
  setFieldValue: FormInstance['setFieldValue'];
  profilesValue: string[];
};

export const AddProfilesModal: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  setFieldValue,
  profilesValue,
  setFields,
}) => {
  const { t } = useTranslation('design');
  const { aiIntegration } = useIntegrations();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IAutomationProfile[]>([]);
  const { data } = useQuery<IAutomationProfile[], string>(
    [DesignService.queryKeyProfiles, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      DesignService.getProfiles({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const updateVAConfig = useUpdateConfig();
  const profiles = data?.filter(item => !profilesValue.includes(item.id.toString())) || [];

  const handleCancel = () => {
    setIsVisible(false);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const handleAdd = async () => {
    setFieldValue(['dial_plan_ids'], [...profilesValue, ...selectedRowKeys]);
    const payload = {
      dial_plan_ids: JSON.stringify(
        [...profilesValue, ...selectedRowKeys].map(item => Number(item)),
      ),
    };
    updateVAConfig(payload, setFields);
    handleCancel();
  };

  const rowSelection: TableRowSelection<IAutomationProfile> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const onRowHandler = (record: IAutomationProfile) => ({
    onClick: () => {
      const id = record.id.toString();
      const profilesIds = selectedRowKeys.includes(id)
        ? selectedRowKeys.filter(val => val !== id)
        : [...selectedRowKeys, id];
      const profiles = selectedRowKeys.includes(id)
        ? selectedRows.filter(val => val.id.toString() !== id)
        : [...selectedRows, record];
      setSelectedRowKeys(profilesIds);
      setSelectedRows(profiles);
    },
  });

  return (
    <Modal
      width={680}
      open={isVisible}
      onCancel={handleCancel}
      title={
        <>
          <PlusIcon width={14} height={14} /> {t('add_profiles')}
        </>
      }
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
        <ActionButton type="create" key="add" text={t('add')} onClick={handleAdd} />,
      ]}
    >
      <ProfilesTable
        profiles={profiles || []}
        rowSelection={rowSelection}
        onRow={onRowHandler}
        style={{ marginBottom: '-2px' }}
      />
      {isAssignedProfile(selectedRowKeys, profiles) && (
        <ModalWarning>{t('reassign_warning')}</ModalWarning>
      )}
    </Modal>
  );
};
