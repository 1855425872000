import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledSign } from '../../components/StyledSign/index.styles';
import { StyledLogin } from './Login.styles';
import { ReactComponent as Logo } from '../../assets/icons/logoConsole.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/external-link.svg';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledLogin.Root>
      <div>
        <StyledSign />
      </div>
      <StyledLogin.Content>
        <StyledLogin.Box>
          <SabioLogo width={263} height={60} />
          <Outlet />
        </StyledLogin.Box>
        <StyledLogin.Footer>
          &copy; {new Date().getFullYear()} {` ${t('footer_legal')}`}{' '}
          <StyledLogin.PolicyLink to="/fair-use-policy">
            <span>{t('legal')}</span> <LinkIcon width={12} />
          </StyledLogin.PolicyLink>
        </StyledLogin.Footer>
      </StyledLogin.Content>
    </StyledLogin.Root>
  );
};

const SabioLogo = styled(Logo)`
  display: block;
  margin: 0 auto 50px;
  fill: ${({ theme }) => theme.primaryColor};
`;
