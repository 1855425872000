import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { Content } from '../../../../components/Content';
import { UsersForm } from '../UsersForm';
import { useGetItem } from '../../../../hooks/useGetItem';
import { ProjectUsers } from '../../../../services/project-users';
import { useAccount } from '../../../../state/account';
import { IUser } from '../UsersList';
import { Paths } from '../../../../types/paths';

export const UsersEdit: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const { activeAccountId, activeProjectId } = useAccount();
  const { id } = useParams() as { id: string };
  const [sendAnotherBtn, setSendAnotherBtn] = useState<boolean>(false);
  const [inviteNewBtn, setInviteNewBtn] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data } = useGetItem<IUser>(
    [ProjectUsers.queryKey, activeAccountId, activeProjectId, id],
    ProjectUsers.find,
    { enabled: !!activeAccountId && !!activeProjectId && !!id },
  );

  const onSubmit = () => {
    // todo: send data and handle errors when the API is ready
    notification['success']({
      message: t('changes_saved'),
    });
    inviteNewBtn && navigate(Paths.usersInvite());
  };

  const initialValues = { emails: [] };

  return (
    <Content>
      <UsersForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        sendAnotherBtn={sendAnotherBtn}
        setSendAnotherBtn={setSendAnotherBtn}
        setInviteNewBtn={setInviteNewBtn}
        isEdit={true}
        user={data}
      />
    </Content>
  );
};
