import {
  MutationFunction,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiErrorResponse } from '../../services/types';

export type ApiResponse = {
  [key: string]: any;
  error?: ApiErrorResponse;
};

export function useCreate<Variables>(
  key: [string, string?, number?],
  fn: MutationFunction<ApiResponse, Variables>,
  options?: UseMutationOptions<ApiResponse, unknown, Variables>,
): UseMutationResult<any, any, any, any> {
  const queryClient = useQueryClient();
  return useMutation<ApiResponse, unknown, Variables>(fn, {
    onSuccess: data => {
      !data.error && queryClient.invalidateQueries(key);
    },
    ...options,
  });
}
