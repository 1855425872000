import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  ITextToSpeechConfig,
  ITTSValues,
  IVoices,
} from '../../pages/AI/Automation/TextToSpeech/types';
import { sortById } from '../../utils/sorting';

const queryKey = 'text-to-speech';
const voiceQueryKey = 'text-to-speech-voices';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ITextToSpeechConfig[]> => {
  const response = await integrationQuery(`/api/text_to_speech_configs`, {
    apiData,
    signal,
  });
  return sortById(response.text_to_speech_configs);
};

const getVoices = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IVoices> => {
  const response = await integrationQuery(`/api/text_to_speech_profiles/voices`, {
    apiData,
    signal,
  });
  return response?.google;
};

const create = async ({ data, apiData }: { data: ITTSValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/text_to_speech_configs`, {
    method: 'POST',
    data: { data: { type: 'text_to_speech_configs', attributes: data } },
    apiData,
  });

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/text_to_speech_configs/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ITextToSpeechConfig> => {
  const response = await integrationQuery(`/api/text_to_speech_configs/${id}`, {
    apiData,
    signal,
  });
  return response?.text_to_speech_config;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  data: ITTSValues;
} & AiIntegrationParams): Promise<ITextToSpeechConfig> => {
  return integrationQuery(`/api/text_to_speech_configs/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'text_to_speech_configs',
        attributes: data,
      },
    },
    apiData,
  });
};

export const TextToSpeechService = {
  queryKey,
  voiceQueryKey,
  get,
  remove,
  create,
  getVoices,
  find,
  update,
};
