import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Select } from 'antd';
import { OptionIntentItem } from '../../PresentationForm/Selectors/IntentSelector';
import { IntentOption } from '../../PresentationForm';
import i18n from '../../../../../locales/i18n';

const { Option } = Select;

interface Props {
  intents?: IntentOption[];
  selectedIntentValue: string;
  setSelectedIntentValue: Dispatch<SetStateAction<string>>;
}

export const getDefaultDestinationOption = () => ({
  destination_type: 'default',
  label: i18n.t('knowledge:default_parameter_value'),
  destination_type_text: i18n.t('knowledge:n_a'),
  name: '',
  value: 'default',
});

export const ChatBotDestinations: React.FC<Props> = ({
  intents,
  selectedIntentValue,
  setSelectedIntentValue,
}) => {
  const selectRef = React.useRef(null);
  const defaultOption = getDefaultDestinationOption();
  useEffect(() => {
    if (intents?.length) {
      setSelectedIntentValue(intents[intents.length - 1].value);
    } else {
      setSelectedIntentValue(defaultOption.value);
    }
  }, [intents]);

  return (
    <Select
      ref={selectRef}
      value={selectedIntentValue}
      getPopupContainer={trigger => trigger.parentElement}
      popupClassName="intents-dropdown"
      onChange={value => setSelectedIntentValue(value)}
      disabled={!intents?.length}
      onSelect={() => {
        if (selectRef?.current) {
          (selectRef?.current as HTMLDivElement).blur();
        }
      }}
    >
      <Option key={defaultOption.value} value={defaultOption.value} label={defaultOption.label}>
        <OptionIntentItem option={defaultOption} labelWidth={230} />
      </Option>
      {intents?.map(item => (
        <Option key={item.value} value={item.value} label={item.label}>
          <OptionIntentItem option={item} labelWidth={230} />
        </Option>
      ))}
    </Select>
  );
};
