import qs from 'qs';
import { TableParams } from '../components/DataTable';

export const getNextPageQsStringify = (
  paramsFn: (values: TableParams) => {},
  params: TableParams,
): string =>
  qs.stringify(
    paramsFn({
      ...params,
      pagination: {
        ...params.pagination,
        current: params.pagination?.current ? params.pagination.current + 1 : 0,
      },
    }),
    { addQueryPrefix: true, arrayFormat: 'repeat' },
  );
