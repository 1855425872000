import { ACTIVE_ACCOUNT_ID, ACTIVE_PROJECT_ID, ACTIVE_USER, AUTH_KEY } from '../constants';

export interface IAuthData {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  session_state: string;
  token_type: string;
  id_token: string;
}

export function setAuthConfig(config: IAuthData): void {
  const data = JSON.stringify(config);
  window.localStorage.setItem(AUTH_KEY, data);
}

export function getAuthConfig(): IAuthData | null {
  const data = window.localStorage.getItem(AUTH_KEY);
  return data ? JSON.parse(data) : null;
}

export function deleteAuthConfig(): void {
  localStorage.removeItem(AUTH_KEY);
}

export const getInitials = (name: string): string => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const checkAccountStorage = (username: string): void => {
  const lastLoggedInUser = localStorage.getItem(ACTIVE_USER);
  if (lastLoggedInUser !== username) {
    localStorage.setItem(ACTIVE_USER, username);
    localStorage.removeItem(ACTIVE_ACCOUNT_ID);
    localStorage.removeItem(ACTIVE_PROJECT_ID);
  }
};
