import styled from '@emotion/styled';
import { Badge, Button, Divider as DividerAnt, Input, Row, Tag } from 'antd';

const Content = styled.div`
  flex: 1 0;
  display: flex;
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
`;

const InnerContent = styled.div`
  position: relative;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;

  & > button {
    flex-shrink: 0;
  }
`;

const SideContent = styled(InnerContent)`
  position: absolute;
  top: 0;
  left: 8px;
  right: 8px;
  z-index: -10;
  height: 0;
  transform: translateY(-300%);
  opacity: 0;
  transition: transform 800ms 200ms, height 600ms 200ms, opacity 500ms;
  &.open {
    z-index: unset;
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
`;

const Search = styled(Input)`
  margin-right: 16px;
  padding-left: 8px;
  padding-right: 8px;
  svg {
    fill: #666;
  }
  .ant-input-clear-icon {
    line-height: 10px;
  }
`;

const LanguageButton = styled.button`
  position: relative;
  height: 36px;
  padding: 8px 34px 8px 16px;
  font-weight: 600;
  text-align: left;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.secondaryBg};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.primaryColor};
    margin-top: -2px;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  svg {
    margin: 0 6px;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const LanguageLabel = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin: 0 6px;
  }
`;

const HighlightedText = styled.span`
  & > strong {
    color: ${props => props.theme.errorColor};
  }
`;

const RowContent = styled(Row)`
  flex: 1 0;
  margin-top: 12px;

  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.scrollColor} transparent;
  }

  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollColor};
  }
`;

const Divider = styled(DividerAnt)`
  margin: 8px 0 0;
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubHeaderTitle = styled.span`
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryColor};
`;

const LinkButton = styled(Button)<{ cancel?: string }>`
  height: auto;
  padding: 0 8px;
  line-height: 1.4;
  color: ${({ theme, cancel }) =>
    cancel === 'true' ? theme.primaryColor : theme.linkColor} !important;
  &.ant-btn-link {
    background: transparent !important;
    span:not(.ant-btn-icon) {
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s;
    }
  }
  &.ant-btn-link {
    &:hover {
      span {
        border-color: ${({ theme, cancel }) =>
          cancel === 'true' ? theme.primaryColor : theme.linkColor};
      }
    }
  }
`;

const ContentList = styled.div`
  flex: 1 0;
  padding: 8px 0;
  overflow-y: auto;
  & > div {
    height: 100%;
    overflow-y: auto;
  }
`;

const Title = styled.h4`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${({ theme }) => theme.textColor};
  :not(:first-of-type) {
    margin-top: 28px;
  }
`;

const PresentationList = styled.div`
  margin-bottom: 24px;

  & > .ant-divider {
    margin: 0 0 8px;
  }
`;

const FilterBlock = styled.div`
  :not(:last-of-type) {
    margin-bottom: 28px;
  }

  & > div {
    margin-bottom: 0;
  }

  & > h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.textColor};
    & > span {
      font-weight: 400;
    }
  }
  .ant-badge {
    margin-right: 8px;
  }
`;

const BadgeColor = styled(Badge)<{
  bordercolor?: string;
  outlinecolor?: string;
  height?: number;
  width?: number;
}>`
  display: inline-flex;
  &:nth-of-type(2), &:nth-of-type(3) {
    margin-left: -5px;
  }
  span {
    ${({ outlinecolor }) => outlinecolor && `outline: 1px solid ${outlinecolor};`};
    ${({ height }) => height && `height: ${height}px !important;`};
    ${({ width }) => width && `width: ${width}px !important;`};
  }
  ${({ bordercolor }) => bordercolor && `border: 1px solid ${bordercolor};`};
}
`;

const TranslationStatus = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 4px 8px;
  color: #fff;
  div {
    font-size: 12px;
    font-weight: 600;
    margin-left: 4px;
    line-height: 14px;
  }
`;

const TransStatusContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  .ant-tag {
    margin: 0 8px 0 0 !important;
  }
`;

const TransChannelType = styled(Tag)`
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 8px;
`;

export const StyledEditTranslations = {
  Content,
  InnerContent,
  Navigation,
  SideContent,
  Search,
  LanguageButton,
  LanguageLabel,
  HighlightedText,
  RowContent,
  Divider,
  SubHeader,
  SubHeaderTitle,
  LinkButton,
  ContentList,
  Title,
  PresentationList,
  FilterBlock,
  BadgeColor,
  TranslationStatus,
  TransStatusContainer,
  TransChannelType,
};
