import { AlertProps } from 'antd';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-circle.svg';
import { ReactComponent as CloseCircleIcon } from '../../assets/icons/close-circle.svg';
import { StyledAlert } from './AlertBanner.styles.ts';

export const AlertBanner: React.FC<AlertProps> = ({ type, icon, closable = true, ...rest }) => {
  const detectIcon = () => {
    if (icon) return icon;
    switch (type) {
      case 'info':
        return <InfoIcon width={14} />;
      case 'error':
        return <CloseCircleIcon width={14} />;
      default:
        return null;
    }
  };

  return (
    <StyledAlert
      type={type}
      icon={detectIcon()}
      banner
      closable={closable}
      closeIcon={<CloseIcon width={12} />}
      {...rest}
    />
  );
};
