import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { FixedNav } from '../../../components/FixedNav';
import { ReactComponent as BriefcaseIcon } from '../../../assets/icons/briefcase.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';

export const ProjectsNav: React.FC = () => {
  const { t } = useTranslation('project_settings');

  const menuItems = [
    {
      key: '/projects',
      icon: <BriefcaseIcon width={14} height={16} />,
      label: <Link to="/projects">{t('projects')}</Link>,
    },
    {
      key: '/users',
      icon: <UsersIcon width={14} height={16} />,
      label: <Link to="/users">{t('users')}</Link>,
    },
  ];
  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
