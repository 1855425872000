import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, SelectProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueries } from '@tanstack/react-query';
import { Global } from '@emotion/react';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { KnowledgeChannelsService } from '../../../../services/knowledge/channels';
import { KnowledgeParameterService } from '../../../../services/knowledge/parameter';
import { KnowledgeLabelsService } from '../../../../services/knowledge/labels';
import { KnowledgeIntentService } from '../../../../services/knowledge/intents';
import { KnowledgeLanguageService } from '../../../../services/knowledge/languages';
import { formItemClass } from '../../../../theme';
import { ChatBotPreview } from '../ChatBotPreview';
import { StyledSider } from '../ChatBotPreview/ChatBotPreview.styles';
import { Content } from '../../../../components/Content';
import { FixedBottomBar } from '../../../../components/FixedBottomBar';
import { ActionButton } from '../../../../components/ActionButton';
import { OverlaySpinner } from '../../../../components/Spinner';
import { LabelSelector } from './Selectors/LabelSelector';
import { IntentSelector } from './Selectors/IntentSelector';
import { ChannelsTab } from './ChannelsTab';
import { StyledTabs } from './ChannelsTab.styles';
import { validateMessages } from '../../../../utils/validation';
import { isRichTextEditor, stripHTMLTags } from '../utils';
import { useAccount } from '../../../../state/account';
import { dropdownStyles } from './Selectors/IntentSelector.styles';
import { getPayload } from '../../../../utils/knowledge';
import {
  IChannelItem,
  IIntent,
  IPresentation,
  IPresentationValues,
  TextEditorPayload,
} from '../../types';

interface IPresentations {
  initialValues?: Partial<IPresentationValues>;
  onSubmit: (values: IPresentationValues, intentsData: IIntent[]) => void;
  submitButton: React.ReactElement;
  presentationIntents?: IPresentation['synchronisations'];
}

export type IntentOption = {
  label: string;
  value: string;
  destination_type: string;
  destination_type_text: string;
  name: string;
};

type ContentEntities = {
  content_type: string;
  payload: { payload: string; id: number } | string;
  language_id?: number;
  id?: number;
  channel_id?: number;
  _destroy?: boolean;
};

export const PresentationForm: React.FC<IPresentations> = ({
  initialValues,
  onSubmit,
  submitButton,
  presentationIntents,
}) => {
  const { activeProjectId } = useAccount();
  const [currentChannel, setCurrentChannel] = useState('web');
  const [chatBotPreviewValue, setChatBotPreviewValue] = useState<string>('');
  const presentationIntentOptions = transformIntentOption(
    presentationIntents?.map(el => el.destination_entity),
  );
  const [selectedIntents, setSelectedIntents] = useState<IntentOption[]>(presentationIntentOptions);
  const { knowledge } = useIntegrations();
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const results = useQueries({
    queries: [
      {
        queryKey: [
          KnowledgeParameterService.queryKey,
          activeProjectId,
          knowledge?.externalId,
          '?page=1&pageSize=ALL&q[s]=key asc',
        ],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          KnowledgeParameterService.get(
            knowledge as IKnowledgeUrl,
            '?page=1&pageSize=ALL&q[s]=key asc',
            signal,
          ),
        enabled: !!knowledge,
      },
      {
        queryKey: [KnowledgeChannelsService.queryKey, activeProjectId, knowledge?.externalId],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          KnowledgeChannelsService.get(knowledge as IKnowledgeUrl, signal),
        enabled: !!knowledge,
      },
      {
        queryKey: [KnowledgeLanguageService.queryKey, activeProjectId, knowledge?.externalId, ''],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          KnowledgeLanguageService.get(knowledge as IKnowledgeUrl, '', signal),
        enabled: !!knowledge,
      },
      {
        queryKey: [KnowledgeLabelsService.queryKey, activeProjectId, knowledge?.externalId],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          KnowledgeLabelsService.get(knowledge as IKnowledgeUrl, signal),
        enabled: !!knowledge,
      },
      {
        queryKey: [
          KnowledgeIntentService.queryKey,
          activeProjectId,
          knowledge?.externalId,
          '?page=1&pageSize=ALL&q[unassigned]=true',
        ],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          KnowledgeIntentService.get(
            knowledge as IKnowledgeUrl,
            '?page=1&pageSize=ALL&q[unassigned]=true',
            signal,
          ),
        enabled: !!knowledge,
      },
    ],
  });

  const isLoading = results.some(result => result.isLoading);
  const [
    { data: paramsData },
    { data: channelsData },
    { data: languagesData },
    { data: labelsData },
    { data: intentsData },
  ] = results;

  const labelsOptions = useMemo(
    () =>
      labelsData && labelsData?.data.length
        ? labelsData?.data.map(el => ({ label: el.name, value: el.name }))
        : [],
    [labelsData],
  );

  const intentsOptions = useMemo<IntentOption[]>(() => {
    const destinationEntities = presentationIntents?.map(el => el.destination_entity);
    if (intentsData?.data?.length && Array.isArray(destinationEntities)) {
      intentsData.data = intentsData.data.concat(destinationEntities);
    }

    return intentsData?.data.length ? transformIntentOption(intentsData.data) : [];
  }, [intentsData, presentationIntents]);

  useEffect(() => {
    setChatBotPreviewValue(
      form.getFieldValue(['content_entities_attributes', currentChannel, 'payload']) || '',
    );
  }, []);

  useEffect(() => {
    setChatBotPreviewValue(
      form.getFieldValue(['content_entities_attributes', currentChannel, 'payload']) || '',
    );
  }, [currentChannel]);

  const generateContentEntities = (data: TextEditorPayload): ContentEntities[] => {
    const defaultLang = languagesData?.data.find(el => el.is_default);
    return Object.keys(data).map((el: string) => {
      const channel = findChannel(channelsData?.data || [], el);
      const payload = getPayload(el, data[el].payload) as string;
      const payloadToSend = data[el].is_using_default_channel_content ? data.web.payload : payload;
      return {
        content_type: 'raw_html',
        language_id: data[el].language_id ?? defaultLang?.id,
        channel_id: channel?.id,
        is_using_default_channel_content: data[el].is_using_default_channel_content,
        _destroy: stripHTMLTags(data[el].payload).length === 0,
        payload: payloadToSend,
        id: !Array.isArray(initialValues?.content_entities_attributes)
          ? initialValues?.content_entities_attributes?.[el]?.id
          : undefined,
      };
    });
  };

  const findChannel = (channels: IChannelItem[], channelType: string): IChannelItem | undefined => {
    return channels?.find(el => el.channel_type === channelType);
  };

  const onFormValuesChange = () => {
    const checked = form.getFieldValue([
      'content_entities_attributes',
      currentChannel,
      'is_using_default_channel_content',
    ]);
    if (checked && isRichTextEditor(currentChannel)) {
      setChatBotPreviewValue(
        form.getFieldValue(['content_entities_attributes', 'web', 'payload']) || '',
      );
    } else {
      const payload = form.getFieldValue([
        'content_entities_attributes',
        currentChannel,
        'payload',
      ]);
      setChatBotPreviewValue(payload || '');
    }
  };

  const handleIntentsChange: SelectProps['onChange'] = (value, option: any) => {
    const selectedOptions =
      Array.isArray(option) && option.map(item => item.children?.props.option);
    selectedOptions && setSelectedIntents(selectedOptions);
  };

  const onFinish = () => {
    const payload = Object.assign({}, form.getFieldsValue(true));
    const content = form.getFieldValue('content_entities_attributes');
    payload.content_entities_attributes = generateContentEntities(content);
    onSubmit(payload, intentsData?.data || []);
  };

  return (
    <Content imgBg={false}>
      <Global styles={dropdownStyles} />
      <OverlaySpinner loading={isLoading} />
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        validateMessages={validateMessages}
        style={{ width: '60%' }}
        onValuesChange={onFormValuesChange}
        onFinish={onFinish}
      >
        <Form.Item
          name="title"
          label={t('presentation_name')}
          className={formItemClass.full}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_presentation_name')} maxLength={100} />
        </Form.Item>
        <Form.Item
          label={t('content')}
          className={formItemClass.full}
          tooltip={t('presentation_content_tooltip')}
        >
          <StyledTabs>
            <ChannelsTab
              channels={channelsData?.data || []}
              options={paramsData?.data || []}
              onChannelChange={setCurrentChannel}
              currentChannel={currentChannel}
            />
          </StyledTabs>
        </Form.Item>
        <Form.Item
          name="synchronisations_attributes"
          label={t('intent')}
          className={formItemClass.full}
          tooltip={t('presentation_intent_tooltip')}
        >
          <IntentSelector options={intentsOptions} onChange={handleIntentsChange} />
        </Form.Item>
        <Form.Item
          name="label_list"
          label={t('label')}
          className={formItemClass.full}
          tooltip={t('presentation_label_tooltip')}
        >
          <LabelSelector options={labelsOptions} />
        </Form.Item>
        <FixedBottomBar>
          <ActionButton type="cancel" onClick={() => navigate(-1)} />
          {submitButton}
        </FixedBottomBar>
      </Form>

      <StyledSider width={340}>
        <ChatBotPreview
          value={chatBotPreviewValue}
          channel={currentChannel}
          intents={selectedIntents}
        />
      </StyledSider>
    </Content>
  );
};

const transformIntentOption = (data?: IIntent[]): IntentOption[] =>
  data?.map(el => ({
    label: el.title,
    value: el.id.toString(),
    destination_type: el.destination.destination_type,
    destination_type_text: el.destination.destination_type_text,
    name: el.destination.name,
  })) || [];
