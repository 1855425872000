import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import { ILabels } from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-label';

const get = async (
  data: IKnowledgeUrl,
  signal: QueryFunctionContext['signal'],
): Promise<ILabels> => {
  return await knowledgeQuery('/labels', { data, signal });
};

export const KnowledgeLabelsService = {
  queryKey,
  get,
};
