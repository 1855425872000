import React from 'react';
import { Form, Input, Select, Slider, FormInstance } from 'antd';
import { equals, keys } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { AiIntegrationParams } from '../../../../../services/api';
import { useIntegrations } from '../../../../../state/integrations';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ITTSValues } from '../types';

interface Props {
  initialValues: ITTSValues;
  onSubmit: (values: ITTSValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const TextToSpeechForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const { aiIntegration } = useIntegrations();

  const { data } = useQuery(
    [TextToSpeechService.voiceQueryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      TextToSpeechService.getVoices({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const locale = Form.useWatch('locale', form);
  const localeOptions = keys(data).map(l => ({ label: l.toString(), value: l }));
  const voicesOptions = data?.[locale] ? data[locale].map(v => ({ label: v, value: v })) : [];
  const providerOptions = [{ label: 'Google', value: 'Google' }];

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('profile_name')} />
      </Form.Item>
      <Form.Item name="tts_provider" label={t('provider')} className={formItemClass.base}>
        <Select options={providerOptions} disabled={true} />
      </Form.Item>
      <Space>
        <Form.Item name="locale" label={t('locale')} className={formItemClass.base}>
          <Select
            options={localeOptions}
            onChange={e => {
              form.setFieldValue('locale', e);
              form.setFieldValue('voice', undefined);
            }}
          />
        </Form.Item>
        <Form.Item
          name="voice"
          label={t('voice')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select options={voicesOptions} placeholder={t('select_voice')} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item name="speaking_rate" label={t('voice_speed')} className={formItemClass.base}>
          <Slider
            min={0.25}
            max={4.0}
            step={0.01}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
        <Form.Item name="pitch" label={t('pitch')} className={formItemClass.base}>
          <Slider
            min={-20}
            max={20}
            step={0.1}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
      </Space>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
