import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoProject } from '../../components/NoProject';
import { useIntegrations } from '../../state/integrations';
import useStateLoading from '../../hooks/useStateLoading';
import { OverlaySpinner } from '../../components/Spinner';

export const DesignV1: React.FC = () => {
  const { t } = useTranslation('pages_title');
  const { design, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const [isIframeLoading, setIframeLoading] = useState<boolean>(true);
  const url = design?.[0].urls.url;

  useEffect(() => setIframeLoading(true), [url]);

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <>
      {design && (
        <iframe
          title={t('design').toLowerCase()}
          src={url}
          onLoad={() => setIframeLoading(false)}
        />
      )}
      <OverlaySpinner loading={isFetching || isIframeLoading} />
    </>
  );
};
