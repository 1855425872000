import styled from '@emotion/styled';

const Root = styled.div`
  display: inline-flex;
  color: ${props => props.theme.primaryColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

const Item = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  &:not(:empty) {
    min-height: 36px;
  }
  &:last-child {
    font-weight: 600;
  }
  & + & {
    margin-left: 11px;
    &::before {
      content: '';
      position: absolute;
      left: -6px;
      top: 50%;
      width: 1px;
      height: 14px;
      background-color: ${({ theme }) => theme.primaryColor};
      transform: translateY(-50%);
    }
  }
  a {
    font-weight: 400;
  }
`;

export const StyledBreadcrumbs = { Root, Item };
