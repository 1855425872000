import React from 'react';
import styled from '@emotion/styled';
import SimpleBar from 'simplebar-react';

export const Scrollbar = ({
  autoHide = false,
  ...other
}: SimpleBar.Props & React.HTMLAttributes<HTMLElement>): JSX.Element => (
  <Root>
    <StyledScrollbar autoHide={autoHide} {...other} />
  </Root>
);

const Root = styled.div`
  padding: 4px 0;
  .ant-dropdown-menu {
    padding: 0 !important;
  }
`;

const StyledScrollbar = styled(SimpleBar)`
  .simplebar-track.simplebar-vertical {
    width: 4px;
    background-color: ${({ theme }) => theme.secondaryBg};
  }
  .simplebar-scrollbar:before {
    left: 0;
    right: 0;
    border-radius: 0;
    background-color: #786fad;
    opacity: 1;
  }
  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 0;
    bottom: 0;
  }
  .ant-menu,
  .ant-dropdown-menu {
    margin: 0;
  }
`;
