import styled from '@emotion/styled';

export const TitleWithIcon = ({
  title,
  icon: Icon,
}: {
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}): JSX.Element => (
  <Container>
    <Icon width={20} height={20} />
    {title}
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
