import React, { useState } from 'react';
import { Form, Input, Divider, InputNumber, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IContextParameter, IContextValues } from '../types';
import { TitleWithToolTip } from '../../../../../components/TitleWithToolTip';
import { AddField } from '../../../../../components/AddField';
import { validateMessages } from '../../../../../utils/validation';
import { useWindowDimensions } from '../../../../../utils/useWindowDimensions';
import { InputWithRemove } from '../../../../../components/InputWithRemove';
import { isFieldNotUnique, formatParamName } from '../../../../../utils/ai';

interface Props {
  initialValues: IContextValues;
  onSubmit: (values: IContextValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isModal?: boolean;
  handleCloseModal?: () => void;
}

export const ContextForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isModal,
  handleCloseModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [isContextNameError, setIsContextNameError] = useState<boolean>(false);

  const checkContextNameError = async () => {
    const parameters = form.getFieldValue('parameters');
    if (parameters && parameters.length > 0) {
      const lastIndex = parameters.length - 1;
      const fieldToValidate = ['parameters', lastIndex, 'name'];

      try {
        await form.validateFields([fieldToValidate]);
        setIsContextNameError(false);
      } catch ({ errorFields }) {
        errorFields && setIsContextNameError(true);
      }
    }
  };

  const handleParamNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    fieldsLength: number,
  ) => {
    const parameters: IContextParameter[] = form.getFieldValue('parameters');
    form.setFields(
      parameters.map((_: IContextParameter, index: number) => ({
        name: ['parameters', index, 'name'],
        errors: [],
      })),
    );
    form.setFieldsValue({
      parameters: parameters.map((item: IContextParameter, i: number) =>
        i === index ? { ...item, name: formatParamName(e.target.value) } : item,
      ),
    });
    if (index === fieldsLength - 1) {
      checkContextNameError();
    }
  };

  const handleCancel = () => {
    if (isModal) {
      handleCloseModal();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue(true))) {
        openModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = () => {
    const data = form.getFieldsValue(true);
    onSubmit(data, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinishFailed={checkContextNameError}
      onFinish={onFinish}
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('context_name')} />
        </Form.Item>
        <Form.Item
          name="duration"
          label={t('lifespan')}
          className={formItemClass.base}
          rules={[{ required: true }]}
          tooltip={t('tooltip_lifespan')}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Space>
      <Divider />
      <Space direction="vertical" size={16}>
        <TitleWithToolTip title={t('context_params')} tooltip={t('tooltip_context_params')} large />
        <Form.List name="parameters">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.key}>
                  <Form.Item
                    name={[index, 'name']}
                    label={t('name')}
                    className={formItemClass.base}
                    rules={[
                      { required: true },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          const parameters = form.getFieldValue('parameters');
                          if (isFieldNotUnique(parameters, 'name', value, index)) {
                            throw new Error(t('name_unique'));
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      onChange={e => handleParamNameChange(e, index, fields.length)}
                      placeholder={t('context_param_name_ph')}
                    />
                  </Form.Item>
                  <InputWithRemove
                    name={[index, 'value']}
                    onClick={() => remove(index)}
                    placeholder={t('context_param_value_ph')}
                    label={t('value')}
                  />
                </Space>
              ))}
              <AddField
                onClick={add}
                buttonText={t('add_context_param')}
                moveUp={fields.length > 0 && (!isContextNameError || width <= 893)}
              />
            </>
          )}
        </Form.List>
      </Space>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </>
      )}
    </Form>
  );
};
