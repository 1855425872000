import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import {
  BatchVerifyProps,
  ITranslationStatus,
  ITranslationStatuses,
  ITranslationPayload,
  ITranslationResponse,
} from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-translation';

const getStatuses = async (
  data: IKnowledgeUrl,
  signal: QueryFunctionContext['signal'],
): Promise<ITranslationStatuses> => {
  const response = await knowledgeQuery(`/translation_statuses`, { data, signal });
  return {
    data: response.data.map((item: ITranslationStatus) => ({
      ...item,
      id: item.language.id,
    })),
  };
};

const verifyAllContent = ({
  languageId,
  data,
  values,
}: Omit<BatchVerifyProps, 'presentationId'>): Promise<{}> => {
  return knowledgeQuery(`/languages/${languageId}/content_entities/batch_updates`, {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: values,
          type: 'batch_updates',
        },
      },
    },
  });
};

const verifySinglePresentation = ({
  languageId,
  presentationId,
  data,
  values,
}: BatchVerifyProps): Promise<{}> => {
  return knowledgeQuery(
    `/languages/${languageId}/presentations/${presentationId}/content_entities/batch_updates`,
    {
      method: 'POST',
      data: {
        ...data,
        payload: {
          data: {
            attributes: values,
            type: 'batch_updates',
          },
        },
      },
    },
  );
};

const translateAll = async ({
  data,
  values,
}: {
  data: IKnowledgeUrl;
  values: ITranslationPayload;
}): Promise<ITranslationResponse> => {
  return knowledgeQuery('/translation_batches', {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: { ...values },
          type: 'translation_batches',
        },
      },
    },
  });
};

const getTranslationStatus = async (
  data: IKnowledgeUrl,
  id: number,
  signal: QueryFunctionContext['signal'],
): Promise<ITranslationResponse> => {
  return await knowledgeQuery(`/translation_batches/${id}`, {
    data,
    signal,
  });
};

const translatePresentation = ({
  id,
  data,
  values,
}: {
  id: number;
  data: IKnowledgeUrl;
  values: ITranslationPayload;
}): Promise<ITranslationResponse> => {
  return knowledgeQuery(`/presentations/${id}/translations`, {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: values,
          type: 'translations',
        },
      },
    },
  });
};

export const KnowledgeTranslationService = {
  queryKey,
  getStatuses,
  verifyAllContent,
  verifySinglePresentation,
  translateAll,
  translatePresentation,
  getTranslationStatus,
};
