import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import { IDestinations } from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-destination';

const get = async (
  data: IKnowledgeUrl,
  signal: QueryFunctionContext['signal'],
): Promise<IDestinations> => {
  return await knowledgeQuery(`/destinations`, { data, signal });
};

export const KnowledgeDestinationService = {
  queryKey,
  get,
};
