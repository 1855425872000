import React from 'react';
import { Divider, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { Space } from '../../../../../components/Space';

export const PulseSettings: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { getFieldValue } = Form.useFormInstance();
  const isPulseSelected = getFieldValue('pulse');

  return (
    <div style={style}>
      <Divider style={{ margin: '0 0 8px' }} />
      <Space>
        <Form.Item
          name={['pulse_attributes', 'telco_account_sid']}
          label={t('twilio_account_sid')}
          className={formItemClass.base}
          rules={[{ required: isPulseSelected }]}
          tooltip={t('twilio_account_sid_tooltip')}
        >
          <Input placeholder="ACxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
        </Form.Item>
        <Form.Item
          name={['pulse_attributes', 'telco_auth_token']}
          label={t('twilio_auth_token')}
          className={formItemClass.base}
          rules={[{ required: isPulseSelected }]}
          tooltip={t('twilio_auth_token_tooltip')}
        >
          <PasswordInput placeholder="*******************" />
        </Form.Item>
      </Space>
    </div>
  );
};
