import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../../state/integrations';
import { IVAConfigPayload } from '../../types';
import { useUpdate } from '../../../../../../hooks/useUpdate';
import { DesignService } from '../../../../../../services/design';
import { formatApiErrors, getFormErrors } from '../../../../../../utils/errors';
import { unexpectedError } from '../../../../../../utils/notification';

export default function useUpdateConfig() {
  const { t } = useTranslation('design');
  const { notification } = App.useApp();
  const { id } = useParams() as { id: string };
  const { aiIntegration } = useIntegrations();
  const queryClient = useQueryClient();
  const { mutate: updateItem } = useUpdate<{
    id: string;
    apiData: IAiIntegrationUrl;
    payload: Partial<IVAConfigPayload>;
  }>(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    DesignService.update,
  );

  return useCallback(
    async (payload: Partial<IVAConfigPayload>, setFields: FormInstance['setFields']) => {
      await updateItem(
        { id, apiData: aiIntegration as IAiIntegrationUrl, payload },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('va_updated_notify', { name: data.virtual_agent_config.name })}
                </Trans>
              ),
            });
            queryClient.invalidateQueries([
              DesignService.queryKeyProfiles,
              aiIntegration?.metadata.PROJECT_ID,
            ]);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      );
    },
    [id, aiIntegration],
  );
}
