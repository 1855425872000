import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { DLPTemplateForm } from '../DLPTemplateForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IDLPTemplate, IDLPTemplateValues } from '../types';
import { DLPTemplateService } from '../../../../../services/ai/dlp-configs';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

export const DLPTemplateEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IDLPTemplate>(
    [DLPTemplateService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      DLPTemplateService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [DLPTemplateService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    DLPTemplateService.update,
  );

  const onSubmit = async (
    data: IDLPTemplateValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('dlp_template_updated_notify', {
                    name: data?.dlp_config.name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IDLPTemplateValues = {
    name: data.name,
    deidentify_template_name: data.deidentify_template_name,
    inspect_template_name: data.inspect_template_name,
    gcp_keyfile: null,
    gcp_project_name: data.gcp_project_name,
  };

  return (
    <Content imgBg={false}>
      <DLPTemplateForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
