import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { FormInstance } from 'rc-field-form/es/interface';
import { ProjectsForm } from '../ProjectsForm';
import { Content } from '../../../components/Content';
import { ActionButton } from '../../../components/ActionButton';
import { IProject, IProjectsValues, IProjectsPayload } from '../types';
import { useAccount } from '../../../state/account';
import { useGetItem } from '../../../hooks/useGetItem';
import { ProjectsService } from '../../../services/projects';
import { useUpdate } from '../../../hooks/useUpdate';
import { getLanguagesAttributes } from './utils';
import { formatApiErrors, getFormErrorPage, getFormErrors } from '../../../utils/errors';
import { projectStepsFields } from '../constants';
import { getFormErrorsConfig, unexpectedError } from '../../../utils/notification';
import { Paths } from '../../../types/paths';
import { useIntegrations } from '../../../state/integrations';
import { OverlaySpinner } from '../../../components/Spinner';

export const ProjectsEdit: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams() as { id: string };
  const { notification } = App.useApp();
  const { activeAccountId } = useAccount() as { activeAccountId: string };
  const { isFetching, aiIntegration, knowledge, pulse } = useIntegrations();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();

  const { data } = useGetItem<IProject>(
    [ProjectsService.queryKey, activeAccountId, id],
    ProjectsService.find,
    {
      enabled: !!activeAccountId,
      retry: 0,
      onError: (error: any) => {
        if (error.response?.status === 404) navigate('..');
      },
    },
  );

  const { mutate: update, isLoading } = useUpdate<{
    id: string;
    activeAccountId: string;
    values: Partial<IProjectsPayload>;
  }>([ProjectsService.queryKey, activeAccountId, id], ProjectsService.update);

  const onSubmit = async (values: IProjectsValues, setFields: FormInstance['setFields']) => {
    const { name, default_language_id, project_languages_attributes = [] } = values;
    setPage(undefined);
    const languages = getLanguagesAttributes(data?.languages || [], project_languages_attributes);
    const payload: Partial<IProjectsPayload> = {
      name,
      default_language_id: default_language_id?.toString(),
      ...(!isEmpty(languages) && { project_languages_attributes: languages }),
    };
    await update(
      { id, activeAccountId, values: payload },
      {
        onSuccess: data => {
          navigate(Paths.projects());
          notification['success']({
            message: <Trans t={t}>{t('project_updated_notify', { name: data.name })}</Trans>,
          });
        },
        onError: (err: any) => {
          if (err?.response.status === 400) {
            const formattedErrors = formatApiErrors(err.response.data);
            const formStep = getFormErrorPage(projectStepsFields, formattedErrors);
            const formErrors = getFormErrors(formattedErrors);
            if (formStep || formStep === 0) {
              setPage(formStep);
              setTimeout(() => setFields(formErrors), 100);
            } else notification['error'](getFormErrorsConfig(formattedErrors));
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  if (!data) return <Content imgBg={true} />;
  if (!data.is_enabled) return <Navigate replace to=".." />;

  const initialValues: Partial<IProjectsValues> = {
    name: data.name,
    is_enabled: data.is_enabled,
    default_language_id: data.default_language_id?.toString(),
    project_languages_attributes: data.languages?.map(item => item.language_id),
    platform_edition: 'es',
    ...(knowledge && {
      knowledge_v2_attributes: {
        is_approval_flow_enabled: knowledge.knowledgeItemMetadata.is_approval_flow_enabled,
      },
    }),
    ...(aiIntegration && {
      ai_n_a: !!aiIntegration,
    }),
    ...(pulse && {
      pulse: !!pulse,
    }),
  };

  return (
    <Content imgBg={false}>
      {(aiIntegration || knowledge || pulse) && (
        <ProjectsForm
          isEdit={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
          page={page}
        />
      )}
      <OverlaySpinner loading={!!isFetching} />
    </Content>
  );
};
