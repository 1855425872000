import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Dropdown, MenuProps } from 'antd';
import { ReactComponent as MenuIcon } from '../../../../../assets/icons/menu-dots.svg';
import { TranslateConfirmation } from '../TranslateConfirmation';
import { VerifyModal } from '../../VerifyModal';

interface Props {
  handleTranslateAll: (value: boolean) => void;
  handleVerifyAll: () => void;
}

export const TranslationMenu: React.FC<Props> = ({ handleTranslateAll, handleVerifyAll }) => {
  const { t } = useTranslation('knowledge');
  const [isVisible, setIsVisible] = useState(false);
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: t('translate_all_presentations'),
    },
    {
      key: '2',
      label: t('verify_all_translations'),
    },
  ];

  const handleMenu: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') setIsVisible(!isVisible);
    if (key === '2') setIsVerifyVisible(!isVerifyVisible);
  };

  return (
    <>
      <Dropdown
        menu={{ items: menuItems, selectable: false, onClick: handleMenu }}
        placement="bottomRight"
        trigger={['click']}
      >
        <MenuButton type="text" icon={<MenuIcon height={14} />} />
      </Dropdown>
      <TranslateConfirmation
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onTranslate={handleTranslateAll}
        title={t('translate_all')}
        description={t('translate_all_description')}
        buttonOkText={t('translate_all')}
        isReplaceContent={true}
      />
      <VerifyModal
        isVisible={isVerifyVisible}
        okButtonText={t('verify_all')}
        description={t('verify_all_description')}
        title={t('verify_all_translations')}
        handleCancel={() => setIsVerifyVisible(false)}
        handleOk={() => {
          handleVerifyAll();
          setIsVerifyVisible(false);
        }}
      />
    </>
  );
};

const MenuButton = styled(Button)`
  color: ${({ theme }) => theme.primaryColor};
`;
