import { useCallback, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useAuth } from '../../state/auth';
import { LAST_TIMESTAMP } from '../../constants';

dayjs.extend(duration);

const events = ['click', 'scroll', 'mousemove', 'keydown', 'touchstart'];
const maxTime = 2; //hours
const maxTimeMs = maxTime * 60 * 60 * 1000;

export const useSessionTimeout = () => {
  const { authenticated, signOut } = useAuth();
  const inactiveInterval = useRef<NodeJS.Timeout>();
  const startTimer = useRef<NodeJS.Timeout>();

  const timeChecker = () => {
    startTimer.current = setTimeout(() => {
      const storedTimeStamp = localStorage.getItem(LAST_TIMESTAMP);
      storedTimeStamp && checkInactivity();
    }, 60000);
  };

  const checkInactivity = () => {
    clearTimeout(startTimer.current);
    clearInterval(inactiveInterval.current);
    inactiveInterval.current = setInterval(() => {
      const timeStamp = localStorage.getItem(LAST_TIMESTAMP);
      const diff = dayjs.duration(dayjs().diff(Number(timeStamp)));
      const hoursPast = diff.hours();

      if (hoursPast >= maxTime) {
        clearInterval(inactiveInterval.current);
        signOut();
      }
    }, 10000);
  };

  const resetIntervalTimer = useCallback(() => {
    clearTimeout(startTimer.current);
    clearInterval(inactiveInterval.current);

    if (authenticated) {
      const timeStamp = dayjs().valueOf();
      localStorage.setItem(LAST_TIMESTAMP, String(timeStamp));
    } else {
      clearInterval(inactiveInterval.current);
      localStorage.removeItem(LAST_TIMESTAMP);
    }
    timeChecker();
  }, [authenticated]);

  useEffect(() => {
    const lastTimestamp = localStorage.getItem(LAST_TIMESTAMP);
    if (lastTimestamp) {
      const currentTime = dayjs().valueOf();
      if (currentTime - Number(lastTimestamp) > maxTimeMs) {
        signOut();
        localStorage.removeItem(LAST_TIMESTAMP);
        return;
      }
    }
    events.forEach(event => window.addEventListener(event, resetIntervalTimer));
    timeChecker();

    return () => {
      events.forEach(event => window.removeEventListener(event, resetIntervalTimer));
      clearTimeout(startTimer.current);
      clearInterval(inactiveInterval.current);
    };
  }, [resetIntervalTimer, timeChecker]);
};
