import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { AutomatedResponseTemplateService } from '../../../../../services/ai/automated-response-template';
import { IAutoRespTemplate } from '../types';
import { Paths } from '../../../../../types/paths';

export const AutomatedResponseTemplatesList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiAutoRespTemplatesEdit({ id })),
  });

  const columns: ColumnProps<IAutoRespTemplate>[] = [
    {
      title: t('response_key'),
      onCell: onCellHandler,
      dataIndex: 'response_key',
      sorter: (a, b) => a.response_key.localeCompare(b.response_key),
      fixed: 'left',
      width: '33%',
    },
    {
      title: t('automated_channel'),
      onCell: onCellHandler,
      dataIndex: ['automated_channel', 'name'],
      sorter: (a, b) => a.automated_channel.name.localeCompare(b.automated_channel.name),
      width: '32%',
    },
    {
      title: t('df_agent'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow_agent', 'display_name'],
      sorter: (a, b) =>
        a.dialogflow_agent.display_name.localeCompare(b.dialogflow_agent.display_name),
      width: '32%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IAutoRespTemplate>
      service={AutomatedResponseTemplateService}
      columns={columns}
      getCreatePath={Paths.aiAutoRespTemplatesCreate}
      deleteConfirmationTitle={t('delete_auto_resp_template')}
      getDisplayName={record => record.response_key}
    />
  );
};
