import React from 'react';
import { Outlet } from 'react-router-dom';
import { AnalyticsMenu } from './AnalyticsMenu';

export const Analytics: React.FC = () => {
  return (
    <>
      <AnalyticsMenu />
      <Outlet />
    </>
  );
};
