import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { authMiddlewareQuery, consoleManagerQuery } from './api';
import { UserCreateValues } from '../pages/Login/CreatePassword';

const queryKey = 'users';

const find = async ({ queryKey, signal }: QueryFunctionContext) =>
  consoleManagerQuery(`/users/${queryKey[1]}`, { signal });

const create = (data: UserCreateValues): Promise<UserCreateValues> =>
  authMiddlewareQuery(`/users/user_createuser`, {
    method: 'POST',
    data,
  });

export const UserService = {
  queryKey,
  find,
  create,
};
