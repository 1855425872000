import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, Select } from 'antd';
import { formItemClass } from '../../../../../theme';
import { validateMessages } from '../../../../../utils/validation';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/pen.svg';
import { ActionButton } from '../../../../../components/ActionButton';
import { ParameterModalTextarea } from './DestinationParameterModal.styles';

interface IParameterModal {
  isEdit: boolean;
  destinations: {
    id: number;
    name: string;
    isDisabled: boolean;
  }[];
  initialValues?: {
    configurable_id: number;
    configurable_name: string;
    value: string;
  };
  visible: boolean;
  setVisible: (value: boolean) => void;
  onSubmit: (values: any) => void;
}

export const DestinationParameterModal: React.FC<IParameterModal> = ({
  isEdit,
  destinations,
  initialValues,
  visible,
  setVisible,
  onSubmit,
}) => {
  const { t } = useTranslation('knowledge');
  const [form] = Form.useForm();

  const handleOk = async () => {
    await form.validateFields();
    const formData = form.getFieldsValue(true);
    formData.configurable_id = Number(formData.configurable_id);
    onSubmit(formData);
    setVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  return (
    <Modal
      title={
        isEdit ? (
          <>
            <EditIcon width={16} /> {t('edit_destination_parameter')}
          </>
        ) : (
          <>
            <PlusIcon width={14} /> {t('add_destination_parameter')}
          </>
        )
      }
      width={408}
      centered
      open={visible}
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
        <ActionButton
          type={isEdit ? 'save' : 'create'}
          key="create"
          onClick={handleOk}
          text={!isEdit ? t('add') : ''}
        />,
      ]}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
        validateMessages={validateMessages}
        validateTrigger="onBlur"
      >
        <Form.Item
          name="configurable_name"
          label={t('destination')}
          className={formItemClass.base}
          rules={[{ required: true }]}
          tooltip={t('modal_parameter_destination_tooltip')}
        >
          <Select
            placeholder={t('select_destination')}
            disabled={isEdit}
            onSelect={(_, option) => {
              form.setFieldValue('configurable_id', option.key);
            }}
          >
            {destinations.map(item => {
              return (
                <Select.Option key={item.id} value={item.name} disabled={item.isDisabled}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="configurable_id" rules={[{ required: true }]} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="value"
          label={t('value')}
          className={formItemClass.base}
          rules={[{ required: true }]}
          tooltip={t('modal_parameter_value_tooltip')}
        >
          <ParameterModalTextarea
            placeholder={t('modal_parameter_value_placeholder')}
            maxLength={256}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
