import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import {
  IDestination,
  IParameter,
  IParameterPayload,
  IParameters,
} from '../../pages/Knowledge/types';
import { ApiResponse } from '../types';
import { knowledgeQuery } from '../api';

type DeleteProps = {
  id: string;
  data: IKnowledgeUrl;
};

const queryKey = 'knowledge-parameter';
const queryKeyDestination = 'knowledge-destination';

const get = async (
  data: IKnowledgeUrl,
  queryString: string,
  signal: QueryFunctionContext['signal'],
): Promise<IParameters> => {
  return await knowledgeQuery(`/parameters${queryString}`, { data, signal });
};

const find = async (
  data: IKnowledgeUrl,
  id: string,
  signal: QueryFunctionContext['signal'],
): Promise<IParameter> => {
  return await knowledgeQuery(`/parameters/${id}`, { data, signal });
};

const create = ({
  data,
  values,
}: {
  data: IKnowledgeUrl;
  values: IParameterPayload;
}): Promise<IParameter> => {
  return knowledgeQuery('/parameters', {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: { ...values },
          type: 'parameters',
        },
      },
    },
  });
};

const update = ({
  id,
  data,
  values,
}: {
  id: string;
  data: IKnowledgeUrl;
  values: IParameterPayload;
}): Promise<IParameter> => {
  return knowledgeQuery(`/parameters/${id}`, {
    method: 'PUT',
    data: {
      ...data,
      payload: {
        data: {
          attributes: { ...values },
          type: 'parameters',
        },
      },
    },
  });
};

const deleteParameter = async ({ id, data }: DeleteProps): Promise<ApiResponse> =>
  await knowledgeQuery(`/parameters/${id}`, {
    method: 'DELETE',
    data,
  });

const getDestinations = async ({
  queryKey: [, data],
  signal,
}: QueryFunctionContext<[string, IKnowledgeUrl]>): Promise<IDestination[]> => {
  return await knowledgeQuery('/destinations', { data, signal });
};

export const KnowledgeParameterService = {
  queryKey,
  queryKeyDestination,
  get,
  find,
  create,
  update,
  deleteParameter,
  getDestinations,
};
