import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paths } from '../../../types/paths';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';
import { FixedNav } from '../../../components/FixedNav';
import { ReactComponent as MessageIcon } from '../../../assets/icons/message-lines.svg';
import { ReactComponent as CurlyBracketsIcon } from '../../../assets/icons/brackets-curly.svg';
import { ReactComponent as PointerIcon } from '../../../assets/icons/pointer.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-left-right.svg';
import { ReactComponent as WorkflowIcon } from '../../../assets/icons/workflow.svg';

export const KnowledgeMenu = (): JSX.Element => {
  const { t } = useTranslation('pages_title');
  const { knowledgeTranslations, knowledgeWorkflow } = useFlags();
  const menuItems = [
    {
      key: Paths.knowledgePresentations(),
      icon: <MessageIcon width={15} height={15} />,
      label: <Link to={Paths.knowledgePresentations()}>{t('presentations')}</Link>,
    },
    {
      key: Paths.knowledgeParameters(),
      icon: <CurlyBracketsIcon width={15} height={15} />,
      label: <Link to={Paths.knowledgeParameters()}>{t('parameters')}</Link>,
    },
    {
      key: Paths.knowledgeIntents(),
      icon: <PointerIcon width={15} height={15} />,
      label: <Link to={Paths.knowledgeIntents()}>{t('intents')}</Link>,
    },
  ];

  if (knowledgeTranslations)
    menuItems.push({
      key: Paths.knowledgeTranslations(),
      icon: <ArrowIcon width={15} height={15} />,
      label: <Link to={Paths.knowledgeTranslations()}>{t('translations')}</Link>,
    });

  //todo: add a check if the workflow feature enabled on a project level when BE is done
  if (knowledgeWorkflow)
    menuItems.push({
      key: Paths.knowledgeWorkflow(),
      icon: <WorkflowIcon width={15} height={15} />,
      label: <Link to={Paths.knowledgeWorkflow()}>{t('workflow')}</Link>,
    });

  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
