import { IProjectLanguage } from '../types';

export const getLanguagesAttributes = (
  initial: IProjectLanguage[],
  current: number[],
): Record<string, { id?: number; _destroy?: boolean; language_id?: number }> => {
  const languages: Record<string, { id?: number; _destroy?: boolean; language_id?: number }> = {};
  initial.forEach(lng => {
    if (!current.includes(lng.language_id)) {
      languages[Object.keys(languages).length] = {
        id: lng.project_language_id,
        _destroy: true,
      };
    } else {
      current = current.filter(item => item !== lng.language_id);
    }
  });
  current.forEach(item => (languages[Object.keys(languages).length] = { language_id: item }));
  return languages;
};
