import React, { useState } from 'react';
import { Form, Input, FormInstance, Switch, Divider, Typography, Tabs, TabsProps, App } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { Space } from '../../../../../components/Space';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { DFAgentTypeValues, IConversationProfileValues, SuggestionFeatureTypes } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { DFAgentSelector } from '../../../../../components/DFAgentSelector';
import { TitleDesc } from '../../../AI.styles';
import { HumanAgentSuggestion } from './HumanAgentSuggestion';
import { HumanAgentFieldsReset } from '../../../../../constants';

const { Title } = Typography;

interface Props {
  initialValues: IConversationProfileValues;
  onSubmit: (values: IConversationProfileValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
}

export const ConversationProfilesForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [humanAssistEnabled, setHumanAssistEnabled] = useState(
    initialValues.human_agent_assistant_config?.enabled ?? false,
  );
  const [dfAgentValues, setDfAgentValues] = useState<DFAgentTypeValues>();
  const [suggestionType, setSuggestionType] = useState<string>(
    initialValues?.human_agent_assistant_config?.human_agent_suggestion_config?.feature_configs?.[0]
      ?.suggestion_feature?.type ?? '',
  );

  const handleHumanAssistEnabled = (checked: boolean) => {
    setHumanAssistEnabled(checked);
    setSuggestionType('');
    HumanAgentFieldsReset.forEach(({ path, value }) => {
      form.setFieldValue(path, value);
    });
  };

  const handleSuggestionTypeChange = (value: string) => {
    if (form.getFieldValue('dialogflow_agent_id')) setSuggestionType(value);
    else {
      notification['error']({ message: t('select_dfagent') });
      setTimeout(
        () =>
          form.setFieldValue(
            [
              'human_agent_assistant_config',
              'human_agent_suggestion_config',
              'feature_configs',
              0,
              'suggestion_feature',
              'type',
            ],
            undefined,
          ),
        100,
      );
    }
  };

  const handleDFChange = (value: number, option: DefaultOptionType | DefaultOptionType[]) => {
    if (!Array.isArray(option)) {
      const dfAgentOption = {
        id: value,
        gcpProjectName: option.gcpProjectName,
        platform_edition: option.platform_edition,
        location: option.location,
        uuid: option.uuid,
      };
      setDfAgentValues(dfAgentOption);
    }
    const getKBField = [
      'human_agent_assistant_config',
      'human_agent_suggestion_config',
      'feature_configs',
      0,
      'query_config',
      'knowledge_base_query_source',
      'knowledge_bases',
      0,
    ];
    if (form.getFieldValue(getKBField)) form.setFieldValue(getKBField, undefined);
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    delete formData.human_agent_assistant_config.enabled;

    let dfValues;
    if (isEditing) {
      dfValues = {
        id: initialValues.dialogflow_agent_id,
        gcpProjectName: initialValues.gcpProjectName,
        platform_edition: initialValues.platform_edition,
        location: initialValues.location,
        uuid: initialValues.uuid,
      };
    } else dfValues = dfAgentValues;

    if (dfValues) {
      formData.automated_agent_config = {};
      dfValues.platform_edition === 'CX'
        ? (formData.automated_agent_config.agent = `projects/${dfValues.gcpProjectName}/locations/${dfValues.location}/agents/${dfValues.uuid}/environments/-`)
        : (formData.automated_agent_config.agent = `projects/${dfValues.gcpProjectName}/agent/environments/-`);
    }

    if (
      dfValues &&
      formData.human_agent_assistant_config?.human_agent_suggestion_config &&
      suggestionType === SuggestionFeatureTypes.DIALOGFLOW_ASSIST
    ) {
      const featureConfig =
        formData.human_agent_assistant_config.human_agent_suggestion_config.feature_configs[0];

      if (!featureConfig.query_config) {
        featureConfig.query_config = {};
      }

      featureConfig.query_config.dialogflow_query_source = {
        agent: `projects/${dfValues.gcpProjectName}/agent`,
      };
    }

    onSubmit(formData, form.setFields);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('human_agent_suggestion').toUpperCase(),
      children: (
        <HumanAgentSuggestion
          suggestionType={suggestionType}
          handleSuggestionTypeChange={handleSuggestionTypeChange}
          dfAgentId={isEditing ? initialValues.dialogflow_agent_id : dfAgentValues?.id}
        />
      ),
    },
  ];

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="display_name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('profile_name')} />
      </Form.Item>
      <Space>
        <Form.Item
          name="dialogflow_agent_id"
          label={t('dialogflow_agent')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <DFAgentSelector
            placeholder={t('select_agent')}
            humanAssist
            onChange={handleDFChange}
            disabled={isEditing}
          />
        </Form.Item>
        <Form.Item
          name={['logging_config', 'enable_stackdriver_logging']}
          valuePropName="checked"
          label={t('stackdriver_logging')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item
        name={['human_agent_assistant_config', 'enabled']}
        valuePropName="checked"
        label={t('human_assist')}
      >
        <Switch onChange={handleHumanAssistEnabled} />
      </Form.Item>
      {humanAssistEnabled && (
        <>
          <Divider className={formItemClass.full} />
          <Title level={5} type="secondary">
            {t('configure_human_assist')}
          </Title>
          <TitleDesc>{t('configure_human_assist_desc')}</TitleDesc>
          <Tabs defaultActiveKey="1" items={items} />
        </>
      )}
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
