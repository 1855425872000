import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ColumnProps } from 'antd/es/table';
import { Content } from '../../../components/Content';
import { DataTable } from '../../../components/DataTable';
import { ActionButton } from '../../../components/ActionButton';
import { IconIndicator } from '../../../components/IconIndicator';
import { useAccount } from '../../../state/account';
import { IProject } from '../types';
import { ProjectsService } from '../../../services/projects';
import { Paths } from '../../../types/paths';

export const ProjectsList: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const navigate = useNavigate();
  const { activeAccountId, setActiveProjectId } = useAccount();
  const { data: projects, isLoading } = useQuery<IProject[], string>(
    [ProjectsService.queryKey, activeAccountId],
    ProjectsService.get,
    { enabled: !!activeAccountId },
  );

  const onRowHandler = ({ id }: IProject) => {
    return {
      onClick: () => {
        navigate(Paths.projectEdit({ id }));
        setActiveProjectId(id.toString());
      },
    };
  };

  const columns: ColumnProps<IProject>[] = [
    {
      title: t('project'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('project_enabled'),
      dataIndex: 'is_enabled',
      width: 150,
      render: enabled => <IconIndicator enabled={enabled} />,
      sorter: (a, b) => Number(a.is_enabled) - Number(b.is_enabled),
    },
  ];

  const ButtonCreate = (
    <ActionButton type="create" onClick={() => navigate(Paths.projectCreate())} />
  );

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable
        dataSource={projects}
        columns={columns}
        onRow={onRowHandler}
        loading={isLoading}
        rowClassName={record => (!record.is_enabled ? 'is-disabled-row' : '')}
      />
    </Content>
  );
};
