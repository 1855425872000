import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiErrorResponse } from '../../services/types';

type ApiResponse = { error?: ApiErrorResponse };

export function useDelete<Variables>(
  key: QueryKey,
  fn: MutationFunction<ApiResponse, Variables>,
  options?: UseMutationOptions<ApiResponse, unknown, Variables>,
): UseMutationResult<ApiResponse, unknown, Variables, unknown> {
  const queryClient = useQueryClient();
  return useMutation<ApiResponse, unknown, Variables>(fn, {
    onSuccess: (data, id) => {
      if (!data.error) {
        queryClient.setQueryData<any[]>(
          key,
          (oldData: any) => oldData?.filter((obj: any) => obj.id !== id) || [],
        );
        void queryClient.invalidateQueries(key);
      }
    },
    ...options,
  });
}
