import React, { useEffect, useState } from 'react';
import { Button, Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { DataTable } from '../../../../../components/DataTable';
import { formItemClass } from '../../../../../theme';
import { PermissionModal } from '../PermissionModal';
import { mockedPermissions } from '../../mockedPermissions';
import { DataType, IPermission } from '../index';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg';

export const Permissions: React.FC<{ selectedPermissions: DataType[] }> = () => {
  const { t } = useTranslation('project_settings');
  const { setFieldsValue } = Form.useFormInstance();
  const [mockedData] = useState<DataType[]>(mockedPermissions);
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const [permissionsQty, setPermissionsQty] = useState<number | '-'>('-');
  const [selectedPermissionsData, setSelectedPermissionsData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();

  const columns = [
    {
      title: t('permission'),
      dataIndex: 'permission',
      sorter: (a: IPermission, b: IPermission) => a.permission.localeCompare(b.permission),
    },
    {
      dataIndex: '',
      width: 40,
      render: (record: IPermission) => (
        <TrashIcon
          width={14}
          height={14}
          style={{ transform: 'translateY(2px)' }}
          onClick={() => removePermission(record.id)}
        />
      ),
      onCell: () => ({
        onClick: (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
      }),
    },
  ];
  const removePermission = (recordID: number) => {
    setSelectedPermissionsData(selectedPermissionsData?.filter(item => item.id !== recordID));
  };

  useEffect(() => {
    selectedRowKeys && setPermissionsQty(selectedRowKeys.length);
  }, [selectedRowKeys]);

  useEffect(() => {
    setSelectedRowKeys(selectedPermissionsData?.map(item => item.id));
    setFieldsValue({ selected_permissions: JSON.stringify(selectedPermissionsData) });
  }, [selectedPermissionsData]);

  function showPermissionModal() {
    setPermissionModalVisible(true);
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const perm = selectedPermissionsData?.filter(item => newSelectedRowKeys.includes(item.id));
    setFieldsValue({ selected_permissions: JSON.stringify(perm) });
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectRow = (record: DataType) => {
    if (selectedRowKeys) {
      const selectedKeys = [...selectedRowKeys];
      selectedKeys.indexOf(record.id) >= 0
        ? selectedKeys.splice(selectedKeys.indexOf(record.id), 1)
        : selectedKeys.push(record.id);
      setSelectedRowKeys(selectedKeys);
      onSelectChange(selectedKeys);
    }
  };

  return (
    <>
      <Divider />
      <Button
        onClick={showPermissionModal}
        icon={<PlusIcon width={15} height={15} />}
        style={{ marginBottom: '20px' }}
      >
        {t('add_permissions')}
      </Button>
      <h3>
        <b>
          {t('assigned_permissions')} ({permissionsQty})
        </b>
      </h3>
      <DataTable
        dataSource={selectedPermissionsData}
        columns={columns}
        rowSelection={rowSelection}
        rowKey={record => record.id}
        onRow={record => ({
          onClick: () => selectRow(record),
        })}
      />
      <Form.Item
        rules={[{ required: false, min: 4, message: t('select_permission') }]}
        name="selected_permissions"
        validateStatus="error"
        className={formItemClass.hidden}
      >
        <input hidden={true} />
      </Form.Item>
      <PermissionModal
        permissionModalVisible={permissionModalVisible}
        setPermissionModalVisible={setPermissionModalVisible}
        mockedData={mockedData}
        selectedPermissionsData={selectedPermissionsData}
        setSelectedPermissionsData={setSelectedPermissionsData}
      />
    </>
  );
};
