import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { ConversationProfilesService } from '../../../../../services/ai/conversation-profiles';
import { IConversationProfile } from '../types';
import { Paths } from '../../../../../types/paths';

export const ConversationProfilesList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiConversationProfilesEdit({ id })),
  });

  const columns: ColumnProps<IConversationProfile>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow_data', 'display_name'],
      sorter: (a, b) =>
        a.dialogflow_data.display_name.localeCompare(b.dialogflow_data.display_name),
      fixed: 'left',
      width: '50%',
    },
    {
      title: t('dialogflow_agent'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow_agent', 'display_name'],
      width: '47%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IConversationProfile>
      service={ConversationProfilesService}
      columns={columns}
      getCreatePath={Paths.aiConversationProfilesCreate}
      deleteConfirmationTitle={t('delete_conversation_profiles')}
      getDisplayName={record => record.dialogflow_data.display_name}
    />
  );
};
