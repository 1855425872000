import { Divider, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { StyledFormItem } from '../../../../AI.styles';

const { Title } = Typography;

export const VoiceRecordingConfig = (): JSX.Element => {
  const { t } = useTranslation('ai');

  return (
    <Space direction="vertical" size={0} style={{ marginBottom: 20 }}>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('voice_recording_config')}
      </Title>
      <StyledFormItem
        name={['voice_recording_config_attributes', 'voice_recording_enabled']}
        valuePropName="checked"
        label={t('enable_voice_recording')}
        tooltip={t('enable_voice_recording_tooltip')}
      >
        <Switch />
      </StyledFormItem>
      <StyledFormItem
        name={['voice_recording_config_attributes', 'stop_on_escalation']}
        valuePropName="checked"
        label={t('stop_voice_recording')}
        tooltip={t('stop_voice_recording_tooltip')}
      >
        <Switch />
      </StyledFormItem>
    </Space>
  );
};
