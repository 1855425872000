import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { Space } from 'antd';
import { MainContent } from '../../pages/Home';

export const FixedBottomBar = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Panel>
      <Space size={10}>{children}</Space>
      <Global
        styles={css`
          ${MainContent} {
            padding-bottom: 64px;
          }
        `}
      />
    </Panel>
  );
};

const Panel = styled.div`
  position: fixed;
  left: ${({ theme }) => theme.navWidth};
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 14px 40px;
  background-color: ${({ theme }) => theme.bgColor};
  box-shadow: 0 -1px 0 0 rgba(16, 0, 107, 0.1);
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
