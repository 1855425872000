import { Button, Popover, Tree, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { DataNode } from 'antd/lib/tree';
import { ReactComponent as SettingsIcon } from '../../assets/icons/table.svg';

type TreeDataType = DataNode & {
  isChecked?: boolean;
};

type ToolBarProps = {
  isDisabled: boolean;
  treeData: TreeDataType[];
  checkedColumns: string[];
  setCheckedColumns: (value: string[]) => void;
};

export const TableToolBar = (props: ToolBarProps): JSX.Element => {
  const { isDisabled, treeData, checkedColumns, setCheckedColumns } = props;
  const { t } = useTranslation('knowledge');

  return (
    <RootToolBar>
      <Popover
        content={
          <StyledTree
            checkable
            selectable={false}
            treeData={treeData}
            multiple={true}
            checkedKeys={checkedColumns}
            onCheck={checkedKeys => setCheckedColumns(checkedKeys as string[])}
          />
        }
        trigger="click"
        placement="bottomRight"
      >
        <Tooltip title={t('column_settings')} placement="top">
          <ToolBarButton type="text" icon={<SettingsIcon width={14} />} disabled={isDisabled} />
        </Tooltip>
      </Popover>
    </RootToolBar>
  );
};

const RootToolBar = styled.div`
  padding-right: 6px;
`;

const ToolBarButton = styled(Button)`
  color: ${props => props.theme.primaryColor};
`;

const StyledTree = styled(Tree)`
  .ant-tree-switcher-noop {
    width: 0;
  }
`;
