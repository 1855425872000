import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'antd';

export const BreadCrumbsText = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  text-transform: uppercase;
  font-weight: 600;
`;

export const UppercaseLink = styled(Link)`
  text-transform: uppercase;
  font-weight: 400;
  display: inline-block;
`;
export const UppercaseLinkBold = styled(UppercaseLink)`
  font-weight: 600;
  margin-bottom: 15px;
`;

export const AnalyticsMenuTabs = styled(Menu)`
  .ant-menu-title-content {
    font-weight: 600;
  }
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      margin-left: 5px;
    }
  }
`;
export const DashboardsMenuArrow = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.primaryColor};
    margin-top: -2px;
    transition: transform 0.3s ease;
  }
`;
export const CreateDashboard = styled(Button)`
  margin-bottom: 15px;
`;
