import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, App, Empty } from 'antd';
import qs from 'qs';
import { isEmpty } from 'ramda';
import { Paths } from '../../../../types/paths';
import { KnowledgeTranslationService } from '../../../../services/knowledge/translations';
import { KnowledgePresentationService } from '../../../../services/knowledge/presentation';
import { KnowledgeEntityService } from '../../../../services/knowledge/content-entity';
import { KnowledgeLanguageService } from '../../../../services/knowledge/languages';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { Content } from '../../../../components/Content';
import { CenteredSpinner } from '../../../../components/Spinner';
import { StyledEditTranslations } from './EditTranslations.styles';
import { SideList } from './SideList';
import { Filters } from './Filters';
import { TranslationMenu } from './TranslationMenu';
import { LanguageDropdown } from './LanguageDropdown';
import { useAccount } from '../../../../state/account';
import { useUpdate } from '../../../../hooks/useUpdate';
import { useCreate } from '../../../../hooks/useCreate';
import { useRemoveQuery } from '../../../../hooks/useRemoveQuery';
import { OnFinishType, TranslationForm } from './TranslationForm';
import { ensure } from '../../../../utils/arrays';
import {
  getTranslationValues,
  getUsedChannels,
  prepareChannelsValues,
  prepareStatusesValues,
} from '../../../../utils/knowledge';
import { TableParams } from '../../../../components/DataTable';
import { unexpectedError } from '../../../../utils/notification';
import { AlertBanner } from '../../../../components/AlertBanner';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/times.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as LoadingIcon } from '../../../../assets/icons/spinner.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import {
  BatchVerifyProps,
  IContentEntity,
  IContentEntityResponse,
  IPresentation,
  IPresentations,
  ITranslationResponse,
  ILanguages,
} from '../../types';

const getParams = (params: TableParams, languageId?: string) => ({
  page: params.pagination?.current,
  pageSize: params.pagination?.pageSize,
  'q[title_cont]': params.searchValue || undefined,
  'q[by_content_entity_attrs][][channel_type][]': params.filters?.channels || undefined,
  'q[by_content_entity_attrs][][translation_status][]': params.filters?.statuses || undefined,
  'q[by_content_entity_attrs][][language_id]':
    params.filters?.channels?.length || params.filters?.statuses?.length ? languageId : undefined,
});

export const EditTranslations: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notification } = App.useApp();
  const { t } = useTranslation('knowledge');
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations();
  const removeQuery = useRemoveQuery();
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [languageCode, setLanguageCode] = useState<string>(id);
  const [languageId, setLanguageId] = useState<number>();
  const [filterValues, setFilterValues] = useState<Record<string, boolean>>({});
  const [activePresentation, setActivePresentation] = useState<IPresentation>({} as IPresentation);
  const [currentChannel, setCurrentChannel] = useState<string>('');
  const [isFormSaved, setIsFormSaved] = useState<boolean>(true);
  const [combinedData, setCombinedData] = useState<IPresentations>({} as IPresentations);
  const [isTranslated, setIsTranslated] = useState<boolean>(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState<boolean>(false);
  const [translationId, setTranslationId] = useState<number>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [channelsValues, setChannelsValues] = useState<string[]>([]);
  const [statusesValues, setStatusesValues] = useState<string[]>([]);
  const [sideListParams, setSideListParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });
  const queryString = qs.stringify(getParams(sideListParams, languageId?.toString()), {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  const { data: languageResponse } = useQuery<ILanguages>(
    [KnowledgeLanguageService.queryKey, activeProjectId, knowledge?.externalId, '?pageSize=ALL'],
    ({ signal }) =>
      KnowledgeLanguageService.get(knowledge as IKnowledgeUrl, '?pageSize=ALL', signal),
    { enabled: !!knowledge },
  );
  const {
    data: presentationResponse,
    isLoading,
    refetch: refetchPresentation,
  } = useQuery<IPresentations>(
    [KnowledgePresentationService.queryKey, activeProjectId, knowledge?.externalId, queryString],
    ({ signal }) =>
      KnowledgePresentationService.get(knowledge as IKnowledgeUrl, queryString, signal),
    { enabled: !!knowledge },
  );

  const { data: translationStatusResponse, isError } = useQuery<ITranslationResponse>(
    [KnowledgeTranslationService.queryKey, activeProjectId, knowledge?.externalId, translationId],
    ({ signal }) =>
      KnowledgeTranslationService.getTranslationStatus(
        knowledge as IKnowledgeUrl,
        translationId as number,
        signal,
      ),
    { enabled: !!translationId, refetchInterval: 2000 },
  );

  const { mutate: updateItem } = useUpdate(
    [KnowledgeEntityService.queryKey, knowledge?.externalId as string],
    KnowledgeEntityService.update,
  );

  const { mutate: createItem } = useCreate(
    [KnowledgeEntityService.queryKey, knowledge?.externalId as string],
    KnowledgeEntityService.create,
  );

  const { mutate: verifyAll, isLoading: isVerifyingLoading } = useCreate<
    Omit<BatchVerifyProps, 'presentationId'>
  >(
    [KnowledgeTranslationService.queryKey, knowledge?.externalId as string],
    KnowledgeTranslationService.verifyAllContent,
  );

  const { mutate: verifyPresentation, isLoading: isVerifySingleLoading } =
    useCreate<BatchVerifyProps>(
      [KnowledgeTranslationService.queryKey, knowledge?.externalId as string],
      KnowledgeTranslationService.verifySinglePresentation,
    );

  const {
    data: entitiesData,
    isLoading: isEntitiesLoading,
    refetch,
  } = useQuery<IContentEntityResponse>(
    [
      KnowledgeEntityService.queryKey,
      activeProjectId,
      knowledge?.externalId,
      activePresentation?.id,
    ],
    ({ signal }) =>
      KnowledgeEntityService.get(knowledge as IKnowledgeUrl, activePresentation.id, signal),
    {
      enabled: !!activePresentation?.id,
      cacheTime: 0,
      retry: false,
    },
  );

  const { mutate: translateAll } = useCreate(
    [KnowledgeTranslationService.queryKey, knowledge?.externalId as string],
    KnowledgeTranslationService.translateAll,
  );

  const { mutate: translatePresentation, isLoading: isTranslationPresentationLoading } = useCreate(
    [KnowledgeTranslationService.queryKey, knowledge?.externalId as string, activePresentation.id],
    KnowledgeTranslationService.translatePresentation,
  );

  const channels = useMemo(
    () => getUsedChannels(presentationResponse?.data),
    [presentationResponse?.data],
  );

  useEffect(() => {
    if (languageResponse?.data.length) {
      setLanguageId(findCurrentLanguage());
    }
  }, [languageResponse, languageCode]);

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      const channels = prepareChannelsValues(filterValues);
      const statuses = prepareStatusesValues(filterValues);
      setChannelsValues(channels);
      setStatusesValues(statuses);
      setSideListParams({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        searchValue,
        filters: {
          channels,
          statuses,
        },
      });
    } else {
      setSideListParams({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        searchValue,
      });
    }
  }, [filterValues]);

  useEffect(() => {
    if (languageCode) {
      setCombinedData({} as IPresentations);
    }
  }, [languageCode, filterValues]);

  useEffect(() => {
    if (searchValue) {
      const timer = setTimeout(() => {
        setCombinedData({} as IPresentations);
        setSideListParams({
          pagination: {
            current: 1,
            pageSize: 10,
          },
          searchValue,
          filters: {
            channels: channelsValues,
            statuses: statusesValues,
          },
        });
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    } else {
      searchReset();
    }
  }, [searchValue]);

  useEffect(() => {
    if (languageResponse?.data.length && !findCurrentLanguage()) {
      notification.error({
        message: t('translation_not_found'),
      });
      navigate(Paths.knowledgeTranslations(), { replace: true });
    }
  }, [languageResponse]);

  useEffect(() => {
    if (!presentationResponse?.data?.length) {
      !combinedData?.data?.length && setActivePresentation({} as IPresentation);
      return;
    }

    if (combinedData?.data?.length) {
      const tempData = combinedData.data.concat(presentationResponse.data);
      const uniqValues = [...new Map(tempData.map(item => [item['id'], item])).values()];
      setCombinedData({ data: uniqValues, pagination: presentationResponse.pagination });
    } else {
      setCombinedData(presentationResponse);
      setActivePresentation(presentationResponse.data[0]);
    }
  }, [presentationResponse]);

  useEffect(() => {
    if (translationStatusResponse && translationStatusResponse.status === 'success') {
      setTranslationId(undefined);
      setIsTranslated(true);
      setTimeout(() => {
        setIsTranslationLoading(false);
        setIsTranslated(false);
        refetch();
        refetchPresentation();
      }, 2000);
    }
    if (translationStatusResponse && translationStatusResponse.status === 'failed') {
      setTranslationId(undefined);
      setIsTranslationLoading(false);
      notification.error(unexpectedError);
    }
  }, [translationStatusResponse]);

  useEffect(() => {
    if (isError) {
      notification.error(unexpectedError);
    }
  }, [isError]);

  const loadMore = () =>
    setSideListParams({
      pagination: {
        current: (sideListParams?.pagination?.current as number) + 1 || 1,
        pageSize: 10,
      },
      searchValue,
      filters: {
        channels: channelsValues,
        statuses: statusesValues,
      },
    });

  const searchReset = () => {
    setSearchValue('');
    if (sideListParams.searchValue) {
      setCombinedData({} as IPresentations);
      setSideListParams({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        filters: {
          channels: channelsValues,
          statuses: statusesValues,
        },
      });
    }
  };

  const handleSubmitError = (err: any) => {
    if (err?.response?.data?.payload?.[0])
      return notification.error({
        message: err.response.data.payload[0],
      });
    return notification.error(unexpectedError);
  };

  const handleTranslateError = (err: any) => {
    setIsTranslationLoading(false);
    if (err?.response?.data?.errors?.[0]?.body === 'Records count must be greater than 0') {
      return notification.error({ message: t('nothing_to_translate') });
    }
    return notification.error(unexpectedError);
  };

  const formData = useMemo(() => {
    const defaultEntities = entitiesData?.data.filter(el => el.language.is_default);
    return defaultEntities?.map(item => ({
      payload: item.payload,
      id: item.id || undefined,
      channel: item.channel,
      language: item.language,
      translation: entitiesData?.data.find(
        el =>
          el.language.code === languageCode &&
          item.channel.channel_type === el.channel.channel_type,
      ),
    }));
  }, [languageCode, entitiesData]);

  const findCurrentLanguage = () => languageResponse?.data.find(el => el.code === languageCode)?.id;

  const isContentLoading =
    isEntitiesLoading ||
    isVerifyingLoading ||
    isVerifySingleLoading ||
    isTranslationPresentationLoading;

  const onSubmit = async (values: OnFinishType) => {
    const currentLanguageID = findCurrentLanguage();
    const currentChannelStuff = ensure(
      formData?.find(el => el.channel.channel_type === currentChannel),
    );
    const currentPayload = values[currentChannel].translation.payload;
    const entityId = formData?.find(el => el.channel.channel_type === currentChannel)?.translation
      ?.id;
    if (entityId) {
      updateItem(
        {
          id: entityId,
          presentationID: ensure(activePresentation.id),
          data: knowledge as IKnowledgeUrl,
          values: getTranslationValues(
            currentChannelStuff.translation as IContentEntity,
            currentPayload,
          ),
        },
        {
          onSuccess: () => {
            refetchPresentation();
            notification.success({
              message: t('translation_success_updated'),
            });
          },
          onError: handleSubmitError,
        },
      );
    } else {
      const tempData = {
        payload: currentPayload,
        language: { id: currentLanguageID },
        channel: {
          id: currentChannelStuff.channel.id,
          channel_type: currentChannelStuff.channel.channel_type,
        },
      };
      createItem(
        {
          presentationID: activePresentation.id,
          data: knowledge as IKnowledgeUrl,
          values: getTranslationValues(tempData as IContentEntity, currentPayload),
        },
        {
          onSuccess: () => {
            refetch();
            refetchPresentation();
            notification.success({
              message: t('translation_success_created'),
            });
          },
          onError: handleSubmitError,
        },
      );
    }
  };
  const handleTranslateAll = async (isReplace: boolean) => {
    setIsTranslationLoading(true);
    translateAll(
      {
        data: knowledge as IKnowledgeUrl,
        values: { language_code_to: languageCode, override_existing_records: isReplace },
      },
      {
        onSuccess: response => setTranslationId(response.id),
        onError: handleTranslateError,
      },
    );
  };
  const clearAllFilters = () => {
    setFilterValues({});
    setChannelsValues([]);
    setStatusesValues([]);
    removeQuery([
      KnowledgePresentationService.queryKey,
      activeProjectId,
      knowledge?.externalId,
      queryString,
    ]);
  };

  const handleTranslatePresentation = async (isReplace: boolean) => {
    setIsTranslationLoading(true);
    translatePresentation(
      {
        id: activePresentation.id,
        data: knowledge as IKnowledgeUrl,
        values: { language_code_to: languageCode, override_existing_records: isReplace },
      },
      {
        onSuccess: response => setTranslationId(response.id),
        onError: handleTranslateError,
      },
    );
  };

  const handleVerifyAll = async () => {
    verifyAll(
      {
        data: knowledge as IKnowledgeUrl,
        values: { translation_status: 'verified' },
        languageId: findCurrentLanguage(),
      },
      {
        onSuccess: () => {
          refetch();
          refetchPresentation();
          notification.success({
            message: t('operation_completed'),
          });
        },
        onError: () => notification.error(unexpectedError),
      },
    );
  };

  const handleVerifyPresentation = async () => {
    verifyPresentation(
      {
        presentationId: activePresentation.id,
        languageId: findCurrentLanguage(),
        data: knowledge as IKnowledgeUrl,
        values: { translation_status: 'verified' },
      },
      {
        onSuccess: () => {
          refetch();
          refetchPresentation();
          notification.success({
            message: t('operation_completed'),
          });
        },
        onError: () => notification.error(unexpectedError),
      },
    );
  };

  if (!isFormSaved) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }

  return (
    <>
      {(isTranslationLoading || isTranslated) && (
        <AlertBanner
          showIcon={true}
          icon={
            isTranslated ? (
              <CheckIcon width={15} height={15} />
            ) : (
              <LoadingIcon width={15} height={15} />
            )
          }
          closable={false}
          type="warning"
          message={isTranslated ? t('translation_completed') : t('translation_in_progress')}
        />
      )}
      <StyledEditTranslations.Content>
        <Content imgBg={false}>
          <StyledEditTranslations.InnerContent>
            <Row gutter={[16, 0]}>
              <Col xs={10} md={8} xl={6}>
                <StyledEditTranslations.Navigation>
                  <StyledEditTranslations.Search
                    placeholder={t('search')}
                    prefix={<SearchIcon width={13} />}
                    onChange={e => setSearchValue(e.target.value)}
                    allowClear={{
                      clearIcon: <CloseIcon width={10} onClick={searchReset} />,
                    }}
                  />
                  {isFilter ? (
                    <Button
                      icon={<CloseIcon width={14} />}
                      onClick={() => setIsFilter(!isFilter)}
                    />
                  ) : (
                    <Button
                      icon={<FilterIcon width={14} />}
                      onClick={() => setIsFilter(!isFilter)}
                    />
                  )}
                </StyledEditTranslations.Navigation>
              </Col>
              <Col xs={14} md={16} xl={18}>
                <StyledEditTranslations.Navigation>
                  <LanguageDropdown
                    clearAllFilters={clearAllFilters}
                    isFormSaved={isFormSaved}
                    setLanguageCode={setLanguageCode}
                    languageResponse={languageResponse?.data || []}
                  />
                  <TranslationMenu
                    handleTranslateAll={handleTranslateAll}
                    handleVerifyAll={handleVerifyAll}
                  />
                </StyledEditTranslations.Navigation>
              </Col>
            </Row>

            <StyledEditTranslations.RowContent gutter={[16, 0]}>
              <Col xs={10} md={8} xl={6} style={{ overflow: 'hidden' }}>
                <SideList
                  setActivePresentation={setActivePresentation}
                  activePresentation={activePresentation}
                  presentations={combinedData}
                  isVisible={isFilter}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  clearAllFilters={clearAllFilters}
                  onLoadMore={loadMore}
                  isLoading={isLoading}
                  languageCode={languageCode}
                  isFormSaved={isFormSaved}
                />
                <Filters
                  isVisible={isFilter}
                  onClose={setIsFilter}
                  initialValues={filterValues}
                  setInitialValues={setFilterValues}
                  channels={channels}
                  clearAllFilters={clearAllFilters}
                />
              </Col>
              <Col xs={14} md={16} xl={18}>
                {isContentLoading && activePresentation.id && <CenteredSpinner height="40vh" />}
                {presentationResponse?.data.length === 0 && (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {!isContentLoading && activePresentation.id && (
                  <TranslationForm
                    setIsFormSaved={setIsFormSaved}
                    langCode={languageCode}
                    initialValues={formData || []}
                    onFinish={onSubmit}
                    setCurrentChannel={setCurrentChannel}
                    title={activePresentation.title}
                    handleVerifyPresentation={handleVerifyPresentation}
                    isDisabled={isTranslationLoading || isTranslated}
                    handleTranslatePresentation={handleTranslatePresentation}
                  />
                )}
              </Col>
            </StyledEditTranslations.RowContent>
          </StyledEditTranslations.InnerContent>
        </Content>
      </StyledEditTranslations.Content>
    </>
  );
};
