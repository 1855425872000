import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { AutomatedResponseTemplatesForm } from '../AutomatedResponseTemplatesForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IAutoRespTemplateValues, IAutoRespTemplate } from '../types';
import { AutomatedResponseTemplateService } from '../../../../../services/ai/automated-response-template';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { stripNonAlphanumeric } from '../../../../../utils/string';

export const AutomatedResponseTemplatesEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IAutoRespTemplate>(
    [AutomatedResponseTemplateService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      AutomatedResponseTemplateService.find(
        { id, apiData: aiIntegration as IAiIntegrationUrl },
        signal,
      ),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading } = useUpdate(
    [AutomatedResponseTemplateService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    AutomatedResponseTemplateService.update,
  );

  const onSubmit = async (
    data: Partial<IAutoRespTemplateValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { response_key } = data;
    data.response_key = stripNonAlphanumeric(response_key ?? '');
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('auto_resp_temp_updated_notify', {
                    name: response?.automated_response_template?.response_key,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response?.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data?.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IAutoRespTemplateValues = {
    response_key: data.response_key,
    body: data.body,
    automated_channel_id: data.automated_channel_id.toString(),
    dialogflow_agent_id: data.dialogflow_agent_id,
  };

  return (
    <Content imgBg={false}>
      <AutomatedResponseTemplatesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
