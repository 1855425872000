import { ISupportedLanguage } from './i18n.d';

export const supportedLanguages: ISupportedLanguage[] = [
  {
    lng: 'English',
    locale: 'en-GB',
  },
  {
    lng: 'Español',
    locale: 'es-ES',
  },
  {
    lng: 'Français',
    locale: 'fr-FR',
  },
  {
    lng: 'Danish',
    locale: 'da-DK',
  },
  {
    lng: 'Nederlands',
    locale: 'nl-NL',
  },
];
