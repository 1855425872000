import React, { useState } from 'react';
import { Select, SelectProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { getProviderIcon } from '../../../../../utils/knowledge';
import { getHighlightedText } from '../../../../../utils/formatText';
import { TruncatedString } from '../../../../../components/TruncatedString';
import { StyledOption } from './IntentSelector.styles';
import { IntentOption } from '../index';

const { Option } = Select;

type Props = SelectProps<string[], IntentOption>;

type OptionItem = {
  destination_type: string;
  label: string;
  destination_type_text: string;
  name: string;
};

const tagRender = (props: CustomTagProps) => {
  const { label, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="presentation-option-tag"
      onMouseDown={onPreventMouseDown}
      closable
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};
export const OptionIntentItem = ({
  option,
  searchText,
  labelWidth = 250,
}: {
  option: OptionItem;
  searchText?: string;
  labelWidth?: number;
}) => (
  <StyledOption.Container>
    <StyledOption.Icon>{getProviderIcon(option.destination_type, true, 22)}</StyledOption.Icon>
    <StyledOption.TextWrapper>
      <StyledOption.Label>
        <TruncatedString isShowFullOnHover width={labelWidth} maxWidth={labelWidth}>
          {searchText ? getHighlightedText(option.label, searchText) : option.label}
        </TruncatedString>
      </StyledOption.Label>
      <StyledOption.SubLabel>
        <TruncatedString isShowFullOnHover width={labelWidth} maxWidth={labelWidth}>
          {option.destination_type_text} {option.name && `| ${option.name}`}
        </TruncatedString>
      </StyledOption.SubLabel>
    </StyledOption.TextWrapper>
  </StyledOption.Container>
);

export const IntentSelector: React.FC<Props> = ({ options, ...rest }) => {
  const [searchText, setSearchText] = useState<string>('');

  const { t } = useTranslation('knowledge');

  return (
    <Select
      tagRender={tagRender}
      placeholder={t('select_intent')}
      onDropdownVisibleChange={() => setSearchText('')}
      mode="multiple"
      onSearch={setSearchText}
      filterOption={(inputValue, option) => {
        return option ? option.label.toLowerCase().includes(inputValue.toLowerCase()) : false;
      }}
      popupClassName="intents-dropdown"
      suffixIcon={null}
      {...rest}
    >
      {options?.map(option => (
        <Option key={option.value} value={option.value} label={option.label}>
          <OptionIntentItem option={option} searchText={searchText} />
        </Option>
      ))}
    </Select>
  );
};
