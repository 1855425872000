import { Row, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-circle.svg';

export const TitleWithToolTip = ({
  title,
  tooltip,
  large = false,
}: {
  title: string;
  tooltip: string;
  large?: boolean;
}): JSX.Element => (
  <Row align="middle">
    <StyledTitle large={large}>{title}</StyledTitle>
    <Tooltip title={tooltip}>
      <QuestionIcon width={14} height={14} color="#bdbdc1" style={{ marginLeft: 4 }} />
    </Tooltip>
  </Row>
);

const StyledTitle = styled.div<{ large: boolean }>`
  ${({ large }) =>
    large &&
    `
    font-size: 16px;
    font-weight: 600;
  `}
`;
