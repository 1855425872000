import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ActionButton } from '../ActionButton';
import { Trans } from 'react-i18next';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';

interface IDeleteConfirmation {
  modalVisible: boolean;
  setIsUnsavedChangesModal: any;
  title: string;
  description: string;
  btnText: string;
}

export const UnsavedChangesModal: React.FC<IDeleteConfirmation> = ({
  modalVisible,
  setIsUnsavedChangesModal,
  title,
  description,
  btnText,
}) => {
  const navigate = useNavigate();

  const handleOk = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    setIsUnsavedChangesModal(false);
  };

  return (
    <>
      <Modal
        width={408}
        title={
          <>
            <Cancel width={16} height={16} /> {title}
          </>
        }
        centered
        open={modalVisible}
        className="modal-danger"
        footer={[
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
          <ActionButton
            icon={<Cancel width={12} height={12} />}
            type="disable"
            text={btnText}
            key="disable"
            onClick={handleOk}
          />,
        ]}
        onCancel={handleCancel}
      >
        <Trans>{description}</Trans>
      </Modal>
    </>
  );
};
