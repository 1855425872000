import styled from '@emotion/styled';

export const StyledCheckboxGroup = styled.div`
  .ant-checkbox-wrapper {
    font-weight: 600;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: 24px;
  }
  .ant-form-item:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const StickyBar = styled.div`
  position: sticky;
  top: 100px;
  z-index: 1;
`;
