import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { ListWrapper, LabelItem, NumberBadge } from '../../../Knowledge.styles';

type LabelType = {
  labels: string[];
};

export const LabelTags = ({ labels }: LabelType): JSX.Element => {
  if (!!labels?.length) {
    const firstItem = labels[0];
    const otherItems = labels.slice(1).map((item: string, index: number) => {
      return {
        key: index,
        label: item,
        style: {
          cursor: 'default',
        },
      };
    });

    const onClick: MenuProps['onClick'] = ({ domEvent }) => {
      domEvent.stopPropagation();
    };

    return (
      <ListWrapper>
        <LabelItem>{firstItem}</LabelItem>
        {otherItems.length > 0 && (
          <Dropdown
            menu={{ items: otherItems, onClick }}
            placement="bottomLeft"
            trigger={['click']}
          >
            <NumberBadge onClick={e => e.stopPropagation()}>+{otherItems.length}</NumberBadge>
          </Dropdown>
        )}
      </ListWrapper>
    );
  }
  return <span>-</span>;
};
