import { getBadgeColor } from '../../../../../utils/knowledge';
import { IContentEntityTransStatus } from '../../../types';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { theme } from '../../../../../theme';

export const TranslationBadge: React.FC<{
  status: IContentEntityTransStatus;
}> = ({ status }) => {
  const badgeColor = status ? getBadgeColor(status) : '#fff';
  const outlinecolor = status ? theme.secondaryBg : '#CCCCCC';
  return (
    <StyledEditTranslations.BadgeColor
      color={badgeColor}
      outlinecolor={outlinecolor}
      style={{ margin: '2px 8px 0 8px' }}
      height={8}
      width={8}
    />
  );
};
