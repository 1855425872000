import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledEditor = styled.div<{ isTranslation?: boolean; isRichTextEditor?: boolean }>`
  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul {
    margin-top: 0;
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ql-toolbar.ql-snow {
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.lightBg};
    border: 1px solid ${props => props.theme.primaryColor};
    border-bottom: none;
  }
  .ql-container.ql-snow {
    border: 1px solid ${props => props.theme.primaryColor};
    border-top: ${props => (props.isRichTextEditor === false ? '' : 'none')};
  }
  .ql-container.ql-snow .ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 140px;
    background: white;
    height: ${({ isTranslation }) => (isTranslation ? '170px' : '300px')};
  }
  .ql-container.ql-bubble .ql-editor {
    background: white;
    height: 254px;
    border: 1px solid ${props => props.theme.primaryColor};
  }
  ${({ isTranslation, theme }) =>
    !isTranslation &&
    css`
      .params {
        margin-left: auto;
        width: 28px;
        height: 28px;
        background-color: ${theme.secondaryBg};
        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          & div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
          }
        }
      }
      .ant-dropdown-menu {
        max-height: 250px;
        overflow: auto;
      }
    `}

  .ql-snow.ql-toolbar button:hover {
    color: ${props => props.theme.primaryColor};
  }
  .ql-formats .ql-active {
    color: ${props => props.theme.primaryColor} !important;
    background-color: ${props => props.theme.secondaryBg};
  }
  .ql-snow.ql-toolbar button {
    height: 28px;
  }
  .ql-tooltip {
    left: 5px !important;
  }
`;

export const StyledEditorOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 2;
  opacity: 0.7;
  background-color: ${props => props.theme.lightBg};
`;

export const PlusIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
