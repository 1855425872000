import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { Trans, useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { CHANNELS } from '../../../../constants';
import { getHighlightedText } from '../../../../utils/formatText';
import { formatDate, getFormattedDate } from '../../../../utils/date';
import { getChannel, getFilterLabels, preparePresentationsData } from '../../../../utils/knowledge';
import { getNextPageQsStringify } from '../../../../utils/queryString';
import { getTableMiscData } from '../../../../utils/table';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { KnowledgePresentationService } from '../../../../services/knowledge/presentation';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { Paths } from '../../../../types/paths';
import { useDelete } from '../../../../hooks/useDelete';
import { useUpdate } from '../../../../hooks/useUpdate';
import { useColumnSearchProps } from '../../../../hooks/useColumnSearchProps';
import { useDateColumnFilter } from '../../../../hooks/useDateColumnFilter';
import { usePrefetchData } from '../../../../hooks/usePrefetchData';
import { Content } from '../../../../components/Content';
import { DataTable, TableParams } from '../../../../components/DataTable';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { TableAction } from '../../../../components/TableAction';
import { ActionButton } from '../../../../components/ActionButton';
import { TableToolBar } from '../../../../components/TableToolBar';
import {
  IDataNode,
  ColumnPropsKnowledge,
  IPresentationSummary,
  IPresentationsDeleteValues,
  IPresentations,
} from '../../types';
import { ListWrapper } from '../../Knowledge.styles';
import { DestinationTags } from './DestinationTags';
import { LabelTags } from './LabelTags';
import { useAccount } from '../../../../state/account';

const getParams = (params: TableParams) => ({
  page: params.pagination?.current,
  pageSize: params.pagination?.pageSize,
  'q[s]': params.sortOrder && `${params.sortField} ${params.sortOrder}`,
  'q[title_cont]': params.filters?.title?.[0],
  'q[content_entities_channel_channel_type_eq_any][]':
    params.filters?.channels?.length && params.filters.channels,
  'q[synchronisations_destination_entity_title_cont]': params.filters?.intents?.[0],
  'q[labels_name_cont]': params.filters?.labels?.length && params.filters.labels,
  'q[updated_at_gteq]':
    params.filters?.updated_at?.length && getFormattedDate(params.filters.updated_at[0], true),
  'q[updated_at_lteq]':
    params.filters?.updated_at?.length &&
    getFormattedDate(params.filters.updated_at[params.filters.updated_at.length - 1], false),
});

export const PresentationsList: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations();
  const [checkedColumns, setCheckedColumns] = useState<string[]>([
    '0-0-0',
    '0-0-1',
    '0-0-2',
    '0-0-3',
  ]);
  const [searchName, setSearchName] = useState('');
  const [searchIntent, setSearchIntent] = useState('');
  const [searchDate, setSearchDate] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: Number(searchParams.get('page')) || 1, pageSize: 10 },
  });
  const queryString = qs.stringify(getParams(tableParams), {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });
  const { data, isLoading } = useQuery<IPresentations>(
    [KnowledgePresentationService.queryKey, activeProjectId, knowledge?.externalId, queryString],
    ({ signal }) =>
      KnowledgePresentationService.get(knowledge as IKnowledgeUrl, queryString, signal),
    { enabled: !!knowledge },
  );

  usePrefetchData({
    totalPages: data?.pagination?.total_pages || 0,
    paginationPage: tableParams.pagination?.current || 0,
    queryKey: [
      KnowledgePresentationService.queryKey,
      activeProjectId,
      knowledge?.externalId,
      getNextPageQsStringify(getParams, tableParams),
    ],
    queryFn: ({ signal }) =>
      KnowledgePresentationService.get(
        knowledge as IKnowledgeUrl,
        getNextPageQsStringify(getParams, tableParams),
        signal,
      ),
  });

  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [KnowledgePresentationService.queryKey],
    KnowledgePresentationService.deletePresentation,
  );

  const { mutate: deleteItems } = useUpdate<{
    data: IKnowledgeUrl;
    values: IPresentationsDeleteValues;
  }>(
    [KnowledgePresentationService.queryKey, knowledge?.externalId as string, knowledge],
    KnowledgePresentationService.deletePresentations,
  );

  const presentations = useMemo(
    () => data && data.data.length && preparePresentationsData(data),
    [data],
  );
  const labels = useMemo(() => presentations && getFilterLabels(presentations), [presentations]);

  const treeSettings: IDataNode[] = [
    {
      title: t('channels'),
      key: '0-0-0',
      isChecked: true,
    },
    {
      title: t('intents'),
      key: '0-0-1',
      isChecked: true,
    },
    {
      title: t('labels'),
      key: '0-0-2',
      isChecked: true,
    },
    {
      title: t('date_modified'),
      key: '0-0-3',
      isChecked: true,
    },
  ];

  // ToolBar
  const ButtonCreate = (
    <ActionButton type="create" onClick={() => navigate(Paths.knowledgePresentationsCreate())} />
  );
  const TableToolbar = (
    <TableToolBar
      isDisabled={isLoading || isDeleteLoading || !data?.data?.length}
      treeData={treeSettings}
      checkedColumns={checkedColumns}
      setCheckedColumns={setCheckedColumns}
    />
  );

  const onRowHandler = ({ id }: IPresentationSummary) => ({
    onClick: () => navigate(`${id}`),
  });

  const handleDelete = useCallback(
    async id => !!knowledge && (await deleteItem({ id, data: knowledge })),
    [knowledge],
  );

  const handleSelectDelete = async () => {
    const values: IPresentationsDeleteValues = {};
    selectedRowKeys.forEach(item => {
      values[Object.keys(values).length] = {
        id: Number(item),
        _destroy: true,
      };
    });

    await deleteItems({
      data: knowledge as IKnowledgeUrl,
      values: values,
    });

    setSelectedRowKeys([]);
  };

  const onIntentsFilter: ColumnType<IPresentationSummary>['onFilter'] = (value, data) => {
    const filteredIntents = data.intents.filter(item =>
      item.name.toLowerCase().includes((value as string).toLowerCase()),
    );
    return !!filteredIntents.length;
  };

  const ButtonDelete = (
    <DeleteConfirmation
      id=""
      onActionDelete={handleSelectDelete}
      title={t('delete_presentation')}
      isTextButton
    >
      <Trans>
        {t('delete_description', {
          name: t('presentationDelete', { count: selectedRowKeys.length }),
        })}
      </Trans>
    </DeleteConfirmation>
  );

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 46,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys && setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const columns: ColumnPropsKnowledge<IPresentationSummary>[] = [
    {
      title: t('name'),
      dataIndex: 'title',
      align: 'left',
      isHidden: false,
      ...useColumnSearchProps('title', t('searchNamePlaceholder'), setSearchName),
      sorter: true,
      render: text => (searchName ? getHighlightedText(text, searchName) : text),
      width: 170,
      fixed: 'left',
    },
    {
      title: t('channels'),
      dataIndex: 'channels',
      align: 'left',
      isHidden: !checkedColumns.find(item => item === '0-0-0'),
      width: 150,
      filterSearch: true,
      filters: CHANNELS,
      filterIcon: () => <FilterIcon width={10} />,
      render: channels => (
        <ListWrapper>
          {CHANNELS.map(channel => {
            const isDisabled = channels?.includes(channel.value);
            return getChannel(channel.value, !isDisabled);
          })}
        </ListWrapper>
      ),
    },
    {
      title: t('intents'),
      dataIndex: 'intents',
      align: 'left',
      width: 220,
      isHidden: !checkedColumns.find(item => item === '0-0-1'),
      ...useColumnSearchProps(
        'intents',
        t('searchIntentsPlaceholder'),
        setSearchIntent,
        onIntentsFilter,
      ),
      render: data => <DestinationTags data={data} searchDestination={searchIntent} />,
    },
    {
      title: t('labels'),
      dataIndex: 'labels',
      align: 'left',
      isHidden: !checkedColumns.find(item => item === '0-0-2'),
      width: 120,
      filterSearch: true,
      filters: labels || [],
      filterIcon: () => <FilterIcon width={10} />,
      render: labels => <LabelTags labels={labels} />,
    },
    {
      title: t('date_modified'),
      dataIndex: 'updated_at',
      align: 'left',
      isHidden: !checkedColumns.find(item => item === '0-0-3'),
      width: 104,
      sorter: true,
      ...useDateColumnFilter('updated_at', searchDate, setSearchDate),
      render: date =>
        searchDate.length ? searchDate.find(item => item == formatDate(date)) : formatDate(date),
    },
    {
      width: 56,
      className: 'actions',
      key: 'actions',
      fixed: 'right',
      onCell: () => ({
        onClick: e => e.stopPropagation(),
      }),
      render: record => (
        <DeleteConfirmation
          id={record.id.toString()}
          onActionDelete={handleDelete}
          title={t('delete_presentation')}
        >
          <Trans>
            {t('delete_description', {
              name: record.name,
            })}
          </Trans>
        </DeleteConfirmation>
      ),
    },
  ];

  return (
    <Content imgBg={false} Actions={[TableToolbar, ButtonCreate]}>
      <TableAction
        selectedRows={selectedRowKeys.length}
        setSelectedRowKeys={setSelectedRowKeys}
        actions={[ButtonDelete]}
      />
      <DataTable
        loading={isLoading || isDeleteLoading}
        dataSource={presentations || []}
        columns={columns.filter(item => !item.isHidden)}
        onRow={onRowHandler}
        rowSelection={rowSelection}
        totalItems={data?.pagination?.total_count}
        onChange={(pagination, filters, sorter) => {
          setTableParams(getTableMiscData(pagination, filters, sorter));
        }}
      />
    </Content>
  );
};
