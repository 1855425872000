import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { IKnowledgeBases } from '../types';
import { StyledLink } from '../../../../../components/StyledLink/StyledLink.styles';
import { Paths } from '../../../../../types/paths';

export const KnowledgeBasesList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiKnowledgeBasesEdit({ id })),
  });
  const onCellHandlerDocument = (id: number) => navigate(Paths.aiKnowledgeBaseDocumentList({ id }));

  const columns: ColumnProps<IKnowledgeBases>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'display_name',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      fixed: 'left',
      width: '50%',
    },
    {
      title: t('dialogflow_agent'),
      onCell: onCellHandler,
      dataIndex: ['dialogflow_agent', 'display_name'],
      width: '30%',
    },
    {
      title: t('documents'),
      dataIndex: ['documents', 'count'],
      width: '17%',
      render: (value, record) => (
        <StyledLink onClick={() => onCellHandlerDocument(record.id)}>
          {t('document_count', { count: value })}
        </StyledLink>
      ),
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IKnowledgeBases>
      service={KnowledgeBasesService}
      columns={columns}
      getCreatePath={Paths.aiKnowledgeBasesCreate}
      deleteConfirmationTitle={t('delete_knowledge_base')}
      getDisplayName={record => record.display_name}
    />
  );
};
