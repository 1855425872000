import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { AnalyticsService } from '../../../../../services/analytics';
import { useDelete } from '../../../../../hooks/useDelete';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';
import { useAnalyticsData } from '../../../../../hooks/useAnalyticsData';

export const LooksList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { analytics: { url: analyticsUrl, externalId: projectId } = {} } = useIntegrations();
  const { data, isLoading } = useAnalyticsData({
    queryKey: AnalyticsService.queryKeyLooks,
    fetchDataFn: AnalyticsService.getLooks,
  });
  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [AnalyticsService.queryKeyLooks],
    AnalyticsService.deleteLook,
  );

  const handleDelete = React.useCallback(
    async reportUrl => analyticsUrl && (await deleteItem({ reportUrl, analyticsUrl })),
    [],
  );

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('looks')}</UppercaseLinkBold>
      <ReportsTable
        loading={!!projectId && (isLoading || isDeleteLoading)}
        reports={data || []}
        handleRemove={handleDelete}
        type="looks"
      />
    </Content>
  );
};
