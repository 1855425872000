import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { knowledgeQuery } from '../api';
import { IKnowledgeUrl } from '../../state/integrations';
import {
  IContentEntity,
  IContentEntityResponse,
  ContentEntityPayload,
} from '../../pages/Knowledge/types';

const queryKey = 'knowledge-entity';
const update = ({
  id,
  presentationID,
  data,
  values,
}: {
  id: string | number;
  presentationID: number;
  data: IKnowledgeUrl;
  values: ContentEntityPayload;
}): Promise<IContentEntity> => {
  return knowledgeQuery(`/presentations/${presentationID}/content_entities/${id}`, {
    method: 'PUT',
    data: {
      ...data,
      payload: {
        data: {
          attributes: { ...values },
          type: 'content_entities',
        },
      },
    },
  });
};

const get = async (
  data: IKnowledgeUrl,
  id: number,
  signal: QueryFunctionContext['signal'],
): Promise<IContentEntityResponse> => {
  return await knowledgeQuery(`/presentations/${id}/content_entities?page=1&pageSize=ALL`, {
    data,
    signal,
  });
};

const create = ({
  presentationID,
  data,
  values,
}: {
  presentationID: string;
  data: IKnowledgeUrl;
  values: ContentEntityPayload;
}): Promise<IContentEntity> => {
  return knowledgeQuery(`/presentations/${presentationID}/content_entities`, {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: { ...values },
          type: 'content_entities',
        },
      },
    },
  });
};

export const KnowledgeEntityService = {
  update,
  get,
  create,
  queryKey,
};
