import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { ContextForm } from '../ContextForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IContextValues } from '../types';
import { ContextsService } from '../../../../../services/ai/contexts';
import { Content } from '../../../../../components/Content';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { transformParameters, retainUnderscoreHyphenPercent } from '../../../../../utils/ai';
import { ActionButton } from '../../../../../components/ActionButton';

interface Props {
  isModal?: boolean;
  handleCloseModal?: () => void;
  handleCreateContext?: () => void;
}

export const ContextNew: React.FC<Props> = ({ isModal, handleCloseModal, handleCreateContext }) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [ContextsService.queryKey],
    ContextsService.create,
  );

  const onSubmit = async (
    values: IContextValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { name, duration, parameters } = values;
    const data = {
      name: retainUnderscoreHyphenPercent(name),
      duration,
      parameters: transformParameters(parameters),
    };
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>{t('context_created_notify', { name: data.context.name })}</Trans>
            ),
          });
          isModal && handleCreateContext ? handleCreateContext() : navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification.error(unexpectedError);
        },
      },
    );
  };

  const initialValues: IContextValues = {
    name: '',
    duration: 1,
    parameters: [],
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <ContextForm {...formProps} isModal={isModal} handleCloseModal={handleCloseModal} />
  ) : (
    <Content imgBg={false}>
      <ContextForm {...formProps} />
    </Content>
  );
};
