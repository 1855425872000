import React from 'react';
import { Button as AntButton, ButtonProps } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as ArrowLeft } from '../../assets/icons/angle-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/angle-right.svg';
import { ReactComponent as ArrowLeftRight } from '../../assets/icons/arrow-left-right.svg';
import { ReactComponent as Ban } from '../../assets/icons/ban.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash-alt.svg';
import { ReactComponent as PaperPlane } from '../../assets/icons/paper-plane.svg';
import { ReactComponent as Rotate } from '../../assets/icons/rotate-solid.svg';
import { ReactComponent as Manage } from '../../assets/icons/pen.svg';
import { ReactComponent as Duplicate } from '../../assets/icons/copy.svg';

export type ActionButtonType =
  | 'back'
  | 'create'
  | 'edit'
  | 'delete'
  | 'cancel'
  | 'disable'
  | 'enable'
  | 'next'
  | 'save'
  | 'invite'
  | 'sync'
  | 'translate'
  | 'manage'
  | 'duplicate';

interface Props extends Omit<ButtonProps, 'children' | 'type'> {
  type: ActionButtonType;
  text?: string;
}

export const ActionButton: React.FC<Props> = props => {
  const { type, text, ...other } = props;
  const { t } = useTranslation();

  const TEXT_STATES = {
    back: t('back'),
    cancel: t('cancel'),
    create: t('create'),
    delete: t('delete'),
    disable: t('disable'),
    duplicate: t('duplicate'),
    enable: t('enable'),
    edit: t('update'),
    next: t('next'),
    save: t('save_changes'),
    invite: t('invite'),
    sync: t('sync'),
    translate: t('translate'),
    manage: t('manage'),
  };

  return (
    <Button
      type={THEME_STATES[type]}
      icon={ICON_STATES[type]}
      danger={type === 'disable' || type === 'delete'}
      isEnabled={type === 'enable'}
      {...other}
    >
      {text ? text : TEXT_STATES[type]}
    </Button>
  );
};

const ICON_STATES = {
  back: <ArrowLeft width={8} height={18} />,
  cancel: <Cancel width={12} height={12} />,
  create: <Plus width={13} height={14} />,
  delete: <Trash width={13} height={14} />,
  disable: <Ban width={13} height={14} />,
  duplicate: <Duplicate width={13} height={14} />,
  enable: <Check width={13} height={14} />,
  edit: <Check width={13} height={14} />,
  next: <ArrowRight width={8} height={18} />,
  save: <Check width={13} height={14} />,
  invite: <PaperPlane width={13} height={14} />,
  sync: <Rotate width={13} height={14} />,
  translate: <ArrowLeftRight width={14} height={14} />,
  manage: <Manage width={14} height={14} />,
};

const THEME_STATES: {
  [key: string]: ButtonType;
} = {
  back: 'default',
  cancel: 'default',
  create: 'primary',
  delete: 'primary',
  disable: 'primary',
  duplicate: 'primary',
  enable: 'primary',
  edit: 'primary',
  next: 'primary',
  save: 'primary',
  invite: 'primary',
  sync: 'primary',
  translate: 'primary',
  manage: 'primary',
};

export const Button = styled(AntButton, {
  shouldForwardProp: propName => propName !== 'isEnabled',
})<{ isEnabled: boolean }>(
  ({ isEnabled, theme }) =>
    isEnabled &&
    css`
      background-color: ${theme.successColor};
      color: #fff;
    `,
);
