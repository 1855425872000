import React from 'react';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Content } from '../../../../../components/Content';
import { ExternalEndpointsForm } from '../ExternalEndpointsForm';
import { ActionButton } from '../../../../../components/ActionButton';
import { useIntegrations } from '../../../../../state/integrations';
import { useCreate } from '../../../../../hooks/useCreate';
import { ExternalEndpointsService } from '../../../../../services/ai/external-endpoints';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { IExternalEndpointValues } from '../types';

export const ExternalEndpointsNew = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [ExternalEndpointsService.queryKey],
    ExternalEndpointsService.create,
  );

  const onSubmit = async (
    data: IExternalEndpointValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('external_endpoint_created_notify', { name: data.outbound_contact_uri.name })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues = {
    name: '',
    endpoint: '',
    router_id: undefined,
  };

  return (
    <Content imgBg={false}>
      <ExternalEndpointsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
