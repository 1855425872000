import { Select, SelectProps } from 'antd';
import { DFAgentType } from '../DFAgentTag';
import { cxLocales, esLocales } from '../../constants';

interface Props extends SelectProps {
  platformEdition?: DFAgentType;
}

export const LocaleSelector: React.FC<Props> = ({ platformEdition, ...other }) => {
  const locale = platformEdition === 'ES' ? esLocales : cxLocales;
  const options = locale?.map(item => ({
    label: item,
    value: item,
  }));

  return <Select options={options} showSearch optionFilterProp="label" {...other}></Select>;
};
