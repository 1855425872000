import React, { useState } from 'react';
import { ActionButton } from '../ActionButton';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';
import { ConfirmationModal } from '../ConfirmationModal';

export interface IDisableConfirmation {
  onDisable?: () => void;
  title: string;
  description: string;
  buttonText?: string;
  confirmationText?: string;
}

export const DisableConfirmation: React.FC<IDisableConfirmation> = ({
  onDisable,
  title,
  description,
  buttonText,
  confirmationText,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleOk = async () => onDisable?.();
  const handleCancel = () => setVisible(false);
  const showModal = () => setVisible(true);

  return (
    <>
      <ActionButton type="disable" text={buttonText} onClick={showModal} />
      <ConfirmationModal
        handleCancel={handleCancel}
        title={title}
        visible={visible}
        onDisable={handleOk}
        confirmationText={confirmationText}
        description={description}
        titleIcon={<BanIcon width={16} height={16} />}
        buttonType="disable"
      />
    </>
  );
};
