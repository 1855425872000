import React, { useState } from 'react';
import { Divider, Form, Input, Select, Switch, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IScheduleValues } from '../../types';
import { formItemClass } from '../../../../theme';
import { DatePicker, RangePicker, TimePicker } from '../../../../components/DatePicker';
import { Space } from '../../../../components/Space';
import { FixedBottomBar } from '../../../../components/FixedBottomBar';
import { ActionButton } from '../../../../components/ActionButton';
import { FormTimezoneSelector } from '../../../../components/FormTimezoneSelector';
import { Description, FormTimePicker, TimeSchedule, To } from '../../Pulse.styles';
import { Weekday, days } from '../../../../constants';

const { Option } = Select;

interface IProps {
  initialValues?: Partial<IScheduleValues>;
  submitButton: React.ReactElement;
}

export const SchedulesForm: React.FC<IProps> = ({ initialValues, submitButton }) => {
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [enabled, setEnabled] = useState<boolean | undefined>(initialValues?.enabled);
  const [recording, setRecording] = useState<boolean | undefined>(initialValues?.recording);

  const handleScheduleEnabled = (value: boolean) => {
    setEnabled(value);
  };

  const handleRecording = (value: boolean) => {
    setRecording(value);
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item name="enabled" label={t('enabled')} className={formItemClass.base}>
        <Switch onChange={handleScheduleEnabled} checked={enabled} />
      </Form.Item>
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        name="serviceName"
        label={t('service_name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('service_name')} />
      </Form.Item>
      <Divider />
      <Space>
        <Form.Item
          name="fromN"
          label={t('caller_from')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('caller_from')} />
        </Form.Item>
        <Form.Item
          name="toN"
          label={t('caller_to')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('caller_to')} />
        </Form.Item>
      </Space>
      <Form.Item
        name="pathCode"
        label={t('path_code')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        tooltip={t('path_code_tooltip')}
      >
        <Input placeholder={t('path_code')} />
      </Form.Item>
      <Divider />
      <b>{t('schedule_period')} </b>
      <Description>{t('schedule_period_description')}</Description>

      <Form.Item
        name="timezone"
        label={t('timezone')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
      >
        <FormTimezoneSelector placeholder={t('select_timezone')} />
      </Form.Item>
      <Space>
        <Form.Item
          name="startDate"
          label={t('start_date')}
          valuePropName={'date'}
          className={formItemClass.base}
          rules={[
            { required: true },
            {
              validator: async (_, startDate) => {
                const endDate = form.getFieldValue('endDate');
                if (endDate != null && endDate <= startDate) {
                  return Promise.reject(t('error_start_date'));
                }
              },
            },
          ]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder={t('start_date')}
            showToday={false}
            onChange={() => {
              form.setFieldValue('exception_period', undefined);
            }}
          />
        </Form.Item>
        <Form.Item
          name="endDate"
          label={t('end_date')}
          valuePropName={'date'}
          className={formItemClass.base}
          rules={[
            { required: true },
            {
              validator: async (_, endDate) => {
                const startDate = form.getFieldValue('startDate');
                if (startDate != null && endDate <= startDate) {
                  return Promise.reject(t('error_end_date'));
                }
              },
            },
          ]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder={t('end_date')}
            showToday={false}
            onChange={() => {
              form.setFieldValue('exception_period', undefined);
            }}
          />
        </Form.Item>
      </Space>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.exception_period !== currentValues.exception_period
        }
      >
        {({ getFieldValue }) => (
          <Form.Item
            name="exception_period"
            label={t('exception_period')}
            className={formItemClass.base}
            rules={[{ required: false }]}
          >
            <RangePicker
              placeholder={[t('start_date'), t('end_date')]}
              disabledDate={current =>
                current.subtract(1, 'days') < getFieldValue('startDate') ||
                current.isAfter(getFieldValue('endDate'))
              }
            />
          </Form.Item>
        )}
      </Form.Item>
      <Divider />
      <b>{t('call_recurrence')} </b>
      <Description>{t('call_recurrence_description')}</Description>
      <Form.Item
        name="repeatPeriod"
        label={t('repeat_call')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <InputNumber placeholder={t('repeat_call')} min={0} />
      </Form.Item>
      <Form.Item
        name="retry"
        label={t('retry')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Divider />
      <div>
        {Object.keys(days).map((day, index) => (
          <TimeSchedule key={day}>
            <Form.Item
              className={formItemClass.xsmall}
              style={{ marginTop: index === 0 ? '25px' : '' }}
            >
              <b className={day}>{days[day as Weekday]}</b>
            </Form.Item>
            <Form.Item
              name={day}
              className={formItemClass.hidden}
              valuePropName="checked"
              style={{ marginTop: index === 0 ? '25px' : '' }}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues[day] !== currentValues[day]}
            >
              {({ getFieldValue }) => (
                <FormTimePicker
                  style={{ margin: '0 12px 20px 20px' }}
                  label={index === 0 && t('start-time')}
                  name={`start_time_${day}`}
                  className={formItemClass.xsmall}
                  rules={[
                    { required: getFieldValue(day), message: t('start_time_requiered') },
                    {
                      validator: async (_, startTime) => {
                        const endTime = getFieldValue(`end_time_${day}`);
                        if (endTime && getFieldValue(day) && endTime <= startTime) {
                          return Promise.reject(t('error_start_time'));
                        }
                      },
                    },
                  ]}
                >
                  <TimePicker showNow={false} disabled={getFieldValue(day) !== true} />
                </FormTimePicker>
              )}
            </Form.Item>
            <To firstIndex={index === 0 ? 'true' : 'false'}>{t('to')}</To>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues[day] !== currentValues[day]}
            >
              {({ getFieldValue }) => (
                <FormTimePicker
                  label={index === 0 && t('end-time')}
                  style={{ margin: '0 20px 20px 12px' }}
                  name={`end_time_${day}`}
                  className={formItemClass.xsmall}
                  rules={[
                    {
                      required: getFieldValue(day),
                      message: t('end_time_requiered'),
                    },

                    {
                      validator: async (_, endTime) => {
                        const startTime = getFieldValue(`start_time_${day}`);
                        if (startTime && getFieldValue(day) && endTime <= startTime) {
                          return Promise.reject(t('error_end_time'));
                        }
                      },
                    },
                  ]}
                >
                  <TimePicker showNow={false} disabled={getFieldValue(day) !== true} />
                </FormTimePicker>
              )}
            </Form.Item>
          </TimeSchedule>
        ))}
      </div>
      <Divider />
      <Form.Item
        name="recording"
        label={t('recording')}
        className={formItemClass.small}
        rules={[{ required: false }]}
        tooltip={t('recording_tooltip')}
      >
        <Switch onChange={handleRecording} checked={recording} />
      </Form.Item>
      <Form.Item
        name="executionType"
        label={t('execution_type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
        tooltip={t('execution_type_tooltip')}
      >
        <Select>
          <Option value="twilioflow">{t('flow_sid')}</Option>
          <Option value="twiml">{t('twiml')}</Option>
        </Select>
      </Form.Item>
      {Form.useWatch('executionType', form) === 'twilioflow' && (
        <Form.Item
          name="flowSid"
          label={t('flow_sid')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('flow_sid')} />
        </Form.Item>
      )}
      {Form.useWatch('executionType', form) === 'twiml' && (
        <>
          <Form.Item
            name="twiml"
            label={t('twiml')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder={t('twiml')} />
          </Form.Item>
          <Form.Item
            name="responseTimeout"
            label={t('response_timeout')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={t('response_timeout')} min={0} />
          </Form.Item>
        </>
      )}
      <FixedBottomBar>
        <ActionButton type="cancel" onClick={() => navigate(-1)} />
        {submitButton}
      </FixedBottomBar>
    </Form>
  );
};
