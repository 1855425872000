import { useFlags } from 'launchdarkly-react-client-sdk';
import { Divider, Form, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { LocaleSelector } from '../../../../../../components/LocaleSelector';
import { TextToSpeechSelector } from '../../../../../../components/TextToSpeechSelector';
import { speechModelOptions, speechModelVariantOptions } from '../../../../../../constants';
import { DFAgentType } from '../../../../../../components/DFAgentTag';

const { Title } = Typography;

export const AudioConfig = ({ platform }: { platform: DFAgentType }): JSX.Element => {
  const { t } = useTranslation('ai');
  const { audioConfig } = useFlags();
  const { setFieldValue } = Form.useFormInstance();

  const speechModelVariantDisable = (value: string) => {
    if (value === 'default') {
      setFieldValue(['audio_config_attributes', 'speech_model_variant'], undefined);
      return true;
    }
    return false;
  };

  return (
    <>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('audio_config')}
      </Title>
      <Space>
        <Form.Item
          name="locale"
          label={t('locale')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <LocaleSelector placeholder={t('select_locale')} platformEdition={platform} />
        </Form.Item>
        <Form.Item
          name="text_to_speech_config_id"
          label={t('text_to_speech_profile')}
          className={formItemClass.base}
        >
          <TextToSpeechSelector placeholder={t('select_text_to_speech_profile')} />
        </Form.Item>
      </Space>
      {audioConfig && (
        <Space>
          <Form.Item
            name={['audio_config_attributes', 'speech_model']}
            label={t('speech_model')}
            className={formItemClass.base}
          >
            <Select placeholder={t('select_speech_model')} options={speechModelOptions} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.audio_config_attributes?.speech_model !==
              currentValues?.audio_config_attributes?.speech_model
            }
          >
            {({ getFieldValue }) => {
              const speechModel = getFieldValue(['audio_config_attributes', 'speech_model']);
              return (
                speechModel && (
                  <Form.Item
                    name={['audio_config_attributes', 'speech_model_variant']}
                    label={t('speech_model_variant')}
                    className={formItemClass.base}
                  >
                    <Select
                      placeholder={t('select_variant')}
                      options={speechModelVariantOptions}
                      disabled={speechModelVariantDisable(speechModel)}
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
        </Space>
      )}
    </>
  );
};
