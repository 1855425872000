import React from 'react';
import { Form, Input, Select, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AiIntegrationParams } from '../../../../../services/api';
import { useIntegrations } from '../../../../../state/integrations';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IAutoRespTemplateValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { AutomatedChannelsService } from '../../../../../services/ai/automated-channel';
import { DFAgentSelector } from '../../../../../components/DFAgentSelector';

interface Props {
  initialValues: IAutoRespTemplateValues;
  onSubmit: (values: IAutoRespTemplateValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const AutomatedResponseTemplatesForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const { aiIntegration } = useIntegrations();

  const { data } = useQuery(
    [AutomatedChannelsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      AutomatedChannelsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={validateMessages}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="response_key"
        label={t('response_key')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('ph_response_key')} />
      </Form.Item>
      <Form.Item name="body" label={t('body')} rules={[{ required: true }]}>
        <Input.TextArea placeholder={t('enter_message')} style={{ height: 76 }} />
      </Form.Item>
      <Space>
        <Form.Item
          name="automated_channel_id"
          label={t('automated_channel')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select placeholder={t('select_channel')}>
            {data?.map(item => (
              <Select.Option key={item.id.toString()} value={item.id.toString()}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="dialogflow_agent_id"
          label={t('dialogflow_agent')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <DFAgentSelector placeholder={t('select_agent')} platformEdition={'ES'} />
        </Form.Item>
      </Space>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
