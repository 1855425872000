import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { getProviderIcon } from '../../../../../utils/knowledge';
import { ListWrapper, NumberBadge } from '../../../Knowledge.styles';
import { IDestinationValue } from '../../../types';
import { DestinationValueTitle, DestinationValueWrap } from './ParameterValue.styles';

type ValueType = {
  value: string;
  destinationValues: IDestinationValue[];
};

export const ParameterValue = ({ value, destinationValues }: ValueType): JSX.Element => {
  const items = !!destinationValues.length
    ? destinationValues.map(item => ({
        key: item.id,
        label: (
          <DestinationValueWrap>
            <DestinationValueTitle>
              {getProviderIcon(item.configurable.destination_type)} {item.configurable.name}
            </DestinationValueTitle>
            <span>{item.value}</span>
          </DestinationValueWrap>
        ),
        style: {
          cursor: 'default',
        },
      }))
    : [];

  const onClick: MenuProps['onClick'] = ({ domEvent }) => {
    domEvent.stopPropagation();
  };

  return (
    <ListWrapper>
      {value}
      {!!destinationValues.length && (
        <Dropdown menu={{ items, onClick }} placement="bottomLeft" trigger={['click']}>
          <NumberBadge onClick={e => e.stopPropagation()}>+{destinationValues.length}</NumberBadge>
        </Dropdown>
      )}
    </ListWrapper>
  );
};
