import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { DFAgentTag } from '../../../../../components/DFAgentTag';
import { Paths } from '../../../../../types/paths';
import { IDFAgent } from '../types';

export const NLUProvidersList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiNLUProvidersEdit({ id })),
  });

  const columns: ColumnProps<IDFAgent>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'display_name',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      fixed: 'left',
      width: '63%',
    },
    {
      title: t('ccai_enabled'),
      onCell: onCellHandler,
      dataIndex: 'ccai_enabled',
      width: '17%',
      render: record => <IconIndicator enabled={record} />,
    },
    {
      title: t('platform_edition'),
      onCell: onCellHandler,
      dataIndex: 'platform_edition',
      width: '17%',
      render: platform => platform && <DFAgentTag type={platform}>{platform}</DFAgentTag>,
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IDFAgent>
      service={NLUProvidersService}
      columns={columns}
      getCreatePath={Paths.aiNLUProvidersCreate}
      deleteConfirmationTitle={t('delete_nlu_provider')}
      getDisplayName={record => record.display_name}
    />
  );
};
