import React, { useState } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { SelectLocalesDropdown, LocaleDropdownButton, LocalesDropdownMenu } from '../Login.styles';
import { useDropdown } from '../../../hooks/useDropdown';
import { supportedLanguages } from '../../../locales/supportedLanguages';

export const SelectLocal: React.FC = () => {
  const { i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState<string>(i18n.language);
  const { visible, toggleDropdown, dropdownRef } = useDropdown<HTMLButtonElement>(false);

  const items = supportedLanguages.map(lng => ({ key: lng.locale, label: lng.lng }));

  const handleLocaleSelect = async (value: MenuInfo) => {
    setCurrentLocale(value.key);
    await i18n.changeLanguage(value.key);
    toggleDropdown();
  };

  return (
    <SelectLocalesDropdown
      menu={{
        items,
        selectedKeys: [currentLocale],
        onClick: handleLocaleSelect,
      }}
      dropdownRender={menu => <LocalesDropdownMenu>{menu}</LocalesDropdownMenu>}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
    >
      <LocaleDropdownButton type="button" ref={dropdownRef} onClick={toggleDropdown}>
        {i18n.language.substring(0, 2)}
      </LocaleDropdownButton>
    </SelectLocalesDropdown>
  );
};
