import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '../../../../../theme';

const Icon = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.lightBg};
  color: ${({ theme }) => theme.primaryColor};
  border-radius: 50%;
  transition: background-color 0.3s;
  .icon-dots {
    width: 18px;
    height: 18px;
  }
`;

export const dropdownStyles = css`
  .intents-dropdown {
    .ant-select-item {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      ${Icon} {
        background-color: #fff;
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      ${Icon} {
        background-color: ${theme.primaryColor};
        color: #fff;
      }
    }
  }
`;

const Container = styled.div`
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

const SubLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${props => props.theme.inputPlaceholderColor};
  margin-bottom: 2px;
`;

const TextWrapper = styled.div`
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const StyledOption = {
  Container,
  Icon,
  Label,
  SubLabel,
  TextWrapper,
};
