import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { NoWrap } from '../../../../../components/DataTable/DataTable.styles';
import { AgenticModelsService } from '../../../../../services/ai/agentic-models';
import { formatDate } from '../../../../../utils/date';
import { IAgenticModel } from '../types';
import { Paths } from '../../../../../types/paths';
import { aiProviders } from '../../../../../constants';

export const AgenticModelsList: React.FC = () => {
  const { t } = useTranslation(['ai', 'knowledge']);
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiAgenticModelsEdit({ id })),
  });

  const columns: ColumnProps<IAgenticModel>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '40%',
    },
    {
      title: t('provider'),
      onCell: onCellHandler,
      dataIndex: 'provider',
      width: '20%',
      render: provider => {
        const value = aiProviders.find(el => el.value === provider);
        return value?.label;
      },
    },
    {
      title: t('model'),
      onCell: onCellHandler,
      dataIndex: 'model',
      width: '20%',
    },
    {
      title: t('date_modified', { ns: 'knowledge' }),
      onCell: onCellHandler,
      dataIndex: 'updated_at',
      width: 80,
      render: date => (date ? <NoWrap>{formatDate(date)}</NoWrap> : ''),
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IAgenticModel>
      service={AgenticModelsService}
      columns={columns}
      getCreatePath={Paths.aiAgenticModelsCreate}
      deleteConfirmationTitle={t('delete_model')}
      getDisplayName={record => record.name}
    />
  );
};
