import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { consoleManagerQuery } from './api';
import { ILang } from '../hooks/useLanguages';

const queryKey = 'languages';

const get = async ({ signal }: QueryFunctionContext): Promise<ILang[]> =>
  consoleManagerQuery('/languages', { signal });

export const LanguagesService = {
  queryKey,
  get,
};
