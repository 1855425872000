import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ArrowsIcon } from '../../../../../assets/icons/arrow-left-right.svg';
import { ActionButton } from '../../../../../components/ActionButton';
import { StyledEditTranslations } from '../EditTranslations.styles';

export interface ITranslateConfirmation {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  onTranslate: (value: boolean) => void;
  title: string;
  description: string;
  buttonOkText?: string;
  isReplaceContent?: boolean;
}

export const TranslateConfirmation: React.FC<ITranslateConfirmation> = ({
  isVisible,
  setIsVisible,
  onTranslate,
  title,
  description,
  buttonOkText,
  isReplaceContent,
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation('knowledge');

  const handleOk = () => {
    setIsVisible(false);
    onTranslate(checked);
    setChecked(false);
  };
  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      width={408}
      title={
        <>
          <ArrowsIcon width={16} height={16} /> {title}
        </>
      }
      centered
      open={isVisible}
      styles={{ body: { paddingBottom: '16px' } }}
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
        <ActionButton
          type="translate"
          key="translate"
          text={buttonOkText && t(buttonOkText)}
          onClick={handleOk}
        />,
      ]}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <p>{description}</p>
      {isReplaceContent && (
        <Checkbox name="replace" checked={checked} onChange={() => setChecked(!checked)}>
          <StyledEditTranslations.HighlightedText>
            <Trans t={t}>{t('replace_all_confirmation')}</Trans>
          </StyledEditTranslations.HighlightedText>
        </Checkbox>
      )}
    </Modal>
  );
};
