import type { SorterResult } from 'antd/es/table/interface';
import { TableParams } from '../components/DataTable';

export const getTableMiscData = (
  pagination: TableParams['pagination'],
  filters: TableParams['filters'],
  sorter: SorterResult<any> | SorterResult<any>[],
): TableParams => ({
  pagination,
  sortOrder: !Array.isArray(sorter) ? sorter.order && setSortOrderName(sorter.order) : undefined,
  sortField: !Array.isArray(sorter) ? sorter.field : undefined,
  filters,
});

const setSortOrderName = (name: string): string | undefined => {
  switch (name) {
    case 'descend':
      return 'desc';
    case 'ascend':
      return 'asc';
    default:
      return undefined;
  }
};
