import React, { useState } from 'react';
import { Form, Input, Switch, Divider, Typography, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { Space } from '../../../../../components/Space';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IKnowledgeBaseDocumentValues, KnowledgeBasesDocTypes } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { UploadButton } from '../../../../../components/UploadButton';
import { normalizeFile, getBase64 } from '../../../../../utils/files';
import { InputWithRemove } from '../../../../../components/InputWithRemove';
import { AddField } from '../../../../../components/AddField';
import { useWindowDimensions } from '../../../../../utils/useWindowDimensions';
import { KnowledgeType } from '../../../../../constants';

const { Title } = Typography;

interface Props {
  initialValues: IKnowledgeBaseDocumentValues;
  onSubmit: (values: IKnowledgeBaseDocumentValues, isCreateAnother: boolean) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
  isModal?: boolean;
  handleCloseModal?: () => void;
}

export const KnowledgeBaseDocumentForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
  isModal,
  handleCloseModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [type, setType] = useState<string>(initialValues.knowledge_types ?? '');
  const [source, setSource] = useState<boolean>(false);
  const [fileUpload, setfileUpload] = useState<boolean>(false);
  const fileType = type === KnowledgeBasesDocTypes.FAQ ? 'CSV' : 'PDF';
  const [isMetadataError, setIsMetadataError] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const handleTypeChange = (value: string) => setType(value);

  const handleSourceInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value ? setSource(true) : setSource(false);
  };

  const handleFileUpload = async () => {
    try {
      await form.validateFields(['raw_content']);
      form.getFieldValue('raw_content').length > 0 ? setfileUpload(true) : setfileUpload(false);
    } catch {
      setfileUpload(false);
    }
  };

  const handleCancel = () => {
    if (isModal) {
      handleCloseModal();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue())) {
        openModal();
      } else {
        navigate(-1);
      }
    }
  };

  const createMedataObject = (metadataList: any) => {
    const metadata = {};
    metadataList.map(({ name, value }: { name: string; value: string }) => {
      Object.assign(metadata, { [name]: value });
    });
    return metadata;
  };

  const onFinish = async () => {
    const formData = form.getFieldsValue();
    const submitButtonType = document.activeElement?.getAttribute('data-action') ? true : false;

    if (formData.raw_content) {
      delete formData.content_uri;
      formData.mime_type = formData.raw_content[0].type;
      formData.raw_content = await getBase64(formData.raw_content[0].originFileObj).then(
        (res: string) => res.split(',')[1],
      );
    }
    if (formData.content_uri) {
      delete formData.raw_content;
      switch (formData.knowledge_types) {
        case KnowledgeBasesDocTypes.FAQ:
          formData.mime_type = 'text/html';
          break;
        case KnowledgeBasesDocTypes.EXTRACTIVE_QA:
          formData.mime_type = 'application/pdf';
          break;
        default:
          formData.mime_type = 'text/html';
      }
    }
    if (!isEditing) {
      formData.knowledge_types.length > 0
        ? (formData.knowledge_types = [formData.knowledge_types])
        : [''];
      formData.metadata
        ? (formData.metadata = createMedataObject(formData.metadata))
        : (formData.metadata = {});
    }
    onSubmit(formData, submitButtonType);
  };

  const checkMetadataError = async () => {
    const parameters = form.getFieldValue('metadata');
    if (parameters && parameters.length > 0) {
      const lastIndex = parameters.length - 1;
      const fieldToValidate =
        width <= 873 ? ['metadata', lastIndex, 'value'] : ['metadata', lastIndex, 'name'];

      try {
        await form.validateFields([fieldToValidate]);
        setIsMetadataError(false);
      } catch ({ errorFields }) {
        errorFields && setIsMetadataError(true);
      }
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinishFailed={checkMetadataError}
      onFinish={onFinish}
    >
      <Form.Item
        name="display_name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('document_name')} />
      </Form.Item>
      {!isEditing && (
        <Space>
          <Form.Item
            name="knowledge_types"
            label={t('knowledge_type')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('select_type')}
              options={KnowledgeType}
              onChange={handleTypeChange}
            />
          </Form.Item>
          {type && (
            <Form.Item
              name="enable_auto_reload"
              valuePropName="checked"
              initialValue={false}
              label={t('auto_reload')}
            >
              <Switch disabled={type === KnowledgeBasesDocTypes.FAQ ? false : true} />
            </Form.Item>
          )}
        </Space>
      )}
      {type.length > 0 && (
        <>
          <Divider className={formItemClass.full} />
          <Form.Item
            name="content_uri"
            label={t('source')}
            rules={[{ required: !fileUpload }]}
            className={formItemClass.full}
          >
            <Input.TextArea
              placeholder={t('enter_source', { filetype: fileType })}
              onChange={handleSourceInput}
              disabled={fileUpload}
            />
          </Form.Item>
          <Form.Item
            name="raw_content"
            label={t('upload_type', { filetype: fileType })}
            className={formItemClass.base}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
          >
            <UploadButton accept={`.${fileType}`} disabled={source} onChange={handleFileUpload} />
          </Form.Item>
          <Divider className={formItemClass.full} />
          <Title level={5} type="secondary">
            {t('metadata')}
          </Title>
          <Form.List name="metadata">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space key={field.key}>
                    <Form.Item
                      name={[index, 'name']}
                      label={t('name')}
                      className={formItemClass.base}
                      rules={[{ required: true }]}
                    >
                      <Input
                        onChange={() => index === fields.length - 1 && checkMetadataError()}
                        placeholder={t('metadata_param_name')}
                      />
                    </Form.Item>
                    <InputWithRemove
                      name={[index, 'value']}
                      onClick={() => {
                        remove(index);
                        checkMetadataError();
                      }}
                      placeholder={t('metadata_param_value')}
                      label={t('value')}
                      required
                      onChange={() => index === fields.length - 1 && checkMetadataError()}
                    />
                  </Space>
                ))}
                <AddField
                  onClick={add}
                  buttonText={t('add_metadata')}
                  moveUp={fields.length > 0 && !isMetadataError}
                />
              </>
            )}
          </Form.List>
        </>
      )}
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </>
      )}
    </Form>
  );
};
