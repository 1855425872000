import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { FixedNav } from '../../../components/FixedNav';
import { ReactComponent as BriefcaseIcon } from '../../../assets/icons/briefcase.svg';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';

export const AccountsNav: React.FC = () => {
  const { t } = useTranslation('account_settings');

  const menuItems = [
    {
      key: '/account-settings',
      icon: <BriefcaseIcon width={14} height={16} />,
      label: <Link to="/account-settings">{t('accounts')}</Link>,
    },
  ];
  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
