import React, { ReactElement, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate, useParams } from 'react-router-dom';
import { DropdownMenu } from '../../../../../components/Dropdown';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { ensure } from '../../../../../utils/arrays';
import { Paths } from '../../../../../types/paths';
import { ReactComponent as ArrowsIcon } from '../../../../../assets/icons/arrow-left-right.svg';
import { ILanguage } from '../../../types';
import { DiscardModal } from '../../DiscardModal';

interface Props {
  languageResponse: ILanguage[];
  setLanguageCode: (value: string) => void;
  isFormSaved?: boolean;
  clearAllFilters: () => void;
}

export const LanguageDropdown: React.FC<Props> = ({
  languageResponse,
  setLanguageCode,
  isFormSaved,
  clearAllFilters,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<ReactElement | string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [languageKey, setLanguageKey] = useState<string>('');
  const { id: languageCode } = useParams() as { id: string };
  const navigate = useNavigate();

  const languageList: MenuItemType[] = useMemo(() => {
    if (languageResponse?.length) {
      const defaultLanguage = languageResponse?.find(item => item.is_default);
      const languageMenu = languageResponse
        ?.filter(item => item.id !== defaultLanguage?.id)
        .map(item => ({
          key: item.code,
          label: (
            <StyledEditTranslations.LanguageLabel>
              {defaultLanguage?.display} <ArrowsIcon width={14} /> {item.display}
            </StyledEditTranslations.LanguageLabel>
          ),
        }));
      setSelectedLanguage(
        languageMenu[languageMenu.findIndex(item => item.key === languageCode)]?.label || '',
      );
      return languageMenu;
    }

    return [];
  }, [languageResponse]);

  const handleChangeLanguage = (key: string) => {
    const lang = ensure(languageResponse?.find(el => el.code === key));
    setLanguageCode(lang.code);
    setSelectedLanguage(
      (languageList?.find(item => item?.key === key)?.label as ReactElement) || '',
    );
    navigate(Paths.knowledgeTranslationsEdit({ id: lang.code }));
  };

  return (
    <>
      <DiscardModal
        handleOk={() => {
          setIsVisible(false);
          handleChangeLanguage(languageKey);
          clearAllFilters();
        }}
        isVisible={isVisible}
        handleCancel={() => {
          setIsVisible(false);
          setLanguageKey('');
        }}
      />
      <Dropdown
        menu={{
          items: languageList,
          selectable: true,
          selectedKeys: [languageCode],
          onClick: event => {
            if (!isFormSaved) {
              setIsVisible(true);
              return setLanguageKey(event.key);
            }
            clearAllFilters();
            handleChangeLanguage(event.key);
          },
        }}
        dropdownRender={menu => <DropdownMenu.Root>{menu}</DropdownMenu.Root>}
        trigger={['click']}
      >
        <StyledEditTranslations.LanguageButton>
          <DropdownMenu.Text>{selectedLanguage}</DropdownMenu.Text>
        </StyledEditTranslations.LanguageButton>
      </Dropdown>
    </>
  );
};
