import React, { useEffect, useState } from 'react';
import { App, Button, Form, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import styled from '@emotion/styled';
import { ChannelLabel } from '../ChannelLabel';
import { StyledEditor } from '../../../../../components/TextEditor/TextEditor.styles';
import { isRichTextEditor } from '../../../Presentations/utils';
import { TextEditor } from '../../../../../components/TextEditor';
import { PresentationToolbar } from '../../../../../components/TextEditor/Toolbars/PresentationToolbar';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { StyledContentEditors } from '../ContentEditors/ContentEditors.styles';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/check.svg';
import { ReactComponent as VerifyIcon } from '../../../../../assets/icons/check-circle.svg';
import { ReactComponent as ArrowsIcon } from '../../../../../assets/icons/arrow-left-right.svg';
import { ConfirmLeaveModal } from '../../../../../components/ConfirmLeaveModal';
import { getPayload, isPresentationVerifiable } from '../../../../../utils/knowledge';
import { IChannelItem, IContentEntity, ILanguage, ITranslationInitialValues } from '../../../types';
import { VerifyModal } from '../../VerifyModal';
import { TranslateConfirmation } from '../TranslateConfirmation';

function arrayToObject(arr: InitialValuesType[]): ITranslationInitialValues {
  const result: ITranslationInitialValues = {};
  for (const obj of arr) {
    if (obj.hasOwnProperty('channel')) {
      result[obj['channel']['channel_type']] = obj;
    }
  }

  return result;
}

export type OnFinishType = { [key: string]: { translation: { payload: string } } };

type InitialValuesType = {
  id?: number | string;
  payload: string;
  channel: IChannelItem;
  language: ILanguage;
  translation?: IContentEntity;
};

type Props = {
  initialValues: InitialValuesType[];
  onFinish: (values: OnFinishType) => void;
  setCurrentChannel: (channel: string) => void;
  setIsFormSaved: (value: boolean) => void;
  title: string;
  langCode: string;
  handleVerifyPresentation: () => void;
  isDisabled?: boolean;
  handleTranslatePresentation: (isReplace: boolean) => void;
};

export const TranslationForm: React.FC<Props> = ({
  initialValues,
  onFinish,
  setCurrentChannel,
  title,
  langCode,
  setIsFormSaved,
  handleVerifyPresentation,
  isDisabled,
  handleTranslatePresentation,
}) => {
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const { t } = useTranslation(['knowledge', 'translation']);
  const formattedData = initialValues ? arrayToObject(initialValues) : undefined;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVerifyVisible, setIsVerifyVisible] = useState<boolean>(false);
  const [isTranslationVisible, setIsTranslationVisible] = useState<boolean>(false);
  const [focusedChannel, setFocusedChannel] = useState<string>('');
  const [selectedChannel, setSelectedChannel] = useState<string>('');

  useEffect(() => {
    if (formattedData) {
      form.setFieldsValue(formattedData);
    }
  }, [form, langCode, initialValues]);

  const isTranslationPresent: boolean = initialValues.some(el => el.translation?.payload);

  useEffect(() => {
    if (focusedChannel) {
      return setIsFormSaved(false);
    }
    return setIsFormSaved(true);
  }, [focusedChannel]);

  const handleValuesChange = (e: OnFinishType) => {
    const channel = Object.keys(e)[0];
    const initial =
      getPayload(channel, formattedData?.[channel].translation?.payload as string) ?? undefined;
    const current = getPayload(channel, e[channel].translation.payload) ?? undefined;

    if (initial !== current && selectedChannel === channel) {
      return setFocusedChannel(channel);
    } else {
      return setFocusedChannel('');
    }
  };

  return (
    <Form
      layout="vertical"
      style={{ maxWidth: '100%' }}
      onFinish={onFinish}
      form={form}
      initialValues={formattedData}
      onValuesChange={handleValuesChange}
    >
      <StyledContentEditors.Editors>
        <TranslateConfirmation
          isVisible={isTranslationVisible}
          setIsVisible={setIsTranslationVisible}
          onTranslate={handleTranslatePresentation}
          title={t('translate_presentation')}
          description={t('translate_single_presentation_desc')}
          buttonOkText={t('translate')}
          isReplaceContent={isTranslationPresent}
        />
        <StyledContentEditors.Header>
          <h3>
            {title || '--'} <span>({t('presentation')})</span>
          </h3>
          <div>
            <Tooltip title={t('verify_presentation_tooltip')}>
              <Button
                type="text"
                icon={<VerifyIcon width={14} />}
                onClick={() => {
                  if (isPresentationVerifiable(formattedData)) {
                    return setIsVerifyVisible(true);
                  }
                  return notification.error({ message: t('nothing_to_verify') });
                }}
              />
            </Tooltip>
            <Tooltip title={t('translate_presentation')}>
              <Button
                type="text"
                icon={<ArrowsIcon width={14} />}
                onClick={() => setIsTranslationVisible(!isTranslationVisible)}
              />
            </Tooltip>
          </div>
        </StyledContentEditors.Header>
        <ConfirmLeaveModal
          title={t('save_changes', { ns: 'translation' })}
          description={t('confirm_leave_description')}
          isVisible={isVisible}
          handleOk={() => {
            setCurrentChannel(focusedChannel);
            form.submit();
            setIsVisible(false);
            setFocusedChannel('');
          }}
          handleCancel={() => setIsVisible(false)}
        />
        <VerifyModal
          title={t('verify_presentation')}
          okButtonText={t('verify')}
          description={t('verify_all_description')}
          isVisible={isVerifyVisible}
          handleCancel={() => setIsVerifyVisible(false)}
          handleOk={() => {
            handleVerifyPresentation();
            setIsVerifyVisible(false);
          }}
        />
        {formattedData &&
          Object.keys(formattedData).map((channel, index, arr) => {
            const translationStatus =
              formattedData[channel]?.translation?.translation_status ?? undefined;
            const handleSubmit = () => {
              setCurrentChannel(channel);
              setFocusedChannel('');
              form.submit();
            };

            return (
              <div key={channel}>
                <ChannelLabel channelType={channel} translationStatus={translationStatus} />
                <StyledWrapper>
                  <div>{parse(formattedData[channel].payload)}</div>
                  <div>
                    <StyledEditor isTranslation={true} isRichTextEditor={isRichTextEditor(channel)}>
                      <div id={channel}>
                        <Form.Item name={[channel, 'translation', 'payload']}>
                          <TextEditor
                            isDisabled={isDisabled}
                            onFocus={() => {
                              setSelectedChannel(channel);
                              if (focusedChannel && focusedChannel !== channel) {
                                setIsVisible(true);
                                const target = document
                                  .getElementById(focusedChannel)
                                  ?.getElementsByClassName('ql-editor')[0];

                                (target as HTMLElement)?.focus();
                              }
                            }}
                            id={channel}
                            toolbar={
                              isRichTextEditor(channel) ? <PresentationToolbar /> : undefined
                            }
                          />
                        </Form.Item>
                      </div>
                    </StyledEditor>
                    {focusedChannel === channel && translationStatus !== 'unverified' && (
                      <div style={{ float: 'right', marginTop: -12 }}>
                        <StyledEditTranslations.LinkButton
                          type="link"
                          cancel="true"
                          icon={<CloseIcon width={11} />}
                          onClick={() => {
                            form.setFieldValue(
                              [channel, 'translation', 'payload'],
                              formattedData[channel]?.translation?.payload ?? undefined,
                            );
                            setFocusedChannel('');
                          }}
                        >
                          {t('cancel')}
                        </StyledEditTranslations.LinkButton>
                        <StyledEditTranslations.LinkButton
                          type="link"
                          icon={<CheckIcon width={11} />}
                          onClick={handleSubmit}
                          htmlType="submit"
                        >
                          {t('save')}
                        </StyledEditTranslations.LinkButton>
                      </div>
                    )}
                    {translationStatus === 'unverified' && (
                      <div style={{ float: 'right', marginTop: -15 }}>
                        <Button
                          style={{ padding: 6 }}
                          size="small"
                          icon={<VerifyIcon width={11} />}
                          onClick={handleSubmit}
                          htmlType="submit"
                        >
                          {t('verify')}
                        </Button>
                      </div>
                    )}
                  </div>
                </StyledWrapper>
                {arr.length !== 1 && index !== arr.length - 1 && (
                  <StyledEditTranslations.Divider style={{ marginBottom: 16 }} />
                )}
              </div>
            );
          })}
      </StyledContentEditors.Editors>
    </Form>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  gap: 20px;
  & > div {
    width: 50%;
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
  }
`;
