import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { DLPTemplateForm } from '../DLPTemplateForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IDLPTemplateValues } from '../types';
import { DLPTemplateService } from '../../../../../services/ai/dlp-configs';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

export const DLPTemplateNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [DLPTemplateService.queryKey],
    DLPTemplateService.create,
  );

  const onSubmit = async (
    data: IDLPTemplateValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('dlp_template_created_notify', {
                  name: data?.dlp_config.name,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: IDLPTemplateValues = {
    name: '',
    deidentify_template_name: '',
    inspect_template_name: '',
    gcp_keyfile: null,
    gcp_project_name: '',
  };

  return (
    <Content imgBg={false}>
      <DLPTemplateForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
