import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';
import { ReactComponent as ConfigIcon } from '../../../assets/icons/config.svg';
import { ReactComponent as CogsIcon } from '../../../assets/icons/cogs.svg';
import { ReactComponent as RouterIcon } from '../../../assets/icons/routing.svg';
import { ReactComponent as ChannelsIcon } from '../../../assets/icons/chart-network.svg';
import { Paths } from '../../../types/paths';
import { FixedNav } from '../../../components/FixedNav';

export const AINav = (): JSX.Element => {
  const { t } = useTranslation('ai');

  const menuItems = [
    {
      key: '/ai/automation',
      icon: <CogsIcon width={15} height={15} />,
      label: <Link to={Paths.aiAutomation()}>{t('automation')}</Link>,
    },
    {
      key: '/ai/channels',
      icon: <ChannelsIcon width={15} height={15} />,
      label: <Link to={Paths.aiChannels()}>{t('channels')}</Link>,
    },
    {
      key: '/ai/routing',
      icon: <RouterIcon width={16} height={16} />,
      label: <Link to={Paths.aiRouting()}>{t('routing')}</Link>,
    },
    {
      key: '/ai/configuration',
      icon: <ConfigIcon width={15} height={15} />,
      label: <Link to={Paths.aiConfiguration()}>{t('configuration')}</Link>,
    },
  ];

  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
