import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { CollapseComponent } from '../../../../components/CollapseComponent';
import { TitleWithToolTip } from '../../../../components/TitleWithToolTip';
import { AIAutomationSettings } from './AIAutomationSettings';
import { PulseSettings } from './PulseSettings';
import { theme } from '../../../../theme';
import { useFormContext } from '../../../../state/form';
import { getActiveKeys } from '../../../../utils/project';
import { settingsFieldChecks } from '../../constants';
import { ProductType } from '../../types';

const panelStyle: React.CSSProperties = {
  marginBottom: 16,
  background: theme.lightBg,
  border: 'none',
};

export const ProjectSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams() as { id: string };
  const { getFieldValue } = Form.useFormInstance();
  const { errorFields, isErrorsAffectingLayout } = useFormContext();
  const [activeKeys, setActiveKeys] = useState<ProductType[]>([]);
  const ai_n_a = getFieldValue('ai_n_a');
  const pulse = getFieldValue('pulse');

  const style = { marginBottom: isErrorsAffectingLayout ? -10 : -20 };

  useEffect(() => {
    setActiveKeys([
      ...new Set([...activeKeys, ...getActiveKeys(errorFields, settingsFieldChecks)]),
    ]);
  }, [errorFields]);

  const items = [
    ...(ai_n_a
      ? [
          {
            key: 'ai_n_a' as ProductType,
            label: <TitleWithToolTip title="AI & Automation" tooltip={t('tooltip_ai_n_a')} />,
            children: <AIAutomationSettings />,
            style: panelStyle,
            forceRender: true,
          },
        ]
      : []),
    ...(pulse && !id
      ? [
          {
            key: 'pulse' as ProductType,
            label: <TitleWithToolTip title="Pulse" tooltip={t('tooltip_pulse')} />,
            children: <PulseSettings style={style} />,
            style: panelStyle,
            forceRender: true,
          },
        ]
      : []),
  ];

  return (
    <div>
      <b>{t('products')} </b>
      <ProductsDescription>
        {t('settings_products_description', { case: items.length > 1 ? t('each') : t('this') })}
      </ProductsDescription>
      <CollapseComponent items={items} activeKeys={activeKeys} setActiveKeys={setActiveKeys} />
    </div>
  );
};

const ProductsDescription = styled.div`
  margin: 8px 0 20px 0;
`;
