import React, { createContext, useContext, useMemo, useState } from 'react';
import { FieldError } from 'rc-field-form/es/interface';
import { FormError } from '../utils/errors';
import { bottomFieldsByWindowSize } from '../pages/Projects/constants';
import { useWindowDimensions } from '../utils/useWindowDimensions';

interface IFormContext {
  errorFields: FormError[];
  setErrorFields: (errorFields: FormError[]) => void;
  checkIfErrorsAffectLayout: (errors: FieldError[]) => void;
  isErrorsAffectingLayout: boolean;
}
const FormContext = createContext<IFormContext>({} as IFormContext);

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [errorFields, setErrorFields] = useState<FormError[]>([]);
  const [isErrorsAffectingLayout, setIsErrorsAffectingLayout] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const checkIfErrorsAffectLayout = (errors: FieldError[]): void => {
    const targetFields =
      width > 885 ? bottomFieldsByWindowSize.big : bottomFieldsByWindowSize.small;
    const isErrorsAffectingLayout = errors.some(
      ({ name, errors }) => targetFields.some(field => name.includes(field)) && errors.length > 0,
    );

    setIsErrorsAffectingLayout(isErrorsAffectingLayout);
  };

  const contextValue = useMemo(() => {
    return {
      errorFields,
      setErrorFields,
      isErrorsAffectingLayout,
      checkIfErrorsAffectLayout,
    };
  }, [errorFields, setErrorFields, isErrorsAffectingLayout, checkIfErrorsAffectLayout]);

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};
