import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-double-right.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/pen.svg';
import { StyledViewLink } from './ViewLink.styles';

export const ViewLink: React.FC<{
  to: string;
  isEdit?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ to, isEdit, onClick, children }) => {
  const { t } = useTranslation();
  const Icon = isEdit ? EditIcon : ArrowIcon;
  const text = isEdit ? t('edit') : t('view');
  return (
    <StyledViewLink to={to} onClick={onClick}>
      <Icon width={14} height={14} />
      <span>{children || text}</span>
    </StyledViewLink>
  );
};
