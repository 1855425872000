import { authMiddlewareQuery, keycloakQuery } from './api';
import { LoginFormValues } from '../pages/Login/LoginForm';
import { ForgotPasswordValues } from '../pages/Login/ForgotPassword';
import { PasswordResetValues } from '../pages/Login/PasswordReset';
import { IUsersValues } from '../pages/Projects/Users/UsersForm';
import { Locale } from '../locales/i18n.d';

const login = async (data: LoginFormValues): Promise<any> => {
  data.grant_type = 'password';
  return keycloakQuery(`/protocol/openid-connect/token`, {
    method: 'POST',
    data: data,
  });
};

const logout = async (data: { token: string }): Promise<any> => {
  return keycloakQuery(`/protocol/openid-connect/revoke`, {
    method: 'POST',
    data: data,
  });
};

const refreshToken = async (data: { refresh_token: string; grant_type: string }): Promise<any> => {
  return keycloakQuery(`/protocol/openid-connect/token`, {
    method: 'POST',
    data: data,
  });
};

type ForgotPasswordPayload = ForgotPasswordValues & {
  locale: Locale;
};
const forgotPassword = async (data: ForgotPasswordPayload): Promise<any> => {
  return authMiddlewareQuery(`/users/forgot_password`, {
    method: 'POST',
    data: data,
  });
};

const resetPassword = async (data: PasswordResetValues): Promise<any> => {
  return authMiddlewareQuery(`/users/reset_password`, {
    method: 'POST',
    data: data,
  });
};

const queryKey = 'user_associateproject';

const addUsers = async (data: IUsersValues): Promise<any> => {
  return authMiddlewareQuery(`/users/user_associateproject`, {
    method: 'POST',
    data: data,
  });
};

export const AuthService = {
  queryKey,
  login,
  logout,
  refreshToken,
  forgotPassword,
  resetPassword,
  addUsers,
};
