import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SignInIcon } from '../../../assets/icons/sign-in.svg';
import { Paths } from '../../../types/paths';

export const InviteLinkExpired: React.FC = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const handleLogin = () => navigate(Paths.login(), { replace: true });

  return (
    <>
      <h2>{t('invite_link_expired')}</h2>
      <b>{t('invite_link_expired_desc')}</b>
      <Text>{t('link_expired_check_for_new')}</Text>
      <Button block type="primary" onClick={handleLogin}>
        {t('continue_to_login')}
        <SignInIcon width={15} height={15} />
      </Button>
    </>
  );
};

const Text = styled.p`
  margin-bottom: 150px;
`;
