import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { consoleManagerQuery } from './api';
import { sortAsc } from '../utils/sorting';
import { IAccount, IAccountsValues } from '../pages/Accounts/types';

const queryKey = 'accounts';

const get = async ({ signal }: QueryFunctionContext): Promise<IAccount[]> => {
  const response = await consoleManagerQuery('/client_accounts', { signal });
  return sortAsc(response);
};

const find = ({ queryKey, signal }: QueryFunctionContext): Promise<IAccount> => {
  return consoleManagerQuery(`/client_accounts/${queryKey[1]}`, { signal });
};

const create = (data: IAccountsValues): Promise<IAccount> =>
  consoleManagerQuery(`/client_accounts`, {
    method: 'POST',
    data: { client_account: data },
  });

const update = ({
  id,
  values,
}: {
  id: string;
  values: Partial<IAccountsValues>;
}): Promise<IAccount> => {
  return consoleManagerQuery(`/client_accounts/${id}`, {
    method: 'PUT',
    data: { client_account: values },
  });
};

const disable = ({ id }: { id: string }): Promise<IAccount> => {
  return consoleManagerQuery(`/client_accounts/${id}/disablements`, {
    method: 'POST',
  });
};

export const AccountsService = {
  queryKey,
  get,
  find,
  create,
  update,
  disable,
};
