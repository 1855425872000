import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { DialPlansService } from '../../services/ai/dial-plans';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';

export const DialPlansSelector: React.FC<SelectProps> = props => {
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [DialPlansService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => DialPlansService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  return (
    <Select {...props}>
      {data?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
