import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import { ILanguages } from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-languages';

const get = async (
  data: IKnowledgeUrl,
  queryString: string,
  signal: QueryFunctionContext['signal'],
): Promise<ILanguages> => {
  return await knowledgeQuery(`/languages${queryString}`, { data, signal });
};

export const KnowledgeLanguageService = {
  queryKey,
  get,
};
