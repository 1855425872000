import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Mustache from 'mustache';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { StyledChatPreview } from './ChatBotPreview.styles';
import { ReactComponent as ChatBotIcon } from '../../../../assets/icons/avatar.svg';
import { isRichTextEditor, stripHTMLTags } from '../utils';
import { IntentOption } from '../PresentationForm';
import { KnowledgeParameterService } from '../../../../services/knowledge/parameter';
import { useAccount } from '../../../../state/account';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { extractParams } from '../../../../utils/string';
import { ChatBotDestinations, getDefaultDestinationOption } from './ChatBotDestinations';

type Props = {
  value: string;
  channel: string;
  intents?: IntentOption[];
};
type ViewType = {
  [key: string]: string;
};

export const ChatBotPreview: React.FC<Props> = ({ value, channel, intents }) => {
  const { t } = useTranslation('knowledge');
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations();
  const [view, setView] = useState<ViewType>();

  const initialIntent = intents?.[intents.length - 1] || getDefaultDestinationOption();
  const [selectedIntentValue, setSelectedIntentValue] = useState(initialIntent.value);
  const intentDestinationName = intents?.find(item => item.value === selectedIntentValue)?.name;

  const params = !!value?.length ? extractParams(value) : undefined;
  const queryString = params?.map(param => `q[key_eq_any][]=${param}`).join('&') || '';
  const { data } = useQuery(
    [
      KnowledgeParameterService.queryKey,
      activeProjectId,
      `?pageSize=ALL&${queryString}`,
      knowledge?.externalId,
    ],
    ({ signal }) =>
      KnowledgeParameterService.get(
        knowledge as IKnowledgeUrl,
        `?pageSize=ALL&${queryString}`,
        signal,
      ),
    { enabled: !!knowledge && !!queryString?.length },
  );

  useEffect(() => {
    if (data?.data.length) {
      const mappedValue = data.data.reduce((obj: ViewType, item) => {
        const destinationValue =
          intentDestinationName &&
          item.children.find(item => item.configurable.name === intentDestinationName)?.value;
        return Object.assign(obj, { [item.key]: destinationValue || item.value });
      }, {});
      setView(mappedValue);
    }
  }, [data?.data, intentDestinationName]);

  let output;
  try {
    output = Mustache.render(value, view);
  } catch (e) {
    output = value;
  }

  return (
    <>
      <StyledChatPreview.Header>
        <div className="title">
          <ChatBotIcon width={26} height={26} />
          <span style={{ verticalAlign: 'super', marginLeft: 6 }}>{t('chatbot')}</span>
        </div>
        <ChatBotDestinations
          selectedIntentValue={selectedIntentValue}
          setSelectedIntentValue={setSelectedIntentValue}
          intents={intents}
        />
      </StyledChatPreview.Header>
      <StyledChatPreview.ContentWrapper>
        {value !== '' && value !== '<p><br></p>' && (
          <StyledChatPreview.Content>
            {isRichTextEditor(channel) ? parse(output) : stripHTMLTags(output)}
          </StyledChatPreview.Content>
        )}
      </StyledChatPreview.ContentWrapper>
      <StyledChatPreview.Footer>
        <div>{t('chatbot_footer')}</div>
      </StyledChatPreview.Footer>
    </>
  );
};
