import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { StyledTableAction } from './TableAction.styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

interface ITableAction {
  selectedRows: number;
  setSelectedRowKeys: (selected: React.Key[]) => void;
  actions?: React.ReactNode[];
}

export const TableAction: React.FC<ITableAction> = ({
  selectedRows,
  setSelectedRowKeys,
  actions,
}) => {
  const { t } = useTranslation('knowledge');

  return (
    <StyledTableAction.Card isVisible={!!selectedRows}>
      <StyledTableAction.Part>
        <Space size="middle">
          <StyledTableAction.Selected>
            {selectedRows} {t('selected')}:
          </StyledTableAction.Selected>
          {actions && actions.map((action, i) => <div key={i}>{action}</div>)}
        </Space>
      </StyledTableAction.Part>
      <StyledTableAction.Part>
        <Button
          type="text"
          size="small"
          icon={<CloseIcon width={12} height={12} />}
          onClick={() => setSelectedRowKeys([])}
        >
          {t('cancel')}
        </Button>
      </StyledTableAction.Part>
    </StyledTableAction.Card>
  );
};
