import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { PresentationForm } from '../PresentationForm';
import { IPresentation, IPresentationValues, IContentEntity, IIntent } from '../../types';
import { useGetItem } from '../../../../hooks/useGetItem';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { KnowledgePresentationService } from '../../../../services/knowledge/presentation';
import { CenteredSpinner } from '../../../../components/Spinner';
import { useUpdate } from '../../../../hooks/useUpdate';
import { unexpectedError } from '../../../../utils/notification';
import {
  getIntents,
  generateTextEditorPayload,
  getInitialIntentsValues,
} from '../../../../utils/knowledge/presentation';
import { ensure } from '../../../../utils/arrays';
import { useAccount } from '../../../../state/account';
import { ActionButton } from '../../../../components/ActionButton';
import { Paths } from '../../../../types/paths';

export const EditPresentation: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { activeProjectId } = useAccount();
  const { knowledge } = useIntegrations();
  const { notification } = App.useApp();
  const { data, isLoading, error } = useGetItem<IPresentation>(
    [KnowledgePresentationService.queryKey, activeProjectId, knowledge?.externalId, id],
    ({ signal }) => KnowledgePresentationService.find(knowledge as IKnowledgeUrl, id, signal),
    { enabled: !!knowledge, retry: 0 },
  );
  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate<{
    id: string;
    data: IKnowledgeUrl;
    values: any;
  }>(
    [KnowledgePresentationService.queryKey, knowledge?.externalId as string, id],
    KnowledgePresentationService.update,
  );

  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate(Paths.knowledgePresentations(), { replace: true });
      notification['error']({ message: 'record_not_found' });
    }
  }, [error]);

  const onSubmit = async (value: IPresentationValues, intents: IIntent[]) => {
    const content =
      Array.isArray(value.content_entities_attributes) &&
      value.content_entities_attributes.reduce(function (
        result: { [key: number]: IContentEntity },
        item,
        index,
      ) {
        result[index] = item;
        return result;
      },
      {});

    const payload = {
      title: value.title,
      label_list: value.label_list,
      content_entities_attributes: content,
      synchronisations_attributes: getIntents(
        data?.synchronisations,
        value.synchronisations_attributes as string[],
        intents,
        ensure(knowledge?.externalId),
      ),
    };
    await updateItem(
      { id, data: knowledge as IKnowledgeUrl, values: payload },
      {
        onSuccess: () => navigate(-1),
        onError: (data: any) => {
          if (data?.response?.data?.title[0] === 'has already been taken')
            return notification['error']({ message: t('presentation_name_exists') });
          else return notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues = data
    ? {
        title: data.title,
        synchronisations_attributes: getInitialIntentsValues(data.synchronisations),
        label_list: data.label_list || [],
        content_entities_attributes: generateTextEditorPayload(data.content_entities),
      }
    : undefined;

  return (
    <>
      {isLoading ? (
        <CenteredSpinner height="80vh" />
      ) : (
        <PresentationForm
          presentationIntents={data?.synchronisations}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        />
      )}
    </>
  );
};
