import React from 'react';
import { ConfigProvider, PaginationProps, Table, TableProps } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { TableBase } from './DataTable.styles';
import { setTranslation } from './tableTranslations';

const areWeTestingWithJest = () => process.env.JEST_WORKER_ID !== undefined;
const TABLE_MARGINS = 328;

export interface IDataTable<T extends { id?: string | number }> extends TableProps<T> {
  isClickable?: boolean;
  isPageSizer?: boolean;
  isPaginationFixed?: boolean;
  totalItems?: number;
}

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<Record<string, any>>['field'];
  sortOrder?: string | null;
  filters?: Record<string, FilterValue | null>;
  searchValue?: string;
}

const paginationItem: PaginationProps['itemRender'] = (page, type) => {
  if (type === 'prev' || type === 'next') {
    return (
      <Link to={`?page=${page}`} replace className="ant-pagination-item-link">
        {type === 'prev' ? (
          <ChevronLeft width={7} height={15} />
        ) : (
          <ChevronRight width={7} height={15} />
        )}
      </Link>
    );
  }
  if (type === 'jump-next' || type === 'jump-prev') {
    return (
      <Link to={`?page=${page}`} replace className="ant-pagination-item-link">
        ...
      </Link>
    );
  }
  return (
    <Link to={`?page=${page}`} replace>
      {page}
    </Link>
  );
};

export const DataTable: <T extends { id?: string | number }>(
  props: IDataTable<T>,
) => React.ReactElement<IDataTable<T>> = props => {
  const {
    isPaginationFixed = true,
    dataSource,
    columns,
    loading = false,
    showSorterTooltip = false,
    isPageSizer = true,
    isClickable = true,
    totalItems,
    ...other
  } = props;
  const [searchParams] = useSearchParams();
  const queryPage = Number(searchParams.get('page')) || 1;
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  const tableProps = {
    rowKey: (record: any) => String(record.id),
    // when scrollY is set, many tests fail with an error
    scroll: {
      x: dataSource && dataSource?.length > 0 ? 'max-content' : undefined,
      y:
        areWeTestingWithJest() || (dataSource && dataSource.length === 0)
          ? undefined
          : height - TABLE_MARGINS,
    },
    dataSource,
    columns,
    sticky: !areWeTestingWithJest(),
    loading,
    showSorterTooltip,
    pagination: {
      defaultCurrent: queryPage,
      itemRender: paginationItem,
      pageSizeOptions: isPageSizer ? ['10', '20', '30', '40', '50'] : [''],
      showSizeChanger: isPageSizer,
      locale: { items_per_page: '' },
      total: totalItems,
      showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} ${t('of')} ${total}`,
    },
    ...other,
  };

  return (
    <TableBase isClickable={isClickable} isPaginationFixed={isPaginationFixed}>
      <ConfigProvider locale={setTranslation()}>
        <Table {...tableProps} />
      </ConfigProvider>
    </TableBase>
  );
};
