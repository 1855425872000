import styled from '@emotion/styled';
import { Collapse } from 'antd';
import { theme } from '../../theme';

export const StyledCollapse = styled(Collapse)`
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    &:hover {
      background-color: ${theme.secondaryBg} !important;
    }
  }
  .ant-input-number-affix-wrapper-disabled,
  .ant-input-number-disabled,
  .ant-input-number-affix-wrapper-disabled:hover,
  .ant-input-number-disabled:hover {
    background-color: ${theme.secondaryBg};
    border: ${theme.secondaryBg};
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-disabled {
    background-color: ${theme.secondaryBg};
    color: ${theme.textColor};
    border: none !important;
  }
`;
