import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { IContextValues, IContext } from '../../pages/AI/Automation/Contexts/types';
import { IAiIntegrationUrl } from '../../state/integrations';
import { sortById } from '../../utils/sorting';

const queryKey = 'contexts';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IContext[]> => {
  const response = await integrationQuery(`/api/contexts`, {
    apiData,
    signal,
  });
  return sortById(response.contexts);
};

const create = async ({ data, apiData }: { data: IContextValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/contexts`, {
    method: 'POST',
    data: { data: { type: 'contexts', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IContext> => {
  const response = await integrationQuery(`/api/contexts/${id}`, { apiData, signal });
  return response?.context;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IContext>;
}): Promise<IContext> => {
  return integrationQuery(`/api/contexts/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'contexts',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/contexts/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const ContextsService = {
  queryKey,
  get,
  create,
  find,
  update,
  remove,
};
