import styled from '@emotion/styled';
import { Form, Input } from 'antd';
import { ReactComponent as MinusCircle } from '../../assets/icons/minus-circle.svg';
import { formItemClass, theme } from '../../theme';
import { ChannelType, ChannelTypes } from '../../pages/AI/Channels/Channel/types';
import { IncomingPhoneNumberSelector } from '../IncomingPhoneNumberSelector';

interface Props {
  placeholder: string;
  label: string;
  name: (string | number)[];
  onClick: () => void;
  onChange?: () => void;
  required?: boolean;
  tooltip?: string;
  channelType?: ChannelType;
}

export const InputWithRemove = ({
  placeholder,
  label,
  name,
  onClick,
  onChange,
  required,
  tooltip,
  channelType,
}: Props) => {
  const isPhoneNumberType =
    channelType &&
    [ChannelTypes.voice, ChannelTypes.sms, ChannelTypes.teams, ChannelTypes.whatsapp].includes(
      channelType as ChannelTypes,
    );

  return (
    <Root>
      <Form.Item
        name={name}
        label={label}
        className={formItemClass.base}
        rules={[{ required: required }]}
        tooltip={tooltip}
      >
        {isPhoneNumberType ? (
          <IncomingPhoneNumberSelector
            placeholder={placeholder}
            channelType={channelType}
            onChange={onChange}
          />
        ) : (
          <Input placeholder={placeholder} onChange={onChange} />
        )}
      </Form.Item>
      <MinusContainer onClick={onClick}>
        <MinusCircle color={theme.primaryColor} width={16} height={16} />
      </MinusContainer>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  @media (min-width: 475px) and (max-width: 875px) {
    width: 400px;
  }
`;

export const MinusContainer = styled.div`
  top: 24px;
  position: absolute;
  left: 364px;
  width: ${({ theme }) => theme.inputHeight};
  height: ${({ theme }) => theme.inputHeight};
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondaryBg};
  }
  @media (max-width: 475px) {
    top: -16px;
    margin-bottom: 20px;
    position: relative;
    left: 0;
  }
`;
