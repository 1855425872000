import React from 'react';
import { StyledLegalWrapper } from './LegalWrapper.styles';
import { NavigationMenu } from './NavigationMenu';

interface ILegal {
  title: string;
}

export const LegalWrapper: React.FC<ILegal> = ({ title, children }) => {
  return (
    <>
      <StyledLegalWrapper.Header>
        <StyledLegalWrapper.Logo to="/">
          <StyledLegalWrapper.SabioLogo fill="#fff" />
        </StyledLegalWrapper.Logo>
        <NavigationMenu />
      </StyledLegalWrapper.Header>
      <StyledLegalWrapper.Root>
        <StyledLegalWrapper.PageTitleWrap>
          <StyledLegalWrapper.InnerWrapper>
            <StyledLegalWrapper.PageTitle>{title}</StyledLegalWrapper.PageTitle>
          </StyledLegalWrapper.InnerWrapper>
        </StyledLegalWrapper.PageTitleWrap>
        <StyledLegalWrapper.Content>
          <StyledLegalWrapper.InnerWrapper>
            <div>{children}</div>
          </StyledLegalWrapper.InnerWrapper>
        </StyledLegalWrapper.Content>
      </StyledLegalWrapper.Root>
    </>
  );
};
