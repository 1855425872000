import React from 'react';
import { Translation } from 'react-i18next';
import { AccountsList } from './AccountsList';
import { AccountsNew } from './AccountsNew';
import { AccountsEdit } from './AccountsEdit';
import { Accounts } from './index';
import { Paths } from '../../types/paths';

export const accountRoutes = () => ({
  path: Paths.accounts(),
  element: <Accounts />,
  breadcrumb: () => <Translation ns={'account_settings'}>{t => t('accounts')}</Translation>,
  children: [
    { index: true, element: <AccountsList /> },
    {
      path: Paths.accountCreate(),
      element: <AccountsNew />,
      breadcrumb: () => <Translation>{t => t('create')}</Translation>,
    },
    {
      path: Paths.accountEdit(),
      element: <AccountsEdit />,
      breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
    },
  ],
});
