import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from './App';
import { CenteredSpinner } from './components/Spinner';
import './locales/i18n';
import { initialLDContext } from './constants';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';

(async () => {
  initializeFaro({
    url: window.__RUNTIME_CONFIG__.REACT_APP_FARO_URL,

    app: {
      environment: window.__RUNTIME_CONFIG__.OTEL_NAMESPACE,
      name: 'ui',
      version: window.__RUNTIME_CONFIG__.REACT_APP_VERSION,
    },

    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true,
      }),

      new TracingInstrumentation({
        instrumentationOptions: {
          propagateTraceHeaderCorsUrls: [new RegExp(window.__RUNTIME_CONFIG__.ROOT_URL + '/*')],
        },
      }),

      new ReactIntegration({
        router: {
          version: ReactRouterVersion.V6,
          dependencies: {
            createRoutesFromChildren,
            matchRoutes,
            Routes,
            useLocation,
            useNavigationType,
          },
        },
      }),
    ],
  });

  const clientid = window.__RUNTIME_CONFIG__.REACT_APP_FEATURE_FLAG_CLIENTSIDE_ID;
  const LDProvider = await asyncWithLDProvider({
    // eslint-disable-next-line
    clientSideID: clientid!,
    context: initialLDContext,
  });
  const container = document.getElementById('root');

  if (container) {
    const root = createRoot(container);
    root.render(
      <LDProvider>
        <Suspense fallback={<CenteredSpinner />}>
          <App />
        </Suspense>
      </LDProvider>,
    );
  }
})();
