import React from 'react';
import { Translation } from 'react-i18next';
import { ArgsProps } from 'antd/es/notification/interface';

export const unexpectedError: ArgsProps = {
  message: <Translation>{t => t('unexpected_error')}</Translation>,
};

export const getFormErrorsConfig = (errors: Record<string, any>): ArgsProps => {
  const msg: string[] = [];
  if (typeof errors.error === 'string') {
    msg.push(errors.error);
  } else {
    Object.keys(errors).forEach((field: any) => msg.push(`${field}: ${errors[field][0]}`));
  }
  return {
    message: msg.join('\r\n'),
    duration: 7,
  };
};
