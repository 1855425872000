import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Select, SelectProps } from 'antd';
import { FallbackConfigsService } from '../../services/ai/fallback-configs';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { SelectWithFooter } from '../SelectWithFooter';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface Props extends SelectProps {
  onNewBehaviour: (value: boolean) => void;
}

export const FallbackBehaviourSelector: React.FC<Props> = ({ onNewBehaviour, ...other }) => {
  const { aiIntegration } = useIntegrations();
  const { t } = useTranslation('ai');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data } = useQuery(
    [FallbackConfigsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      FallbackConfigsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleNewBehaviour = () => {
    setIsOpen(false);
    onNewBehaviour(true);
  };

  const options = data?.map(item => ({
    label: item.name,
    value: item.id,
  }));

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewBehaviour}>
        <PlusIcon width={10} height={10} /> {t('new_behaviour')}
      </SelectWithFooter.Footer>
    </>
  );

  return (
    <Select
      open={isOpen}
      showSearch
      optionFilterProp="label"
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      options={options}
      {...other}
    ></Select>
  );
};
