import styled from '@emotion/styled';
import { Card, Form } from 'antd';
import { StyledViewLink } from '../../components/ViewLink/ViewLink.styles';

export const StyledCard = styled(Card)`
  padding: 24px 0 16px;
  .ant-card-head {
    min-height: 0;
  }
  .ant-card-head-wrapper {
    margin-bottom: 16px;
  }
  .ant-card-head-title {
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
  }
  .ant-card-body {
    padding: 0 24px;
  }
  ${StyledViewLink} {
    margin-top: 28px;
  }
`;

export const CardInfo = styled.div`
  min-height: 60px;
`;

export const TitleDesc = styled.div`
  margin-bottom: 20px;
`;

export const CardInfoChannels = styled.div`
  min-height: 80px;
`;

export const StyledFormItem = styled(Form.Item)`
  width: 360px;
  .ant-form-item-label {
    font-weight: normal;
    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: unset;
    }
  }
  .ant-form-item-row {
    max-height: 20px;
    @media (min-width: 576px) {
      align-items: center;
      flex-direction: row;
    }
  }
  .ant-col {
    width: auto !important;
  }
  .ant-form-item-control-input {
    float: right;
  }
`;
