import React, { useEffect } from 'react';
import { Divider, Form, FormInstance, Steps as AntSteps, FormProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { equals } from 'ramda';
import { validateMessages } from '../../utils/validation';
import { FixedBottomBar } from '../FixedBottomBar';
import { FormActions } from '../FormActions';
import { ActionButton } from '../ActionButton';
import { Space } from '../Space';
import useSteps from '../../hooks/useSteps';
import { useModal } from '../../hooks/useModal';
import { DiscardChangesModal } from '../DiscardChangesModal';
import { useFormContext } from '../../state/form';
import { FormError } from '../../utils/errors';

export interface IStepsForm<Values> extends FormProps<Values> {
  form: FormInstance<Values>;
  steps: { title: string; content: JSX.Element }[];
  isEdit?: boolean;
  onFinish: (values: Values) => void;
  submitButton: React.ReactElement;
  page?: number;
}

export const WizardForm = <Values,>(props: IStepsForm<Values>): JSX.Element => {
  const {
    form,
    steps,
    initialValues,
    isEdit = false,
    onFinish,
    submitButton,
    page,
    ...other
  } = props;
  const { currentStep, next, prev, setStep } = useSteps();
  const navigate = useNavigate();
  const { visible, openModal, closeModal } = useModal(false);
  const { setErrorFields, checkIfErrorsAffectLayout } = useFormContext();

  const onStepChange = (value: number) => {
    setStep(value);
  };

  useEffect(() => {
    (page || page === 0) && setStep(page);
  }, [page]);

  const handleNext = async () => {
    setErrorFields?.([]);
    try {
      await form.validateFields();
      next();
    } catch ({ errorFields }) {
      setErrorFields?.(errorFields);
    }
  };

  const onFinishFailed: FormProps['onFinishFailed'] = ({ errorFields }) => {
    if (setErrorFields) {
      const transformedErrorFields: FormError[] = errorFields.map(({ name, errors }) => ({
        name: Array.isArray(name) ? name.map(String) : [String(name)],
        errors,
      }));
      setErrorFields(transformedErrorFields);
    }
  };

  const handleFieldsChange = () => {
    checkIfErrorsAffectLayout?.(form.getFieldsError());
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue(true))) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const handleBack = () => {
    setErrorFields?.([]);
    prev();
  };

  return (
    <Space direction="vertical" size="middle">
      <Steps
        items={steps.map(step => ({ title: step.title }))}
        current={currentStep}
        {...(isEdit && { onChange: onStepChange })}
        isEdit={isEdit}
      />
      <div>
        {isEdit && <Divider style={{ marginTop: 0 }} />}
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          validateTrigger="onBlur"
          onFieldsChange={handleFieldsChange}
          {...other}
        >
          {steps[currentStep].content}
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {!isEdit ? (
                <>
                  <ActionButton type="back" disabled={currentStep === 0} onClick={handleBack} />
                  {currentStep < steps.length - 1 && (
                    <ActionButton type="next" onClick={handleNext} />
                  )}
                  {currentStep === steps.length - 1 && submitButton}
                </>
              ) : (
                <>{submitButton}</>
              )}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </Form>
      </div>
    </Space>
  );
};

export const Steps = styled(AntSteps, {
  shouldForwardProp: propName => propName !== 'isEdit',
})<{ isEdit: boolean }>(
  ({ isEdit, theme }) =>
    isEdit &&
    css`
      border: 1px solid ${theme.primaryColor};
      .ant-steps-item-icon {
        display: none;
      }
      .ant-steps-item {
        padding: 0 !important;
        & + .ant-steps-item {
          border-left: 1px solid ${theme.primaryColor};
        }
        &.ant-steps-item-active .ant-steps-item-container {
          .ant-steps-item-title {
            color: #fff;
            font-weight: 600;
            background-color: ${theme.primaryColor};
          }
        }
      }
      .ant-steps-item-title {
        padding: 0 16px !important;
        line-height: 34px;
        color: ${theme.textColor50};
        &::after {
          display: none;
        }
      }
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title,
      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: ${theme.textColor50};
        &:hover {
          color: ${theme.primaryColor} !important;
        }
      }
    `,
);
