import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-double-right.svg';
import { ReactComponent as PaperPlaneIcon } from '../../../assets/icons/paper-plane.svg';
import { Paths } from '../../../types/paths';

export const ForgotPasswordComplete: React.FC = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const handleBack = () => navigate(Paths.login(), { replace: true });

  return (
    <>
      <h2>{t('email_sent')}</h2>
      <p>{t('forget_password_desc')}</p>
      <PaperPlane width={84} height={84} />
      <Button block type="primary" onClick={handleBack}>
        {t('back_login')}
        <ArrowIcon width={14} height={14} />
      </Button>
    </>
  );
};

const PaperPlane = styled(PaperPlaneIcon)`
  display: block;
  margin: 28px auto 50px;
  color: ${({ theme }) => theme.primaryColor};
`;
