import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { TeamsForm } from '../TeamsForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { ITeamsValues, ITeams } from '../types';
import { TeamsService } from '../../../../../services/ai/teams';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { getTimeScheduleAttributes } from '../../../../../utils/date';
import { sortByDay } from '../../../../../utils/sorting';

export const TeamsEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<ITeams>(
    [TeamsService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) => TeamsService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [TeamsService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    TeamsService.update,
  );

  const onSubmit = async (data: Partial<ITeamsValues>): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('teams_updated_notify', {
                    name: data?.team.name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: () => {
            notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: ITeamsValues = {
    name: data.name,
    skill: data.skill,
    workflow_sid: data.workflow_sid,
    timezone: data.timezone,
    is_default: data.is_default,
    time_schedules_attributes: getTimeScheduleAttributes(sortByDay(data.time_schedules)),
  };

  return (
    <Content imgBg={false}>
      <TeamsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
