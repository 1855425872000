import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Form,
  Input,
  FormInstance,
  Switch,
  Divider,
  Typography,
  Col,
  Select,
  TimePicker,
  Row,
  Flex,
} from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IDisabledState, IDisabledTimes, ITeamsValues, ITimeSchedule } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { Space } from '../../../../../components/Space';
import { TitleDesc } from '../../../AI.styles';
import { TimeState, TimezoneByCity } from '../../../../../constants';
import { snakeToCapitalCase } from '../../../../../utils/string';

const { Title, Text } = Typography;

interface Props {
  initialValues: ITeamsValues;
  onSubmit: (values: ITeamsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const TeamsForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [disabledTimes, setDisabledTimes] = useState<IDisabledTimes>({});
  const [disabledState, setDisabledState] = useState<IDisabledState>({});

  const initializeDisabled = () => {
    const initialDisabledState: IDisabledState = {};
    const initialDisabledTimes: IDisabledTimes = {};

    initialValues.time_schedules_attributes.forEach((schedule, index) => {
      initialDisabledState[index] = schedule.closed;
      initialDisabledTimes[index] = schedule.state;

      if (schedule.closed) {
        form.setFieldValue(['time_schedules_attributes', index, 'state'], '-');
        form.setFieldValue(['time_schedules_attributes', index, 'start_time'], '-');
        form.setFieldValue(['time_schedules_attributes', index, 'end_time'], '-');
      }
    });

    setDisabledState(initialDisabledState);
    setDisabledTimes(initialDisabledTimes);
  };

  useEffect(() => {
    initializeDisabled();
  }, []);

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const handleClosedChange = (checked: boolean, index: number) => {
    setDisabledState(prev => ({ ...prev, [index]: checked }));
    if (checked) {
      form.setFieldValue(['time_schedules_attributes', index, 'state'], '-');
      form.setFieldValue(['time_schedules_attributes', index, 'start_time'], '-');
      form.setFieldValue(['time_schedules_attributes', index, 'end_time'], '-');
    } else {
      setDisabledTimes(prev => ({ ...prev, [index]: false }));
      form.setFieldValue(
        ['time_schedules_attributes', index, 'start_time'],
        initialValues.time_schedules_attributes[index].start_time,
      );
      form.setFieldValue(
        ['time_schedules_attributes', index, 'end_time'],
        initialValues.time_schedules_attributes[index].end_time,
      );
      form.setFieldValue(['time_schedules_attributes', index, 'state'], false);
    }
  };

  const handleStateChange = (value: boolean, index: number) => {
    if (value) {
      form.setFieldValue(
        ['time_schedules_attributes', index, 'start_time'],
        dayjs('12:00 AM', 'h:mm A'),
      );
      form.setFieldValue(
        ['time_schedules_attributes', index, 'end_time'],
        dayjs('11:59 PM', 'h:mm A'),
      );
      setDisabledTimes(prev => ({ ...prev, [index]: true }));
    } else {
      setDisabledTimes(prev => ({ ...prev, [index]: false }));
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    formData.time_schedules_attributes = formData.time_schedules_attributes.map(
      (schedule: ITimeSchedule) => {
        const formatTime = (time: Dayjs): string => {
          return dayjs(time).format('HH:mm') === 'Invalid Date'
            ? '00:00'
            : dayjs(time).format('HH:mm');
        };

        return {
          ...(schedule.id && { id: schedule.id }),
          day: snakeToCapitalCase(schedule.day),
          start_time: formatTime(schedule.start_time),
          end_time: formatTime(schedule.end_time),
          closed: schedule.closed,
        };
      },
    );
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item
          name="is_default"
          tooltip={t('teams_default_tooltip')}
          valuePropName="checked"
          label={t('default')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name="workflow_sid"
          label={t('workflow_sid')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_workflow_sid')} />
        </Form.Item>
        <Form.Item name="skill" label={t('skill')} className={formItemClass.base}>
          <Input placeholder={t('enter_skill')} />
        </Form.Item>
      </Space>
      <Form.Item
        name="timezone"
        label={t('timezone')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
      >
        <Select showSearch placeholder={t('select_timezone')} options={TimezoneByCity} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('call_schedule')}
      </Title>
      <TitleDesc>{t('call_schedule_desc')}</TitleDesc>
      <Form.List name="time_schedules_attributes">
        {fields => (
          <Flex vertical gap={16}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
                xl: 40,
              }}
              align={'middle'}
            >
              <Col span={3}>
                <Text strong>{t('day')}</Text>
              </Col>
              <Col span={3}>
                <Text strong>{t('closed')}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{t('state')}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{t('start_time')}</Text>
              </Col>
              <Col span={6}>
                <Text strong>{t('end_time')}</Text>
              </Col>
            </Row>
            {fields.map(({ key, name }) => (
              <Row
                key={key}
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                  xl: 40,
                }}
                align={'middle'}
              >
                <Col span={3} style={{ paddingRight: 0 }}>
                  <Form.Item name={[name, 'id']} noStyle>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item name={[name, 'day']} noStyle>
                    <Text>{t(initialValues.time_schedules_attributes?.[name]?.day)}</Text>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name={[name, 'closed']} valuePropName="checked" noStyle>
                    <Switch onChange={checked => handleClosedChange(checked, key)} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[name, 'state']} noStyle>
                    {form.getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                      <Input disabled />
                    ) : (
                      <Select
                        options={TimeState}
                        disabled={disabledState[key]}
                        style={{ width: '100%' }}
                        onChange={value => handleStateChange(value, key)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[name, 'start_time']} noStyle>
                    {form.getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                      <Input disabled />
                    ) : (
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm A"
                        minuteStep={5}
                        disabled={disabledTimes[key]}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={[name, 'end_time']} noStyle>
                    {form.getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                      <Input disabled />
                    ) : (
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        format="h:mm A"
                        minuteStep={5}
                        disabled={disabledTimes[key]}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Flex>
        )}
      </Form.List>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
