import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiErrorResponse } from '../../services/types';

type ApiResponse = ApiErrorResponse & {
  [key: string]: any;
  error?: ApiErrorResponse;
};

export function useUpdate<Variables>(
  key: [string, string, any?],
  fn: MutationFunction<ApiResponse, Variables>,
  options?: UseMutationOptions<ApiResponse, unknown, Variables>,
): UseMutationResult<ApiResponse, unknown, Variables, unknown> {
  const queryClient = useQueryClient();
  return useMutation<ApiResponse, unknown, Variables>(fn, {
    onSuccess: data => {
      if (!data.error) {
        void queryClient.invalidateQueries([key[0]]);
      }
    },
    ...options,
  });
}
