import React from 'react';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Ban } from '../../assets/icons/ban.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { theme } from '../../theme';

interface Props {
  enabled: boolean;
  isStatus?: boolean;
}

export const IconIndicator: React.FC<Props> = ({ enabled, isStatus }) => {
  return enabled ? (
    <Check width={16} height={16} style={{ color: theme.successColor }} />
  ) : isStatus ? (
    <Close width={12} height={12} style={{ color: theme.errorColor }} />
  ) : (
    <Ban width={16} height={16} style={{ color: theme.errorColor }} />
  );
};
