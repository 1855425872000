import { Checkbox, Divider, Form, Input, Typography, Upload, UploadProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { FontSelector } from '../../../../../../components/FontSelector';
import { getBase64, normalizeFile } from '../../../../../../utils/files';
import { ReactComponent as UploadIcon } from '../../../../../../assets/icons/upload.svg';
import { StyledCheckboxGroup } from '../VirtualAssistant.styles';
import { IAutomationProfile } from '../../types';
import { AutomationProfiles } from './AutomationProfiles';

const { Title } = Typography;

export const General: React.FC<{ avatarUrl?: string; profiles: IAutomationProfile[] }> = ({
  avatarUrl,
  profiles,
}) => {
  const { t } = useTranslation('design');
  const [imageUrl, setImageUrl] = useState<string | undefined>(avatarUrl);
  const { setFields } = Form.useFormInstance();
  const { designMenuOptions } = useFlags();

  const handleAvatarChange: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'uploading') return;
    if (info.file.status === 'done') {
      const url = await getBase64(info.file.originFileObj as RcFile);
      setImageUrl(url);
    }
  };

  const dummyRequest: UploadProps['customRequest'] = ({ onSuccess }) => {
    setTimeout(() => onSuccess?.('ok'), 0);
  };

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setFields([{ name: ['avatar'], errors: [t('avatar_error')] }]);
    } else {
      setFields([{ name: ['avatar'], errors: [] }]);
    }
    return isLt2M;
  };

  return (
    <>
      <Space>
        <Form.Item
          name={['config', 'title']}
          label={t('virtual_assistant_title')}
          className={formItemClass.base}
        >
          <Input placeholder={t('virtual_assistant_title')} />
        </Form.Item>
        <Form.Item name={['config', 'font']} label={t('font')} className={formItemClass.base}>
          <FontSelector />
        </Form.Item>
      </Space>
      {designMenuOptions && (
        <>
          <Title level={5} type="secondary">
            {t('menu_options')}
          </Title>
          <p>{t('menu_options_desc')}</p>
          <StyledCheckboxGroup>
            <Form.Item name={['menu_items', 'print_message']} valuePropName="checked">
              <Checkbox>{t('print_message')}</Checkbox>
            </Form.Item>
            <Form.Item name={['menu_items', 'term_of_use']} valuePropName="checked">
              <Checkbox>{t('term_of_use')}</Checkbox>
            </Form.Item>
          </StyledCheckboxGroup>
        </>
      )}
      <Form.Item
        name={['avatar']}
        label={t('avatar_icon')}
        valuePropName="fileList"
        getValueFromEvent={normalizeFile}
        extra={t('avatar_icon_info')}
      >
        <Upload
          maxCount={1}
          accept=".png,.jpg,.jpeg"
          listType="picture-card"
          showUploadList={false}
          onChange={handleAvatarChange}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="project-logo" />
          ) : (
            <div>
              <UploadIcon width={12} height={12} /> {t('upload')}
            </div>
          )}
        </Upload>
      </Form.Item>
      <Divider />
      <AutomationProfiles initialProfiles={profiles} />
    </>
  );
};
