import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { ContextsService } from '../../../../../services/ai/contexts';
import { IContext } from '../types';
import { Paths } from '../../../../../types/paths';

export const ContextsList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiContextEdit({ id })),
  });

  const columns: ColumnProps<IContext>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '80%',
    },
    {
      title: t('duration'),
      onCell: onCellHandler,
      dataIndex: 'duration',
      width: '17%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IContext>
      service={ContextsService}
      columns={columns}
      getCreatePath={Paths.aiContextCreate}
      deleteConfirmationTitle={t('delete_context')}
      getDisplayName={record => record.name}
    />
  );
};
