import { ConfirmLeaveModal } from '../../../../components/ConfirmLeaveModal';
import { ReactComponent as VerifyIcon } from '../../../../assets/icons/check-circle.svg';

type Props = {
  isVisible: boolean;
  handleCancel: (key: boolean) => void;
  handleOk: () => void;
  description: string;
  title: string;
  okButtonText: string;
};

export const VerifyModal: React.FC<Props> = ({
  isVisible,
  handleCancel,
  handleOk,
  description,
  title,
  okButtonText,
}) => {
  return (
    <ConfirmLeaveModal
      titleIcon={<VerifyIcon width={15} height={15} />}
      handleCancel={() => handleCancel(!isVisible)}
      handleOk={handleOk}
      description={description}
      title={title}
      isVisible={isVisible}
      okButtonText={okButtonText}
    />
  );
};
