import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ChannelForm } from '../ChannelForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IChannelsValues, IChannels, ChannelTypes } from '../types';
import { InboundPhoneNumbersService } from '../../../../../services/ai/inbound-phone-numbers';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

export const ChannelEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id, channel } = useParams() as { id: string; channel: string };

  const { data } = useGetItem<IChannels>(
    [InboundPhoneNumbersService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      InboundPhoneNumbersService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [InboundPhoneNumbersService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    InboundPhoneNumbersService.update,
  );

  const onSubmit = async (
    data: Partial<IChannelsValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            const channelType = data?.inbound_phone_number.channel_type;
            const phoneNumber = data?.inbound_phone_number.phone_number;
            notification.success({
              message: (
                <Trans t={t}>
                  {t('channel_created_updated', {
                    channel:
                      channelType === ChannelTypes.facebook
                        ? t('channel_messenger')
                        : t(`channel_${channelType}`),
                    name: phoneNumber,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (error: any) => {
            if (error.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...error.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} breadcrumbParamTitle={t(`channel_${channel}`)} />;

  const initialValues: IChannelsValues = {
    channel_type: data.channel_type,
    phone_number: data.phone_number,
    dial_plan_id: data.dial_plan_id,
  };

  return (
    <Content imgBg={false} breadcrumbParamTitle={t(`channel_${channel}`)}>
      <ChannelForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        isChannel={channel && channel !== ChannelTypes.all ? true : false}
        isEditing
      />
    </Content>
  );
};
