import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export const StyledCodeSnippet = styled(Paragraph)`
  code {
    position: relative;
    display: block;
    padding: 44px 16px 8px;
    border: 0 none;
    white-space: break-spaces;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      content: '';
      height: 36px;
      background: ${({ theme }) => theme.secondaryBg};
    }
  }
  .ant-typography-copy {
    position: absolute;
    top: 8px;
    right: 13px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
`;
