import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { RoutersForm } from '../RoutersForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IRouterValues, IRouter } from '../types';
import { RoutersService } from '../../../../../services/ai/routers';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { base64ToObject } from '../../../../../utils/base64';

export const RoutersEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IRouter>(
    [RoutersService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      RoutersService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [RoutersService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    RoutersService.update,
  );

  const onSubmit = async (
    data: Partial<IRouterValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('router_updated_notify', {
                    name: data.router.name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (error: any) => {
            if (error.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...error.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const decodedConfig = data?.config ? base64ToObject(data.config) : {};

  const initialValues: IRouterValues = {
    name: data.name,
    outbound_contact_uri: data.outbound_contact_uri,
    protocol: data.protocol,
    routing_method: data.routing_method,
    is_default: data.is_default,
    sip_refer_enabled: data.sip_refer_enabled,
    team_id: data.team_id,
    integration_id: decodedConfig?.integration_id,
    client_id: decodedConfig?.client_id,
    client_secret: decodedConfig?.client_secret,
    region: decodedConfig?.region,
    organization_id: decodedConfig?.organization_id,
    pat_token: decodedConfig?.pat_token,
  };

  return (
    <Content imgBg={false}>
      <RoutersForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
