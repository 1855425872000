import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IAnalyticsWebhook,
  IAnalyticsWebhookValues,
} from '../../pages/Analytics/Configurations/AnalyticsWebhook/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'analytics-webhook';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAnalyticsWebhook> => {
  const response = await integrationQuery(`/api/analytics_webhooks`, {
    apiData,
    signal,
  });
  return response?.analytics_webhook || null;
};

const create = async ({ data, apiData }: { data: IAnalyticsWebhookValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/analytics_webhooks`, {
    method: 'POST',
    data: { data: { type: 'analytics_webhooks', attributes: data } },
    apiData,
  });

const update = ({
  apiData,
  data,
}: {
  apiData: IAiIntegrationUrl;
  data: Partial<IAnalyticsWebhookValues>;
}): Promise<IAnalyticsWebhook> => {
  return integrationQuery(`/api/analytics_webhooks`, {
    method: 'PATCH',
    data: { data: { type: 'analytics_webhooks', attributes: data } },
    apiData,
  });
};

export const AnalyticsWebhookService = {
  queryKey,
  get,
  create,
  update,
};
