import React from 'react';
import { ReactComponent as Exclamation } from '../../assets/icons/exclamation-circle.svg';
import { StyledAlert } from './Alert.styles';

export interface IAlert {
  title: string;
}

export const Alert: React.FC<IAlert> = ({ title, children }) => {
  return (
    <StyledAlert.Root>
      <StyledAlert.Title>
        <Exclamation width={14} height={14} /> {title}
      </StyledAlert.Title>
      {children}
    </StyledAlert.Root>
  );
};
