import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { ContextForm } from '../ContextForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IContextValues, IContext } from '../types';
import { ContextsService } from '../../../../../services/ai/contexts';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import {
  objToArray,
  transformParameters,
  retainUnderscoreHyphenPercent,
} from '../../../../../utils/ai';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { ApiResponse } from '../../../../../services/types';

export const ContextEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IContext>(
    [ContextsService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      ContextsService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading } = useUpdate(
    [ContextsService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    ContextsService.update,
  );

  const handleSuccess = (data: ApiResponse) => {
    notification.success({
      message: <Trans t={t}>{t('context_updated_notify', { name: data.context.name })}</Trans>,
    });
    navigate(-1);
  };

  const handleError = (err: any, setFields: FormInstance['setFields']) => {
    if (err.response.status === 400) {
      const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
      setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
    } else {
      notification.error(unexpectedError);
    }
  };

  const onSubmit = async (
    values: IContextValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { name, duration, parameters } = values;
    const data = {
      name: retainUnderscoreHyphenPercent(name),
      duration,
      parameters: transformParameters(parameters),
    };
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: handleSuccess,
          onError: (err: any) => handleError(err, setFields),
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IContextValues = {
    name: data.name,
    duration: data.duration,
    parameters: objToArray(JSON.parse(data.parameters)),
  };

  return (
    <Content imgBg={false}>
      <ContextForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
