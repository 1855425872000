import styled from '@emotion/styled';

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.linkColor};
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.linkColor};
    border-color: ${({ theme }) => theme.linkColor};
  }
`;
