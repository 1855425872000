import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';

export const LivechatConfig = (): JSX.Element => {
  const { t } = useTranslation('ai');

  return (
    <>
      <Space>
        <Form.Item
          name="client_id"
          label={t('client_id')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_client_id')} />
        </Form.Item>
        <Form.Item
          name="organization_id"
          label={t('organization_id')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_organization_id')} />
        </Form.Item>
      </Space>
      <Form.Item
        name="pat_token"
        label={t('pat_token')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <PasswordInput placeholder={t('enter_pat_token')} />
      </Form.Item>
    </>
  );
};
