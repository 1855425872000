import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { AnalyticsWebhookForm } from './AnalyticsWebhookForm';
import { useCreate } from '../../../../hooks/useCreate';
import { useUpdate } from '../../../../hooks/useUpdate';
import { useIntegrations } from '../../../../state/integrations';
import { unexpectedError } from '../../../../utils/notification';
import { IAnalyticsWebhookValues } from './types';
import { AnalyticsWebhookService } from '../../../../services/ai/analytics-webhook';
import { AiIntegrationParams } from '../../../../services/api';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { useAccount } from '../../../../state/account';

export const AnalyticsWebhook: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const { activeProjectId } = useAccount();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { data: webhookData, refetch } = useQuery(
    [AnalyticsWebhookService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      AnalyticsWebhookService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  useEffect(() => {
    async function fetchWebhook() {
      setIsFetching(true);
      await refetch();
      setIsFetching(false);
    }
    fetchWebhook();
  }, [activeProjectId]);

  const { mutate: createItem, isLoading: isCreateLoading } = useCreate(
    [AnalyticsWebhookService.queryKey],
    AnalyticsWebhookService.create,
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [AnalyticsWebhookService.queryKey, aiIntegration?.metadata.PROJECT_ID as string],
    AnalyticsWebhookService.update,
  );

  const onSubmit = async (data: IAnalyticsWebhookValues): Promise<void> => {
    webhookData
      ? aiIntegration &&
        (await updateItem(
          { apiData: aiIntegration, data },
          {
            onSuccess: () => {
              notification.success({ message: t('ext_webhook_req_update') });
            },
            onError: () => {
              notification.error(unexpectedError);
            },
          },
        ))
      : await createItem(
          { apiData: aiIntegration, data },
          {
            onSuccess: () => {
              notification.success({ message: t('ext_webhook_req_create') });
            },
            onError: () => {
              notification.error(unexpectedError);
            },
          },
        );
  };

  const initialValues: IAnalyticsWebhookValues = {
    enabled: webhookData?.enabled || false,
    url: webhookData?.url || '',
    username: webhookData?.username || '',
    password: webhookData?.password || '',
    headers_attributes: webhookData?.headers || [],
  };

  return (
    <Content imgBg={false}>
      {!isFetching && (
        <AnalyticsWebhookForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={
            <ActionButton
              type={webhookData ? 'save' : 'create'}
              htmlType="submit"
              loading={isCreateLoading || isEditLoading}
            />
          }
        />
      )}
    </Content>
  );
};
