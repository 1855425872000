import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { isEmpty } from 'ramda';
import { ActionButton } from '../../../../../components/ActionButton';
import { StyledEditTranslations } from '../EditTranslations.styles';
import { theme } from '../../../../../theme';

interface Props {
  isVisible: boolean;
  initialValues: any;
  setInitialValues: (value: any) => void;
  onClose: (value: boolean) => void;
  channels: string[];
  clearAllFilters: () => void;
}

export const Filters: React.FC<Props> = ({
  isVisible,
  initialValues,
  setInitialValues,
  onClose,
  channels,
  clearAllFilters,
}) => {
  const { t } = useTranslation('knowledge');
  const [form] = Form.useForm();

  useEffect(() => {
    isEmpty(initialValues) ? form.resetFields() : form.setFieldsValue(initialValues);
  }, [initialValues]);

  const onFinish = () => {
    setInitialValues(form.getFieldsValue(true));
    onClose(false);
  };

  const onClear = () => {
    clearAllFilters();
    setTimeout(() => form.resetFields(), 100);
  };

  return (
    <StyledEditTranslations.SideContent className={isVisible ? 'open' : ''}>
      <div>
        <StyledEditTranslations.SubHeader>
          <StyledEditTranslations.SubHeaderTitle style={{ textTransform: 'uppercase' }}>
            {t('filter')}
          </StyledEditTranslations.SubHeaderTitle>
          <StyledEditTranslations.LinkButton type="link" onClick={onClear}>
            {t('clear_all')}
          </StyledEditTranslations.LinkButton>
        </StyledEditTranslations.SubHeader>
        <StyledEditTranslations.Divider />
      </div>
      <StyledEditTranslations.ContentList>
        <div>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            requiredMark={false}
          >
            <StyledEditTranslations.FilterBlock>
              <h4>{t('status')}</h4>
              <Form.Item name="missing" valuePropName="checked">
                <Checkbox value="missing">
                  <StyledEditTranslations.BadgeColor outlinecolor="#CCCCCC" color="#fff" />
                  {t('untranslated')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="verified" valuePropName="checked">
                <Checkbox>
                  <StyledEditTranslations.BadgeColor color={theme.successColor} />
                  {t('verified')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="unverified" valuePropName="checked">
                <Checkbox>
                  <StyledEditTranslations.BadgeColor color={theme.progressColor} />
                  {t('unverified')}
                </Checkbox>
              </Form.Item>
            </StyledEditTranslations.FilterBlock>
            <StyledEditTranslations.FilterBlock>
              <h4>
                {t('channel')} <span>({t('presentation')})</span>
              </h4>
              {channels.sort().map(channel => (
                <Form.Item name={channel} valuePropName="checked" key={channel}>
                  <Checkbox>{channel}</Checkbox>
                </Form.Item>
              ))}
            </StyledEditTranslations.FilterBlock>
          </Form>
        </div>
      </StyledEditTranslations.ContentList>
      <ActionButton type="save" htmlType="submit" text={t('apply_changes')} onClick={onFinish} />
    </StyledEditTranslations.SideContent>
  );
};
