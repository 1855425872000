import styled from '@emotion/styled';

const EmptyContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.secondaryBg};
`;

const Editors = styled.div`
  max-height: 71.875vh;
  padding: 20px 24px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.secondaryBg};
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor};
  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    font-weight: 600;
    line-height: ${({ theme }) => theme.typography.h3.lineHeight};
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.66;
    }
  }

  button {
    color: ${({ theme }) => theme.primaryColor};
  }
  button:hover,
  button:focus {
    color: #fff;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
`;

export const StyledContentEditors = {
  Editors,
  Header,
  Pagination,
  EmptyContent,
};
