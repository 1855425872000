import React from 'react';
import { Form, Input, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IDLPTemplateValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { GoogleCloudProjectInput } from '../../../../../components/GoogleCloudProjectInput';
import { getGCPKeyfile } from '../../../../../utils/files';

interface Props {
  initialValues: IDLPTemplateValues;
  onSubmit: (values: IDLPTemplateValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const DLPTemplateForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = async () => {
    const formData = form.getFieldsValue();
    formData.gcp_keyfile = await getGCPKeyfile(formData.gcp_keyfile);
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_name')} />
      </Form.Item>
      <Form.Item
        name="deidentify_template_name"
        label={t('de_identify')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_deidentify')} />
      </Form.Item>
      <Form.Item
        name="inspect_template_name"
        label={t('inspect_template')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_inspect_template')} />
      </Form.Item>
      <GoogleCloudProjectInput initialValues={initialValues} isDLP />
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
