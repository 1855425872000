import React from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HistoryIcon } from '../../../../assets/icons/clock-rotate-left.svg';
import { TableActionButton } from '../../../../components/DataTable/DataTable.styles';

interface Props {
  id: number;
  isTextButton?: boolean;
}

export const WorkflowHistory: React.FC<Props> = ({ isTextButton }) => {
  const { t } = useTranslation('knowledge');

  return (
    <>
      {isTextButton ? (
        <Button type="link" size="small" icon={<HistoryIcon width={12} height={12} />}>
          {t('history')}
        </Button>
      ) : (
        <TableActionButton>
          <Tooltip title={t('history')}>
            <HistoryIcon width={12} height={12} />
          </Tooltip>
        </TableActionButton>
      )}
    </>
  );
};
