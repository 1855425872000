import styled from '@emotion/styled';

const Root = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.errorColor};
  background-color: rgba(255, 56, 64, 0.1);
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.typography.bodySmall.lineHeight};
  & + & {
    margin-top: 10px !important;
  }
`;

const Title = styled.div`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  color: ${({ theme }) => theme.errorColor};

  svg {
    transform: translateY(2px);
  }
`;

export const StyledAlert = {
  Root,
  Title,
};
