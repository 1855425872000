import { pipe, map, reduce, mergeRight, toPairs } from 'ramda';
import dayjs from 'dayjs';
import { IContextParameter } from '../pages/AI/Automation/Contexts/types';
import i18n from '../locales/i18n';

export const formatParamName = (name: string): string => name.replace(/(?!%)([^\w-])/gi, '');

export const formatParameter = (item: IContextParameter): Record<string, string | undefined> => ({
  [formatParamName(item.name)]: item.value?.trim() ?? '',
});

export const transformParameters = (data: IContextParameter[]): string =>
  JSON.stringify(pipe(map(formatParameter), reduce(mergeRight, {}))(data));

export const retainUnderscoreHyphenPercent = (str: string): string =>
  str.replace(/(?!%)([^\w-])/gi, '');

export const objToArray = pipe(
  toPairs,
  map(([name, value]) => ({ name: name as string, value: value as string | undefined })),
);

export const isFieldNotUnique = (
  parent: {
    [key: string]: any;
  }[],
  key: string,
  value: string,
  index: number,
): boolean => {
  return parent
    .filter((_, i) => i !== index)
    .some(v => v[key]?.toLowerCase() === value?.toLowerCase());
};

export const validateDateInput = (_: any, value: string): Promise<void> => {
  // Match the structure: YYYY-MM-DD followed by optional -word-word
  const datePattern = /^(\d{4}-\d{2}-\d{2})(?:-[a-zA-Z0-9]+)*$/;
  const format = 'YYYY-MM-DD';
  const match = value.match(datePattern);
  if (!match) {
    return Promise.reject(new Error(i18n.t('ai:api_version_validation_error')));
  }
  const date = match[1];
  const isValidDate = dayjs(date, format).format(format) === date;
  if (!isValidDate) {
    return Promise.reject(new Error(i18n.t('ai:api_version_wrong_date')));
  }
  return Promise.resolve();
};
