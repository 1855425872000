import styled from '@emotion/styled';
import { Input } from 'antd';

export const StyledFilter = styled.div`
  width: 360px;
  padding-top: 5px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.primaryColor};

  .ant-divider {
    margin: 0;
  }
`;

export const SearchInput = styled(Input)`
  &.ant-input {
    border-color: ${({ theme }) => `transparent transparent ${theme.secondaryBg}`};
    transition: none;
    &:focus {
      box-shadow: none;
    }
  }
`;
