import { Switch } from 'antd';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ILang } from '../../../../../hooks/useLanguages';

interface ILanguageItem {
  language: ILang;
  onSelected?: (ang: number, val: boolean) => void;
  selectedItem: number[];
}

export const LanguageItem: React.FC<ILanguageItem> = ({ language, onSelected, selectedItem }) => {
  const [selected, setSelected] = useState(selectedItem.includes(language.id));

  const handleSwitch = (val: boolean) => {
    setSelected(val);
    onSelected?.(language.id, val);
  };

  return (
    <StyledLanguageItem key={language.locale}>
      <Row>
        <StyledLanguage>
          {language.name}&nbsp;({language.locale})
        </StyledLanguage>
        <Switch onChange={handleSwitch} checked={selected} />
      </Row>
    </StyledLanguageItem>
  );
};

export const StyledLanguageItem = styled.div`
  padding: 5px 16px;
`;

const StyledLanguage = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
