import React, { useEffect, useState } from 'react';
import { Divider, Modal, notification } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ActionButton } from '../../../../components/ActionButton';
import { LinkBox } from '../../../../components/LinkBox';
import { IntegrationType, IProductIntegration } from '../../../../state/integrations';
import { Paths } from '../../../../types/paths';

interface IProjectCreatedModal {
  visible: boolean;
  projectName: string;
  integrations?: IProductIntegration[];
}

export const ProjectCreatedModal: React.FC<IProjectCreatedModal> = ({
  visible,
  projectName,
  integrations,
}) => {
  const { t } = useTranslation('project_settings');
  const [isVisible, setIsVisible] = useState<boolean>(visible);
  const [knowledgeMeta, setKnowledgeMeta] = useState();
  const navigate = useNavigate();

  useEffect(() => setIsVisible(visible), [visible]);

  useEffect(() => {
    const oldKnowledge = integrations?.find(item => item.type === IntegrationType.oldKnowledge);
    !!oldKnowledge?.metadata && setKnowledgeMeta(oldKnowledge.metadata);
  }, [integrations]);

  const handleCancel = () => {
    setIsVisible(false);
    navigate(Paths.projects());
    notification['success']({
      message: <Trans t={t}>{t('project_created_notify', { name: projectName })}</Trans>,
    });
  };

  return (
    <Modal
      width={640}
      open={isVisible}
      title={
        <>
          <PlusIcon width={18} height={18} /> {t('project_created')}
        </>
      }
      footer={[
        <ActionButton
          icon={<CheckIcon width={13} height={14} />}
          type="cancel"
          key="cancel"
          text={t('done')}
          onClick={handleCancel}
        />,
      ]}
    >
      <p>{t('project_successfully_created_desc')}</p>
      {knowledgeMeta && (
        <>
          <Divider />

          <p style={{ marginBottom: '20px' }}>
            <Trans t={t}>{t('view_virtual_assistant_info')}</Trans>
          </p>
        </>
      )}
      {knowledgeMeta?.['VALink'] && (
        <LinkBox url={knowledgeMeta['VALink']} title={t('view_virtual_assistant')} />
      )}
      {knowledgeMeta?.['FAQLink'] && (
        <LinkBox url={knowledgeMeta['FAQLink']} title={t('view_faq')} />
      )}
    </Modal>
  );
};
