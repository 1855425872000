import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../../state/account';
import { StyledNavbar } from './Navbar.styles';
import { Paths } from '../../types/paths';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';
import { ReactComponent as PaintIcon } from '../../assets/icons/paint-brush.svg';
import { ReactComponent as PulseIcon } from '../../assets/icons/pulse.svg';
import { ReactComponent as AiIcon } from '../../assets/icons/ai.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

export interface Props {
  modalToggle: () => void;
}

export const Navbar: React.FC<Props> = ({ modalToggle }) => {
  const { t } = useTranslation('pages_title');
  const { ldContext } = useAccount();
  const navigate = useNavigate();
  const { newKnowledge, pulse, analytics, legacyKnowledge, integration, legacyDesign } = useFlags();

  return (
    <StyledNavbar.Root>
      <StyledNavbar.Logo onClick={() => navigate(Paths.index())}>
        <LogoIcon />
      </StyledNavbar.Logo>
      <StyledNavbar.Nav>
        {/*Wait until ldContext is updated with user data*/}
        {ldContext && !ldContext.user.anonymous ? (
          <>
            {analytics && (
              <StyledNavbar.NavItem to="analytics">
                <AnalyticsIcon width={28} height={22} />
                {t('analytics')}
              </StyledNavbar.NavItem>
            )}
            {integration && (
              <StyledNavbar.NavItem to="ai">
                <AiIcon width={32} height={32} />
                {t('ai')}
              </StyledNavbar.NavItem>
            )}
            {legacyKnowledge && (
              <StyledNavbar.NavItem to="knowledge">
                <LightIcon width={26} height={26} />
                {t('knowledge')}
              </StyledNavbar.NavItem>
            )}
            {legacyDesign && (
              <StyledNavbar.NavItem to="design">
                <PaintIcon width={22} height={22} />
                {t('design')}
              </StyledNavbar.NavItem>
            )}
            {newKnowledge && (
              <StyledNavbar.NavItem to="new-knowledge">
                <LightIcon width={26} height={26} /> {t('knowledge')}
              </StyledNavbar.NavItem>
            )}
            {pulse && (
              <StyledNavbar.NavItem to="pulse">
                <PulseIcon width={26} height={22} /> {t('pulse')}
              </StyledNavbar.NavItem>
            )}
          </>
        ) : null}
      </StyledNavbar.Nav>
      <StyledNavbar.HelpButton onClick={modalToggle}>
        <QuestionIcon width={22} height={22} /> {t('need_help')}
      </StyledNavbar.HelpButton>
    </StyledNavbar.Root>
  );
};
