import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Input, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProps } from 'rc-field-form/lib/Form';
import debounce from 'lodash.debounce';
import { equals } from 'ramda';
import { validateMessages } from '../../../../../utils/validation';
import { formItemClass, theme } from '../../../../../theme';
import { CONSOLE_CHAT_ID, VAFonts } from '../../../../../constants';
import { Content } from '../../../../../components/Content';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { General } from './General';
import { Colours } from './Colours';
import { Layout } from './Layout';
import { StickyBar } from './VirtualAssistant.styles';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { IVAConfig, IVAConfigValues } from '../types';
import { DesignService } from '../../../../../services/design';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import useUpdateConfig from '../hooks/useUpdateConfig';
import useChatScript from '../hooks/useChatScript';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { getConfigAttribute, getConfigValues, getHexForColours } from '../../../../../utils/design';

export const initialColours = {
  header_background: '#10006B',
  header_text: '#FFFFFF',
  body_background: '#F2F2FA',
  body_text: '#999999',
  button_default_background: '#E2E0F0',
  button_default_text: '#10006B',
  button_hover_background: '#10006B',
  button_hover_text: '#FFFFFF',
  button_default_outline: '#10006B',
  button_hover_outline: '#05013F',
  button_default_outline_text: '#10006B',
  button_hover_outline_text: '#05013F',
  button_type: 'outline',
  chatbot_agent_background: '#FFFFFF',
  chatbot_agent_text: '#333333',
  user_background: '#10006B',
  user_text: '#FFFFFF',
  input_background: '#FFFFFF',
  input_text: '#333333',
  input_icon: '#10006B',
};

export const initialLayout = {
  icon_width: '64',
  icon_height: '64',
  x_axis_offset: '20',
  y_axis_offset: '20',
  is_speech_bubble: false,
  speech_bubble_text: '',
  window_width: '360',
  window_height: '550',
  border_radius: '0',
  advance_options: {
    text_input: true,
    avatar_icon: false,
    upload_button: false,
  },
};

export const VirtualAssistant: React.FC = () => {
  const [form] = Form.useForm<IVAConfigValues>();
  const { t } = useTranslation('design');
  const { id } = useParams() as { id: string };
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { visible, openModal, closeModal } = useModal(false);
  const { data } = useGetItem<IVAConfig>(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) => DesignService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );
  const updateVAConfig = useUpdateConfig();

  useChatScript(data?.dial_plans?.[0].id);

  const updateScriptConfig = useCallback(
    async (changedValues?: Partial<IVAConfigValues>) => {
      const values = form.getFieldsValue(true);
      const config = await getConfigAttribute(values, data?.avatar_url);
      const chat = document.getElementById(CONSOLE_CHAT_ID);
      if (chat) {
        if (
          changedValues?.config?.window_width ||
          changedValues?.config?.window_height ||
          changedValues?.config?.border_radius
        ) {
          debouncedSetChatConfig(chat, config);
        } else {
          chat.dataset.config = JSON.stringify(config);
        }
      }
    },
    [data?.avatar_url],
  );

  const debouncedUpdateScriptConfig = useMemo(
    () => debounce(() => updateScriptConfig(), 400),
    [updateScriptConfig],
  );
  const debouncedSetChatConfig = useMemo(
    () =>
      debounce((chat: HTMLElement, config: Record<string, any>) => {
        chat.dataset.config = JSON.stringify(config);
      }, 400),
    [],
  );

  useEffect(() => {
    if (data?.dial_plans?.length) {
      debouncedUpdateScriptConfig();
    }
  }, [data?.dial_plans]);

  if (!data) return <Content imgBg={false} />;

  const config = data.config?.length > 2 && JSON.parse(data.config);

  if (!config.button_type && typeof config === 'object') {
    config.button_type = 'filled';
  }

  const initialValues = {
    name: data.name,
    config: {
      title: config.title,
      font: config.font || VAFonts[2].value,
      ...getConfigValues(config, initialColours),
      ...getConfigValues(config, initialLayout),
    },
    dial_plan_ids: data.dial_plans?.map(item => item.id.toString()) || [],
    menu_items: data.menu_items?.length > 2 && JSON.parse(data.menu_items),
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue(true))) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  async function onFinish() {
    const formData = form.getFieldsValue(true);
    const config = { ...formData.config, ...getHexForColours(formData.config) };
    const payload = {
      name: formData.name,
      config: JSON.stringify(config),
      avatar: formData.avatar?.[0].originFileObj,
      dial_plan_ids: JSON.stringify(
        formData.dial_plan_ids.map((item: string | number) => Number(item)),
      ),
      menu_items: JSON.stringify(formData.menu_items),
    };
    await updateVAConfig(payload, form.setFields);
  }

  const handleValuesChange: FormProps['onValuesChange'] = async changedValues => {
    if (!form.getFieldError(['avatar']).length) {
      await updateScriptConfig(changedValues);
    }
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBar>
      <DefaultTabBar {...props} style={{ background: theme.bgColor }} />
    </StickyBar>
  );

  return (
    <Content imgBg={false}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        validateMessages={validateMessages}
        validateTrigger="onBlur"
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name={['name']}
          label={t('virtual_assistant_name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Tabs
          defaultActiveKey="general"
          items={[
            {
              label: t('general'),
              key: 'general',
              children: <General avatarUrl={data.avatar_url} profiles={data.dial_plans || []} />,
            },
            {
              label: t('colours'),
              key: 'colours',
              children: <Colours updateScriptConfig={updateScriptConfig} />,
            },
            {
              label: t('layout'),
              key: 'layout',
              children: <Layout updateScriptConfig={updateScriptConfig} />,
            },
          ]}
          renderTabBar={renderTabBar}
        />
        <FixedBottomBar>
          <FormActions>
            <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
            <ActionButton type="save" htmlType="submit" />
          </FormActions>
        </FixedBottomBar>
        <DiscardChangesModal visible={visible} closeModal={closeModal} />
      </Form>
    </Content>
  );
};
