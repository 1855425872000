import enGBIntl from 'antd/lib/locale/en_GB';
import esESIntl from 'antd/lib/locale/es_ES';
import frFRIntl from 'antd/lib/locale/fr_FR';
import nlNLIntl from 'antd/lib/locale/nl_NL';
import daDKIntl from 'antd/lib/locale/da_DK';

import i18n from '../../locales/i18n';

const localeValuesEnGb = {
  locale: 'en-gb',
  Table: {
    filterConfirm: 'Apply',
    filterReset: 'Reset',
    filterSearchPlaceholder: 'Search in filters',
  },
};
const localeValuesEs = {
  locale: 'es-es',
  Table: {
    filterConfirm: 'Aplicar',
    filterReset: 'Reiniciar',
    filterSearchPlaceholder: 'Búsqueda en filtros',
  },
};
const localeValuesFr = {
  locale: 'fr-fr',
  Table: {
    filterConfirm: 'Pomme',
    filterReset: 'Réinitialiser',
    filterSearchPlaceholder: 'Recherche dans filtres',
  },
};
const localeValuesNl = {
  locale: 'nl-nl',
  Table: {
    filterConfirm: 'Appel',
    filterReset: 'Resetten',
    filterSearchPlaceholder: 'Zoeken en filteren',
  },
};
const localeValuesDa = {
  locale: 'da-dk',
  Table: {
    filterConfirm: 'Anvend',
    filterReset: 'Nulstil',
    filterSearchPlaceholder: 'Søg i filtre',
  },
};

export const setTranslation = () => {
  switch (i18n.resolvedLanguage) {
    case 'en-GB':
      return { ...enGBIntl, ...localeValuesEnGb };
    case 'es-ES':
      return { ...esESIntl, ...localeValuesEs };
    case 'fr-FR':
      return { ...frFRIntl, ...localeValuesFr };
    case 'nl-NL':
      return { ...nlNLIntl, ...localeValuesNl };
    case 'da-DK':
      return { ...daDKIntl, ...localeValuesDa };
    default:
      return { ...enGBIntl, ...localeValuesEnGb };
  }
};
