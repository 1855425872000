import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { AutomationProfilesForm } from '../AutomationProfilesForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IAutomationProfileValues } from '../types';
import { DialPlansService } from '../../../../../services/ai/dial-plans';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

export const AutomationProfilesNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [DialPlansService.queryKey],
    DialPlansService.create,
  );

  const onSubmit = async (
    data: IAutomationProfileValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('automation_profile_created_notify', {
                  name: data.dial_plan.name,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const hasInboundPhoneNumberError = err.response.data.errors.some(
              (error: any) => 'inbound_phone_numbers.phone_number' in error,
            );
            if (hasInboundPhoneNumberError)
              notification.error({
                message: t('channel_error'),
              });
            else {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            }
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: IAutomationProfileValues = {
    integration_type: 'standard',
    platform_edition: 'ES',
    name: '',
    analyze_query_text_sentiment: undefined,
    dialogflow_agent_id: undefined,
    dlp_config_id: undefined,
    context_ids: undefined,
    fallback_config_id: undefined,
    text_to_speech_config_id: undefined,
    locale: undefined,
    audio_config_attributes: { speech_model: undefined },
    analytic_openai_summary_enabled: undefined,
    openai_summary_enabled: undefined,
    ccai_summary_enabled: undefined,
    voice_recording_config_attributes: {
      voice_recording_enabled: undefined,
      stop_on_escalation: undefined,
    },
    inbound_phone_numbers_attributes: undefined,
  };

  return (
    <Content imgBg={false}>
      <AutomationProfilesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
