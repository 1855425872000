import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Paths } from '../../../../types/paths';
import { KnowledgeParameterService } from '../../../../services/knowledge/parameter';
import { KnowledgeDestinationService } from '../../../../services/knowledge/destination';
import { useCreate } from '../../../../hooks/useCreate';
import { IKnowledgeUrl, useIntegrations } from '../../../../state/integrations';
import { unexpectedError } from '../../../../utils/notification';
import { Content } from '../../../../components/Content';
import { IDestinations, IParameterValues } from '../../types';
import { ParameterForm } from '../ParameterForm';
import { useAccount } from '../../../../state/account';
import { ActionButton } from '../../../../components/ActionButton';

export const CreateParameter: React.FC = () => {
  const { notification } = App.useApp();
  const { knowledge } = useIntegrations();
  const navigate = useNavigate();
  const { t } = useTranslation('knowledge');
  const { activeProjectId } = useAccount();

  const { data: destinations } = useQuery<IDestinations>(
    [KnowledgeDestinationService.queryKey, activeProjectId, knowledge?.externalId],
    ({ signal }) => KnowledgeDestinationService.get(knowledge as IKnowledgeUrl, signal),
    { enabled: !!knowledge },
  );

  const { mutate: createItem, isLoading } = useCreate(
    [KnowledgeParameterService.queryKey],
    KnowledgeParameterService.create,
  );

  const onSubmit = async (values: IParameterValues) => {
    await createItem(
      {
        data: knowledge,
        values: {
          configurable_id: Number(knowledge?.externalId),
          configurable_type: 'Project',
          ...values,
          children_attributes: values.children_attributes.map(parameter => ({
            configurable_id: parameter.configurable_id,
            configurable_type: 'Destination',
            key: values.key,
            value: parameter.value,
          })),
        },
      },
      {
        onSuccess: data => {
          if (!data.error) {
            navigate(Paths.knowledgeParameters());
            notification['success']({
              message: <Trans t={t}>{t('parameter_created_notify', { name: data.key })}</Trans>,
            });
          }
        },
        onError: data => {
          if (data.response.data.key[0] === 'has already been taken')
            return notification['error']({ message: t('parameter_name_exists') });
          else return notification['error'](unexpectedError);
        },
      },
    );
  };

  return (
    <Content imgBg={false}>
      <ParameterForm
        destinations={destinations?.data || []}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
