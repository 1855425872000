import React from 'react';
import { Select, SelectProps } from 'antd';

const { Option } = Select;

export type CXLocationType =
  | 'global'
  | 'europe-west1'
  | 'europe-west2'
  | 'australia-southeast1'
  | 'asia-northeast1'
  | 'asia-south1'
  | 'northamerica-northeast1'
  | 'us-central1'
  | 'us-east1'
  | 'us-west1';

export const locations: CXLocationType[] = [
  'global',
  'asia-northeast1',
  'asia-south1',
  'australia-southeast1',
  'europe-west1',
  'europe-west2',
  'northamerica-northeast1',
  'us-central1',
  'us-east1',
  'us-west1',
];

export const LocationSelect: React.FC<SelectProps> = props => {
  return (
    <Select {...props}>
      {locations.map(item => (
        <Option key={item} value={item}>
          {item}
        </Option>
      ))}
    </Select>
  );
};
