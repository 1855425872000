import React, { useState } from 'react';
import { Divider, Form, Input, Radio, RadioChangeEvent } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { validateMessages } from '../../../../../utils/validation';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { ReactComponent as Trash } from '../../../../../assets/icons/trash.svg';
import { WarningBanner } from '../../../../../components/WarningBanner';

export interface IDeleteConversationsValues {
  attribute_type?: string;
  session_uuid?: string;
  from?: string;
}

type Props = {
  submitButton: React.ReactElement;
  initialValues: { attribute_type: string };
  onSubmit: (values: IDeleteConversationsValues) => Promise<void>;
};

export const DeleteConversationsForm: React.FC<Props> = ({
  submitButton,
  initialValues,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [attributeType, setAttributeType] = useState<string>('session_uuid');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['ai', 'translation']);

  const onFinish = () => setIsVisible(true);
  const onChange = (e: RadioChangeEvent) => setAttributeType(e.target.value);
  const handleCancel = () => navigate(-1);

  return (
    <>
      <Form
        form={form}
        validateMessages={validateMessages}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Space direction="vertical" size="middle">
          <WarningBanner className={formItemClass.full} message={t('delete_conversation_banner')} />
          <Form.Item name="attribute_type" label={t('delete_by')} className={formItemClass.base}>
            <Radio.Group buttonStyle="solid" onChange={onChange} value={attributeType}>
              <Radio.Button value="session_uuid">{t('session_uuid')}</Radio.Button>
              <Radio.Button value="from">{t('phone_number', { ns: 'ai' })}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Space>

        <Divider className={formItemClass.full} />
        {attributeType === 'session_uuid' && (
          <Form.Item
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[a-zA-Z0-9]*$/,
                message: t('session_uuid_not_valid'),
              },
            ]}
            name="session_uuid"
            label={t('session_uuid')}
            className={formItemClass.base}
          >
            <Input placeholder={t('session_uuid')} />
          </Form.Item>
        )}
        {attributeType === 'from' && (
          <>
            <p>
              <Trans>{t('delete_conversation_phone_description')}</Trans>
            </p>
            <Form.Item
              rules={[
                { required: true },
                {
                  pattern: /^\+[1-9]{1}[0-9]{3,14}$/,
                  message: t('invalid_phone_number_format'),
                },
              ]}
              name="from"
              label={t('phone_number', { ns: 'ai' })}
              className={formItemClass.base}
            >
              <Input placeholder={t('phone_number', { ns: 'ai' })} />
            </Form.Item>
          </>
        )}
        <FixedBottomBar>
          <FormActions>
            <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
            {submitButton}
          </FormActions>
        </FixedBottomBar>
      </Form>
      <ConfirmationModal
        handleCancel={() => setIsVisible(false)}
        title={t('delete', { ns: 'translation' })}
        visible={isVisible}
        onDisable={async () => {
          await onSubmit(form.getFieldsValue());
          setIsVisible(false);
        }}
        confirmationText={t('delete', { ns: 'translation' }).toLowerCase()}
        description={t('delete_conversation_modal_description')}
        titleIcon={<Trash width={16} height={16} />}
        buttonType="delete"
      />
    </>
  );
};
