import React, { MutableRefObject } from 'react';
import ReactQuill from 'react-quill';
import { ParametersDropdown } from './ParametersDropdown';
import { IParameter } from '../../../../pages/Knowledge/types';
import { ReactComponent as BoldIcon } from '../../../../assets/icons/editor/icon_bold.svg';
import { ReactComponent as ItalicIcon } from '../../../../assets/icons/editor/icon_italic.svg';
import { ReactComponent as UnderlineIcon } from '../../../../assets/icons/editor/icon_underline.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/editor/icon_link.svg';
import { ReactComponent as ListOLIcon } from '../../../../assets/icons/editor/icon_list-ol.svg';
import { ReactComponent as ListULIcon } from '../../../../assets/icons/editor/icon_list-ul.svg';

type Props = {
  id?: string;
  customRef?: MutableRefObject<ReactQuill>;
  options?: IParameter[];
};

const icons = ReactQuill.Quill.import('ui/icons');
icons.bold = null;
icons.italic = null;
icons.underline = null;
icons.link = null;
icons.list.ordered = null;
icons.list.bullet = null;

export const PresentationToolbar: React.FC<Props> = ({ id, customRef, options }): JSX.Element => {
  return (
    <div id={`toolbar-${id}`}>
      <span className="ql-formats">
        <button className="ql-bold">
          <BoldIcon height={18} width={16} />
        </button>
        <button className="ql-italic">
          <ItalicIcon height={18} width={16} />
        </button>
        <button className="ql-underline">
          <UnderlineIcon height={17} width={17} />
        </button>
      </span>
      <span className="ql-formats">
        <button className="ql-link">
          <LinkIcon height={20} width={18} />
        </button>
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="bullet">
          <ListULIcon height={20} width={18} />
        </button>
        <button className="ql-list" value="ordered">
          <ListOLIcon height={20} width={18} />
        </button>
      </span>
      <span className="params">
        {options && (
          <button
            onClick={e => {
              e.preventDefault();
            }}
          >
            <ParametersDropdown customRef={customRef} options={options} />
          </button>
        )}
      </span>
    </div>
  );
};

export const PresentationToolbarLight: React.FC<Props> = ({
  id,
  customRef,
  options,
}): JSX.Element => {
  return (
    <div id={`toolbar-${id}`} style={{ height: 45 }}>
      <span className="params">
        <button
          onClick={e => {
            e.preventDefault();
          }}
        >
          {options && <ParametersDropdown customRef={customRef} options={options} />}
        </button>
      </span>
    </div>
  );
};
