import React from 'react';
import { Outlet } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { KnowledgeMenu } from './KnowledgeMenu';
import { IntroModal } from './IntroModal';

export const Knowledge: React.FC = () => {
  const { isStateLoading, isProject } = useStateLoading();

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <>
      <KnowledgeMenu />
      <Outlet />
      <IntroModal />
    </>
  );
};
