import styled from '@emotion/styled';

const Root = styled.div`
  background: var(--blue-color);
  min-height: 100vh;
  color: #fff;
  display: flex;
  font-size: ${({ theme }) => theme.typography.bodyLarge.fontSize};
  line-height: ${({ theme }) => theme.typography.bodyLarge.lineHeight};
`;
const Half = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1 0 50%;
`;

const LeftHalf = styled(Half)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const RightHalf = styled(Half)`
  padding: 3% 7%;
  @media (max-width: 425px) {
    padding: 3% 15px;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  font-size: 88px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 28px;
  color: #fff;
  text-transform: none;
`;

export const StyledPageNotFound = {
  Root,
  LeftHalf,
  RightHalf,
  Content,
  Title,
};
