import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import React from 'react';

export const timezonesOptions: Record<string, string>[] = [
  { value: 'Europe/London', label: 'Greenwich Mean Time' },
  { value: 'Europe/Madrid', label: 'Central European Time' },
  { value: 'Europe/Paris', label: 'Central European Time' },
  { value: 'Europe/Amsterdam', label: 'Central European Time' },
  { value: 'MIT', label: 'West Samoa Time' },
  { value: 'Pacific/Apia', label: 'West Samoa Time' },
  { value: 'Pacific/Niue', label: 'Niue Time' },
  { value: 'Pacific/Pago_Pago', label: 'Samoa Standard Time' },
  { value: 'America/Adak', label: 'Hawaii-Aleutian Standard Time' },
  { value: 'HST', label: 'Hawaii Standard Time' },
  { value: 'Pacific/Fakaofo', label: 'Tokelau Time' },
  { value: 'Pacific/Honolulu', label: 'Hawaii Standard Time' },
  { value: 'Pacific/Rarotonga', label: 'Cook Is. Time' },
  { value: 'Pacific/Tahiti', label: 'Tahiti Time' },
  { value: 'Pacific/Marquesas', label: 'Marquesas Time' },
  { value: 'AST', label: 'Alaska Standard Time' },
  { value: 'America/Anchorage', label: 'Alaska Standard Time' },
  { value: 'Pacific/Gambier', label: 'Gambier Time' },
  { value: 'America/Los_Angeles', label: 'Pacific Standard Time' },
  { value: 'America/Tijuana', label: 'Pacific Standard Time' },
  { value: 'America/Vancouver', label: 'Pacific Standard Time' },
  { value: 'PST', label: 'Pacific Standard Time' },
  { value: 'Pacific/Pitcairn', label: 'Pitcairn Standard Time' },
  { value: 'America/Dawson_Creek', label: 'Mountain Standard Time' },
  { value: 'America/Denver', label: 'Mountain Standard Time' },
  { value: 'America/Edmonton', label: 'Mountain Standard Time' },
  { value: 'America/Mazatlan', label: 'Mountain Standard Time' },
  { value: 'America/Phoenix', label: 'Mountain Standard Time' },
  { value: 'MST', label: 'Mountain Standard Time' },
  { value: 'PNT', label: 'Mountain Standard Time' },
  { value: 'America/Belize', label: 'Central Standard Time' },
  { value: 'America/Chicago', label: 'Central Standard Time' },
  { value: 'America/Costa_Rica', label: 'Central Standard Time' },
  { value: 'America/El_Salvador', label: 'Central Standard Time' },
  { value: 'America/Guatemala', label: 'Central Standard Time' },
  { value: 'America/Managua', label: 'Central Standard Time' },
  { value: 'America/Mexico_City', label: 'Central Standard Time' },
  { value: 'America/Regina', label: 'Central Standard Time' },
  { value: 'America/Tegucigalpa', label: 'Central Standard Time' },
  { value: 'America/Winnipeg', label: 'Central Standard Time' },
  { value: 'CST', label: 'Central Standard Time' },
  { value: 'Pacific/Easter', label: 'Easter Is. Time' },
  { value: 'Pacific/Galapagos', label: 'Galapagos Time' },
  { value: 'America/Bogota', label: 'Colombia Time' },
  { value: 'America/Cayman', label: 'Eastern Standard Time' },
  { value: 'America/Grand_Turk', label: 'Eastern Standard Time' },
  { value: 'America/Guayaquil', label: 'Ecuador Time' },
  { value: 'America/Havana', label: 'Central Standard Time' },
  { value: 'America/Indianapolis', label: 'Eastern Standard Time' },
  { value: 'America/Jamaica', label: 'Eastern Standard Time' },
  { value: 'America/Lima', label: 'Peru Time' },
  { value: 'America/Montreal', label: 'Eastern Standard Time' },
  { value: 'America/Nassau', label: 'Eastern Standard Time' },
  { value: 'America/New_York', label: 'Eastern Standard Time' },
  { value: 'America/Panama', label: 'Eastern Standard Time' },
  { value: 'America/Port-au-Prince', label: 'Eastern Standard Time' },
  { value: 'America/Porto_Acre', label: 'Acre Time' },
  { value: 'America/Rio_Branco', label: 'GMT-05:00' },
  { value: 'EST', label: 'Eastern Standard Time' },
  { value: 'IET', label: 'Eastern Standard Time' },
  { value: 'America/Anguilla', label: 'Atlantic Standard Time' },
  { value: 'America/Antigua', label: 'Atlantic Standard Time' },
  { value: 'America/Aruba', label: 'Atlantic Standard Time' },
  { value: 'America/Asuncion', label: 'Paraguay Time' },
  { value: 'America/Barbados', label: 'Atlantic Standard Time' },
  { value: 'America/Caracas', label: 'Venezuela Time' },
  { value: 'America/Cuiaba', label: 'Amazon Standard Time' },
  { value: 'America/Curacao', label: 'Atlantic Standard Time' },
  { value: 'America/Dominica', label: 'Atlantic Standard Time' },
  { value: 'America/Grenada', label: 'Atlantic Standard Time' },
  { value: 'America/Guadeloupe', label: 'Atlantic Standard Time' },
  { value: 'America/Guyana', label: 'Guyana Time' },
  { value: 'America/Halifax', label: 'Atlantic Standard Time' },
  { value: 'America/La_Paz', label: 'Bolivia Time' },
  { value: 'America/Manaus', label: 'Amazon Standard Time' },
  { value: 'America/Martinique', label: 'Atlantic Standard Time' },
  { value: 'America/Montserrat', label: 'Atlantic Standard Time' },
  { value: 'America/Port_of_Spain', label: 'Atlantic Standard Time' },
  { value: 'America/Puerto_Rico', label: 'Atlantic Standard Time' },
  { value: 'America/Santiago', label: 'Chile Time' },
  { value: 'America/Santo_Domingo', label: 'Atlantic Standard Time' },
  { value: 'America/St_Kitts', label: 'Atlantic Standard Time' },
  { value: 'America/St_Lucia', label: 'Atlantic Standard Time' },
  { value: 'America/St_Thomas', label: 'Atlantic Standard Time' },
  { value: 'America/St_Vincent', label: 'Atlantic Standard Time' },
  { value: 'America/Thule', label: 'Atlantic Standard Time' },
  { value: 'America/Tortola', label: 'Atlantic Standard Time' },
  { value: 'Antarctica/Palmer', label: 'Chile Time' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic Standard Time' },
  { value: 'Atlantic/Stanley', label: 'Falkland Is. Time' },
  { value: 'PRT', label: 'Atlantic Standard Time' },
  { value: 'America/St_Johns', label: 'Newfoundland Standard Time' },
  { value: 'CNT', label: 'Newfoundland Standard Time' },
  { value: 'AGT', label: 'Argentine Time' },
  { value: 'America/Buenos_Aires', label: 'Argentine Time' },
  { value: 'America/Cayenne', label: 'French Guiana Time' },
  { value: 'America/Fortaleza', label: 'Brazil Time' },
  { value: 'America/Godthab', label: 'Western Greenland Time' },
  { value: 'America/Miquelon', label: 'Pierre & Miquelon Standard Time' },
  { value: 'America/Montevideo', label: 'Uruguay Time' },
  { value: 'America/Paramaribo', label: 'Suriname Time' },
  { value: 'America/Sao_Paulo', label: 'Brazil Time' },
  { value: 'BET', label: 'Brazil Time' },
  { value: 'America/Noronha', label: 'Fernando de Noronha Time' },
  { value: 'Atlantic/South_Georgia', label: 'South Georgia Standard Time' },
  { value: 'America/Scoresbysund', label: 'Eastern Greenland Time' },
  { value: 'Atlantic/Azores', label: 'Azores Time' },
  { value: 'Atlantic/Cape_Verde', label: 'Cape Verde Time' },
  { value: 'Atlantic/Jan_Mayen', label: 'Eastern Greenland Time' },
  { value: 'Africa/Abidjan', label: 'Greenwich Mean Time' },
  { value: 'Africa/Accra', label: 'Greenwich Mean Time' },
  { value: 'Africa/Banjul', label: 'Greenwich Mean Time' },
  { value: 'Africa/Bissau', label: 'Greenwich Mean Time' },
  { value: 'Africa/Casablanca', label: 'Western European Time' },
  { value: 'Africa/Conakry', label: 'Greenwich Mean Time' },
  { value: 'Africa/Dakar', label: 'Greenwich Mean Time' },
  { value: 'Africa/Freetown', label: 'Greenwich Mean Time' },
  { value: 'Africa/Lome', label: 'Greenwich Mean Time' },
  { value: 'Africa/Monrovia', label: 'Greenwich Mean Time' },
  { value: 'Africa/Nouakchott', label: 'Greenwich Mean Time' },
  { value: 'Africa/Ouagadougou', label: 'Greenwich Mean Time' },
  { value: 'Africa/Sao_Tome', label: 'Greenwich Mean Time' },
  { value: 'Africa/Timbuktu', label: 'Greenwich Mean Time' },
  { value: 'Atlantic/Canary', label: 'Western European Time' },
  { value: 'Atlantic/Faeroe', label: 'Western European Time' },
  { value: 'Atlantic/Reykjavik', label: 'Greenwich Mean Time' },
  { value: 'Atlantic/St_Helena', label: 'Greenwich Mean Time' },
  { value: 'Europe/Dublin', label: 'Greenwich Mean Time' },
  { value: 'Europe/Lisbon', label: 'Western European Time' },
  { value: 'GMT', label: 'Greenwich Mean Time' },
  { value: 'UTC', label: 'Coordinated Universal Time' },
  { value: 'WET', label: 'Western European Time' },
  { value: 'Africa/Algiers', label: 'Central European Time' },
  { value: 'Africa/Bangui', label: 'Western African Time' },
  { value: 'Africa/Douala', label: 'Western African Time' },
  { value: 'Africa/Kinshasa', label: 'Western African Time' },
  { value: 'Africa/Lagos', label: 'Western African Time' },
  { value: 'Africa/Libreville', label: 'Western African Time' },
  { value: 'Africa/Luanda', label: 'Western African Time' },
  { value: 'Africa/Malabo', label: 'Western African Time' },
  { value: 'Africa/Ndjamena', label: 'Western African Time' },
  { value: 'Africa/Niamey', label: 'Western African Time' },
  { value: 'Africa/Porto-Novo', label: 'Western African Time' },
  { value: 'Africa/Tunis', label: 'Central European Time' },
  { value: 'Africa/Windhoek', label: 'Western African Time' },
  { value: 'ECT', label: 'Central European Time' },
  { value: 'Europe/Andorra', label: 'Central European Time' },
  { value: 'Europe/Belgrade', label: 'Central European Time' },
  { value: 'Europe/Berlin', label: 'Central European Time' },
  { value: 'Europe/Brussels', label: 'Central European Time' },
  { value: 'Europe/Budapest', label: 'Central European Time' },
  { value: 'Europe/Copenhagen', label: 'Central European Time' },
  { value: 'Europe/Gibraltar', label: 'Central European Time' },
  { value: 'Europe/Luxembourg', label: 'Central European Time' },
  { value: 'Europe/Malta', label: 'Central European Time' },
  { value: 'Europe/Monaco', label: 'Central European Time' },
  { value: 'Europe/Oslo', label: 'Central European Time' },
  { value: 'Europe/Prague', label: 'Central European Time' },
  { value: 'Europe/Rome', label: 'Central European Time' },
  { value: 'Europe/Stockholm', label: 'Central European Time' },
  { value: 'Europe/Tirane', label: 'Central European Time' },
  { value: 'Europe/Vaduz', label: 'Central European Time' },
  { value: 'Europe/Vienna', label: 'Central European Time' },
  { value: 'Europe/Warsaw', label: 'Central European Time' },
  { value: 'Europe/Zurich', label: 'Central European Time' },
  { value: 'ART', label: 'Eastern European Time' },
  { value: 'Africa/Blantyre', label: 'Central African Time' },
  { value: 'Africa/Bujumbura', label: 'Central African Time' },
  { value: 'Africa/Cairo', label: 'Eastern European Time' },
  { value: 'Africa/Gaborone', label: 'Central African Time' },
  { value: 'Africa/Harare', label: 'Central African Time' },
  { value: 'Africa/Johannesburg', label: 'South Africa Standard Time' },
  { value: 'Africa/Kigali', label: 'Central African Time' },
  { value: 'Africa/Lubumbashi', label: 'Central African Time' },
  { value: 'Africa/Lusaka', label: 'Central African Time' },
  { value: 'Africa/Maputo', label: 'Central African Time' },
  { value: 'Africa/Maseru', label: 'South Africa Standard Time' },
  { value: 'Africa/Mbabane', label: 'South Africa Standard Time' },
  { value: 'Africa/Tripoli', label: 'Eastern European Time' },
  { value: 'Asia/Amman', label: 'Eastern European Time' },
  { value: 'Asia/Beirut', label: 'Eastern European Time' },
  { value: 'Asia/Damascus', label: 'Eastern European Time' },
  { value: 'Asia/Jerusalem', label: 'Israel Standard Time' },
  { value: 'Asia/Nicosia', label: 'Eastern European Time' },
  { value: 'CAT', label: 'Central African Time' },
  { value: 'EET', label: 'Eastern European Time' },
  { value: 'Europe/Athens', label: 'Eastern European Time' },
  { value: 'Europe/Bucharest', label: 'Eastern European Time' },
  { value: 'Europe/Chisinau', label: 'Eastern European Time' },
  { value: 'Europe/Helsinki', label: 'Eastern European Time' },
  { value: 'Europe/Istanbul', label: 'Eastern European Time' },
  { value: 'Europe/Kaliningrad', label: 'Eastern European Time' },
  { value: 'Europe/Kiev', label: 'Eastern European Time' },
  { value: 'Europe/Minsk', label: 'Eastern European Time' },
  { value: 'Europe/Riga', label: 'Eastern European Time' },
  { value: 'Europe/Simferopol', label: 'Eastern European Time' },
  { value: 'Europe/Sofia', label: 'Eastern European Time' },
  { value: 'Europe/Tallinn', label: 'Eastern European Time' },
  { value: 'Europe/Vilnius', label: 'Eastern European Time' },
  { value: 'Africa/Addis_Ababa', label: 'Eastern African Time' },
  { value: 'Africa/Asmera', label: 'Eastern African Time' },
  { value: 'Africa/Dar_es_Salaam', label: 'Eastern African Time' },
  { value: 'Africa/Djibouti', label: 'Eastern African Time' },
  { value: 'Africa/Kampala', label: 'Eastern African Time' },
  { value: 'Africa/Khartoum', label: 'Eastern African Time' },
  { value: 'Africa/Mogadishu', label: 'Eastern African Time' },
  { value: 'Africa/Nairobi', label: 'Eastern African Time' },
  { value: 'Asia/Aden', label: 'Arabia Standard Time' },
  { value: 'Asia/Baghdad', label: 'Arabia Standard Time' },
  { value: 'Asia/Bahrain', label: 'Arabia Standard Time' },
  { value: 'Asia/Kuwait', label: 'Arabia Standard Time' },
  { value: 'Asia/Qatar', label: 'Arabia Standard Time' },
  { value: 'Asia/Riyadh', label: 'Arabia Standard Time' },
  { value: 'EAT', label: 'Eastern African Time' },
  { value: 'Europe/Moscow', label: 'Moscow Standard Time' },
  { value: 'Indian/Antananarivo', label: 'Eastern African Time' },
  { value: 'Indian/Comoro', label: 'Eastern African Time' },
  { value: 'Indian/Mayotte', label: 'Eastern African Time' },
  { value: 'Asia/Tehran', label: 'Iran Time' },
  { value: 'MET', label: 'Iran Time' },
  { value: 'Asia/Aqtau', label: 'Aqtau Time' },
  { value: 'Asia/Baku', label: 'Azerbaijan Time' },
  { value: 'Asia/Dubai', label: 'Gulf Standard Time' },
  { value: 'Asia/Muscat', label: 'Gulf Standard Time' },
  { value: 'Asia/Tbilisi', label: 'Georgia Time' },
  { value: 'Asia/Yerevan', label: 'Armenia Time' },
  { value: 'Europe/Samara', label: 'Samara Time' },
  { value: 'Indian/Mahe', label: 'Seychelles Time' },
  { value: 'Indian/Mauritius', label: 'Mauritius Time' },
  { value: 'Indian/Reunion', label: 'Reunion Time' },
  { value: 'NET', label: 'Armenia Time' },
  { value: 'Asia/Kabul', label: 'Afghanistan Time' },
  { value: 'Asia/Aqtobe', label: 'Aqtobe Time' },
  { value: 'Asia/Ashgabat', label: 'Turkmenistan Time' },
  { value: 'Asia/Ashkhabad', label: 'Turkmenistan Time' },
  { value: 'Asia/Bishkek', label: 'Kirgizstan Time' },
  { value: 'Asia/Dushanbe', label: 'Tajikistan Time' },
  { value: 'Asia/Karachi', label: 'Pakistan Time' },
  { value: 'Asia/Tashkent', label: 'Uzbekistan Time' },
  { value: 'Asia/Yekaterinburg', label: 'Yekaterinburg Time' },
  { value: 'Indian/Chagos', label: 'Indian Ocean Territory Time' },
  { value: 'Indian/Kerguelen', label: 'French Southern & Antarctic Lands Time' },
  { value: 'Indian/Maldives', label: 'Maldives Time' },
  { value: 'PLT', label: 'Pakistan Time' },
  { value: 'Asia/Calcutta', label: 'India Standard Time' },
  { value: 'IST', label: 'India Standard Time' },
  { value: 'Asia/Katmandu', label: 'Nepal Time' },
  { value: 'Antarctica/Mawson', label: 'Mawson Time' },
  { value: 'Asia/Almaty', label: 'Alma-Ata Time' },
  { value: 'Asia/Colombo', label: 'Sri Lanka Time' },
  { value: 'Asia/Dacca', label: 'Bangladesh Time' },
  { value: 'Asia/Dhaka', label: 'Bangladesh Time' },
  { value: 'Asia/Novosibirsk', label: 'Novosibirsk Time' },
  { value: 'Asia/Thimbu', label: 'Bhutan Time' },
  { value: 'Asia/Thimphu', label: 'Bhutan Time' },
  { value: 'BST', label: 'Bangladesh Time' },
  { value: 'Asia/Rangoon', label: 'Myanmar Time' },
  { value: 'Indian/Cocos', label: 'Cocos Islands Time' },
  { value: 'Asia/Bangkok', label: 'Indochina Time' },
  { value: 'Asia/Jakarta', label: 'Java Time' },
  { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk Time' },
  { value: 'Asia/Phnom_Penh', label: 'Indochina Time' },
  { value: 'Asia/Saigon', label: 'Indochina Time' },
  { value: 'Asia/Vientiane', label: 'Indochina Time' },
  { value: 'Indian/Christmas', label: 'Christmas Island Time' },
  { value: 'VST', label: 'Indochina Time' },
  { value: 'Antarctica/Casey', label: 'Western Standard Time (Australia)' },
  { value: 'Asia/Brunei', label: 'Brunei Time' },
  { value: 'Asia/Hong_Kong', label: 'Hong Kong Time' },
  { value: 'Asia/Irkutsk', label: 'Irkutsk Time' },
  { value: 'Asia/Kuala_Lumpur', label: 'Malaysia Time' },
  { value: 'Asia/Macao', label: 'China Standard Time' },
  { value: 'Asia/Manila', label: 'Philippines Time' },
  { value: 'Asia/Shanghai', label: 'China Standard Time' },
  { value: 'Asia/Singapore', label: 'Singapore Time' },
  { value: 'Asia/Taipei', label: 'China Standard Time' },
  { value: 'Asia/Ujung_Pandang', label: 'Borneo Time' },
  { value: 'Asia/Ulaanbaatar', label: 'Ulaanbaatar Time' },
  { value: 'Asia/Ulan_Bator', label: 'Ulaanbaatar Time' },
  { value: 'Australia/Perth', label: 'Western Standard Time (Australia)' },
  { value: 'CTT', label: 'China Standard Time' },
  { value: 'Asia/Jayapura', label: 'Jayapura Time' },
  { value: 'Asia/Pyongyang', label: 'Korea Standard Time' },
  { value: 'Asia/Seoul', label: 'Korea Standard Time' },
  { value: 'Asia/Tokyo', label: 'Japan Standard Time' },
  { value: 'JST', label: 'Japan Standard Time' },
  { value: 'Pacific/Palau', label: 'Palau Time' },
  { value: 'ACT', label: 'Central Standard Time (Northern Territory)' },
  { value: 'Australia/Adelaide', label: 'Central Standard Time (South Australia)' },
  {
    value: 'Australia/Broken_Hill',
    label: 'Central Standard Time (South Australia/New South Wales)',
  },
  { value: 'Australia/Darwin', label: 'Central Standard Time (Northern Territory)' },
  { value: 'AET', label: 'Eastern Standard Time (New South Wales)' },
  { value: 'Antarctica/DumontDUrville', label: "Dumont-d'Urville Time" },
  { value: 'Asia/Vladivostok', label: 'Vladivostok Time' },
  { value: 'Australia/Brisbane', label: 'Eastern Standard Time (Queensland)' },
  { value: 'Australia/Hobart', label: 'Eastern Standard Time (Tasmania)' },
  { value: 'Australia/Sydney', label: 'Eastern Standard Time (New South Wales)' },
  { value: 'Pacific/Guam', label: 'Chamorro Standard Time' },
  { value: 'Pacific/Port_Moresby', label: 'Papua New Guinea Time' },
  { value: 'Pacific/Saipan', label: 'Chamorro Standard Time' },
  { value: 'Pacific/Truk', label: 'Truk Time' },
  { value: 'Australia/Lord_Howe', label: 'Lord Howe Standard Time' },
  { value: 'Asia/Magadan', label: 'Magadan Time' },
  { value: 'Pacific/Efate', label: 'Vanuatu Time' },
  { value: 'Pacific/Guadalcanal', label: 'Solomon Is. Time' },
  { value: 'Pacific/Kosrae', label: 'Kosrae Time' },
  { value: 'Pacific/Noumea', label: 'New Caledonia Time' },
  { value: 'Pacific/Ponape', label: 'Ponape Time' },
  { value: 'SST', label: 'Solomon Is. Time' },
  { value: 'BST', label: 'Bangladesh Time' },
  { value: 'Asia/Rangoon', label: 'Myanmar Time' },
  { value: 'Indian/Cocos', label: 'Cocos Islands Time' },
  { value: 'Asia/Bangkok', label: 'Indochina Time' },
  { value: 'Asia/Jakarta', label: 'Java Time' },
  { value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk Time' },
  { value: 'Asia/Phnom_Penh', label: 'Indochina Time' },
  { value: 'Asia/Saigon', label: 'Indochina Time' },
  { value: 'Asia/Vientiane', label: 'Indochina Time' },
  { value: 'Indian/Christmas', label: 'Christmas Island Time' },
  { value: 'VST', label: 'Indochina Time' },
  { value: 'Antarctica/Casey', label: 'Western Standard Time (Australia)' },
  { value: 'Asia/Brunei', label: 'Brunei Time' },
  { value: 'Asia/Hong_Kong', label: 'Hong Kong Time' },
  { value: 'Asia/Irkutsk', label: 'Irkutsk Time' },
  { value: 'Asia/Kuala_Lumpur', label: 'Malaysia Time' },
  { value: 'Asia/Macao', label: 'China Standard Time' },
  { value: 'Asia/Manila', label: 'Philippines Time' },
  { value: 'Asia/Shanghai', label: 'China Standard Time' },
  { value: 'Asia/Singapore', label: 'Singapore Time' },
  { value: 'Asia/Taipei', label: 'China Standard Time' },
  { value: 'Asia/Ujung_Pandang', label: 'Borneo Time' },
  { value: 'Asia/Ulaanbaatar', label: 'Ulaanbaatar Time' },
  { value: 'Asia/Ulan_Bator', label: 'Ulaanbaatar Time' },
  { value: 'Australia/Perth', label: 'Western Standard Time (Australia)' },
  { value: 'CTT', label: 'China Standard Time' },
  { value: 'Asia/Jayapura', label: 'Jayapura Time' },
  { value: 'Asia/Pyongyang', label: 'Korea Standard Time' },
  { value: 'Asia/Seoul', label: 'Korea Standard Time' },
  { value: 'Asia/Tokyo', label: 'Japan Standard Time' },
  { value: 'JST', label: 'Japan Standard Time' },
  { value: 'Pacific/Palau', label: 'Palau Time' },
  { value: 'ACT', label: 'Central Standard Time (Northern Territory)' },
  { value: 'Australia/Adelaide', label: 'Central Standard Time (South Australia)' },
  {
    value: 'Australia/Broken_Hill',
    label: 'Central Standard Time (South Australia/New South Wales)',
  },
  { value: 'Australia/Darwin', label: 'Central Standard Time (Northern Territory)' },
  { value: 'AET', label: 'Eastern Standard Time (New South Wales)' },
  { value: 'Antarctica/DumontDUrville', label: "Dumont-d'Urville Time" },
  { value: 'Asia/Vladivostok', label: 'Vladivostok Time' },
  { value: 'Australia/Brisbane', label: 'Eastern Standard Time (Queensland)' },
  { value: 'Australia/Hobart', label: 'Eastern Standard Time (Tasmania)' },
  { value: 'Australia/Sydney', label: 'Eastern Standard Time (New South Wales)' },
  { value: 'Pacific/Guam', label: 'Chamorro Standard Time' },
  { value: 'Pacific/Port_Moresby', label: 'Papua New Guinea Time' },
  { value: 'Pacific/Saipan', label: 'Chamorro Standard Time' },
  { value: 'Pacific/Truk', label: 'Truk Time' },
  { value: 'Australia/Lord_Howe', label: 'Lord Howe Standard Time' },
  { value: 'Asia/Magadan', label: 'Magadan Time' },
  { value: 'Pacific/Efate', label: 'Vanuatu Time' },
  { value: 'Pacific/Guadalcanal', label: 'Solomon Is. Time' },
  { value: 'Pacific/Kosrae', label: 'Kosrae Time' },
  { value: 'Pacific/Noumea', label: 'New Caledonia Time' },
  { value: 'Pacific/Ponape', label: 'Ponape Time' },
  { value: 'SST', label: 'Solomon Is. Time' },
  { value: 'Pacific/Norfolk', label: 'Norfolk Time' },
  { value: 'Antarctica/McMurdo', label: 'New Zealand Standard Time' },
  { value: 'Asia/Anadyr', label: 'Anadyr Time' },
  { value: 'Asia/Kamchatka', label: 'Petropavlovsk-Kamchatski Time' },
  { value: 'NST', label: 'New Zealand Standard Time' },
  { value: 'Pacific/Auckland', label: 'New Zealand Standard Time' },
  { value: 'Pacific/Fiji', label: 'Fiji Time' },
  { value: 'Pacific/Funafuti', label: 'Tuvalu Time' },
  { value: 'Pacific/Majuro', label: 'Marshall Islands Time' },
  { value: 'Pacific/Nauru', label: 'Nauru Time' },
  { value: 'Pacific/Tarawa', label: 'Gilbert Islands Time' },
  { value: 'Pacific/Wake', label: 'Wake Time' },
  { value: 'Pacific/Wallis', label: 'Wallis & Futuna Time' },
  { value: 'Pacific/Chatham', label: 'Chatham Standard Time' },
  { value: 'Pacific/Enderbury', label: 'Phoenix Islands Time' },
  { value: 'Pacific/Tongatapu', label: 'Tonga Time' },
  { value: 'Pacific/Kiritimati', label: 'Line Islands Time' },
];

export const FormTimezoneSelector: React.FC<SelectProps> = props => {
  const options = timezonesOptions.map(item => ({
    label: item.value,
    value: item.value,
    key: item.value,
  }));
  return <Select {...props} options={options} />;
};
