import { pick } from 'ramda';
import { IUser } from '../pages/Projects/Users/UsersList';

export const getName = (data: IUser['external_data']): string => {
  const name = data && Object.values(pick(['firstName', 'lastName'], data)).join(' ');
  return name?.trim() ? name.trim() : '-';
};

export const formatFullName = (firstName = '', lastName = ''): string => {
  const name = `${firstName} ${lastName}`;
  return name?.trim() ? name.trim() : '-';
};
