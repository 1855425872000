import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const beforeHeight = keyframes`
  0% { border-width: 0 calc(100vw / 2) 0 0; }
  100% { border-width: 0 calc(100vw / 2) 160px 0; }
`;

const afterHeight = keyframes`
  0% { border-width: 0 0 0 calc(100vw / 2); }
  100% { border-width: 160px 0 0 calc(100vw / 2); }
`;

export const StyledSign = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.secondaryColor};
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &:before {
    top: 20%;
    border-color: transparent ${({ theme }) => theme.primaryColor} transparent transparent;
    animation: ${beforeHeight} 0.5s linear forwards 1.25s;
  }
  &:after {
    bottom: 20%;
    border-color: transparent transparent transparent ${({ theme }) => theme.primaryColor};
    animation: ${afterHeight} 0.5s linear forwards 1.25s;
  }
`;
