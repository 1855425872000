import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import schedules from '../pages/Pulse/Schedules/SchedulesList/schedules.json';
import { ISchedulePayload } from '../pages/Pulse/types';

const queryKey = 'pulse';

const getSchedules = async (
  projectName: string,
  signal: QueryFunctionContext['signal'],
): Promise<any> => {
  // this console.log is to avoid warnings in the console
  console.log(projectName, signal);
  return schedules;
};

const create = async ({ projectName, data }: any): Promise<any> => {
  // this console.log is to avoid warnings in the console
  console.log(projectName, data);
  return schedules[0];
};

const find = async ({ queryKey, signal }: QueryFunctionContext): Promise<any> => {
  // this console.log is to avoid warnings in the console
  console.log(signal);
  const schedule = await schedules.find(item => item.id === parseInt(queryKey[1] as string));
  return schedule;
};

const update = ({ id, values }: { id: string; values: ISchedulePayload }): any => {
  // this console.log is to avoid warnings in the console
  console.log(id, values);
  return null;
};

export const PulseService = {
  queryKey,
  getSchedules,
  create,
  find,
  update,
};
