import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { KnowledgeBasesForm } from '../KnowledgeBasesForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IKnowledgeBases, IKnowledgeBasesValues } from '../types';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';

interface Props {
  isModal?: boolean;
  handleCloseModal?: () => void;
  handleCreateKB?: (obj: IKnowledgeBases) => void;
  dfAgentId?: number;
}

export const KnowledgeBasesNew: React.FC<Props> = ({
  isModal,
  handleCloseModal,
  handleCreateKB,
  dfAgentId,
}) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [KnowledgeBasesService.queryKey],
    KnowledgeBasesService.create,
  );

  const onSubmit = async (
    data: IKnowledgeBasesValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('knowledge_base_created_notify', {
                  name: data['dialogflow/knowledge_base'].display_name,
                })}
              </Trans>
            ),
          });
          if (isModal && handleCreateKB) {
            handleCreateKB(data['dialogflow/knowledge_base']);
          } else navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: IKnowledgeBasesValues = {
    display_name: '',
    dialogflow_agent_id: dfAgentId || undefined,
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <KnowledgeBasesForm {...formProps} isModal={isModal} handleCloseModal={handleCloseModal} />
  ) : (
    <Content imgBg={false}>
      <KnowledgeBasesForm {...formProps} />
    </Content>
  );
};
