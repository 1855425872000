import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/ActionButton';
import { ConfirmLeaveModal } from '../../../../components/ConfirmLeaveModal';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-alt.svg';

type Props = {
  handleOk: () => void;
  handleCancel: () => void;
  isVisible: boolean;
};

export const DiscardModal: React.FC<Props> = ({ handleOk, handleCancel, isVisible }) => {
  const { t } = useTranslation(['knowledge', 'project_settings']);

  return (
    <ConfirmLeaveModal
      titleIcon={<TrashIcon height={16} width={16} />}
      description={t('confirm_discard_changes_description')}
      title={t('unsaved_changes', { ns: 'project_settings' })}
      okButton={<ActionButton type="delete" key="delete" text={t('discard')} onClick={handleOk} />}
      handleCancel={handleCancel}
      isVisible={isVisible}
    />
  );
};
