export const mockedPermissions = [
  {
    id: 1,
    permission: 'accessapproval.requests.approve',
  },
  {
    id: 2,
    permission: 'accessapproval.requests.dismiss',
  },
  {
    id: 3,
    permission: 'accessapproval.requests.get',
  },
  {
    id: 4,
    permission: 'accessapproval.requests.list',
  },
  {
    id: 5,
    permission: 'accessapproval.requests.delete',
  },
  {
    id: 6,
    permission: 'accessapproval.requests.dismiss',
  },
  {
    id: 7,
    permission: 'accessapproval.requests.get',
  },
  {
    id: 8,
    permission: 'accessapproval.requests.list',
  },
  {
    id: 9,
    permission: 'accessapproval.requests.delete',
  },
];
