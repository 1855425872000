import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { KnowledgeBaseDocumentForm } from '../KnowledgeBaseDocumentForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IKnowledgeBaseDocumentValues, IKnowledgeBaseDocument, IKnowledgeBases } from '../types';
import { KnowledgeBaseDocumentService } from '../../../../../services/ai/knowledge-base-document';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { KnowledgeBasesService } from '../../../../../services/ai/knowledge-bases';
import { OverlaySpinner } from '../../../../../components/Spinner';

export const KnowledgeBaseDocumentEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id, docId } = useParams() as { id: string; docId: string };

  const { data: knowledgeBase } = useGetItem<IKnowledgeBases>(
    [KnowledgeBasesService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      KnowledgeBasesService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { data: knowledgeBaseDocument, isLoading: isKBDLoading } =
    useGetItem<IKnowledgeBaseDocument>(
      [KnowledgeBaseDocumentService.queryKey, aiIntegration?.metadata.PROJECT_ID, id, docId],
      ({ signal }) =>
        KnowledgeBaseDocumentService.find(
          { id, docId, apiData: aiIntegration as IAiIntegrationUrl },
          signal,
        ),
      { enabled: !!id && !!aiIntegration?.url },
    );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [KnowledgeBaseDocumentService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    KnowledgeBaseDocumentService.update,
  );

  const onSubmit = async (data: Partial<IKnowledgeBaseDocumentValues>): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, docId, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            if (!data?.['dialogflow/knowledge_base_document'].missing_remotely) {
              notification.error({
                message: t('entry_no_update_deleted_df'),
              });
            } else {
              notification.success({
                message: (
                  <Trans t={t}>
                    {t('document_updated_notify', {
                      name: data?.['dialogflow/knowledge_base_document'].display_name,
                    })}
                  </Trans>
                ),
              });
            }
            navigate(-1);
          },
          onError: () => {
            notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!knowledgeBaseDocument) return <OverlaySpinner loading={isKBDLoading} />;

  const initialValues: IKnowledgeBaseDocumentValues = {
    display_name: knowledgeBaseDocument.display_name,
  };

  return (
    <Content imgBg={false} breadcrumbParamTitle={knowledgeBase?.display_name}>
      <KnowledgeBaseDocumentForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        isEditing
      />
    </Content>
  );
};
