import React, { MutableRefObject, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Dropdown, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from '../../../Scrollbar';
import { TruncatedString } from '../../../TruncatedString';
import { getHighlightedText } from '../../../../utils/formatText';
import { StyledDropdownParameter } from './ParametersDropdown.styles';
import { IParameter } from '../../../../pages/Knowledge/types';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as ParameterIcon } from '../../../../assets/icons/parameter.svg';

type Props = {
  options: IParameter[];
  customRef?: MutableRefObject<ReactQuill>;
};

type ParamsResult = {
  name: string;
  label: JSX.Element;
  value: string;
  key: number;
};

const generateParams = (data: IParameter[], text: string): ParamsResult[] => {
  return data.map(el => ({
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TruncatedString isShowFullOnHover width={170}>
          {getHighlightedText(el.key, text)}
        </TruncatedString>
        <TruncatedString align="right" color="#808080" isShowFullOnHover width={170}>
          {el.value}
        </TruncatedString>
      </div>
    ),
    key: el.id,
    name: el.key,
    value: el.value,
  }));
};
const SearchParameter = ({
  onChange,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation('knowledge');
  return (
    <div style={{ padding: '12px 16px 8px' }}>
      <Input
        placeholder={t('search_parameter')}
        onChange={onChange}
        prefix={<SearchIcon width={12} height={12} />}
        allowClear
      />
    </div>
  );
};
export const ParametersDropdown: React.FC<Props> = ({ customRef, options }) => {
  const [customQuill, setQuill] = useState<ReactQuill['editor']>();
  const [searchText, setSearchText] = useState<string>('');
  const [items, setItems] = useState<ParamsResult[]>();
  const params = generateParams(options, searchText);

  useEffect(() => {
    setItems(params);
  }, [options]);

  useEffect(() => {
    if (searchText) {
      const result = params?.filter(el =>
        el.name.toLowerCase().includes(searchText?.toLowerCase()),
      );
      setItems(result);
    } else {
      setItems(params);
    }
  }, [searchText]);

  useEffect(() => {
    const quill = customRef?.current.getEditor();
    if (quill) {
      quill.focus();
      setQuill(quill);
    }
  }, [customRef]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onClick = (e: { key: string | number }) => {
    const value = params?.find(el => String(el.key) === String(e.key));
    const formattedValue = value && '{{' + value.name + '}} ';
    if (customQuill) {
      customQuill.focus();
      const index = customQuill.getSelection()?.index;
      if (index !== undefined && formattedValue) {
        customQuill.insertText(index, formattedValue);
        customQuill.format('bold', false);
      }
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      align={{ offset: [16, 0] }}
      placement="bottomRight"
      trigger={['click']}
      dropdownRender={menu => (
        <StyledDropdownParameter>
          <Scrollbar autoHide={true} style={{ maxHeight: 400 }}>
            <SearchParameter onChange={handleSearchInput} />
            {menu}
          </Scrollbar>
        </StyledDropdownParameter>
      )}
    >
      <div>
        <ParameterIcon width={16} height={16} />
      </div>
    </Dropdown>
  );
};
