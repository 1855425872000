import { IIntent, IPresentation, Status, TextEditorPayload } from '../../pages/Knowledge/types';

type IntentsMap = {
  id?: number;
  project_id: number | string;
  destination_entity_id: number;
  status: Status;
  _destroy: boolean;
};

export const getInitialIntentsValues = (data: IPresentation['synchronisations']): string[] =>
  data.map(el => el.destination_entity.id.toString());

export const generateTextEditorPayload = (
  data?: IPresentation['content_entities'],
): TextEditorPayload => {
  const result: TextEditorPayload = {};
  data = data?.filter(el => el.language.is_default);
  data?.forEach(el => {
    result[el.channel.channel_type] = {
      payload: el.payload,
      id: el.id,
      is_using_default_channel_content: el.is_using_default_channel_content,
      language_id: el.language.id,
    };
  });
  return result;
};

export const getIntents = (
  data: IPresentation['synchronisations'] | undefined,
  values: string[],
  intents: IIntent[],
  projectId: string,
) => {
  const formattedIntents: { destination_entity_id: number }[] = intents.map(item => ({
    destination_entity_id: item.id,
  }));
  const tempMap = new Map<number, IntentsMap>();
  formattedIntents.forEach((el, i) => {
    tempMap.set(i, {
      id: data?.find(item => item.destination_entity.id === el.destination_entity_id)?.id,
      project_id: projectId,
      destination_entity_id: el.destination_entity_id,
      status: 'draft',
      _destroy: !values.find(item => item.toString() === el.destination_entity_id.toString()),
    });
  });

  const resultObj = Object.fromEntries(tempMap);
  Object.keys(resultObj).forEach(key =>
    resultObj[key]['id'] === undefined && resultObj[key]['_destroy'] ? delete resultObj[key] : {},
  );
  return resultObj;
};
