import React from 'react';
import styled from '@emotion/styled';
import { Space as AntSpace, SpaceProps } from 'antd';
import { css } from '@emotion/react';

export const Space = styled((props: SpaceProps) => {
  const { size, direction, align = 'start', ...other } = props;
  const overriddenSize =
    typeof size === 'number' ? size : size === 'small' ? 10 : size === 'middle' ? 20 : 40;
  return <AntSpace align={align} size={overriddenSize} direction={direction} {...other} />;
})`
  display: flex;
  .ant-space-item {
    max-width: 100%;
  }
  ${({ direction }) =>
    (!direction || direction === 'horizontal') &&
    css`
      row-gap: 0 !important;
      flex-wrap: wrap;
    `}

  & + .ant-divider-horizontal {
    margin-top: 0;
  }
`;
