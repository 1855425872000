import { useQueryClient } from '@tanstack/react-query';
import { QueryFunction } from '@tanstack/query-core/src/types';
import { useEffect } from 'react';

interface Props {
  totalPages: number;
  paginationPage: number;
  queryKey: any[];
  queryFn: QueryFunction;
}

export const usePrefetchData = ({ totalPages, paginationPage, queryKey, queryFn }: Props) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (paginationPage < totalPages && paginationPage !== 0) {
      queryClient.prefetchQuery({
        queryKey,
        queryFn,
      });
    }
  }, [paginationPage, totalPages]);
};
