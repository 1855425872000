import { useState } from 'react';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAiIntegrationUrl, useIntegrations } from '../../state/integrations';
import { useGetItem } from '../../hooks/useGetItem';
import { IDFAgent } from '../../pages/AI/Automation/NLUProviders/types';
import { NLUProvidersService } from '../../services/ai/nlu-providers';
import { SelectWithFooter } from '../SelectWithFooter';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface Props extends SelectProps {
  dfAgentId?: number;
  onNewKB: (value: boolean) => void;
}

export const KnowledgeBasesByAgentSelector: React.FC<Props> = ({
  dfAgentId,
  onNewKB,
  ...other
}) => {
  const { aiIntegration } = useIntegrations();
  const searchParams = 'knowledge_bases';
  const { t } = useTranslation('ai');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data } = useGetItem<IDFAgent>(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID, dfAgentId, searchParams],
    ({ signal }) =>
      NLUProvidersService.find(
        {
          id: dfAgentId?.toString(),
          searchParams,
          apiData: aiIntegration as IAiIntegrationUrl,
        },
        signal,
      ),
    { enabled: !!dfAgentId && !!aiIntegration?.url },
  );

  const handleNewKB = () => {
    setIsOpen(false);
    onNewKB(true);
  };

  const options = data?.['dialogflow/knowledge_bases']?.map(item => ({
    label: item.display_name,
    value: item.uuid,
  }));

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewKB}>
        <PlusIcon width={10} height={10} /> {t('new_knowledge_base')}
      </SelectWithFooter.Footer>
    </>
  );

  return (
    <Select
      open={isOpen}
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      options={options}
      {...other}
    ></Select>
  );
};
