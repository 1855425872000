import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export const Spinner = (): JSX.Element => <Spin indicator={antIcon} />;

Spin.setDefaultIndicator(antIcon);

export const CenteredSpinner = ({ height }: { height?: string }): JSX.Element => (
  <SpinnerWrapper propsHeight={height}>
    <Spinner />
  </SpinnerWrapper>
);

const SpinnerWrapper = styled.div<{ propsHeight?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: ${({ propsHeight }) => propsHeight || '100vh'};
`;

interface IOverlaySpinner {
  loading: boolean;
}
export const OverlaySpinner: React.FC<IOverlaySpinner> = ({ loading }): JSX.Element => {
  return (
    <Overlay isVisible={loading}>
      <Spinner />
    </Overlay>
  );
};

export const FullScreenSpinner = (): JSX.Element => (
  <FullScreenWrapper>
    <CenteredSpinner />
  </FullScreenWrapper>
);

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: all 0.3s ease;
  z-index: 1;
`;

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
`;
