import React from 'react';
import { Form, Input, Select, Switch, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AiIntegrationParams } from '../../../../../services/api';
import { useIntegrations } from '../../../../../state/integrations';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { FallbackBehaviourValues } from '../types';
import { RoutersService } from '../../../../../services/ai/routers';

interface Props {
  initialValues: FallbackBehaviourValues;
  onSubmit: (values: FallbackBehaviourValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isModal?: boolean;
  handleCloseModal?: () => void;
}

export const FallbackBehaviourForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isModal,
  handleCloseModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [RoutersService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => RoutersService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleCancel = () => {
    if (isModal) {
      handleCloseModal();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue())) {
        openModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item name="is_default" valuePropName="checked" label={t('default')}>
          <Switch />
        </Form.Item>
        <Form.Item
          name="powered_by_twilio"
          valuePropName="checked"
          label={t('raise_error_twilio')}
          tooltip={t('tooltip_powered_by_twilio')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item name="router_id" label={t('router')} className={formItemClass.base}>
        <Select placeholder={t('select_router')}>
          {data?.map(item => (
            <Select.Option key={item.id.toString()} value={item.id.toString()}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.powered_by_twilio !== currentValues.powered_by_twilio
        }
      >
        {({ getFieldValue }) =>
          !getFieldValue('powered_by_twilio') && (
            <>
              <Form.Item
                name="escalate_message"
                label={t('handoff')}
                tooltip={t('tooltip_handoff')}
                rules={[{ required: true }]}
                className={formItemClass.full}
              >
                <Input.TextArea placeholder={t('enter_message')} />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => {
                  const isTaskRouter =
                    data?.find(router => router.id.toString() === form.getFieldValue('router_id'))
                      ?.routing_method === 'task_router';
                  return (
                    isTaskRouter && (
                      <Form.Item
                        name="hangup_message"
                        label={t('no_agents')}
                        tooltip={t('tooltip_hangup')}
                        rules={[{ required: true }]}
                        className={formItemClass.full}
                      >
                        <Input.TextArea placeholder={t('enter_message')} />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeModal} />
        </>
      )}
    </Form>
  );
};
