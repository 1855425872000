import React from 'react';
import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Analytics } from './index';
import { DashboardsList } from './Reports/Dashboards/DashboardsList';
import { LooksList } from './Reports/Looks/LooksList';
import { ExploreList } from './Reports/Explore/ExploreList';
import { FAReportsList } from './Reports/FAReports/FAReportsList';
import { LookerIframe } from './Reports/LookerIframe';
import { FAReportIframe } from './Reports/FAReports/FAReportIframe';
import { Configurations } from './Configurations';
import { ConfigurationsList } from './Configurations/ConfigurationsList';
import { AnalyticsWebhook } from './Configurations/AnalyticsWebhook';
import { DLPTemplateList } from './Configurations/DLPTemplate/DLPTemplateList';
import { DLPTemplateNew } from './Configurations/DLPTemplate/DLPTemplateNew';
import { DLPTemplateEdit } from './Configurations/DLPTemplate/DLPTemplateEdit';
import { DataRetentionPolicyList } from './Configurations/DataRetentionPolicy/DataRetentionPolicyList';
import { DataRetentionPolicyEdit } from './Configurations/DataRetentionPolicy/DataRetentionPolicyEdit';
import { DeleteConversations } from './Configurations/DeleteConversations';

export const analyticRoutes = () => ({
  path: Paths.analytics(),
  element: <Analytics />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.analyticDashboards()} />,
      breadcrumb: null,
    },
    {
      path: Paths.analyticDashboards(),
      element: <DashboardsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('dashboards')}</Translation>,
    },
    {
      path: Paths.analyticLooks(),
      element: <LooksList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('looks')}</Translation>,
    },
    {
      path: Paths.analyticExplore(),
      element: <ExploreList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('explore')}</Translation>,
    },
    {
      path: Paths.analyticFAReports(),
      element: <FAReportsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('reports')}</Translation>,
    },
    {
      path: Paths.analyticConfigurations(),
      element: <Configurations />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configurations')}</Translation>,
      children: [
        {
          index: true,
          element: <ConfigurationsList />,
        },
        {
          path: Paths.analyticWebhook(),
          element: <AnalyticsWebhook />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('ext_webhook_req')}</Translation>,
        },
        {
          path: Paths.analyticDLP(),
          element: <DLPTemplateList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('dlp_template')}</Translation>,
        },
        {
          path: Paths.analyticDLPCreate(),
          element: <DLPTemplateNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.analyticDLPEdit(),
          element: <DLPTemplateEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.analyticDataRetentionPolicy(),
          element: <DataRetentionPolicyList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('data_retention_policy')}</Translation>,
        },
        {
          path: Paths.analyticDataRetentionPolicyEdit(),
          element: <DataRetentionPolicyEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.analyticsDeleteConversations(),
          element: <DeleteConversations />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('delete_conversations')}</Translation>,
        },
      ],
    },
    { path: Paths.analyticDashboardView(), element: <LookerIframe /> },
    { path: Paths.analyticLookView(), element: <LookerIframe /> },
    { path: Paths.analyticExploreView(), element: <LookerIframe /> },
    { path: Paths.analyticFAReportView(), element: <FAReportIframe /> },
  ],
});
