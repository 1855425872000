import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { ConversationProfilesService } from '../../services/ai/conversation-profiles';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';

export const ConversationProfilesSelector: React.FC<SelectProps> = props => {
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [ConversationProfilesService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      ConversationProfilesService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  return (
    <Select {...props}>
      {data?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.dialogflow_data.display_name}
        </Select.Option>
      ))}
    </Select>
  );
};
