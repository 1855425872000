import styled from '@emotion/styled';

export const FormActions = styled.div`
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FormActionsModal = styled.div`
  padding: 0 24px 10px;
  position: absolute;
  right: 0;
  bottom: 4px;

  .ant-btn-default {
    margin-right: 8px;
  }
`;
