import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface AddFieldProps extends Omit<ButtonProps, 'children'> {
  buttonText: string;
  moveUp: boolean;
}

export const AddField: React.FC<AddFieldProps> = ({
  buttonText,
  moveUp = false,
  ...other
}): JSX.Element => (
  <Container moveUp={moveUp}>
    <Button icon={<PlusIcon width={10} height={10} />} {...other}>
      {buttonText}
    </Button>
  </Container>
);

const Container = styled.div<{ moveUp: boolean }>`
  margin-top: ${({ moveUp }) => (moveUp ? '-12px' : '0')};
`;
