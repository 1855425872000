import { useMemo } from 'react';
import { useAccount } from '../../state/account';

export default function useStateLoading(): { isStateLoading: boolean; isProject: boolean } {
  const { activeAccountId, activeProjectId } = useAccount();
  const isStateLoading = useMemo(
    () => !activeAccountId && activeProjectId === undefined,
    [activeAccountId, activeProjectId],
  );

  return { isStateLoading, isProject: !!activeProjectId };
}
