import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { NLUProvidersForm } from '../NLUProvidersForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IDFAgentValues } from '../types';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { Space } from '../../../../../components/Space';
import { locations } from '../../../../Projects/ProjectsForm/LocationSelect';

export const NLUProvidersNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [NLUProvidersService.queryKey],
    NLUProvidersService.create,
  );

  const onSubmit = async (
    data: IDFAgentValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    await createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('nlu_provider_created_notify', { name: data.dialogflow_agent.display_name })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: IDFAgentValues = {
    display_name: '',
    gcp_keyfile: null,
    gcp_project_name: '',
    ccai_enabled: false,
    gcp_fulfillment_auth_keyfile: null,
    platform_edition: 'ES',
    location: locations[0],
    uuid: null,
  };

  return (
    <Content imgBg={false}>
      <Space direction="vertical" size="middle">
        <NLUProvidersForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
        />
      </Space>
    </Content>
  );
};
