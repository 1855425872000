import { Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../../components/ActionButton';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/trash-alt.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/times.svg';
import { getChannelName } from '../../utils';

type Props = {
  visible: boolean;
  channel?: string;
  close: () => void;
  action: () => void;
};

export const DeleteChannelModal: React.FC<Props> = ({
  visible,
  close,
  action,
  channel = 'web',
}) => {
  const { t } = useTranslation('knowledge');
  return (
    <Modal
      title={
        <>
          <DeleteIcon width={16} height={16} /> {t('delete_response').toUpperCase()}
        </>
      }
      centered
      open={visible}
      closeIcon={<CloseIcon width={16} height={16} />}
      className="modal-danger"
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={close} />,
        <ActionButton type="delete" key="delete" onClick={action} />,
      ]}
      onCancel={close}
    >
      <Trans>{t('confirm_channel_delete', { channel: getChannelName(channel) })}</Trans>
    </Modal>
  );
};
