import React, { useState } from 'react';
import { Form, Input, FormInstance, Switch, Radio, RadioChangeEvent } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IAutomationProfileValues, IntegrationType } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { Space } from '../../../../../components/Space';
import { DFAgentType } from '../../../../../components/DFAgentTag';
import { ConversationSummary } from './ConversationSummary';
import { AudioConfig } from './AudioConfig';
import { VoiceRecordingConfig } from './VoiceRecordingConfig';
import { Channels } from './Channels';
import { AutomationConfig } from './AutomationConfig';
import { IChannelsValues } from '../../../Channels/Channel/types';

interface Props {
  initialValues: IAutomationProfileValues;
  onSubmit: (values: IAutomationProfileValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const AutomationProfilesForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [type, setType] = useState<IntegrationType>(initialValues.integration_type ?? 'standard');
  const [platform, setPlatform] = useState<DFAgentType>(initialValues.platform_edition ?? 'ES');

  const handleTypeChange = (e: RadioChangeEvent) => {
    const val = e.target.value;
    setType(val);
    val === 'ccai'
      ? form.setFieldValue('dialogflow_agent_id', null)
      : form.setFieldValue('dialogflow_conversation_profile_id', null);
  };

  const handlePlatformChange = (e: RadioChangeEvent) => {
    const val = e.target.value;
    setPlatform(val);
    form.setFieldValue('dialogflow_agent_id', null);
    form.setFieldValue('locale', null);
  };

  const handleChannels = (channelList: IChannelsValues[]) => {
    const initialChannels = initialValues.inbound_phone_numbers_attributes;
    const formChannels = new Map();
    channelList && channelList.forEach(item => formChannels.set(item.id, item));

    const channels = initialChannels?.map(item => {
      const formItem = formChannels.get(item.id);
      if (formItem) {
        return { ...item, ...formItem };
      } else {
        return { ...item, _destroy: true };
      }
    });

    channelList &&
      channelList.forEach(item => {
        if (!item.id) {
          channels?.push({ channel_type: item.channel_type, phone_number: item.phone_number });
        }
      });

    return channels;
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();

    formData.integration_type === 'standard'
      ? (formData.dialogflow_conversation_profile_id = null)
      : (formData.dialogflow_agent_id = null);

    ['platform_edition', 'integration_type'].forEach(field => {
      delete formData[field];
    });

    if (initialValues.inbound_phone_numbers_attributes)
      formData.inbound_phone_numbers_attributes = handleChannels(
        formData.inbound_phone_numbers_attributes,
      );

    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item
          name="analyze_query_text_sentiment"
          valuePropName="checked"
          label={t('sentiment_analysis')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item
        name="integration_type"
        label={t('integration_type')}
        className={formItemClass.base}
      >
        <Radio.Group buttonStyle="solid" onChange={handleTypeChange} value={type}>
          <Radio.Button value="standard">{t('standard')}</Radio.Button>
          <Radio.Button value="ccai">CCAI</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="platform_edition"
        label={t('platform_edition')}
        className={formItemClass.base}
      >
        <Radio.Group
          buttonStyle="solid"
          onChange={handlePlatformChange}
          value={platform}
          disabled={type === 'ccai'}
        >
          <Radio.Button value="ES">ES</Radio.Button>
          <Radio.Button value="CX">CX</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <AutomationConfig type={type} platform={platform} />
      <ConversationSummary />
      <AudioConfig platform={platform} />
      <VoiceRecordingConfig />
      <Channels initialValues={initialValues} />
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
