import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Tag } from 'antd';
import { formItemClass } from '../../../../../theme';
import { validateMessages } from '../../../../../utils/validation';

export const EmailsInput: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const form = Form.useFormInstance();
  const emails = Form.useWatch(['emails'], form);

  const handleClose = (removedEmail: string) => {
    const newEmails = emails.filter((email: string) => email !== removedEmail);
    form.setFieldsValue({ emails: newEmails });
  };

  const handleInputConfirm = async (
    e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    try {
      const emailVal = form.getFieldValue('email');
      await form.validateFields(['email']);
      if (emailVal && emails.indexOf(emailVal) === -1) {
        form.setFieldsValue({ emails: [...emails, emailVal] });
        form.setFieldsValue({ email: '' });
      }
    } catch (e) {}
  };

  return (
    <>
      <Form.Item
        label={t('invite_new_user')}
        className={formItemClass.full}
        style={{ marginBottom: 2 }}
        name={'email'}
        validateTrigger={['onBlur']}
        rules={[
          { type: 'email' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('emails')?.length) return Promise.resolve();
              if (!value) return Promise.reject(new Error(validateMessages.required()));
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          placeholder={t('enter_email_address')}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      </Form.Item>
      {emails?.map((email: string) => (
        <Tag key={email} closable={true} onClose={() => handleClose(email)}>
          {email}
        </Tag>
      ))}
      <Form.Item hidden={true} name="emails">
        <input />
      </Form.Item>
    </>
  );
};
