import React, { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from 'antd';
import { DataTable } from '../../../../../components/DataTable';
import { DataType, IPermission } from '../index';
const Search = Input.Search;

import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { ReactComponent as Cancel } from '../../../../../assets/icons/cancel.svg';

interface IPermissionModal {
  permissionModalVisible: boolean;
  setPermissionModalVisible: Dispatch<SetStateAction<boolean>>;
  selectedPermissionsData?: DataType[];
  setSelectedPermissionsData: Dispatch<SetStateAction<DataType[]>>;
  mockedData: DataType[];
}

export const PermissionModal: React.FC<IPermissionModal> = ({
  permissionModalVisible,
  setPermissionModalVisible,
  mockedData,
  selectedPermissionsData = [],
  setSelectedPermissionsData,
}) => {
  const { t } = useTranslation('project_settings');

  const [searchInput, setSearchInput] = useState<string>('');
  const [data, setDate] = useState(mockedData);
  const [selectedPermissions, setSelectedPermissions] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const filteredData = data.filter(item => !selectedPermissionsData.includes(item));

  const columns = [
    {
      title: t('permission'),
      dataIndex: 'permission',
      sorter: (a: IPermission, b: IPermission) => a.permission.localeCompare(b.permission),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const perm = data.filter(item => newSelectedRowKeys.includes(item.id));
    setSelectedPermissions(perm);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectRow = (record: DataType) => {
    const selectedKeys = [...selectedRowKeys];
    selectedKeys.indexOf(record.id) >= 0
      ? selectedKeys.splice(selectedKeys.indexOf(record.id), 1)
      : selectedKeys.push(record.id);
    setSelectedRowKeys(selectedKeys);
    onSelectChange(selectedKeys);
  };

  const dataFilterByPermission = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
    const filtered = mockedData?.filter((char: DataType) =>
      char.permission.includes(e.currentTarget.value),
    );
    setDate(filtered);
  };

  const handlePermissionOk = () => {
    setPermissionModalVisible(false);
    setSelectedPermissionsData([...selectedPermissionsData, ...selectedPermissions]);
    setSelectedRowKeys([]);
    setSearchInput('');
    setDate(mockedData);
  };
  const handlePermissionCancel = () => {
    setPermissionModalVisible(false);
    setSearchInput('');
    setDate(mockedData);
  };

  return (
    <Modal
      title={t('add_permissions')}
      open={permissionModalVisible}
      onOk={handlePermissionOk}
      onCancel={handlePermissionCancel}
      okText={t('add')}
      okButtonProps={{
        disabled: selectedRowKeys.length > 0 ? false : true,
        icon: <PlusIcon width={15} height={15} />,
      }}
      cancelText={t('cancel')}
      cancelButtonProps={{ icon: <Cancel width={10} height={10} /> }}
      width={640}
      closable={false}
    >
      <Search
        onChange={dataFilterByPermission}
        value={searchInput}
        placeholder={t('search_permission')}
        style={{ marginBottom: 10 }}
      />

      <DataTable
        dataSource={filteredData}
        columns={columns}
        rowSelection={rowSelection}
        rowKey={record => record.id}
        onRow={record => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      />
      <div style={{ height: 0, transform: 'translateY(-44px)' }}>
        {selectedRowKeys.length === 0 ? '- ' : selectedRowKeys.length} /{' ' + filteredData?.length}{' '}
        {t('of_permissions')}
      </div>
    </Modal>
  );
};
