import { SelectProps } from 'antd';

export const filterSelectOptions: SelectProps['filterOption'] = (inputValue, option) =>
  option?.children
    ? option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
    : false;

export interface IdValue {
  value: string;
}

export const findByBiggestId = (array: IdValue[]): string => {
  return array
    .reduce((prev: IdValue, current: IdValue) =>
      Number(prev.value) > Number(current.value) ? prev : current,
    )
    .value.toString();
};
