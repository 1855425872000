import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Input, Typography } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

const { Text } = Typography;

type Props = InputProps & {
  valueToCopy?: string;
};

export const CopyableInput: React.FC<Props> = ({ valueToCopy, value, ...rest }) => {
  return (
    <Input
      value={value || valueToCopy}
      suffix={
        <StyledText
          disabled={value === '' || valueToCopy === ''}
          copyable={{
            text: valueToCopy || value?.toString(),
            icon: [
              <CopyIcon key="copy-icon" width={14} height={14} />,
              <CheckIcon key="copied-icon" width={14} height={14} />,
            ],
          }}
        />
      }
      {...rest}
    />
  );
};

const StyledText = styled(Text)<{ disabled: boolean }>`
  display: flex;
  .ant-typography-copy {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
  ${({ disabled, theme }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      svg {
        color: ${theme.secondaryBg};
      }
    `}
`;
