import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { ITextToSpeechConfig } from '../types';
import { Paths } from '../../../../../types/paths';

export const TextToSpeechList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiTextToSpeechEdit({ id })),
  });

  const columns: ColumnProps<ITextToSpeechConfig>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('provider'),
      onCell: onCellHandler,
      dataIndex: 'tts_provider',
      width: '15%',
    },
    {
      title: t('voice_type'),
      onCell: onCellHandler,
      dataIndex: 'voice',
    },
    {
      title: t('language'),
      onCell: onCellHandler,
      dataIndex: 'locale',
    },
    {
      title: t('voice_speed'),
      onCell: onCellHandler,
      dataIndex: 'speaking_rate',
      width: '10%',
    },
    {
      title: t('pitch'),
      onCell: onCellHandler,
      dataIndex: 'pitch',
    },

    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<ITextToSpeechConfig>
      service={TextToSpeechService}
      columns={columns}
      getCreatePath={Paths.aiTextToSpeechCreate}
      deleteConfirmationTitle={t('delete_profile')}
      getDisplayName={record => record.name}
    />
  );
};
