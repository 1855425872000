import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { FixedNav } from '../../components/FixedNav';
import useSelectedMenuItem from '../../hooks/useSelectedMenuItem';
import { ReactComponent as InsightIcon } from '../../assets/icons/insights.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/schedules.svg';

export const PulseMenu = (): JSX.Element => {
  const { t } = useTranslation('pulse');

  const menuItems = [
    {
      key: '/pulse/insights',
      icon: <InsightIcon width={15} height={15} />,
      label: <Link to="/pulse/insights">{t('insights')}</Link>,
    },
    {
      key: '/pulse/schedules',
      icon: <ScheduleIcon width={15} height={15} />,
      label: <Link to="/pulse/schedules">{t('schedules')}</Link>,
    },
  ];

  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
