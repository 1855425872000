import styled from '@emotion/styled';
import { Button, Card as AntdCard, Modal as AntModal } from 'antd';

const Modal = styled(AntModal)`
  max-width: 480px;
  margin: 0;

  @media (min-width: 1440px) {
    max-width: 724px;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    .slick-slide {
      height: auto;
      & > div {
        height: 100%;
      }
    }
  }

  .circle-dots {
    bottom: 30px;
    align-items: center;
    height: 20px;
    margin-left: 80px;
    margin-right: 80px;
    & li {
      margin: 0 2px;
      & > button {
        background: ${({ theme }) => theme.secondaryBg};
        border-radius: 50%;
        opacity: 1;
      }
    }
    & li.slick-active {
      & > button {
        background: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

const Card = styled(AntdCard)`
  display: flex !important;
  flex-direction: column;
  height: 100%;
  max-height: 660px;
  margin: 0;
  padding: 0;
`;

const Image = styled.div`
  height: 200px;
  background-color: ${({ theme }) => theme.secondaryBg};
  overflow: hidden;
  @media (min-width: 1440px) {
    height: 388px;
  }

  img {
    position: relative;
    top: 18%;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 28px;
`;

const Subtitle = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  text-transform: uppercase;
  color: ${({ theme }) => theme.lightPurpleColor};
`;

const Title = styled.h4`
  margin: 4px 0 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.333;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

const Text = styled.p`
  max-width: 480px;
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
`;

const Navigation = styled.div`
  button:not(:last-of-type) {
    margin-right: 8px;
  }
`;

const SkipButton = styled(Button)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  padding: 0;
  line-height: 1.43;
  color: ${({ theme }) => theme.linkColor};

  &:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):hover,
  &:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):focus {
    text-decoration: underline !important;
    color: ${({ theme }) => theme.linkColor};
    background-color: transparent;
  }
`;

export const StyledIntroModal = {
  Modal,
  Card,
  Image,
  Content,
  Subtitle,
  Title,
  Text,
  Navigation,
  SkipButton,
};
