import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/es/interface';
import { useNavigate } from 'react-router-dom';
import { equals } from 'ramda';
import { IAccountsValues } from '../types';
import { formItemClass } from '../../../theme';
import { FixedBottomBar } from '../../../components/FixedBottomBar';
import { FormActions } from '../../../components/FormActions';
import { ActionButton } from '../../../components/ActionButton';
import { DiscardChangesModal } from '../../../components/DiscardChangesModal';
import { useModal } from '../../../hooks/useModal';
import { AccountControl } from './AccountControl';

interface IAccounts {
  initialValues?: Partial<IAccountsValues>;
  onSubmit: (values: IAccountsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEdit?: boolean;
}

export const AccountsForm: React.FC<IAccounts> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEdit,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation('account_settings');
  const { visible, openModal, closeModal } = useModal(false);

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    onSubmit(formData, form.setFields);
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_name')} />
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
      {isEdit && <AccountControl />}
    </Form>
  );
};
