import React from 'react';
import { Outlet } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { PulseMenu } from './PulseMenu';

export const Pulse: React.FC = () => {
  const { isStateLoading, isProject } = useStateLoading();

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <>
      <PulseMenu />
      <Outlet />
    </>
  );
};
