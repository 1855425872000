import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { getAuthConfig } from '../utils/auth';
import { WithRequired } from '../types';
import { IAiIntegrationUrl } from '../state/integrations';

const authMiddlewareEndpoint =
  process.env.NODE_ENV === 'test'
    ? 'https://authmiddleware.staging-sabio.cloud/api/v1'
    : window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT;

const keycloakTokenEndpoint =
  process.env.NODE_ENV === 'test'
    ? ''
    : window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_TOKEN_ENDPOINT;

const consoleManagerEndpoint =
  process.env.NODE_ENV === 'test'
    ? 'https://console.manager.api.staging-sabio.cloud/api/v1'
    : window.__RUNTIME_CONFIG__.REACT_APP_CONSOLE_MANAGER_API_ENDPOINT;

const faReportsDataFileUrl =
  process.env.NODE_ENV === 'test'
    ? 'https://console-flex-api.ap.sabio.cloud/CSS/aqua/reports/reports.json'
    : window.__RUNTIME_CONFIG__.REACT_APP_API_FA_DATA_FILE_URL;

export type QueryOptions<D = any> = Omit<AxiosRequestConfig<D>, 'url'>;

export const keycloakQuery: (url: string, options?: QueryOptions) => Promise<any> = async (
  url: string,
  options: QueryOptions = {},
): Promise<any> => {
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'text/plain',
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const config: AxiosRequestConfig = {
    url: keycloakTokenEndpoint + url,
    method,
    headers,
    data,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    return formatApiErrors(e);
  }
};

export const authMiddlewareQuery = async (
  url: string,
  options: QueryOptions = {},
): Promise<any> => {
  const authConfig = getAuthConfig();
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'text/plain',
    'Content-Type': 'application/json-patch+json',
  };

  Object.assign(headers, {
    ...(authConfig?.access_token && {
      Authorization: `Bearer ${authConfig.access_token}`,
    }),
  });

  const config: AxiosRequestConfig = {
    url: authMiddlewareEndpoint + url,
    method,
    headers,
    data,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    return formatApiErrors(e);
  }
};

export type AiIntegrationParams = { apiData: IAiIntegrationUrl };
export const integrationQuery: (
  url: string,
  options: QueryOptions & { apiData: IAiIntegrationUrl },
) => Promise<any> = async (url, options) => {
  const { method = 'GET', data = null, apiData, ...other } = options;
  const { PROJECT_ID } = apiData.metadata;
  const headers = options.headers || {
    Accept: 'text/plain',
    'Content-Type': 'application/json-patch+json',
  };

  const authConfig = getAuthConfig();

  Object.assign(headers, {
    ...(authConfig?.access_token && {
      Authorization: 'Bearer ' + authConfig.access_token,
    }),
  });
  Object.assign(headers, { PROJECT_ID });

  const config: AxiosRequestConfig = {
    url: options.apiData.url + url,
    method,
    headers,
    data,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const fAReportsQuery: (options?: QueryOptions) => Promise<any> = async (
  options: QueryOptions = {},
): Promise<any> => {
  const { method = 'GET', ...other } = options;
  const headers = {
    Accept: 'application/json',
  };

  const config: AxiosRequestConfig = {
    url: faReportsDataFileUrl,
    method,
    headers,
    ...other,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
export const consoleManagerQuery: (url: string, options?: QueryOptions) => Promise<any> = async (
  url: string,
  options: QueryOptions = {},
): Promise<any> => {
  const authConfig = getAuthConfig();
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'application/json',
  };
  Object.assign(headers, {
    ...(authConfig?.access_token && {
      Authorization: 'Bearer ' + authConfig.access_token,
    }),
  });
  const config: AxiosRequestConfig = {
    url: consoleManagerEndpoint + url,
    method,
    headers,
    data,
    ...other,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const knowledgeQuery: (
  url: string,
  options: WithRequired<
    QueryOptions<{
      externalId: string;
      url: string;
      metadata: {
        AUTH_TOKEN: string;
        AUTH_PROJECT_LIST: string;
        PROJECT_ID: string;
      };
      payload?: any;
    }>,
    'data'
  >,
) => Promise<any> = async (url, options): Promise<any> => {
  const { method = 'GET', data = null, ...other } = options;
  const authConfig = getAuthConfig();
  const headers = {
    ContentType: 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
    ...options.headers,
  };
  Object.assign(headers, {
    ...options.data.metadata,
    ...(authConfig?.access_token && {
      Authorization: 'Bearer ' + authConfig.access_token,
      'Content-Type': 'application/vnd.api+json',
    }),
  });
  const URL = url === '/channels' ? url : `/projects/${options.data.externalId}${url}`;
  const config: AxiosRequestConfig = {
    url: `${options.data.url}/api/v1/admin${URL}`,
    method,
    headers,
    data: data?.payload,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const analyticsQuery: (
  url: string,
  options: QueryOptions & { analyticsUrl: string },
) => Promise<any> = async (url, options) => {
  const authConfig = getAuthConfig();
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'application/json',
  };
  Object.assign(headers, {
    ...(authConfig?.access_token && {
      Authorization: 'Bearer ' + authConfig.access_token,
    }),
  });

  const config: AxiosRequestConfig = {
    url: options.analyticsUrl + url,
    method,
    headers,
    data,
    paramsSerializer: (params: Record<string, any>) => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    ...other,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

const formatApiErrors = (error: any) => ({
  error: {
    status: error.response?.status,
    data: error.response?.data,
    message: error.message,
  },
});
