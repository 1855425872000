import React from 'react';
import { Translation } from 'react-i18next';
import { UserSettings } from './index';
import { Paths } from '../../types/paths';

export const userSettingsRoutes = () => ({
  path: Paths.userSettings(),
  element: <UserSettings />,
  breadcrumb: () => <Translation ns={'pages_title'}>{t => t('user_settings')}</Translation>,
});
