import { IDFAgent } from '../../Automation/NLUProviders/types';
import { DocumentStatus } from '../../../../utils/documents';

export interface IKnowledgeBases {
  id: number;
  display_name: string;
  dialogflow_agent: IDFAgent;
  documents: {
    count: number;
  };
}

export interface IKnowledgeBasesValues {
  display_name: string;
  dialogflow_agent_id: number | undefined;
}

export interface IKnowledgeBaseDocument {
  id: number;
  display_name: string;
  status: DocumentStatus;
}

export interface IKnowledgeBaseDocumentValues {
  display_name: string;
  knowledge_types?: string | null;
  dialogflow_knowledge_base_id?: string;
  mime_type?: string;
  enable_auto_reload?: boolean;
  metadata?: [];
  content_uri?: string;
  raw_content?: string;
}

export enum KnowledgeBasesDocTypes {
  FAQ = 'FAQ',
  CSV = 'CSV',
  EXTRACTIVE_QA = 'EXTRACTIVE_QA',
  ARTICLE_SUGGESTION = 'ARTICLE SUGGESTION',
}
