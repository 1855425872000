import React from 'react';
import { useTranslation } from 'react-i18next';
import { LegalWrapper } from '../../components/LegalWrapper';

export const FairUsePolicy: React.FC = () => {
  const { t } = useTranslation('legal');

  return (
    <LegalWrapper title={t('fair_use_policy')}>
      <h2>{t('fair_use_policy_content.introduction_title')}</h2>
      <p>{t('fair_use_policy_content.introduction_text_one')}</p>
      <p>{t('fair_use_policy_content.introduction_text_two')}</p>
      <h2>{t('fair_use_policy_content.fair_usage_limits_title')}</h2>
      <h3>{t('fair_use_policy_content.fair_usage_limits_subtitle_one')}</h3>
      <p>{t('fair_use_policy_content.fair_usage_limits_text_one')}</p>
      <h3>{t('fair_use_policy_content.fair_usage_limits_subtitle_two')}</h3>
      <p>{t('fair_use_policy_content.fair_usage_limits_text_two')}</p>
      <h2>{t('fair_use_policy_content.consequences_title')}</h2>
      <p>{t('fair_use_policy_content.consequences_text_one')}</p>
      <p>{t('fair_use_policy_content.consequences_text_two')}</p>
      <h2>{t('fair_use_policy_content.updates_policy_title')}</h2>
      <p>{t('fair_use_policy_content.updates_policy_text_one')}</p>
    </LegalWrapper>
  );
};
