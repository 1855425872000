import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '../../components/Content';
import { Space } from '../../components/Space';
import { UserNav } from './UserNav';
import { UserSettingsForm } from './UserSettingsForm';

export const UserSettings: React.FC = () => {
  const { t } = useTranslation('user_settings');

  return (
    <>
      <UserNav />
      <Content header={false}>
        <Space direction="vertical" size={20}>
          <span>{t('form_description')}</span>
          <UserSettingsForm />
        </Space>
      </Content>
    </>
  );
};
