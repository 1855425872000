import React, { useState } from 'react';
import { Form, FormItemProps, Input, InputProps } from 'antd';
import { StyledFloatItem } from './FloatField.styles';
import { LoginFormErrors } from '../../pages/Login/ErrorMessages';
import { PasswordInput } from '../PasswordInput';

type Props = Omit<FormItemProps, 'help'> &
  Pick<InputProps, 'placeholder' | 'onBlur' | 'type' | 'onChange'> & {
    setErrors?: (value: LoginFormErrors) => void;
  };

export const FloatField = (props: Props): JSX.Element => {
  const { name, onBlur, type, placeholder, setErrors, onChange, ...other } = props;
  const [focus, setFocus] = useState(false);
  const form = Form.useFormInstance();
  const isOccupied = focus || (!!name && form.getFieldValue(name));

  const handleOnBlur = async (value: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    onBlur && onBlur(value);
    if (name && setErrors) {
      setErrors({});
      await form.validateFields().catch(({ errorFields }) => {
        const fieldName = Array.isArray(errorFields[0].name) ? errorFields[0].name.join('.') : name;
        setErrors({ [fieldName]: errorFields[0].errors[0] });
      });
    }
  };

  const handleFocus = () => setFocus(true);

  return (
    <StyledFloatItem name={name} occupied={!!isOccupied} help="" {...other}>
      {type === 'password' ? (
        <PasswordInput
          type={type}
          placeholder={placeholder}
          onBlur={handleOnBlur}
          onFocus={handleFocus}
          {...(onChange && { onChange: onChange })}
        />
      ) : (
        <Input
          type={type}
          placeholder={placeholder}
          onBlur={handleOnBlur}
          onFocus={handleFocus}
          {...(onChange && { onChange: onChange })}
        />
      )}
    </StyledFloatItem>
  );
};
