import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tag, TagProps } from 'antd';

export type DFAgentType = 'ES' | 'CX';

interface Props extends TagProps {
  type: DFAgentType;
  disabled?: boolean;
}

export const DFAgentTag = (props: Props): JSX.Element => {
  const { type, disabled, children, ...other } = props;
  return (
    <Root type={type} disabled={disabled} {...other}>
      {children}
    </Root>
  );
};

interface RootProps {
  type: DFAgentType;
  disabled?: boolean;
}

const Root = styled(Tag)<RootProps>`
  width: 38px;
  font-weight: 600;
  margin: auto 0 auto auto;
  text-align: center;

  ${props =>
    props.type === 'CX' &&
    css`
      border: 1px solid #5e1bb3;
      color: #5e1bb3;
      background-color: rgba(94, 27, 179, 0.1);
    `};

  ${props =>
    props.type === 'ES' &&
    css`
      border: 1px solid #0047fa;
      color: #0047fa;
      background-color: rgba(0, 71, 250, 0.1);
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;
