import React, { useState } from 'react';
import { Form, Input, Switch, FormInstance, Divider, Typography } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { AnalyticsWebhookHeaders, IAnalyticsWebhookValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { AddField } from '../../../../../components/AddField';
import { useWindowDimensions } from '../../../../../utils/useWindowDimensions';
import { Space } from '../../../../../components/Space';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { InputWithRemove } from '../../../../../components/InputWithRemove';

const { Title } = Typography;

interface Props {
  initialValues: IAnalyticsWebhookValues;
  onSubmit: (values: IAnalyticsWebhookValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const AnalyticsWebhookForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [isHeaderError, setIsHeaderError] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const checkHeaderError = async () => {
    const parameters = form.getFieldValue('headers_attributes');
    if (parameters && parameters.length > 0) {
      const lastIndex = parameters.length - 1;
      const fieldToValidate =
        width <= 873
          ? ['headers_attributes', lastIndex, 'value']
          : ['headers_attributes', lastIndex, 'key'];

      try {
        await form.validateFields([fieldToValidate]);
        setIsHeaderError(false);
      } catch ({ errorFields }) {
        errorFields && setIsHeaderError(true);
      }
    }
  };

  const handleCancel = () => {
    if (!form.getFieldValue('headers_attributes')) form.setFieldValue('headers_attributes', []);
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const handleHeaders = (headerList: AnalyticsWebhookHeaders) => {
    const initialHeaders = initialValues.headers_attributes;
    const formHeaders = new Map();
    headerList.forEach(item => formHeaders.set(item.id, item));

    const headers = initialHeaders.map(item => {
      const formItem = formHeaders.get(item.id);
      if (formItem) {
        return { ...item, ...formItem };
      } else {
        return { ...item, _destroy: true };
      }
    });

    headerList.forEach(item => {
      if (!item.id) {
        headers.push({ key: item.key, value: item.value });
      }
    });

    return headers;
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    if (initialValues.headers_attributes.length || formData.headers_attributes.length)
      formData.headers_attributes = handleHeaders(formData.headers_attributes);
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={validateMessages}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={checkHeaderError}
    >
      <Form.Item name="enabled" valuePropName="checked" label={t('enabled')}>
        <Switch />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Form.Item
        name="url"
        label={t('url')}
        className={formItemClass.base}
        rules={[{ type: 'url', required: true }]}
      >
        <Input placeholder={t('enter_url')} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('basic_auth')}
      </Title>
      <Space>
        <Form.Item name="username" label={t('username')} className={formItemClass.base}>
          <Input placeholder={t('enter_username')} />
        </Form.Item>
        <Form.Item name="password" label={t('password')} className={formItemClass.base}>
          <PasswordInput placeholder={t('enter_password')} />
        </Form.Item>
      </Space>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('headers')}
      </Title>
      <Form.List name="headers_attributes">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space key={field.key}>
                <Form.Item
                  name={[index, 'key']}
                  label={t('key')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={() => index === fields.length - 1 && checkHeaderError()}
                    placeholder={t('enter_key')}
                  />
                </Form.Item>
                <InputWithRemove
                  name={[index, 'value']}
                  onClick={() => {
                    remove(index);
                    checkHeaderError();
                  }}
                  placeholder={t('enter_value')}
                  label={t('value')}
                  required
                  onChange={() => index === fields.length - 1 && checkHeaderError()}
                />
                <Form.Item name={[index, 'id']} noStyle>
                  <Input type="hidden" />
                </Form.Item>
              </Space>
            ))}
            <AddField
              onClick={add}
              buttonText={t('add_header')}
              moveUp={fields.length > 0 && !isHeaderError}
            />
          </>
        )}
      </Form.List>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
