import { App, Divider } from 'antd';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUpdate } from '../../../../hooks/useUpdate';
import { AccountsService } from '../../../../services/client-account';
import { DisableConfirmation } from '../../../../components/DisableConfirmation';
import { unexpectedError } from '../../../../utils/notification';

export const AccountControl: React.FC = () => {
  const { t } = useTranslation('account_settings');
  const { notification } = App.useApp();
  const { id } = useParams() as { id: string };

  const { mutate: disableItem } = useUpdate<{
    id: string;
  }>([AccountsService.queryKey, id], AccountsService.disable);

  const disableAccount = async () => {
    await disableItem(
      { id },
      {
        onSuccess: data => {
          notification['success']({
            message: <Trans t={t}>{t('account_updated_notify', { name: data.name })}</Trans>,
          });
        },
        onError: () => notification['error'](unexpectedError),
      },
    );
  };

  return (
    <StyledAccountControl>
      <Divider />
      <p>
        <Trans t={t}>{t('account_disable_desc')}</Trans>
      </p>
      <DisableConfirmation
        onDisable={disableAccount}
        description={t('modal_disable_account_desc')}
        title={t('modal_disable_account')}
        buttonText={t('account_disable')}
        confirmationText={t('disable')}
      />
    </StyledAccountControl>
  );
};

const StyledAccountControl = styled.div`
  margin-top: 20px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`;
