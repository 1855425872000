import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/es/upload';

export const normalizeFile = (e: UploadChangeParam) => (Array.isArray(e) ? e : e?.fileList);

export const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  });
};

export const getGCPKeyfile = async (fileList: UploadFile[]): Promise<string | undefined> => {
  if (fileList.length && fileList[0].originFileObj) {
    const url = await getBase64(fileList[0].originFileObj as RcFile);
    return url.split(',')[1];
  }
};

export const downloadFile = (data: ArrayBuffer, type: string): void => {
  const blob = new Blob([data], { type: type });
  const url = window.URL.createObjectURL(blob);
  const pwa = window.open(url);

  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    alert('Please disable your Pop-up blocker and try again.');
  }
};

const b64DecodeUnicode = (str: string): string => {
  try {
    return decodeURIComponent(
      Array.from(
        atob(str),
        (char: string) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2),
      ).join(''),
    );
  } catch (e) {
    return '';
  }
};

const isJSON = (json: string): boolean => {
  try {
    const obj = JSON.parse(json);
    if (obj && typeof obj === 'object') return true;
    return false;
  } catch (e) {
    return false;
  }
};

export const getKeyfileProjectId = (keyfile: string): string | undefined => {
  try {
    return isJSON(keyfile)
      ? JSON.parse(keyfile).project_id
      : JSON.parse(b64DecodeUnicode(keyfile))?.project_id;
  } catch (e) {
    return undefined;
  }
};
